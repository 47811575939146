import { defineMessages } from "react-intl";

export default defineMessages({
  addPeriod: {
    id: "AddModalPeriodCropsTable.addPeriod",
    defaultMessage: "Add new period",
  },
  editPeriod: {
    id: "AddModalPeriodCropsTable.editPeriod",
    defaultMessage: "Edit period",
  },
  stageName: {
    id: "AddModalPeriodCropsTable.stageName",
    defaultMessage: "Stage Name",
  },
  stageUnnamed: {
    id: "AddModalPeriodCropsTable.stageUnnamed",
    defaultMessage: "Stage Name",
  },
  cropHeight: {
    id: "AddModalPeriodCropsTable.cropHeight",
    defaultMessage: "Crop Height (m)",
  },
  startDegreeDay: {
    id: "AddModalPeriodCropsTable.startDegreeDay",
    defaultMessage: "Start Degree Day",
  },
  endDegreeDay: {
    id: "AddModalPeriodCropsTable.endDegreeDay",
    defaultMessage: "End Degree Day",
  },
  dateStart: {
    id: "AddModalPeriodCropsTable.dateStart",
    defaultMessage: "Start Date",
  },
  dateEnd: {
    id: "AddModalPeriodCropsTable.dateEnd",
    defaultMessage: "End Date",
  },
  chooseUnit: {
    id: "AddModalPeriodCropsTable.chooseUnit",
    defaultMessage: "Choose the unit",
  },
  crop_coefficient: {
    id: "AddModalPeriodCropsTable.crop_coefficient",
    defaultMessage: "Cultural Coefficient",
  },
  help_crop_coefficient: {
    id: "AddModalPeriodCropsTable.help_crop_coefficient",
    defaultMessage: "Coefficient is used for water balance, the default value is 0.6, which is equivalent to grassland. (Source: FAO)",
  },
});
