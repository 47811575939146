import axios, { AxiosRequestConfig } from "axios";
import { removeAccessToken } from "store/user/services/tokenService";
import { ROUTES } from "constants/routes";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      removeAccessToken();
      location.replace(ROUTES.LOGIN);
    }
    return Promise.reject(error);
  }
);

const api = (props: AxiosRequestConfig) =>
  axiosInstance(props).then((response) => response.data);

const withMethod = (method: string) => (props: AxiosRequestConfig) =>
  api({
    method,
    ...props,
  });

export const apiGET = withMethod("GET");
export const apiPOST = withMethod("POST");
export const apiPUT = withMethod("PUT");
export const apiPATCH = withMethod("PATCH");
export const apiDELETE = withMethod("DELETE");

export const setHeader = (name: string, value: string) => {
  axiosInstance.defaults.headers.common[name] = value;
};

export const removeHeader = (name: string) => {
  delete axiosInstance.defaults.headers.common[name];
};

export default api;
