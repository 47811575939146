import { defineMessages } from "react-intl";

export default defineMessages({
  simulationSetting: {
    id: "WaterBalanceCreateMessages.simulationSetting",
    defaultMessage: "Simulation Setting",
  },
  runSimulation: {
    id: "WaterBalanceCreateMessages.runBatchSimulation",
    defaultMessage: "Run Simulation",
  },
  description: {
    id: "WaterBalanceCreateMessages.description",
    defaultMessage: "Description",
  },
  resolution: {
    id: "WaterBalanceCreateMessages.resolution",
    defaultMessage: "Resolution",
  },
  simulationFrequency: {
    id: "WaterBalanceCreateMessages.simulationFrequency",
    defaultMessage: "Simulation Frequency",
  },
  maxScale: {
    id: "WaterBalanceCreateMessages.maxScale",
    defaultMessage: "Max Scale Value",
  },
  setMaxPlot: {
    id: "WaterBalanceCreateMessages.setMaxPlot",
    defaultMessage: "Set max plot scale value",
  },
  waterBalanceName: {
    id: "WaterBalanceCreateMessages.waterBalanceName",
    defaultMessage: "Water Balance Name",
  },
  waterBalanceUnnamed: {
    id: "WaterBalanceCreateMessages.waterBalanceUnnamed",
    defaultMessage: "Water Balance Unnamed",
  },
});
