import { defineMessages } from "react-intl";

export default defineMessages({
  hour: {
    id: "SelectMessages.hour",
    defaultMessage: "{value} hour",
  },
  hours: {
    id: "SelectMessages.hours",
    defaultMessage: "{value} hours",
  },
  minutes: {
    id: "SelectMessages.minutes",
    defaultMessage: "{value} minutes",
  },
  weather: {
    id: "SelectMessages.weather",
    defaultMessage: "Weather",
  },
  production: {
    id: "SelectMessages.production",
    defaultMessage: "Production",
  },
  etp: {
    id: "SelectMessages.etp",
    defaultMessage: "Evapotranspiration",
  },
  dates: {
    id: "SelectMessages.dates",
    defaultMessage: "Dates",
  },
  degreeDays: {
    id: "SelectMessages.degreeDates",
    defaultMessage: "Degree Days",
  },
  admin: {
    id: "SelectMessages.admin",
    defaultMessage: "Admin",
  },
  manager: {
    id: "SelectMessages.manager",
    defaultMessage: "Manager",
  },
  user: {
    id: "SelectMessages.user",
    defaultMessage: "User",
  },
});
