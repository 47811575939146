import { object, string, ref } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";

export const password_schema = (intl: IntlShape) =>
  string()
    .min(6, intl.formatMessage(validationMessages.minLength, { number: 6 }))
    .required(intl.formatMessage(validationMessages.required));

export const username_schema = (intl: IntlShape) =>
  string()
    .max(50, intl.formatMessage(validationMessages.maxLength, { number: 50 }))
    .required(intl.formatMessage(validationMessages.required));

const RegisterSchema = (intl: IntlShape) =>
  object().shape({
    username: username_schema(intl),
    password: password_schema(intl),
    confirm_password: string()
      .oneOf(
        [ref("password"), null],
        intl.formatMessage(validationMessages.passwordNotMatch)
      )
      .required(intl.formatMessage(validationMessages.required)),
  });

export default RegisterSchema;
