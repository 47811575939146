import { defineMessages } from "react-intl";

export default defineMessages({
  inviteNewUser : {
    id             : "InviteUserModalMessages.inviteNewUser",
    defaultMessage : "Invite New User",
  },
  emailLabel : {
    id             : "InviteUserModalMessages.emailLabel",
    defaultMessage : "Email",
  },
  emailPlaceholder : {
    id             : "InviteUserModalMessages.emailPlaceholder",
    defaultMessage : "Enter email",
  },
  roleLabel : {
    id             : "InviteUserModalMessages.roleLabel",
    defaultMessage : "Select Role",
  },
  sendInvite : {
    id             : "InviteUserModalMessages.sendInvite",
    defaultMessage : "Send Invite",
  },
});
