import React from "react";
import { Modal, ModalProps } from "react-bootstrap";
import { ReactComponent as Close } from "assets/icons/close.svg";
import styled from "./styled.module.scss";

interface IModalContainer extends ModalProps {
  show: boolean;
  title: string;
  subTitle?: string;
  onHide: () => void;
  children: React.ReactNode;
}

const ModalContainer = ({ show, onHide, title, subTitle, children, ...rest }: IModalContainer) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      {...rest}
    >
      <div className={styled.container}>
        <button onClick={onHide} className={styled.closeBtn}>
          <Close />
        </button>
        <div className={styled.textContainer}>
          <h1 className={styled.title}>{title}</h1>
          <p className={styled.subTitle}>{subTitle}</p>
        </div>

        {children}
      </div>
    </Modal>
  );
};

export default ModalContainer;
