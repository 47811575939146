import { defineMessages } from "react-intl";

export default defineMessages({
  editProject : {
    id             : "DatasetsMessages.editProject",
    defaultMessage : "Edit project",
  },
  datasetTypeWeatherProject : {
    id             : "DatasetsMessages.datasetTypeWeatherProject",
    defaultMessage : "Weather Dataset",
  },
  datasetTypeElectricalProductionProject : {
    id             : "DatasetsMessages.datasetTypeElectricalProductionProject",
    defaultMessage : "Electrical Production Datasets",
  },
  datasetTypeETPProject : {
    id             : "DatasetsMessages.datasetTypeETPProject",
    defaultMessage : "Evapotranspiration Datasets",
  },
  datasetTypeClimaticProject : {
    id             : "DatasetsMessages.datasetTypeClimaticProject",
    defaultMessage : "Climatic Dataset",
  },
  waitingText : {
    id             : "DatasetsMessages.waitingText",
    defaultMessage : "It may take up to a minute...",
  },
  creating : {
    id             : "DatasetsMessages.creating",
    defaultMessage : "Dataset creation in progress.",
  },
});
