import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";
import React, { useEffect, useState } from "react";
import tokenService from "store/user/services/tokenService";
import { Loader } from "components/common/loader/Loader";

export default function withAuthProtection<T>(Component: React.ElementType) {
  return function WithLoggedGuard(props: T) {
    const [isAuth, setIsAuth] = useState(false);
    const navigate = useNavigate();

    const getToken = async () => {
      const token = await tokenService.storage.get();
      if (!token) navigate(ROUTES.LOGIN);
      await tokenService.headers.set(token);
      setIsAuth(true);
    };

    useEffect(() => {
      getToken();
    }, []);

    if (!isAuth) {
      return <Loader />;
    }
    return <Component {...props} />;
  };
}
