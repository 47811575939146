import React from "react";
import styled from "./styled.module.scss";
import clsx from "clsx";
import { getTrackBackground, Range } from "react-range";

interface IInputRange {
  label?: string;
  className?: string;
  min: number;
  max: number;
  step: number;
  onChange: (values: number[]) => void;
  values: number[];
  disabled?: boolean
}

const InputRange = ({ label, className, disabled = false, ...rest }: IInputRange) => {
  return (
    <div className={clsx(styled.container, className, {
      [styled.disabled]: disabled
    })}>
      {!!label && <label className={styled.label}>{label}</label>}
      <Range
        {...rest}
        disabled={disabled}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            className={clsx(styled.trackContainer, {
              [styled.disabled]: disabled
            })}
          >
            <div
              ref={props.ref}
              style={{
                height: "6px",
                width: "100%",
                background: getTrackBackground({
                  colors: ["#56A179", "#E3F9F0"],
                  ...rest,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props }) => (
          <div {...props} className={clsx(styled.thumbContainer, {
      [styled.disabled]: disabled
    })} style={{position: 'static'}}>
            <span className={clsx(styled.line, {
      [styled.disabled]: disabled
    })} />
            <span className={clsx(styled.line, {
      [styled.disabled]: disabled
    })} />
            <span className={clsx(styled.line, {
      [styled.disabled]: disabled
    })} />
            <span className={clsx(styled.line, {
      [styled.disabled]: disabled
    })} />
            <div className={clsx(styled.tooltip, {
      [styled.disabled]: disabled
    })}>{rest.values[0]}</div>
          </div>
        )}
      />
    </div>
  );
};

export default InputRange;
