import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { deleteUser as action } from "store/users/actions";
// api
import { deleteUser as api } from "store/users/api";
import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload: { callback, ...payload } }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success(response.data));

    if (callback) callback();
  } catch (error) {
    yield call(errorNotifications, error.response.data.data);
    yield put(action.failure(error));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* deleteUser() {
  yield takeLatest(action.TRIGGER, saga);
}
