import routine from "store/services/routine";

export const getDatasets = routine("GET_DATASETS");
export const createDataset = routine("CREATE_DATASET");
export const getDatasetAnalysis = routine("GET_DATASET_ANALYSIS");
export const createDatasetFromAPI = routine("CREATE_DATASET_FROM_API");
export const createDatasetFromOpen = routine("CREATE_DATASET_FROM_OPEN");
export const copyDataset = routine("COPY_DATASET");
export const deleteDataset = routine("DELETE_DATASET");
export const getTimezones = routine("GET_TIMEZONES");
export const getSingleDataset = routine("GET_SINGLE_DATASETS");
export const updateDataset = routine("UPDATE_DATASETS");
export const setLoading = routine("SET_LOADING");
