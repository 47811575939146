import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { setPassword as action } from "store/user/actions";
// api
import { setPassword as api } from "store/user/api";
import { errorNotifications } from "utils/errorNotifications";
import { setAccessToken } from "store/user/services/tokenService";

function* saga({ payload: { callback, ...payload } }) {
  try {
    yield put(action.request());

    const { access_token, user } = yield call(api, payload);

    yield call(setAccessToken, access_token);

    yield put(
      action.success({
        token: access_token,
        user,
      })
    );

    if (callback) callback(user);
  } catch (error) {
    yield call(errorNotifications, error.response.data.data);
    yield put(action.failure(error));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* setPassword() {
  yield takeLatest(action.TRIGGER, saga);
}
