import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { auth as action } from "store/user/actions";
// api
import { auth as api } from "store/user/api";
// services
import { getAccessToken } from "store/user/services/tokenService";

function* saga({ payload }) {
  try {
    yield put(action.request());

    yield call(getAccessToken);

    const response = yield call(api, payload);

    yield put(action.success(response.data));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(action.fulfill());
  }
}

export default function* auth() {
  yield takeLatest(action.TRIGGER, saga);
}
