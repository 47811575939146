import React from "react";
import styled from "./styled.module.scss";
import { getInitials } from "utils/getInitials";
import CardMenu from "components/CardMenu/CardMenu";
import clientCardMessages from "components/ClientCard/ClientCardMessages";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { ROUTES } from "constants/routes";
import moment from "moment/moment";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";

export interface IClient {
  name: string;
  projects_number: number;
  updated_at: string;
  created_at: string;
  description: string;
  email: string;
  id: number;
}

interface IClientCard {
  client: IClient;
  onEdit: () => void;
  onDelete: () => void;
}

const ClientCard = ({ client, onEdit, onDelete }: IClientCard) => {
  return (
    <div className={styled.container}>
      <div className={styled.content}>
          <div className={styled.initials}>{getInitials(client.name || "new user")}</div>
        <div>
          <Link
            className={styled.name}
            to={ROUTES.PROJECTS_LIST.replace(":client_id", String(client.id))}
          >
            {client.name}
          </Link>

          <span className={styled.projects}>
            {client.projects_number ?? 0}{" "}
            <FormattedMessage {...clientCardMessages.projects} />
          </span>
        </div>

        <span className={styled.date}>
          <FormattedMessage {...clientCardMessages.lastUpdate} />
          {": "}
          {moment.utc(client.updated_at).local().fromNow()}
        </span>
      </div>
      <CardMenu onEdit={onEdit} onDelete={onDelete} />
    </div>
  );
};

export default ClientCard;
