import { object, string } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";

const InviteUserSchema = (intl: IntlShape) =>
  object().shape({
    email: string()
      .email()
      .required(intl.formatMessage(validationMessages.required)),
    role: string().required(intl.formatMessage(validationMessages.required)),
  });

export default InviteUserSchema;
