import routine from 'store/services/routine';

export const getSimulations = routine('GET_SIMULATIONS');
export const getSimulation = routine('GET_SIMULATION');
export const resetSimulation = routine('RESET_SIMULATION');
export const createSimulation = routine('CREATE_SIMULATION');
export const copySimulation = routine('COPY_SIMULATION');
export const deleteSimulation = routine('DELETE_SIMULATION');
export const updateSimulation = routine('UPDATE_SIMULATION');
export const runSimulation = routine('RUN_SIMULATION');
export const cancelSimulation = routine('CANCEL_SIMULATION');
export const getSimulationResult = routine('GET_SIMULATION_RESULT');
export const deleteSimulationResult = routine('DELETE_SIMULATION_RESULT');
export const resetSimulationResult = routine('RESET_SIMULATION_RESULT');
