import { object, string, mixed } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";

const DatasetEditSchema = (intl: IntlShape) =>
  object().shape({
    name: string()
      .max(50, intl.formatMessage(validationMessages.maxLength, { number: 50 }))
      .required(intl.formatMessage(validationMessages.required)),
    dataset_type: string().required(
      intl.formatMessage(validationMessages.required)
    ),
    timezone: string().required(
      intl.formatMessage(validationMessages.required)
    ),
    frequency: string().required(
      intl.formatMessage(validationMessages.required)
    ),
    dataset_filename: string().required(
      intl.formatMessage(validationMessages.required)
    ),
    upload: mixed(),
    comment: string().nullable(),
  });

export default DatasetEditSchema;
