import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getDatasets = ({ clientId, projectId, type }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/datasets`,
    params: { type: type },
  });

export const createDataset = ({ clientId, projectId, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/datasets`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const createDatasetFromAPI = ({ clientId, projectId, data }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/datasets/meteonorm`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const createDatasetFromOpen = ({ clientId, projectId, year }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/datasets/openmeteo/${year}`,
  });

export const copyDataset = ({ clientId, projectId, datasetId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/datasets/${datasetId}/copy`,
  });

export const copyDatasetToOtherProject = ({
  clientId,
  projectId,
  datasetId,
  projectToId,
}) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/datasets/${datasetId}/copy/${projectToId}`,
  });

export const deleteDataset = ({ clientId, projectId, datasetId }) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}/datasets/${datasetId}`,
  });

export const getTimezones = () =>
  apiGET({
    url: `/extra/timezones`,
  });

export const getSingleDataset = ({ clientId, projectId, dataset_id }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/datasets/${dataset_id}`,
  });

export const getDatasetFile = ({ clientId, projectId, dataset_id }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/datasets/${dataset_id}/download`,
  });

export const updateDataset = ({ clientId, projectId, dataset_id, ...data }) =>
  apiPATCH({
    url: `/clients/${clientId}/projects/${projectId}/datasets/${dataset_id}`,
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const getDatasetDailyChart = ({ clientId, projectId, dataset_id }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/datasets/${dataset_id}/daily_chart`,
  });

export const getDatasetMonthlyChart = ({ clientId, projectId, dataset_id }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/datasets/${dataset_id}/monthly_chart`,
  });

export const getDatasetAnalysis = ({ clientId, projectId, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/canarii`,
    data,
  });
