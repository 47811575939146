export const getSimulationsState = (state) => state.simulations;
export const getSimulationsListState = (state) => getSimulationsState(state).list;
export const getSimulationsLoadingState = (state) => getSimulationsState(state).isLoading;
export const getSimulationItemState = (state) => getSimulationsState(state).single;
export const getSimulationResultState = (state) => getSimulationsState(state).result;

export const getSimulationsById = (projectId) => (state) => {
  const list = getSimulationsState(state).list;
  return list.find(({ id }) => id.toString() === projectId?.toString())
}
