import React, { useEffect } from "react";
import Button from "components/common/button/Button";
import styled from "./styled.module.scss";
import { useFormik } from "formik";
import Input from "components/common/input/Input";
import ModalContainer from "components/ModalContainer/ModalContainer";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import AddPointSchema from "components/AddPointModal/AddPointSchema";
import addPointModalMessages from "components/AddPointModal/AddPointModalMessages";

interface IAddPointModal {
  show: boolean;
  onHide: () => void;
  onSave: (v: IAddPointForm) => void;
  initialValues?: IAddPointForm;
}

export interface IAddPointForm {
  name: string;
  x: number;
  y: number;
}

const getDefaultFormValue = (intl: any): IAddPointForm => {
  return { name: intl.formatMessage(addPointModalMessages.pointUnnamed), x: 0, y: 0 };
};

const AddPointModal = ({
  show,
  onHide,
  onSave,
  initialValues,
}: IAddPointModal) => {
  const intl = useIntl();
  const onSubmit = (values: IAddPointForm) => {
    onSave(values);
  };

  const getInputProps = (key: keyof IAddPointForm) => ({
    name: key,
    value: formik.values[key] as string | number,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    errorMessage: formik.touched[key] ? (formik.errors[key] as string) : "",
  });

  const formik = useFormik<IAddPointForm>({
    validationSchema: AddPointSchema(intl),
    onSubmit,
    initialValues: getDefaultFormValue(intl),
  });

  useEffect(() => {
    if (initialValues?.name)
      formik.setValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (!show) formik.resetForm();
  }, [show]);

  return (
    <ModalContainer
      show={show}
      onHide={onHide}
      title={
        formik.values.name === ""
          ? intl.formatMessage(addPointModalMessages.addPoint)
          : intl.formatMessage(addPointModalMessages.editPoint)
      }
    >
      <form className={styled.form} onSubmit={formik.handleSubmit}>
        <Input
          label={intl.formatMessage(addPointModalMessages.pointName)}
          {...getInputProps("name")}
          autoFocus={!initialValues}
          onFocus={event => {event.target.select()}}
        />

        <div className={styled.inputRow}>
          <Input
            type="number"
            beforeIcon="X"
            label={intl.formatMessage(addPointModalMessages.coordinates)}
            {...getInputProps("x")}
          />

          <Input type="number" beforeIcon="Y" {...getInputProps("y")} />
        </div>

        <div className={styled.row}>
          <Button type="submit">
            <FormattedMessage {...commonMessages.add} />
          </Button>
          <Button variant="text" onClick={onHide}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default AddPointModal;
