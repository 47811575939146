import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import datasetsMessages from "./DatasetsMessages";
import Button from "components/common/button/Button";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { DatasetsCard } from "components/DatasetsCard/DatasetsCard";
import styled from "./Datasets.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getDatasets,
  deleteDataset,
  copyDataset,
  createDatasetFromOpen,
} from "store/datasets/actions";
import {
  getDatasetList,
  isLoadingCopy,
  isLoadingDatasets,
} from "store/datasets/selectors";
import { ROUTES } from "constants/routes";
import MainContent from "components/MainContent/MainContent";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import { IDataset } from "./DatasetsType";
import { getProjectsListState } from "store/projects/selectors";
import { useOptionData } from "hooks/useOptionData";
import { Modal } from "react-bootstrap";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import CommonMessages from "components/common/CommonMessages";
import Select from "components/common/select/Select";
import { copyDatasetToOtherProject } from "store/datasets/api";
import { createDatasetFromAPI } from "store/datasets/actions";
import { successNotifications } from "utils/successNotifications";
import { Years } from "./DatasetYears";

function Datasets() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client_id, project_id } = useParams();
  const datasets = useSelector(getDatasetList);
  const isLoading = useSelector(isLoadingCopy);
  const isLoadingDownload = useSelector(isLoadingDatasets);
  const [show, onShow] = useState(false);
  const [currentId, setCurrentId] = useState(NaN);
  const [copyToOtherProjectId, setCopyToOtherProjectId] = useState<
    number | null
  >(null);
  const projectList = useSelector(getProjectsListState);
  const projectOptions = useOptionData(projectList);
  const yearsList = useOptionData(Years);
  const [projectId, setProjectId] = useState(0);
  const [loaderDownload, setLoaderDownload] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [openMeteoYear, setOpenMeteoYear] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    dispatch(
      getDatasets({
        clientId: client_id,
        projectId: project_id,
        type: ["weather", "production", "ETP", "universal"],
      })
    );
  }, [client_id, project_id]);

  const onDeleteDataset = () => {
    dispatch(
      deleteDataset({
        clientId: client_id,
        projectId: project_id,
        datasetId: currentId,
        callback: () => onShow(false),
      })
    );
  };

  const onShowWarning = (id: number) => {
    setCurrentId(id);
    onShow(true);
  };

  const onCopy = (id: string | number) => () => {
    dispatch(
      copyDataset({
        clientId: client_id,
        projectId: project_id,
        datasetId: id,
      })
    );
  };

  const onSubmit = async () => {
    try {
      setLoaderDownload(true);
      dispatch(
        createDatasetFromAPI({
          clientId: client_id,
          projectId: project_id,
          callback: () => {
            successNotifications({
              title: intl.formatMessage(CommonMessages.create),
              message: intl.formatMessage(
                CommonMessages.successCreateDatasets,
                { objet_type: intl.formatMessage(CommonMessages.dataset) }
              ),
            });
          },
        })
      );
    } catch (err) {
      console.log(err);
      setLoaderDownload(false);
    } finally {
      setLoaderDownload(false);
      setIsDownloading(false);
    }
  };

  const onSubmitYearly = async () => {
    try {
      setLoaderDownload(true);
      dispatch(
        createDatasetFromOpen({
          clientId: client_id,
          projectId: project_id,
          year: openMeteoYear,
          callback: () => {
            successNotifications({
              title: intl.formatMessage(CommonMessages.create),
              message: intl.formatMessage(
                CommonMessages.successCreateDatasets,
                { objet_type: intl.formatMessage(CommonMessages.dataset) }
              ),
            });
          },
        })
      );
    } catch (err) {
      console.log(err);
      setLoaderDownload(false);
    } finally {
      setLoaderDownload(false);
      setIsDownloading(false);
      setOpenMeteoYear(null);
    }
  };

  return (
    <MainContent
      header={
        <>
          <h2 className={styled.title}>
            <FormattedMessage {...commonMessages.datasets} />
          </h2>

          <div className={styled.choices}>
            <Button
              iconBefore={<Plus />}
              type="submit"
              onClick={() => setIsDownloading(true)}
              isLoading={loaderDownload || isLoadingDownload}
              disabled={loaderDownload || isLoadingDownload}
            >
              <FormattedMessage {...commonMessages.DownloadData} />
            </Button>

            <Button
              iconBefore={<Plus />}
              onClick={() =>
                navigate(
                  ROUTES.DATASETS_CREATE.replace(
                    ":client_id",
                    client_id as string
                  ).replace(":project_id", project_id as string)
                )
              }
            >
              <FormattedMessage {...commonMessages.createNew} />
            </Button>
          </div>
        </>
      }
    >
      <div className={styled.list}>
        {/* <h3 className={styled["datasets-type"]}>
          <FormattedMessage {...datasetsMessages.datasetTypeWeatherProject} />
        </h3>
        {datasets.map((card: IDataset) => (
          <DatasetsCard
            isShow={card.dataset_type == "weather"}
            key={card.id}
            content={card}
            onDelete={() => onShowWarning(card.id)}
            onCopy={onCopy(card.id)}
            onEdit={() =>
              navigate(
                ROUTES.DATASETS_EDIT.replace(":client_id", client_id as string)
                  .replace(":project_id", project_id as string)
                  .replace(":id", String(card.id))
              )
            }
            onCopyToOtherProject={() => {
              setCopyToOtherProjectId(card.id);
            }}
            isLoading={isLoading}
          />
        ))} */}
        {/* <h3 className={styled["datasets-type"]}>
          <FormattedMessage {...datasetsMessages.datasetTypeETPProject} />
        </h3>
        {datasets.map((card: IDataset) => (
          <DatasetsCard
            isShow={card.dataset_type === "ETP"}
            content={card}
            key={card.id}
            onDelete={() => onShowWarning(card.id)}
            onCopy={onCopy(card.id)}
            onEdit={() =>
              navigate(
                ROUTES.DATASETS_EDIT.replace(":client_id", client_id as string)
                  .replace(":project_id", project_id as string)
                  .replace(":id", String(card.id))
              )
            }
            onCopyToOtherProject={() => {
              setCopyToOtherProjectId(card.id);
            }}
            isLoading={isLoading}
          />
        ))} */}

        <h3 className={styled["datasets-type"]}>
          <FormattedMessage {...datasetsMessages.datasetTypeClimaticProject} />
        </h3>
        {datasets.map((card: IDataset) => (
          <DatasetsCard
            isShow={card.dataset_type == "universal"}
            key={card.id}
            content={card}
            onDelete={() => onShowWarning(card.id)}
            onCopy={onCopy(card.id)}
            onEdit={() =>
              navigate(
                ROUTES.DATASETS_EDIT.replace(":client_id", client_id as string)
                  .replace(":project_id", project_id as string)
                  .replace(":id", String(card.id))
              )
            }
            onCopyToOtherProject={() => {
              setCopyToOtherProjectId(card.id);
            }}
            isLoading={isLoading}
          />
        ))}
        {loaderDownload || isLoadingDownload ? (
          <div className={styled.loadingCardContainer}>
            <div className={styled.loadingCard}>
              <div className={styled.loadingFlex}>
                <Icon
                  name={ICON_NAMES_ENUM.dataset_weather}
                  className={styled.loadingIcon}
                />
                <p className={styled.loadingName}>
                  <FormattedMessage {...datasetsMessages.creating} />
                </p>
              </div>
              <div className={styled.loaderContainer}>
                <span className={styled.loader}></span>
                <p className={styled.waitingText}>
                  {" "}
                  <FormattedMessage {...datasetsMessages.waitingText} />{" "}
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <LeaveModalWindow
          type={LeaveModalEnum.DELETE}
          onDelete={onDeleteDataset}
          show={show}
          onHide={() => onShow(false)}
        />
        <Modal
          show={copyToOtherProjectId != null}
          onHide={() => setCopyToOtherProjectId(null)}
          centered
        >
          <div className={styled.container}>
            <Icon name={ICON_NAMES_ENUM.warning} />
            <h3 className={styled.modal_title}>
              <FormattedMessage {...CommonMessages.copy_to_other_project} />
            </h3>
            <Select
              label={"Select project"}
              options={projectOptions}
              onChange={(e: any) => {
                setProjectId(e.value);
              }}
            />
            <div className={styled.row}>
              <Button
                onClick={() => {
                  copyDatasetToOtherProject({
                    clientId: client_id,
                    projectId: project_id,
                    datasetId: copyToOtherProjectId,
                    projectToId: projectId,
                  }).then(() => {
                    setCopyToOtherProjectId(null);
                  });
                }}
              >
                Copy
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  setCopyToOtherProjectId(null);
                }}
              >
                <FormattedMessage {...CommonMessages.cancel} />
              </Button>
            </div>
          </div>
        </Modal>
      </div>

      <Modal
        show={isDownloading}
        onHide={() => {
          setIsDownloading(false);
          setOpenMeteoYear(null);
        }}
        centered
      >
        <section>
          <div className={styled.modalContent}>
            <h3>
              <FormattedMessage {...commonMessages.downloadTitle} />
            </h3>
            <div className={styled.choiceButton}>
              <div className={styled.meteonormContainer}>
                <Button
                  type="submit"
                  onClick={onSubmit}
                  isLoading={loaderDownload || isLoadingDownload}
                  disabled={loaderDownload || isLoadingDownload}
                >
                  <FormattedMessage {...commonMessages.MeteonormTMYData} />
                </Button>
                <ul>
                  <li>
                    {" "}
                    <FormattedMessage
                      {...commonMessages.meteonormTypical}
                    />{" "}
                  </li>
                </ul>
              </div>
              <div className={styled.openMetoContainer}>
                <Button
                  onClick={onSubmitYearly}
                  isLoading={loaderDownload || isLoadingDownload}
                  disabled={openMeteoYear === null}
                >
                  <FormattedMessage {...commonMessages.OpenMeteoData} />
                </Button>
                <Select
                  label={"Select year"}
                  options={yearsList.reverse()}
                  onChange={(e: any) => setOpenMeteoYear(e.value)}
                />
              </div>
            </div>
          </div>
        </section>
      </Modal>
    </MainContent>
  );
}

export { Datasets };
