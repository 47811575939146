import { defineMessages } from "react-intl";

export default defineMessages({
  settings : {
    id             : "SettingsLayoutMessages.settings",
    defaultMessage : "Settings",
  },
  mainSettings : {
    id             : "SettingsLayoutMessages.mainSettings",
    defaultMessage : "Main Settings",
  },
  userManagement : {
    id             : "SettingsLayoutMessages.userManagement",
    defaultMessage : "User Management",
  },
});
