import {
  deleteBatchWaterBalance,
  getBatchesWaterBalance,
  copyBatchWaterBalance,
  createBatchWaterBalance,
  updateBatchWaterBalance,
  getBatchWaterBalanceParametersOfInterest,
  runBatchWaterBalanceSimulation,
  getBatchWaterBalanceResults,
  getBatchWaterBalanceSimulationResult,
  cancelBatchWaterBalanceSimulation,
  resetBatchWaterBalanceResults,
  resetBatchWaterBalanceSimulationResult,
} from "store/batches_water_balance/actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  list: [],
  parameters: [],
  batchWaterBalanceResults: null,
  simulationResult: null,
};

const batchesWaterBalanceReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case getBatchesWaterBalance.TRIGGER:
    case runBatchWaterBalanceSimulation.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getBatchesWaterBalance.SUCCESS: {
      return {
        ...state,
        list: payload,
      };
    }

    case createBatchWaterBalance.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }

    case copyBatchWaterBalance.SUCCESS: {
      const newBatch = {
        ...payload,
        rows_number: payload.rows.length,
      };
      return {
        ...state,
        list: [...state.list, newBatch],
      };
    }

    case deleteBatchWaterBalance.SUCCESS: {
      const list = state.list.filter(({ id }) => id !== payload.id);
      return {
        ...state,
        list,
      };
    }

    case updateBatchWaterBalance.SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = payload;

      return {
        ...state,
        list: newList,
      };
    }

    case getBatchWaterBalanceParametersOfInterest.SUCCESS: {
      return {
        ...state,
        parameters: payload,
      };
    }

    case runBatchWaterBalanceSimulation.SUCCESS:
    case cancelBatchWaterBalanceSimulation.SUCCESS:
    case getBatchWaterBalanceResults.SUCCESS: {
      return {
        ...state,
        batchResults: payload,
      };
    }

    case resetBatchWaterBalanceResults.TRIGGER: {
      return {
        ...state,
        batchResults: INITIAL_STATE.batchResults,
      };
    }

    case getBatchWaterBalanceSimulationResult.SUCCESS: {
      return {
        ...state,
        simulationResult: payload,
      };
    }

    case resetBatchWaterBalanceSimulationResult.TRIGGER: {
      return {
        ...state,
        simulationResult: INITIAL_STATE.simulationResult,
      };
    }

    case getBatchesWaterBalance.FULFILL:
    case runBatchWaterBalanceSimulation.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default batchesWaterBalanceReducer;
