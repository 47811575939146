import { object, string, number, boolean } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";

const WaterBalanceSchema = (intl: IntlShape) =>
  object().shape({
    name: string()
      .max(50, intl.formatMessage(validationMessages.maxLength, { number: 50 }))
      .matches(/^[a-zA-Z0-9-àéèçù_ ]*$/, intl.formatMessage(validationMessages.invalidCharacters))
      .required(intl.formatMessage(validationMessages.required)),
    resolution: number()
      .integer(intl.formatMessage(validationMessages.integer))
      .min(1, intl.formatMessage(validationMessages.minValue, { number: 1 }))
      .required(intl.formatMessage(validationMessages.required)),
    description: string().max(
      250,
      intl.formatMessage(validationMessages.maxLength, { number: 250 })
    ),
    frequency: string().required(
      intl.formatMessage(validationMessages.required)
    ),
    max_scale_value: number()
      .integer(intl.formatMessage(validationMessages.integer))
      .required(intl.formatMessage(validationMessages.required)),
    enable_max_scale: boolean(),
    structure_id: number()
      .nullable()
      .required(intl.formatMessage(validationMessages.required)),
    crop_id: number()
      .nullable()
      .required(intl.formatMessage(validationMessages.required)),
      steering_algorithm_id: number()
      .nullable(),
    weather_dataset_id: number()
      .nullable(),
      // .required(intl.formatMessage(validationMessages.required)),
    production_dataset_id: number()
      .nullable(),
    universal_dataset_id: number()
      .nullable(),
    ETP_dataset_id: number()
      .nullable(),
      // .required(intl.formatMessage(validationMessages.required)),
    soil_information_id: number()
      .nullable()
      .required(intl.formatMessage(validationMessages.required)),
  });

export default WaterBalanceSchema;
