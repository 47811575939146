import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getSimulations = ({ clientId, projectId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/simulations`,
  });

export const getSimulation = ({ clientId, projectId, simulationId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${simulationId}`,
  });

export const createSimulation = ({ clientId, projectId, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/simulations`,
    data,
  });

export const copySimulation = ({ clientId, projectId, simulationId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${simulationId}/copy`,
  });

export const deleteSimulation = ({ clientId, projectId, simulationId }) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${simulationId}`,
  });

export const updateSimulation = ({
  clientId,
  projectId,
  simulationId,
  ...data
}) =>
  apiPATCH({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${simulationId}`,
    data,
  });

export const runSimulation = ({ clientId, projectId, simulationId }) => 
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${simulationId}/run`,
  });

export const getSimulationResult = ({
  clientId,
  projectId,
  simulationId,
  resultId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${simulationId}/results/${resultId}`,
  });

export const deleteSimulationResult = ({
  clientId,
  projectId,
  simulationId,
  resultId,
}) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${simulationId}/results/${resultId}`,
  });

export const cancelSimulation = ({
  clientId,
  projectId,
  simulationId,
  resultId,
}) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${simulationId}/results/${resultId}/cancel`,
  });

export const getSimulationResultImage = ({
  clientId,
  projectId,
  simulationId,
  resultId,
  imageId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${simulationId}/results/${resultId}/images/${imageId}`,
    responseType: "blob",
  }).then((r) => URL.createObjectURL(r));

export const getSimulationResultFile = ({
  clientId,
  projectId,
  simulationId,
  resultId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${simulationId}/results/${resultId}/download`,
    responseType: "blob",
  }).then((r) => URL.createObjectURL(r));
