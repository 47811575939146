import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { getSizingOptimizationResult as action } from "store/sizing_optimizations/actions";
// api
import { getSizingOptimizationResult as api } from "store/sizing_optimizations/api";
import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success(response.data));
  } catch (error) {
    yield call(errorNotifications, error.response.data.data);
  } finally {
    yield put(action.fulfill());
  }
}

export default function* getSizingOptimizationResult() {
  yield takeLatest(action.TRIGGER, saga);
}
