import {
  getSteeringAlgorithms,
  createSteeringAlgorithm,
  getSingleSteeringAlgorithm,
  updateSteeringAlgorithm,
  deleteSteeringAlgorithm,
  copySteeringAlgorithm,
} from "store/steering_algorithms/actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  list: [],
  item: {},
};

const steeringAlgorithmReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case getSteeringAlgorithms.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getSteeringAlgorithms.SUCCESS: {
      return {
        ...state,
        list: payload,
      };
    }

    case getSteeringAlgorithms.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case copySteeringAlgorithm.TRIGGER:
    case getSingleSteeringAlgorithm.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getSingleSteeringAlgorithm.SUCCESS: {
      return {
        ...state,
        item: payload,
      };
    }

    case copySteeringAlgorithm.FULFILL:
    case getSingleSteeringAlgorithm.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case createSteeringAlgorithm.SUCCESS:
    case copySteeringAlgorithm.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }

    case updateSteeringAlgorithm.SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = payload;

      return {
        ...state,
        list: newList,
      };
    }

    case deleteSteeringAlgorithm.SUCCESS: {
      const list = state.list.filter(({ id }) => id !== payload.id);
      return {
        ...state,
        list,
      };
    }

    default: {
      return state;
    }
  }
};

export default steeringAlgorithmReducer;
