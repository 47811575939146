import React, { useState } from "react";
import styled from "./DatasetsCard.module.scss";
import clsx from "clsx";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import { IDataset } from "../../pages/Datasets/DatasetsType";
import Spinner from "react-bootstrap/Spinner";
import { useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import { Tooltip } from "@mui/material";
import { ReactComponent as Export } from "assets/icons/export.svg";

interface IDatasetsCard {
  onEdit: () => void;
  onDelete: () => void;
  onCopy: () => void;
  onCopyToOtherProject: () => void;
  isShow: boolean;
  content: IDataset;
  isLoading?: boolean;
}
const DatasetsCard = ({
  isShow = true,
  onEdit,
  content,
  onCopy,
  onDelete,
  onCopyToOtherProject,
  isLoading = false,
}: IDatasetsCard) => {
  if (!isShow) return null;
  const intl = useIntl();

  const { name, timezone, frequency, dataset_filename, dataset_type, comment } =
    content;

  const deleteCard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    onDelete();
  };
  const duplicateCard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    onCopy();
  };
  const duplicateToOtherProjectCard = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    onCopyToOtherProject();
  };

  return (
    <div className={styled.container} onClick={onEdit}>
      <div className={styled.card}>
        <div className={styled.flex}>
          {(dataset_type == "weather" || dataset_type == "ETP") && (
            <Icon
              name={ICON_NAMES_ENUM.dataset_weather}
              className={styled.icon}
            />
          )}
          {dataset_type == "production" && (
            <Icon
              name={ICON_NAMES_ENUM.dataset_electrical}
              className={styled.icon}
            />
          )}
          {dataset_type == "universal" && (
            <Icon
              name={ICON_NAMES_ENUM.dataset_weather}
              className={styled.icon}
            />
          )}

          <p className={styled.name}>{name}</p>
        </div>
        <div className={styled.flex}>
          <span className={styled.option}>{comment}</span>
          <span className={styled.option}>
            <Icon name={ICON_NAMES_ENUM.file} className={styled.file} />
            {dataset_filename}
          </span>
          <span className={styled.option}>{frequency}</span>
          <Tooltip title={intl.formatMessage(commonMessages.edit)}>
            <button
              className={styled.button}
              onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                onEdit();
              }}
            >
              <Icon name={ICON_NAMES_ENUM.edit} />
            </button>
          </Tooltip>
          <span className={styled.vertical} />
          <Tooltip title={intl.formatMessage(commonMessages.copy)}>
            <button
              className={styled.button}
              onClick={duplicateCard}
              disabled={isLoading}
            >
              <Icon name={ICON_NAMES_ENUM.copy} />
              {isLoading && (
                <div className={styled["spinner-container"]}>
                  <Spinner className={styled.spinner} animation="border" />
                </div>
              )}
            </button>
          </Tooltip>
          <Tooltip
            title={intl.formatMessage(commonMessages.copy_to_other_project)}
          >
            <button
              className={clsx(styled.button, styled.copy_to_other_project)}
              onClick={duplicateToOtherProjectCard}
              type="button"
            >
              <Export />
            </button>
          </Tooltip>
          <span className={styled.vertical} />
          <Tooltip title={intl.formatMessage(commonMessages.delete)}>
            <button
              className={clsx(styled.button, styled.red)}
              onClick={deleteCard}
            >
              <Icon name={ICON_NAMES_ENUM.delete} />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export { DatasetsCard };

