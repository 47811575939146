import {number, object, string} from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";

const ProjectSchema = (intl: IntlShape) =>
  object().shape({
    name: string()
      .max(50, intl.formatMessage(validationMessages.maxLength, { number: 50 }))
      .required(intl.formatMessage(validationMessages.required)),
    lat: number()
      .min(-90, intl.formatMessage(validationMessages.minValue, { number: -90 }))
      .max(90, intl.formatMessage(validationMessages.maxValue, { number: 90 }))
      .required(intl.formatMessage(validationMessages.required)),
    long: number()
      .min(-180, intl.formatMessage(validationMessages.minValue, { number: -180 }))
      .max(180, intl.formatMessage(validationMessages.maxValue, { number: 180 }))
      .required(intl.formatMessage(validationMessages.required)),
  });

export default ProjectSchema;
