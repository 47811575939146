import { defineMessages } from "react-intl";

export default defineMessages({
  save: {
    id: "CommonMessages.save",
    defaultMessage: "Save",
  },
  run: {
    id: "CommonMessages.run",
    defaultMessage: "Run",
  },
  download: {
    id: "CommonMessages.download",
    defaultMessage: "Download",
  },
  downloadAll: {
    id: "CommonMessages.downloadAll",
    defaultMessage: "Download All",
  },
  downloadAllMetadata: {
    id: "CommonMessages.downloadAllMetadata",
    defaultMessage: "Download All Metadata",
  },
  create: {
    id: "CommonMessages.create",
    defaultMessage: "Create",
  },
  createFromTemplate: {
    id: "CommonMessages.createFromTemplate",
    defaultMessage: "Create From Template",
  },
  change: {
    id: "CommonMessages.change",
    defaultMessage: "Change",
  },
  cancel: {
    id: "CommonMessages.cancel",
    defaultMessage: "Cancel",
  },
  close: {
    id: "CommonMessages.close",
    defaultMessage: "Close",
  },
  delete: {
    id: "CommonMessages.delete",
    defaultMessage: "Delete",
  },
  copy: {
    id: "CommonMessages.copy",
    defaultMessage: "Copy",
  },
  copy_to_other_project: {
    id: "CommonMessages.copy_to_other_project",
    defaultMessage: "Copy to other project",
  },
  edit: {
    id: "CommonMessages.edit",
    defaultMessage: "Edit",
  },
  createSteeringForCrop: {
    id: "CommonMessages.createSteeringForCrop",
    defaultMessage: "Create steering algorithm for ':crop_name' crop.",
  },
  clients: {
    id: "CommonMessages.clients",
    defaultMessage: "Clients",
  },
  projects: {
    id: "CommonMessages.projects",
    defaultMessage: "Projects",
  },
  createNew: {
    id: "CommonMessages.createNew",
    defaultMessage: "Create new",
  },
  structures: {
    id: "CommonMessages.structures",
    defaultMessage: "Structures",
  },
  structure: {
    id: "CommonMessages.structure",
    defaultMessage: "Structure",
  },
  crops: {
    id: "CommonMessages.crops",
    defaultMessage: "Crops",
  },
  crop: {
    id: "CommonMessages.crop",
    defaultMessage: "Crop",
  },
  steering: {
    id: "CommonMessages.steeringAlgorithms",
    defaultMessage: "Steering algorithms",
  },
  steeringFromCrop: {
    id: "CommonMessages.steeringFromCrop",
    defaultMessage: "Steering algorithms from crop: ':crop_name'",
  },
  soil_information: {
    id: "CommonMessages.soilInformation",
    defaultMessage: "Soil information",
  },
  waterBalances: {
    id: "CommonMessages.waterBalances",
    defaultMessage: "Water balances",
  },
  waterBalance: {
    id: "CommonMessages.waterBalance",
    defaultMessage: "Water balance",
  },
  batches: {
    id: "CommonMessages.batches",
    defaultMessage: "Batches",
  },
  batches_water_balance: {
    id: "CommonMessages.batches_water_balance",
    defaultMessage: "Risk Analysis",
  },
  datasets: {
    id: "CommonMessages.datasets",
    defaultMessage: "Datasets",
  },
  dataset: {
    id: "CommonMessages.dataset",
    defaultMessage: "Dataset",
  },
  simulations: {
    id: "CommonMessages.simulations",
    defaultMessage: "Simulations",
  },
  simulation: {
    id: "CommonMessages.simulation",
    defaultMessage: "Simulation",
  },
  sizing_optimization: {
    id: "CommonMessages.sizing_optimization",
    defaultMessage: "Sizing Evaluation",
  },
  light_requirements: {
    id: "CommonMessages.light_requirements",
    defaultMessage: "Light requirements",
  },
  login: {
    id: "CommonMessages.login",
    defaultMessage: "login",
  },
  error: {
    id: "CommonMessages.error",
    defaultMessage: "Error",
  },
  success: {
    id: "CommonMessages.success",
    defaultMessage: "Success",
  },
  add: {
    id: "CommonMessages.add",
    defaultMessage: "Add",
  },
  about: {
    id: "CommonMessages.about",
    defaultMessage: "About {value} minute(s)",
  },
  daily: {
    id: "CommonMessages.daily",
    defaultMessage: "Daily",
  },
  monthly: {
    id: "CommonMessages.monthly",
    defaultMessage: "Monthly",
  },
  bar: {
    id: "CommonMessages.bar",
    defaultMessage: "Bar",
  },
  line: {
    id: "CommonMessages.line",
    defaultMessage: "Line",
  },
  successCreate: {
    id: "CommonMessages.successCreate",
    defaultMessage: "Your {objet_type} has been created successfully.",
  },
  successEdit: {
    id: "CommonMessages.successEdit",
    defaultMessage: "Your {objet_type} has been successfully modified.",
  },
  DownloadData : {
    id: "CommonMessages.DownloadData",
    defaultMessage: "Download dataset"
  },
  MeteonormTMYData : {
    id: "CommonMessages.MeteonormTMYData",
    defaultMessage: "Meteonorm TMY Dataset"
  },
  OpenMeteoData : {
    id: "CommonMessages.OpenMeteoData",
    defaultMessage: "Open-Meteo data"
  },
  meteonormTypical : {
    id: "CommonMessages.meteonormTypical",
    defaultMessage: "Typical meteorological year"
  },
  downloadTitle : {
    id: "CommonMessages.downloadTitle",
    defaultMessage: "Download dataset"
  },
  successCreateDatasets : {
    id: "CommonMessages.successCreateDatasets",
    defaultMessage: "Your datasets have been successfully created."
  }
});
