import React from "react";
import styled from "./styled.module.scss";
import clsx from "clsx";

interface ISwitch {
  label1: string;
  label2: string;
  value: boolean;
  disabled?: boolean;
  onChange: (v: boolean) => void;
}

const Switch = ({
  label1,
  label2,
  value,
  disabled = false,
  onChange,
}: ISwitch) => {
  const stopPropagation =
    (event: React.MouseEvent<HTMLButtonElement>) => (obj: () => void) => {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      obj();
    };
  const onClick = (newVal: boolean) => () => {
    if (value === newVal) return;
    onChange(newVal);
  };
  return (
    <div
      className={styled.container}
    >
      <button
        disabled={disabled}
        type="button"
        onClick={(e) => stopPropagation(e)(onClick(false))}
        className={clsx(styled.button, {
          [styled.checked]: !value,
        })}
      >
        {label1}
      </button>
      <button
        disabled={disabled}
        type="button"
        onClick={(e) => stopPropagation(e)(onClick(true))}
        className={clsx(styled.button, {
          [styled.checked]: value,
        })}
      >
        {label2}
      </button>
    </div>
  );
};

export default Switch;
