import React from "react";
import { Modal } from "react-bootstrap";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import styled from "./LeaveModalWindow.module.scss";
import { SaveTemplate } from "./SaveTemplate";
import { DeleteTemplate } from "./DeleteTemplate";
import { CancelTemplate } from "components/LeaveModalWindow/CancelTemplate";

export enum LeaveModalEnum {
  DELETE = "delete",
  SAVE = "save",
  CANCEL = "cancel",
}

interface ILeaveModalWindow {
  show: boolean;
  isLoading?: boolean;
  onHide: () => void;
  onClose?: () => void;
  onSave?: () => void;
  onDelete?: () => void;
  onConfirm?: () => void;
  type: LeaveModalEnum;
}

function LeaveModalWindow({
  show,
  isLoading,
  onHide,
  onClose,
  onSave,
  onDelete,
  onConfirm,
  type,
}: ILeaveModalWindow) {
  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className={styled.container}>
        <button type="button" onClick={onHide} className={styled.close}>
          <Icon name={ICON_NAMES_ENUM.close} />
        </button>
        {type === LeaveModalEnum.SAVE && (
          <SaveTemplate onClose={onClose} onSave={onSave} isLoading={isLoading} />
        )}
        {type === LeaveModalEnum.DELETE && (
          <DeleteTemplate onClose={onHide} onDelete={onDelete} isLoading={isLoading}/>
        )}
        {type === LeaveModalEnum.CANCEL && (
          <CancelTemplate onClose={onHide} onSubmit={onConfirm} isLoading={isLoading}/>
        )}
      </div>
    </Modal>
  );
}

export { LeaveModalWindow };
