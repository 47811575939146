import React from "react";
import styled from "./styled.module.scss";
import loginBackground from "../../assets/icons/login-bg.png";
interface IAuthLayout {
  children: React.ReactNode;
}

const AuthLayout = ({ children }: IAuthLayout) => {
  return (
    <div className={styled.container}>
      <img
        src={loginBackground}
        alt="background"
        className={styled.container__background}
      />
      <div className={styled.window}>{children}</div>
    </div>
  );
};

export default AuthLayout;
