import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "constants/routes";

import styled from "./styled.module.scss";
import { ReactComponent as NotFoundImage } from "assets/icons/not-found.svg";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import Profile from "components/Profile/Profile";
import { FormattedMessage } from "react-intl";
import notFoundMessages from "pages/NotFound/NotFoundMessages";

const NotFound = () => {
  return (
    <>
      <header className={styled.header}>
        <Link to={ROUTES.CLIENTS_LIST}>
          <Logo className={styled.logo} />
        </Link>

        <Profile />
      </header>
      <div className={styled.container}>
        <NotFoundImage />
        <h1 className={styled.title}>
          <FormattedMessage {...notFoundMessages.title} />
        </h1>
        <p className={styled.text}>
          <FormattedMessage {...notFoundMessages.text} />
        </p>
        <Link className={styled.link} to={ROUTES.CLIENTS_LIST}>
          <FormattedMessage {...notFoundMessages.goHome} />
        </Link>
      </div>
    </>
  );
};

export default NotFound;
