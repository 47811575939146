import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getSizingOptimizations = ({ clientId, projectId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/sizing_optimizations`,
  });

export const getSingleSizingOptimization = ({ clientId, projectId, sizingOptimizationId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/sizing_optimizations/${sizingOptimizationId}`,
  });

export const getSingleCopySizingOptimization = ({ clientId, projectId, sizingOptimizationId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/sizing_optimizations/get_copy/${sizingOptimizationId}`,
  });

export const createSizingOptimization = ({ clientId, projectId, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/sizing_optimizations`,
    data,
  });

export const copySizingOptimization = ({ clientId, projectId, sizingOptimizationId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/sizing_optimizations/${sizingOptimizationId}/copy`,
  });

export const updateSizingOptimization = ({ clientId, projectId, sizingOptimizationId, ...data }) =>
  apiPATCH({
    url: `/clients/${clientId}/projects/${projectId}/sizing_optimizations/${sizingOptimizationId}`,
    data,
  });

export const deleteSizingOptimization = ({ clientId, projectId, sizingOptimizationId }) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}/sizing_optimizations/${sizingOptimizationId}`,
  });

  export const getSizingOptimizationResult = ({ clientId, projectId, sizingOptimizationId, batchId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/sizing_optimizations/${sizingOptimizationId}/batches/${batchId}`,
  });

  export const runSizingOptimizationSimulation = ({ clientId, projectId, sizingOptimizationId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/sizing_optimizations/${sizingOptimizationId}/run`,
  });

export const cancelSizingOptimizationSimulation = ({ clientId, projectId, sizingOptimizationId, resultId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/sizing_optimizations/${sizingOptimizationId}/results/${resultId}/cancel`,
  });

export const getSizingOptimizationSimulationResult = ({
  clientId,
  projectId,
  sizingOptimizationId,
  resultId,
  simulationId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/sizing_optimizations/${sizingOptimizationId}/results/${resultId}/simulations/${simulationId}`,
  });

export const deleteSizingOptimizationResult = ({ clientId, projectId, sizingOptimizationId, resultId }) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}/sizing_optimizations/${sizingOptimizationId}/results/${resultId}`,
  });

export const getSizingOptimizationSimulationResultImage = ({
  clientId,
  projectId,
  sizingOptimizationId,
  resultId,
  simulationId,
  imageId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/sizing_optimizations/${sizingOptimizationId}/results/${resultId}/simulations/${simulationId}/images/${imageId}`,
    responseType: "blob",
  }).then((r) => URL.createObjectURL(r));

export const getSizingOptimizationMetadata = ({
    clientId,
    projectId,
    sizingOptimizationId,
    resultId,
  }) =>
    apiGET({
      url: `/clients/${clientId}/projects/${projectId}/sizing_optimizations/${sizingOptimizationId}/batches/${resultId}/download_all_metadata`,
      responseType: "blob",
    }).then((r) => URL.createObjectURL(r));

    // export const getBatchMetadata = ({
    //   clientId,
    //   projectId,
    //   batchId,
    //   resultId,
    // }) =>
    //   apiGET({
    //     url: `/clients/${client_id}/projects/${project_id}/sizing_optimizations/${sizing_optimization_id}/batches`,
    //     responseType: "blob",
    //   }).then((r) => URL.createObjectURL(r));