import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getClientsList = ({ sort }) =>
  apiGET({
    url: "/clients",
    params: {
      ...sort,
    },
  });

export const createClient = (data) =>
  apiPOST({
    url: "/clients",
    data,
  });

export const deleteClient = (clientId) =>
  apiDELETE({
    url: `/clients/${clientId}`,
  });

export const updateClient = ({ clientId, ...data }) =>
  apiPATCH({
    url: `/clients/${clientId}`,
    data,
  });
