import { all, fork } from 'redux-saga/effects';
// sagas
import user from './user/sagas';
import projects from './projects/sagas';
import clients from './clients/sagas';
import steering_algorithms from './steering_algorithms/sagas';
import soil_informations from './soil_informations/sagas';
import water_balances from './water_balances/sagas';
import structures from './structures/sagas';
import crops from './crops/sagas';
import simulations from './simulations/sagas';
import datasets from './datasets/sagas';
import navigation from './navigation/sagas';
import batches from './batches/sagas';
import batches_water_balance from './batches_water_balance/sagas';
import sizing_optimizations from './sizing_optimizations/sagas';
import users from './users/sagas';

export default function* rootSaga() {
  yield all([
    fork(user),
    fork(projects),
    fork(clients),
    fork(steering_algorithms),
    fork(soil_informations),
    fork(water_balances),
    fork(structures),
    fork(crops),
    fork(simulations),
    fork(datasets),
    fork(navigation),
    fork(batches),
    fork(batches_water_balance),
    fork(sizing_optimizations),
    fork(users),
  ]);
}
