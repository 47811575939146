import { defineMessages } from "react-intl";

export default defineMessages({
  welcome : {
    id             : "RegisterMessages.welcome",
    defaultMessage : "Welcome",
  },
  subtitle : {
    id             : "RegisterMessages.subtitle",
    defaultMessage : "Fill in the fields to continue using Agrisoleo",
  },
  usernameLabel : {
    id             : "RegisterMessages.usernameLabel",
    defaultMessage : "Enter your name",
  },
  usernamePlaceholder : {
    id             : "RegisterMessages.usernamePlaceholder",
    defaultMessage : "Enter username",
  },
  passwordLabel : {
    id             : "RegisterMessages.passwordLabel",
    defaultMessage : "Password",
  },
  passwordPlaceholder : {
    id             : "RegisterMessages.passwordPlaceholder",
    defaultMessage : "Enter password",
  },
  confirmPasswordLabel : {
    id             : "RegisterMessages.confirmPasswordLabel",
    defaultMessage : "Confirm Password",
  },
  confirmPasswordPlaceholder : {
    id             : "RegisterMessages.confirmPasswordPlaceholder",
    defaultMessage : "Enter password",
  },
  createAccount : {
    id             : "RegisterMessages.createAccount",
    defaultMessage : "Create an account",
  },
});
