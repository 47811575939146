import { all, fork } from 'redux-saga/effects';

// sagas
import getBatchesWaterBalance from 'store/batches_water_balance/sagas/getBatchesWaterBalance';
import createBatchWaterBalance from 'store/batches_water_balance/sagas/createBatchWaterBalance';
import copyBatchWaterBalance from 'store/batches_water_balance/sagas/copyBatchWaterBalance';
import deleteBatchWaterBalance from 'store/batches_water_balance/sagas/deleteBatchWaterBalance';
import updateBatchWaterBalance from 'store/batches_water_balance/sagas/updateBatchWaterBalance';
import getBatchWaterBalanceParametersOfInterest from 'store/batches_water_balance/sagas/getBatchWaterBalanceParametersOfInterest';
import runBatchWaterBalanceSimulation from 'store/batches_water_balance/sagas/runBatchWaterBalanceSimulation';
import cancelBatchWaterBalanceSimulation from 'store/batches_water_balance/sagas/cancelBatchWaterBalanceSimulation';
import getBatchWaterBalanceResults from 'store/batches_water_balance/sagas/getBatchWaterBalanceResults';
import deleteBatchWaterBalanceResult from 'store/batches_water_balance/sagas/deleteBatchWaterBalanceResult';
import getBatchWaterBalanceSimulationResult from 'store/batches_water_balance/sagas/getBatchWaterBalanceSimulationResult';

export default function* auth() {
  yield all([
    fork(getBatchesWaterBalance),
    fork(createBatchWaterBalance),
    fork(copyBatchWaterBalance),
    fork(deleteBatchWaterBalance),
    fork(updateBatchWaterBalance),
    fork(getBatchWaterBalanceParametersOfInterest),
    fork(runBatchWaterBalanceSimulation),
    fork(cancelBatchWaterBalanceSimulation),
    fork(getBatchWaterBalanceResults),
    fork(deleteBatchWaterBalanceResult),
    fork(getBatchWaterBalanceSimulationResult),
  ]);
}
