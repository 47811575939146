import { defineMessages } from "react-intl";

export default defineMessages({
  addPoint : {
    id             : "AddPointModalMessages.addPoint",
    defaultMessage : "Add new point",
  },
  editPoint : {
    id             : "AddPointModalMessages.editPoint",
    defaultMessage : "Edit point",
  },
  pointName : {
    id             : "AddPointModalMessages.pointName",
    defaultMessage : "Point name",
  },
  pointUnnamed : {
    id             : "AddPointModalMessages.pointUnnamed",
    defaultMessage : "Point Unnamed",
  },
  coordinates : {
    id             : "AddPointModalMessages.coordinates",
    defaultMessage : "Coordinates",
  },
});
