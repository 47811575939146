import React from "react";
import { ReactComponent as Batch } from "assets/icons/batch.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as Copy } from "assets/icons/copy.svg";

import styled from "./styled.module.scss";
import clsx from "clsx";
import moment from "moment";
import { FormattedMessage, useIntl } from "react-intl";
import batchesCardMessages from "components/BatchesCard/BatchesCardMessages";
import commonMessages from "components/common/CommonMessages";
import { Tooltip } from "@mui/material";

interface IBatchesCard {
  name: string;
  date: string;
  rows_number: number;
  onEdit: () => void;
  onDelete: () => void;
  onCopy: () => void;
}

const BatchesCard = ({
  name,
  date,
  rows_number,
  onEdit,
  onCopy,
  onDelete,
}: IBatchesCard) => {
  const intl = useIntl();

  const deleteCard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    onDelete();
  };

  const duplicateCard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    onCopy();
  };

  return (
    <div className={styled.container} onClick={onEdit}>
      <div className={styled.card}>
        <div className={styled.flex}>
          <Batch />
          <p className={styled.name}>{name}</p>
        </div>

        <div className={styled.flex}>
          <span className={styled.date}>
            <FormattedMessage
              {...batchesCardMessages.variations}
              values={{ value: rows_number }}
            />
          </span>
          <span className={styled.date}>
            {moment.utc(date).local().format("DD MMMM YYYY, HH:mm")}
          </span>
          <Tooltip title={intl.formatMessage(commonMessages.edit)}>
            <button className={styled.button} onClick={onEdit} type="button">
              <Edit />
            </button>
          </Tooltip>
          <Tooltip title={intl.formatMessage(commonMessages.copy)}>
            <button
              className={styled.button}
              onClick={duplicateCard}
              type="button"
            >
              <Copy />
            </button>
          </Tooltip>
          <span className={styled.vertical} />
          <Tooltip title={intl.formatMessage(commonMessages.delete)}>
            <button
              type="button"
              className={clsx(styled.button, styled.red)}
              onClick={deleteCard}
            >
              <Delete />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default BatchesCard;
