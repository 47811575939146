import routine from 'store/services/routine';

export const getSizingOptimizations = routine('GET_SIZING_OPTIMIZATIONS');
export const getSingleSizingOptimization = routine('GET_SINGLE_SIZING_OPTIMIZATIONS');
export const getSizingOptimizationResult = routine('GET_SIZING_OPTIMIZATION_RESULT')
export const createSizingOptimization = routine('CREATE_SIZING_OPTIMIZATION');
export const copySizingOptimization = routine('COPY_SIZING_OPTIMIZATION');
export const updateSizingOptimization = routine('UPDATE_SIZING_OPTIMIZATION');
export const deleteSizingOptimization = routine('DELETE_SIZING_OPTIMIZATION');

export const runSizingOptimizationSimulation = routine('RUN_SIZING_OPTIMIZATION_SIMULATION');
export const cancelSizingOptimizationSimulation = routine('CANCEL_SIZING_OPTIMIZATION_SIMULATION');
export const getSizingOptimizationResults = routine('GET_SIZING_OPTIMIZATION_RESULTS');
export const deleteSizingOptimizationResult = routine('DELETE_SIZING_OPTIMIZATION_RESULT');
export const getSizingOptimizationSimulationResult = routine('GET_SIZING_OPTIMIZATION_SIMULATION_RESULT');
export const resetSizingOptimizationResults = routine('RESET_SIZING_OPTIMIZATION_RESULTS');
export const resetSizingOptimizationSimulationResult = routine('RESET_SIZING_OPTIMIZATION_SIMULATION_RESULTS');