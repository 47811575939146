import {
  getSimulation,
  getSimulations,
  createSimulation,
  updateSimulation,
  deleteSimulation,
  getSimulationResult,
  runSimulation,
  resetSimulationResult,
  copySimulation,
  deleteSimulationResult,
  resetSimulation,
  cancelSimulation,
} from "store/simulations/actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  list: [],
  result: null,
  single: null,
};

const structuresReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case getSimulations.TRIGGER:
    case runSimulation.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case runSimulation.SUCCESS:
    case cancelSimulation.SUCCESS:
    case getSimulationResult.SUCCESS: {
      // const newList = [...state.list.map((item: any) => {item.})]
      return {
        ...state,
        result: payload,
      };
    }

    case resetSimulationResult.TRIGGER: {
      return {
        ...state,
        result: INITIAL_STATE.result,
      };
    }

    case getSimulations.SUCCESS: {
      return {
        ...state,
        list: payload,
      };
    }

    case getSimulation.SUCCESS: {
      return {
        ...state,
        single: payload,
      };
    }

    case resetSimulation.TRIGGER: {
      return {
        ...state,
        single: INITIAL_STATE.single,
      };
    }

    case createSimulation.SUCCESS:
    case copySimulation.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }

    case deleteSimulation.SUCCESS: {
      const list = state.list.filter(({ id }) => id !== payload.id);

      return {
        ...state,
        list,
      };
    }

    case updateSimulation.SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = payload;

      return {
        ...state,
        list: newList,
      };
    }

    case deleteSimulationResult.SUCCESS: {
      return {
        ...state,
        result: null,
      };
    }

    case getSimulations.FULFILL:
    case runSimulation.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default structuresReducer;
