import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { createDataset as action } from "store/datasets/actions";
// api
import { createDataset as api } from "store/datasets/api";

import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload: { callback, finallyCallback, ...payload } }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success(response.data));

    if (callback) {
      callback(response);
    }
  } catch (error) {
    yield call(errorNotifications, error.response.data.data);
  } finally {
    yield put(action.fulfill());
    if(finallyCallback) finallyCallback()
  }
}

export default function* createDataset() {
  yield takeLatest(action.TRIGGER, saga);
}
