import React, { ButtonHTMLAttributes } from "react";
import styled from "./styled.module.scss";
import clsx from "clsx";
import Spinner from "react-bootstrap/Spinner";

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: string | React.ReactNode;
  variant?: "outlined" | "fill" | "text";
  fullwidth?: boolean;
  iconBefore?: string | React.ReactNode;
  isLoading?: boolean;
}

const Button = ({
  children,
  className,
  variant = "fill",
  fullwidth,
  type = "button",
  iconBefore,
  isLoading,
  ...rest
}: IButton) => {
  return (
    <button
      type={type}
      className={clsx(styled.button, {
        [styled.fill]: variant === "fill",
        [styled.outlined]: variant === "outlined",
        [styled.text]: variant === "text",
        [styled.fullwidth]: fullwidth,
      }, className)}
      {...rest}
    >
      {iconBefore ? (
        <span className={styled.iconBefore}>{iconBefore}</span>
      ) : null}
      <div className={styled.labelContainer}>
        {isLoading && (
          <div className={styled.spinnerContainer}>
            <Spinner className={styled.spinner} animation="border" />
          </div>
        )}

        <span
          className={clsx(styled.label, {
            [styled.isLoading]: isLoading,
          })}
        >
          {children}
        </span>
      </div>
    </button>
  );
};

export default Button;
