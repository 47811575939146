import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { deleteClient as action } from "store/clients/actions";
// api
import { deleteClient as api } from "store/clients/api";

function* saga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload.id);

    yield put(action.success(response.data));

    payload?.callback();
  } catch (error) {
    console.error(error);
    yield put(action.failure(error));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* deleteClient() {
  yield takeLatest(action.TRIGGER, saga);
}
