import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCropsItem, getIsLoading } from "store/crops/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getSingleCrop } from "store/crops/actions";
import moment from "moment/moment";
import { ROUTES } from "constants/routes";
import { updateCrop, deleteCrop } from "store/crops/actions";
import { FormikHelpers, useFormik } from "formik";
import CropSchema from "../CropsCreate/CropSchema";
import { useIntl } from "react-intl";
import {
  IRowField,
  IValues,
} from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmsCreateTypes";
import { getSteeringAlgorithmsItem } from "store/steering_algorithms/selectors";
import {
  deleteSteeringAlgorithm,
  getSingleSteeringAlgorithm,
  updateSteeringAlgorithm,
} from "store/steering_algorithms/actions";
import SteeringAlgorithmSchema from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmSchema";
import { getInitialFormValue } from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmsCreateHook";
import { successNotifications } from "utils/successNotifications";
import CommonMessages from "components/common/CommonMessages";

function SteeringAlgorithmsEditHook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const steering_algorithm = useSelector(getSteeringAlgorithmsItem);
  const isLoading = useSelector(getIsLoading);
  const { client_id, project_id, id } = useParams();

  const [IsOpenModal, setIsOpenModal] = useState(false);
  const [isEditFunc, setIsEditFunc] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<IRowField | undefined>();

  const transformPeriods = async () => {
    let rows: Array<IRowField> = await [];
    await steering_algorithm.rows.map((row: any) =>
      rows.push({
        id: row.id,
        type: row.row_type,
        state: row.row_state,
        period_id: row.period_id,
        value: JSON.parse(row.value),
      })
    );

    await formik.setFieldValue("name", steering_algorithm.name);
    await formik.setFieldValue("crop_id", steering_algorithm.crop_id);
    await formik.setFieldValue("row", rows);
  };

  useEffect(() => {
    dispatch(
      getSingleSteeringAlgorithm({
        clientId: client_id,
        projectId: project_id,
        steeringAlgorithmId: id,
      })
    );
  }, [client_id, project_id, id]);

  useEffect(() => {
    if (steering_algorithm.name != undefined) {
      transformPeriods();
    }
  }, [steering_algorithm]);

  const onEditField = async (row: IRowField) => {
    await setIsEditFunc(true);
    await setCurrentRow(row);
    await onShowModal();
  };
  const onEditSave = async (row: IRowField) => {
    const temp = await [...formik.values.row];
    let thisRow = await temp.findIndex((item) => item.id === row.id);

    if (thisRow >= 0) {
      temp[thisRow] = await row;
      await formik.setFieldValue("row", temp);
      await onHideModal();
    }
  };
  const onBack = () =>
    navigate(
      ROUTES.STEERING_ALGORITHM.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );
  const onUpdate = (values: IValues, actions: FormikHelpers<IValues>) => {
    let rows = values.row.map((row) => ({
      id: row.id,
      row_type: row.type,
      row_state: row.state,
      period_id: row.period_id,
      row_value: JSON.stringify(row.value),
    }));

    const steering_algorithm = {
      name: values.name,
      crop_id: values.crop_id,
      rows: rows,
    };

    dispatch(
      updateSteeringAlgorithm({
        ...steering_algorithm,
        clientId: client_id,
        projectId: project_id,
        steeringAlgorithmId: id,
        callback: () => {
          successNotifications({title: intl.formatMessage(CommonMessages.edit), message: intl.formatMessage(CommonMessages.successEdit, {objet_type: intl.formatMessage(CommonMessages.steering)})})
          dispatch(
            getSingleSteeringAlgorithm({
              clientId: client_id,
              projectId: project_id,
              steeringAlgorithmId: id,
            })
          );
        },
        finallyCallback: () => actions.setSubmitting(false),
      })
    );
  };
  const onAddNewRow = async () => {
    await setIsEditFunc(false);
    await setIsOpenModal(true);
  };

  const onShowModal = () => {
    setIsOpenModal(true);
  };
  const onHideModal = () => {
    setCurrentRow(undefined)
    setIsOpenModal(false);
  };
  const onDeleteRow = (id: number) => {
    const temp = [...formik.values.row];
    temp.splice(
      temp.findIndex((element) => element.id === id),
      1
    );
    formik.setFieldValue("row", temp);
  };
  const onSaveRow = (v: IRowField) => {
    const temp = [...formik.values.row];
    temp.push({
      id: v.id,
      type: v.type,
      state: v.state,
      period_id: v.period_id,
      value: v.value,
    });
    formik.setFieldValue("row", temp);
    onHideModal();
  };

  const onDeleteSteeringAlgorithm = () => {
    dispatch(
      deleteSteeringAlgorithm({
        clientId: client_id,
        projectId: project_id,
        steeringAlgorithmId: Number(id),
        callback: onBack,
      })
    );
  };

  const formik = useFormik<IValues>({
    validationSchema: SteeringAlgorithmSchema(intl),
    onSubmit: onUpdate,
    initialValues: getInitialFormValue(intl),
  });

  return {
    models: {
      IsOpenModal,
      isEditFunc,
      currentRow,
      client_id,
      project_id,
      isLoading,
      formik,
    },
    commands: {
      onSaveRow,
      onDeleteRow,
      onAddNewRow,
      onEditSave,
      onEditField,
      onHideModal,
      onShowModal,
      onUpdate,
      onDeleteSteeringAlgorithm,
      onBack,
    },
  };
}

export { SteeringAlgorithmsEditHook };
