import {
  getClientsList,
  createClient,
  deleteClient,
  updateClient,
  changeClientSort,
  getClientSort,
} from "store/clients/actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  list: [],
  sort: {
    order_by: "created_at",
    order: "asc",
  },
};

const clientsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case changeClientSort.TRIGGER: {
      return {
        ...state,
        sort: payload.sort,
      };
    }

    case getClientSort.SUCCESS: {
      if (payload) {
        return {
          ...state,
          sort: payload,
        };
      }

      return {
        ...state,
      };
    }

    case getClientsList.TRIGGER:
    case createClient.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getClientsList.SUCCESS: {
      return {
        ...state,
        list: payload,
      };
    }

    case createClient.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }

    case deleteClient.SUCCESS: {
      const list = state.list.filter(({ id }) => id !== payload.id);
      return {
        ...state,
        list,
      };
    }

    case updateClient.SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = { ...newList[index], ...payload };

      return {
        ...state,
        list: newList,
      };
    }

    case getClientsList.FULFILL:
    case createClient.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default clientsReducer;
