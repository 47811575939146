import React, {InputHTMLAttributes} from "react";
import styled from './styled.module.scss';
import clsx from "clsx";

interface ICheckBox extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const CheckBox = ({ label, disabled = false, ...rest }: ICheckBox) => {
  return (
    <label className={clsx(styled.container, {
      [styled.disabled]: disabled
    })}>
      <input {...rest} type="checkbox"/>
      <span className={styled.checkmark} />

      {label}
    </label>
  );
};

export default CheckBox;
