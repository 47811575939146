import React from "react";
import { Icon, ICON_NAMES_ENUM } from "../icon/Icon";
import styled from "./Msg.module.scss";
import { FormattedMessage } from "react-intl";
import commonMessages from "components/common/CommonMessages";

interface IMsg {
  title?: string;
  type: "error" | "success";
  msg: string;
}
function Msg({ msg, title, type }: IMsg) {
  return (
    <div className={styled.container}>
      {type == "error" && <Icon name={ICON_NAMES_ENUM.warning} />}
      {type == "success" && (
        <Icon name={ICON_NAMES_ENUM.success} className={styled.successIcon} />
      )}
      <h3 className={styled.title}>
        {type == "error" && <FormattedMessage {...commonMessages.error} />}{" "}
        {type == "success" && <FormattedMessage {...commonMessages.success} />}{" "}
        {title && title}
      </h3>
      <p className={styled.text}>{msg}</p>
    </div>
  );
}

export { Msg };
