import {
  getSizingOptimizations,
  createSizingOptimization,
  getSingleSizingOptimization,
  updateSizingOptimization,
  deleteSizingOptimization,
  copySizingOptimization,
  runSizingOptimizationSimulation,
  getSizingOptimizationResult,
} from "store/sizing_optimizations/actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  list: [],
  item: {},
  sizingOptimizationResult: null,
  // simulationResult: null,
};

const sizingOptimizationsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case getSizingOptimizations.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getSizingOptimizations.SUCCESS: {
      return {
        ...state,
        list: payload,
      };
    }

    case getSizingOptimizations.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case copySizingOptimization.TRIGGER:
    case getSingleSizingOptimization.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getSingleSizingOptimization.SUCCESS: {
      return {
        ...state,
        item: payload,
      };
    }

    case copySizingOptimization.FULFILL:
    case getSingleSizingOptimization.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case createSizingOptimization.SUCCESS:
    case copySizingOptimization.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }

    case updateSizingOptimization.SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = payload;

      return {
        ...state,
        list: newList,
      };
    }

    case runSizingOptimizationSimulation.SUCCESS:
    case getSizingOptimizationResult.SUCCESS: {
      return {
        ...state,
        sizingOptimizationResult: payload,
      };
    }

    case deleteSizingOptimization.SUCCESS: {
      const list = state.list.filter(({ id }) => id !== payload.id);
      return {
        ...state,
        list,
      };
    }

    default: {
      return state;
    }
  }
};

export default sizingOptimizationsReducer;
