import { useMemo } from "react";

interface IDefault {
  id: number;
  name: string;
}

export function useOptionData<T extends IDefault>(data: T[]) {
  return useMemo(() => {
    return data.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [data]);
}
