import { defineMessages } from "react-intl";

export default defineMessages({
  myProfile : {
    id             : "ProfilePageMessages.myProfile",
    defaultMessage : "My Profile",
  },
  save : {
    id             : "ProfilePageMessages.save",
    defaultMessage : "Save Changes",
  },
  usernameLabel : {
    id             : "ProfilePageMessages.usernameLabel",
    defaultMessage : "User Name",
  },
  usernamePlaceholder : {
    id             : "ProfilePageMessages.usernamePlaceholder",
    defaultMessage : "Enter username",
  },
  emailLabel : {
    id             : "ProfilePageMessages.emailLabel",
    defaultMessage : "Email",
  },
  emailPlaceholder : {
    id             : "ProfilePageMessages.emailPlaceholder",
    defaultMessage : "Enter email",
  },
  roleLabel : {
    id             : "ProfilePageMessages.roleLabel",
    defaultMessage : "Role",
  },
  resetText1 : {
    id             : "ProfilePageMessages.resetText1",
    defaultMessage : "If you want to change the password click",
  },
  resetText2 : {
    id             : "ProfilePageMessages.resetText2",
    defaultMessage : "We will send an email with instructions",
  },
  here : {
    id             : "ProfilePageMessages.here",
    defaultMessage : "Here",
  },
});
