import { defineMessages } from "react-intl";

export default defineMessages({
  titleDelete: {
    id: "LeaveModalWindow.titleDelete",
    defaultMessage: "Are you sure you want to delete?",
  },
  titleSave: {
    id: "LeaveModalWindow.titleSave",
    defaultMessage: "Are you sure you want to leave without saving?",
  },
  titleCancel: {
    id: "LeaveModalWindow.titleCancel",
    defaultMessage: "Are you sure you want to leave?",
  },
  discardChanges: {
    id: "LeaveModalWindow.discardChanges",
    defaultMessage: "Discard Changes",
  },
  leavePage: {
    id: "LeaveModalWindow.leavePage",
    defaultMessage: "Leave page",
  },
  descriptionSave: {
    id: "LeaveModalWindow.descriptionSave",
    defaultMessage:
      "You have unsaved changes. Are you sure you want to leave this page? Unsaved changes will be lost",
  },
});
