import { defineMessages } from "react-intl";

export default defineMessages({
  welcome : {
    id             : "ForgotMessages.welcome",
    defaultMessage : "Welcome",
  },
  subtitle : {
    id             : "ForgotMessages.subtitle",
    defaultMessage : "Create a new password to continue using Agrisoleo",
  },
  passwordLabel : {
    id             : "ForgotMessages.passwordLabel",
    defaultMessage : "Password",
  },
  passwordPlaceholder : {
    id             : "ForgotMessages.passwordPlaceholder",
    defaultMessage : "Enter password",
  },
  confirmPasswordLabel : {
    id             : "ForgotMessages.confirmPasswordLabel",
    defaultMessage : "Confirm Password",
  },
  confirmPasswordPlaceholder : {
    id             : "ForgotMessages.confirmPasswordPlaceholder",
    defaultMessage : "Enter password",
  },
  savePassword : {
    id             : "ForgotMessages.savePassword",
    defaultMessage : "Save Password",
  },
});
