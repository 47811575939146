import {
  getUsers,
  updateUser,
  deleteUser,
  createUser,
  activateUser,
  deactivateUser,
} from "store/users/actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  list: [],
};

const usersReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case getUsers.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getUsers.SUCCESS: {
      return {
        ...state,
        list: payload,
      };
    }

    case createUser.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }

    case activateUser.SUCCESS:
    case deactivateUser.SUCCESS:
    case updateUser.SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = payload;

      return {
        ...state,
        list: newList,
      };
    }

    case deleteUser.SUCCESS: {
      const list = state.list.filter(({ id }) => id !== payload.id);

      return {
        ...state,
        list,
      };
    }

    case getUsers.FAILURE: {
      return {
        ...state,
        error: payload,
      };
    }

    case getUsers.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default usersReducer;
