import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import styled from "./styled.module.scss";
import StructureCard, {
  IStructure,
} from "components/StructuresCard/StructuresCard";
import { ROUTES } from "constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  copyStructure,
  deleteStructure,
  getStructures,
} from "store/structures/actions";
import { getStructuresListState } from "store/structures/selectors";
import MainContent from "components/MainContent/MainContent";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { log } from "console";
import { copyStructureToOtherProject } from "store/structures/api";
import { getProjectsListState } from "store/projects/selectors";
import { useOptionData } from "hooks/useOptionData";
import Select from "components/common/select/Select";
import { Modal } from "react-bootstrap";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import CommonMessages from "components/common/CommonMessages";

const Structures = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client_id, project_id } = useParams();
  const structures = useSelector(getStructuresListState);
  const projectList = useSelector(getProjectsListState);
  const projectOptions = useOptionData(projectList);

  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [copyToOtherProjectId, setCopyToOtherProjectId] = useState<
    number | null
  >(null);
  const [projectId, setProjectId] = useState(0);

  useEffect(() => {
    dispatch(getStructures({ clientId: client_id, projectId: project_id }));
  }, [project_id, client_id]);

  const onCreateFromTemplate = () => {
    navigate(
      ROUTES.STRUCTURES_CREATE_FROM_TEMPLATE.replace(
        ":client_id",
        client_id as string
      ).replace(":project_id", project_id as string)
    );
  };

  const onDelete = () => {
    dispatch(
      deleteStructure({
        clientId: client_id,
        projectId: project_id,
        structureId: deleteId,
        callback: () => setDeleteId(null),
      })
    );
  };

  const onCopy = (id: string | number) => () => {
    dispatch(
      copyStructure({
        clientId: client_id,
        projectId: project_id,
        structureId: id,
      })
    );
  };

  const onShowWarning = (id: number) => {
    setDeleteId(id);
  };

  return (
    <MainContent
      header={
        <>
          <h2 className={styled.title}>
            <FormattedMessage {...commonMessages.structures} />
          </h2>
          <div className={styled.flex}>
          <Button iconBefore={<Plus />} onClick={onCreateFromTemplate}>
            <FormattedMessage {...commonMessages.createFromTemplate} />
          </Button>
          <Button
            iconBefore={<Plus />}
            onClick={() =>
              navigate(
                ROUTES.STRUCTURES_CREATE.replace(
                  ":client_id",
                  client_id as string
                ).replace(":project_id", project_id as string)
              )
            }
          >
            <FormattedMessage {...commonMessages.createNew} />
          </Button>
          </div>
        </>
      }
    >
      <div className={styled.list}>
        {structures.map((structure: IStructure) => (
          <StructureCard
            key={structure.id}
            name={structure.name}
            date={structure.updated_at}
            onEdit={() =>
              navigate(
                ROUTES.STRUCTURES_EDIT.replace(
                  ":client_id",
                  client_id as string
                )
                  .replace(":project_id", project_id as string)
                  .replace(":id", String(structure.id))
              )
            }
            onDelete={() => onShowWarning(structure.id)}
            onCopy={onCopy(structure.id)}
            onCopyToOtherProject={() => {
              setCopyToOtherProjectId(structure.id);
            }}
          />
        ))}
        <Modal
          show={copyToOtherProjectId != null}
          onHide={() => setCopyToOtherProjectId(null)}
          centered
        >
          <div className={styled.container}>
            <Icon name={ICON_NAMES_ENUM.warning} />
            <h3 className={styled.modal_title}>
              <FormattedMessage {...CommonMessages.copy_to_other_project} />
            </h3>
            <Select
              label={"Select project"}
              options={projectOptions}
              onChange={(e: any) => {
                setProjectId(e.value);
              }}
            />
            <div className={styled.row}>
              <Button
                onClick={() => {
                  copyStructureToOtherProject({
                    clientId: client_id,
                    projectId: project_id,
                    structureId: copyToOtherProjectId,
                    projectToId: projectId,
                  }).then(() => {
                    setCopyToOtherProjectId(null);
                  });
                }}
              >
                Copy
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  setCopyToOtherProjectId(null);
                }}
              >
                <FormattedMessage {...CommonMessages.cancel} />
              </Button>
            </div>
          </div>
        </Modal>
        <LeaveModalWindow
          type={LeaveModalEnum.DELETE}
          onDelete={onDelete}
          show={deleteId !== null}
          onHide={() => setDeleteId(null)}
        />
      </div>
    </MainContent>
  );
};

export default Structures;
