import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { logout as logoutAction } from "store/user/actions";
// services
import { removeAccessToken } from "store/user/services/tokenService";

function* logoutSaga({ payload }) {
  try {
    yield put(logoutAction.request());
    yield call(removeAccessToken);
    yield put(logoutAction.success());
    payload();
  } catch (error) {
    yield put(logoutAction.failure(error));
  } finally {
    yield put(logoutAction.fulfill());
  }
}

export default function* logout() {
  yield takeLatest(logoutAction.TRIGGER, logoutSaga);
}
