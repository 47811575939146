const DisplayZone = (props: {
  nb_panel_y: number;
  nb_panel_x: number;
  no_crop_zone: number;
  initial_offset_y: number;
  initial_offset_x: number;
  panel_width: number;
  floor: { height: number; width: number };
  panels_gap_x: number;
  panels_gap_y: number;
  panel_x: number;
  panel_y: number;
  opacity: number;
  type: "tracker" | "fixed";
}) => {
  const returnArray = [];

  for (
    let index = 0;
    index < (props.type == "fixed" ? props.nb_panel_y : props.nb_panel_x);
    index++
  ) {
    if (props.type == "fixed")
      returnArray.push(
        <>
          <mesh
            position={[
              props.initial_offset_y -
                props.floor.height / 2 +
                index * props.panels_gap_y,
              0.01,
              0,
            ]}
            receiveShadow
          >
            <boxGeometry
              attach="geometry"
              args={[props.no_crop_zone, 0.1, props.floor.width - 0.2]}
            />
            <meshStandardMaterial
              attach="material"
              color="#f00"
              opacity={props.opacity}
              transparent
            />
          </mesh>
          <mesh
            position={[
              props.initial_offset_y -
                props.floor.height / 2 +
                index * props.panels_gap_y,
              0.01,
              0,
            ]}
          >
            <boxGeometry
              attach="geometry"
              args={[props.no_crop_zone, 0.1, props.floor.width - 0.2]}
            />
            <meshStandardMaterial attach="material" color="#f00" />
          </mesh>
        </>
      );
    if (props.type == "tracker")
      returnArray.push(
        <>
          <mesh
            position={[
              0,
              0.01,
              props.initial_offset_x -
                props.floor.width / 2 +
                index * props.panels_gap_x,
            ]}
            receiveShadow
          >
            <boxGeometry
              attach="geometry"
              args={[props.floor.height - 0.2, 0.1, props.no_crop_zone]}
            />
            <meshStandardMaterial
              attach="material"
              color="#f00"
              opacity={props.opacity}
              transparent
            />
          </mesh>
          <mesh
            position={[
              0,
              0.01,
              props.initial_offset_x -
                props.floor.width / 2 +
                index * props.panels_gap_x,
            ]}
            receiveShadow
          >
            <boxGeometry
              attach="geometry"
              args={[props.floor.height - 0.2, 0.1, props.no_crop_zone]}
            />
            <meshStandardMaterial attach="material" color="#f00" />
          </mesh>
        </>
      );
  }

  return returnArray;
};

export default DisplayZone;
