import React, { useEffect } from "react";
import styled from "./styled.module.scss";
import Input from "components/common/input/Input";
import Button from "components/common/button/Button";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import profilePageMessages from "pages/ProfilePage/ProfilePageMessages";
import ModalContainer from "components/ModalContainer/ModalContainer";
import { useNavigate } from "react-router-dom";
import { FormikValues, useFormik } from "formik";
import ProfilePageSchema from "pages/ProfilePage/ProfilePageSchema";
import { ReactComponent as Save } from "assets/icons/save.svg";
import Select from "components/common/select/Select";
import { selectOptionsText } from "components/common/select/selectOptionsText";
import { useDispatch, useSelector } from "react-redux";
import { getUserDataState } from "store/user/selectors";
import { editProfile, resetPassword } from "store/user/actions";

interface IProfilePageForm {
  name: string;
  email: string;
  role: string;
}
const initialValues: IProfilePageForm = {
  name: "",
  email: "",
  role: "user",
};

const ProfilePage = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(getUserDataState);
  const { userRoles } = selectOptionsText();

  const onHide = () => navigate(-1);

  const onResetPassword = () => {
    dispatch(resetPassword());
  };

  const onSubmit = (values: FormikValues) => {
    dispatch(
      editProfile({
        full_name: values.name,
        email: values.email,
      })
    );

    onHide();
  };

  const formik = useFormik<IProfilePageForm>({
    validationSchema: ProfilePageSchema(intl),
    onSubmit,
    initialValues,
  });

  useEffect(() => {
    if (user) {
      formik.setValues({
        name: user.full_name,
        email: user.email,
        role: user.role.name,
      });
    }
  }, [user]);

  const getInputProps = (key: keyof IProfilePageForm) => ({
    name: key,
    value: formik.values[key] as string | number,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    errorMessage: formik.touched[key] ? (formik.errors[key] as string) : "",
  });

  return (
    <ModalContainer
      show={true}
      onHide={onHide}
      dialogClassName={styled.dialog}
      title={intl.formatMessage(profilePageMessages.myProfile)}
    >
      <form className={styled.form} onSubmit={formik.handleSubmit}>
        <Input
          label={intl.formatMessage(profilePageMessages.usernameLabel)}
          placeholder={intl.formatMessage(
            profilePageMessages.usernamePlaceholder
          )}
          {...getInputProps("name")}
        />

        <Input
          label={intl.formatMessage(profilePageMessages.emailLabel)}
          placeholder={intl.formatMessage(profilePageMessages.emailPlaceholder)}
          {...getInputProps("email")}
        />

        <Select
          label={intl.formatMessage(profilePageMessages.roleLabel)}
          options={userRoles}
          value={userRoles.find((role) => role.value === formik.values.role)}
          isDisabled
        />

        <p className={styled.resetText}>
          <FormattedMessage {...profilePageMessages.resetText1} />{" "}
          <a className={styled.link} onClick={onResetPassword}>
            <FormattedMessage {...profilePageMessages.here} />
          </a>
          <br />
          <FormattedMessage {...profilePageMessages.resetText2} />
        </p>

        <div className={styled.row}>
          <Button iconBefore={<Save />} type="submit">
            <FormattedMessage {...profilePageMessages.save} />
          </Button>
          <Button variant="text" onClick={onHide}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default ProfilePage;
