import React, { useEffect, useMemo, useState } from "react";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { DnDTypes } from "constants/dndTypes";
import { ReactComponent as DataElec } from "assets/icons/data-elec.svg";
import { ReactComponent as DataTemp } from "assets/icons/data-temp.svg";
import { ReactComponent as Structures } from "assets/icons/structures.svg";
import { ReactComponent as Crops } from "assets/icons/crops.svg";
import styled from "./styled.module.scss";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import waterBalanceDropItemMessages from "components/WaterBalanceDropItem/WaterBalanceDropItemMessages";
import StructuresRenderList from "components/SimulationDropItem/StructuresRenderList";
import DatasetRenderList from "components/SimulationDropItem/DatasetRenderList";
import { SpanError } from "components/common/span-error/SpanError";
import { IFormik } from "components/common/FormikType";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import TextSelect from "components/common/textSelect/TextSelect";
import { IWaterBalancesState } from "components/WaterBalanceBody/WaterBalanceBody";
import SoilInformationsRenderList from "./SoilInformationsRenderList";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import CropsRenderList from "./CropsRenderList";
import SteeringAlgorithmsRenderList from "components/SimulationDropItem/SteeringAlgorithmsRenderList";

export interface IVariant {
  value: string | number | null;
  label: string;
}

interface IWaterBalanceDropItem {
  onDrop: (type: DnDTypes, id: number) => void;
  accept: DnDTypes;
  variants?: any[];
  selected?: any;
  formik: IFormik<IWaterBalancesState>;
  formikKey: keyof IWaterBalancesState;
}

const WaterBalanceDropItem = ({
  onDrop,
  accept,
  variants,
  selected,
  formik,
  formikKey,
}: IWaterBalanceDropItem) => {
  const [selectValue, setSelectValue] = useState<IVariant | null>(null);

  const options = useMemo(() => {
    return variants?.map(({ id, name }) => ({
      value: id,
      label: name,
    }));
  }, [variants]);

  useEffect(() => {
    if (formik.values[formikKey]) {
      const findVariant = options?.find(
        (item) => item.value === formik.values[formikKey]
      ) as IVariant;
      setSelectValue(findVariant);
    }
  }, [formik.values[formikKey], options]);

  useEffect(() => {
    if (
      !selectValue &&
      selected &&
      selected.name == "None" &&
      selected.id == null
    ) {
      setSelectValue({ value: null, label: "None" });
    }
  }, [selected]);

  const onSelect = (item: any) => {
    setSelectValue(item);
    onDrop(accept, item.value);
  };

  var [{ canDrop }, drop] = useDrop(
    () => ({
      accept: accept,
      drop(_item: any, monitor) {
        const newValue = {
          value: monitor.getItem().id,
          label: monitor.getItem().name,
        };
        setSelectValue(newValue);
        onDrop(accept, monitor.getItem().id);
      },
      collect: (monitor: DropTargetMonitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
        draggingColor: monitor.getItemType() as string,
      }),
    }),
    [onDrop]
  );

  const getItemLabel = () => {
    switch (accept) {
      case DnDTypes.STRUCTURE: {
        return (
          <>
            <Structures /> <FormattedMessage {...commonMessages.structure} />
          </>
        );
      }

      case DnDTypes.CROP: {
        return (
          <>
            <Crops /> <FormattedMessage {...commonMessages.crop} />
          </>
        );
      }

      case DnDTypes.STEERING_ALGORITHM: {
        return (
          <>
            <Crops /> <FormattedMessage {...commonMessages.steering} />
          </>
        );
      }

      case DnDTypes.DATA_WEATHER: {
        return (
          <>
            <DataTemp />{" "}
            <FormattedMessage
              {...waterBalanceDropItemMessages.weatherDatasets}
            />
          </>
        );
      }

      case DnDTypes.DATA_PROD: {
        return (
          <>
            <DataElec />{" "}
            <FormattedMessage
              {...waterBalanceDropItemMessages.productionDatasets}
            />
          </>
        );
      }

      case DnDTypes.DATA_ETP: {
        return (
          <>
            <DataTemp />{" "}
            <FormattedMessage {...waterBalanceDropItemMessages.etpDatasets} />
          </>
        );
      }

      case DnDTypes.DATA_UNI: {
        return (
          <>
            <DataTemp />{" "}
            <FormattedMessage
              {...waterBalanceDropItemMessages.climaticDatasets}
            />
          </>
        );
      }

      case DnDTypes.SOIL_INFORMATION: {
        return (
          <>
            <Icon
              name={ICON_NAMES_ENUM.terrain_icon}
              className={styled.file__icon}
            />{" "}
            <FormattedMessage
              {...waterBalanceDropItemMessages.soilInformation}
            />
          </>
        );
      }
    }
  };

  const getCurrentList = () => {
    switch (accept) {
      case DnDTypes.STRUCTURE: {
        return <StructuresRenderList selected={selected} />;
      }

      case DnDTypes.CROP: {
        return <CropsRenderList selected={selected} />;
      }

      case DnDTypes.STEERING_ALGORITHM: {
        return <SteeringAlgorithmsRenderList selected={selected} />;
      }

      case DnDTypes.DATA_WEATHER: {
        return <DatasetRenderList selected={selected} />;
      }

      case DnDTypes.DATA_PROD: {
        return <DatasetRenderList selected={selected} />;
      }

      case DnDTypes.DATA_ETP: {
        return <DatasetRenderList selected={selected} />;
      }

      case DnDTypes.DATA_UNI: {
        return <DatasetRenderList selected={selected} />;
      }

      case DnDTypes.SOIL_INFORMATION: {
        return <SoilInformationsRenderList selected={selected} />;
      }
    }
  };

  const isError = formik.touched[formikKey] && !!formik.errors[formikKey];
  const errorMessage = formik.errors[formikKey];

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={clsx(styled.container, {
        [styled.error]: isError,
        [styled.canDropBorder]: canDrop,
        [styled.selected]: selected,
      })}
      ref={drop}
      id={formikKey}
    >
      <div className={styled.row}>
        <div style={{ display: "flex" }}>
          {selected &&
          selected &&
          selected.name != "None" &&
          selected.id != null ? (
            <button
              className={clsx(styled.toggle, {
                [styled.toggleOpen]: isOpen,
              })}
              type="button"
              onClick={() => {
                setIsOpen((prev) => !prev);
              }}
            >
              <Arrow />
            </button>
          ) : (
            <></>
          )}
          <span className={styled.label}>{getItemLabel()}</span>
        </div>
        <div className={styled.selector}>
          <TextSelect
            options={options}
            value={selectValue}
            onChange={onSelect}
            onBlur={() => formik.setFieldTouched(formikKey, true)}
          />
        </div>
      </div>

      {!selected ||
      (selected && selected.name == "None" && selected.id == null) ? (
        <div className={styled.dnd}>
          <FormattedMessage {...waterBalanceDropItemMessages.dragAndDrop} />
        </div>
      ) : isOpen ? (
        getCurrentList()
      ) : (
        <></>
      )}
      {isError && <SpanError errorMessage={errorMessage} />}
    </div>
  );
};

export default WaterBalanceDropItem;
