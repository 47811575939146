import { Canvas } from "@react-three/fiber";
import { Html, Line, Loader, OrbitControls } from "@react-three/drei";

import { Sun } from "./Sun";
import { Floor } from "./Floor";
import { AllSolarPanel } from "./AllSolarPanel";
import { useEffect, useRef, useState } from "react";
import { IAddPointForm } from "components/AddPointModal/AddPointModal";
import styled from "./styled.module.scss";
import NorthArrow from "../../assets/icons/north_arrow.png";
import DisplayZone from "./DisplayZones";



export const Structure_3D = (props: {
  panel_height: number;
  panel_opacity: number;
  panel_x: number;
  panel_y: number;
  initial_offset_x: number;
  initial_offset_y: number;
  panels_number_x: number;
  panels_number_y: number;
  panels_gap_x: number;
  panels_gap_y: number;
  field_size_x: number;
  field_size_y: number;
  azimuth: number;
  structure_type: "tracker" | "fixed";
  static_angle: number;
  tracking_max_angle: number;
  backtracking: boolean;
  displacement_tracking: boolean;
  solar_position: "calculated" | "simple";
  azimuth_solar: number;
  zenith_solar: number;
  day: string | null;
  hour: number;
  lat: number;
  long: number;
  points: IAddPointForm[];
  defaultPositionBool: boolean;
  setDefaultPositionBool: Function;
  no_crop_zone: number;
}) => {
  const SunCalc = require("suncalc");

  var controlRef = useRef<any>(null!);
  var canvasRef = useRef<any>(null!);

  const handleResetCamera = () => {
    controlRef.current.target.set(0, 0, 0);
    controlRef.current.object.position.set(-0.1, 50, 0);
    controlRef.current.object.rotation.set(0, Math.PI / 2, 0);
    controlRef.current.update();
  };

  const [solarAngle, setSolarAngle] = useState(0);
  const distance = 100
  const [pos, setPos] = useState({ x: 0, y: 0, z: 0 });

  useEffect(() => {
    const timeout_id = setTimeout(() => {

      const date = new Date(props.day!)
      date.setHours(props.hour)
      date.setMinutes(30)
      const sunPosition = SunCalc.getPosition(
        date,
        props.lat,
        props.long
      );


      const azimuth = sunPosition.azimuth - Math.PI / 2 + Math.PI;
      const altitude = sunPosition.altitude;


      const sunX = -distance * Math.cos(altitude) * Math.sin(azimuth);
      const sunY = distance * Math.sin(altitude);
      const sunZ = distance * Math.cos(altitude) * Math.cos(azimuth);

      setSolarAngle(sunPosition.azimuth)
      setPos({ x: sunX, y: sunY, z: sunZ });
    }, 50)
    return () => clearTimeout(timeout_id)
  }, [props.day, props.hour]);

  const [compassRotation, setCompassRotation] = useState(-Math.PI / 2);
  const [pointDisplay, setPointDisplay] = useState(Object());

  useEffect(() => {
    if (props.defaultPositionBool) if (controlRef.current) handleResetCamera();
  }, [props.defaultPositionBool]);

  return (
    <>
      <div
        className={styled.compass}
        style={{
          transform: `rotate(${compassRotation + Math.PI / 2 + (props.azimuth * Math.PI) / 180
            }rad)`,
        }}
      >
        <img src={NorthArrow} alt="North-Arrow" />
      </div>
      <Canvas
        ref={canvasRef}
        shadows={"variance"}
        camera={{ position: [-0.1, 50, 0] }}
      >
        <Loader />
        <OrbitControls
          ref={controlRef}
          position={[0, 50, 0]}
          maxPolarAngle={Math.PI / 2.01}
          enablePan={false}
          onChange={(e) => {
            setCompassRotation(controlRef!.current.getAzimuthalAngle());
            if (props.defaultPositionBool) props.setDefaultPositionBool(false);
          }}
        />
        <Sun
          position={pos}
          distance={distance}
          field_height={props.field_size_y}
          field_width={props.field_size_x}
        />

        <Floor
          height={props.field_size_y}
          width={props.field_size_x}
          opacity={props.panel_opacity}
        />
        {props.points.map((point) => (
          <>
            <Line
              points={[
                [
                  point.y - props.field_size_y * 2.5,
                  0.1,
                  point.x - props.field_size_x / 2,
                ],
                [
                  point.y + props.field_size_y * 2.5,
                  0.1,
                  point.x - props.field_size_x / 2,
                ],
              ]}
              color="green"
              lineWidth={1}
              segments
              dashed={true}
            ></Line>
            <Line
              points={[
                [
                  point.y - props.field_size_y / 2,
                  0.1,
                  point.x - props.field_size_x * 2.5,
                ],
                [
                  point.y - props.field_size_y / 2,
                  0.1,
                  point.x + props.field_size_x * 2.5,
                ],
              ]}
              color="red"
              lineWidth={1}
              segments
              dashed={true}
            ></Line>
            <Html
              key={point.name}
              style={{
                transition: "all 0.5s",
              }}
              position={[
                point.y - props.field_size_y / 2,
                0.5,
                point.x - props.field_size_x / 2,
              ]}
            >
              <div className={styled.pointContainer}>{point.name}</div>
            </Html>
          </>
        ))}
        {AllSolarPanel({
          nb_panel_x: props.panels_number_x,
          nb_panel_y: props.panels_number_y,
          panel_span_x: props.panels_gap_x,
          panel_span_y: props.panels_gap_y,
          panel_height: props.panel_height,
          panel_size_x: props.panel_x,
          panel_size_y: props.panel_y,
          panel_rotation: props.static_angle,
          opacity: props.panel_opacity,
          floor: { height: props.field_size_y, width: props.field_size_x },
          offset_x: props.initial_offset_x,
          offset_y: props.initial_offset_y,
          solar_azimuth: props.azimuth_solar,
          solar_zenith: props.zenith_solar,
          structure_type: props.structure_type,
          tracking_max_angle: props.tracking_max_angle,
          backtracking: props.backtracking,
          sun_position: pos,
          sun_angle: solarAngle,
        })}

        {props.no_crop_zone && DisplayZone({
          nb_panel_y: props.panels_number_y,
          nb_panel_x: props.panels_number_x,
          no_crop_zone: props.no_crop_zone,
          floor: { height: props.field_size_y, width: props.field_size_x },
          initial_offset_y: props.initial_offset_y,
          initial_offset_x: props.initial_offset_x,
          panel_width: props.panel_y,
          panels_gap_x: props.panels_gap_x,
          panels_gap_y: props.panels_gap_y,
          panel_x: props.panel_x,
          panel_y: props.panel_y,
          opacity: props.panel_opacity,
          type: props.structure_type,
        })}
      </Canvas>
    </>
  );
};
