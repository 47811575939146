import React from "react";
import Button from "components/common/button/Button";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import styled from "./LeaveModalWindow.module.scss";
import commonMessages from "components/common/CommonMessages";
import { FormattedMessage } from "react-intl";
import LeaveModalWindowMessage from "./LeaveModalWindowMessages";

interface ISaveTemplate {
  onClose?: () => void;
  onSubmit?: () => void;
  isLoading?: boolean;
}

function CancelTemplate({ isLoading, onClose, onSubmit }: ISaveTemplate) {
  return (
    <>
      <Icon name={ICON_NAMES_ENUM.warning} />
      <h3 className={styled.title}>
        <FormattedMessage {...LeaveModalWindowMessage.titleCancel} />
      </h3>
      <p className={styled.subtitle}>
        <FormattedMessage {...LeaveModalWindowMessage.descriptionSave} />
      </p>
      <div className={styled.row}>
        <Button onClick={onSubmit} isLoading={isLoading} disabled={isLoading}>
          <FormattedMessage {...LeaveModalWindowMessage.leavePage} />
        </Button>
        <Button variant="text" onClick={onClose} isLoading={isLoading} disabled={isLoading}>
          <FormattedMessage {...commonMessages.cancel} />
        </Button>
      </div>
    </>
  );
}

export { CancelTemplate };
