import { useEffect } from "react";
import { getNavigation } from "store/navigation/actions";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

export function useUpdateNavigation(callback?: any) {
  const dispatch = useDispatch();
  const { client_id, project_id } = useParams();

  useEffect(() => {
    dispatch(getNavigation({ clientId: client_id, projectId: project_id, callback }));
  }, [project_id, client_id, callback]);
}
