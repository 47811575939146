import { defineMessages } from "react-intl";

export default defineMessages({
  uploadDrop: {
    id: "dragUploaderMessages.uploadDrop",
    defaultMessage: "Drop your Excel file to upload",
  },
  orclick: {
    id: "dragUploaderMessages.orclick",
    defaultMessage: "or click",
  },
  here: {
    id: "dragUploaderMessages.here",
    defaultMessage: " here ",
  },
  to_select_from_PC: {
    id: "dragUploaderMessages.to_select_from_PC",
    defaultMessage: "to select from PC",
  },
  fileRequired: {
    id: "dragUploaderMessages.fileRequired",
    defaultMessage: "Dataset file is required",
  },
});
