import { useCallback, useState } from "react";

export default function useDebounceState<T>(
  initialValue: T,
  timeout: number = 300
): [v: T, q: T, setSearch: (v: T) => void] {
  const [value, setValue] = useState<T>(initialValue);
  const [queryVal, setQueryVal] = useState<T>(initialValue);
  const [cbTimeout, setCbTimeout] = useState<number | null>(null);

  const setSearch = useCallback(
    (newValue: T) => {
      setValue(newValue);

      if (cbTimeout) clearTimeout(cbTimeout);

      setCbTimeout(
        window.setTimeout(async () => {
          await setQueryVal(newValue);

          if (cbTimeout) {
            clearTimeout(cbTimeout);
            setCbTimeout(null);
          }
        }, timeout)
      );
    },
    [setCbTimeout, setValue, cbTimeout, timeout]
  );

  return [value, queryVal, setSearch];
}
