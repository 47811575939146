export const getBatchesState = (state) => state.batches;
export const getBatchesListState = (state) => getBatchesState(state).list;
export const getBatchesLoadingState = (state) => getBatchesState(state).isLoading;
export const getBatchResultsState = (state) => getBatchesState(state).batchResults;
export const getBatchSimulationResultState = (state) => getBatchesState(state).simulationResult;
export const getBatchesParameters = (state) => getBatchesState(state).parameters;
export const getBatchById = (batchId) => (state) => {
  const list = getBatchesState(state).list;
  return list.find(({ id }) => id.toString() === batchId?.toString())
}
