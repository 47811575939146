export const getWaterBalancesState = (state) => state.water_balances;
export const getWaterBalancesListState = (state) => getWaterBalancesState(state).list;
export const getWaterBalancesLoadingState = (state) => getWaterBalancesState(state).isLoading;
export const getWaterBalanceItemState = (state) => getWaterBalancesState(state).single;
export const getWaterBalanceResultState = (state) => getWaterBalancesState(state).result;

export const getWaterBalancesById = (projectId) => (state) => {
  const list = getWaterBalancesState(state).list;
  return list.find(({ id }) => id.toString() === projectId?.toString())
}
