import React, { useState } from "react";
import { isEqual } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import { ReactComponent as Save } from "assets/icons/save.svg";
import styled from "./styled.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import EditName from "components/EditName/EditName";
import { useDispatch, useSelector } from "react-redux";
import waterBalanceCreateMessages from "pages/WaterBalanceCreate/WaterBalanceCreateMessages";
import { createSimulation, runSimulation } from "store/simulations/actions";
import MainContent from "components/MainContent/MainContent";
import { FormikHelpers, useFormik } from "formik";
import SimulationBody, {
  frequencyOptions,
} from "components/SimulationBody/SimulationBody";
import SimulationSchema from "pages/SimulationCreate/SimulationSchema";
import { usePrompt } from "hooks/usePromt";
import { useUpdateNavigation } from "hooks/useUpdateNavigation";
import WaterBalanceBody, {
  IWaterBalancesState,
} from "components/WaterBalanceBody/WaterBalanceBody";
import {
  createWaterBalance,
  runWaterBalance,
} from "store/water_balances/actions";
import WaterBalanceSchema from "./WaterBalanceSchema";
import { getSoilInformationsById } from "store/soil_informations/selectors";
import { ReactComponent as Play } from "assets/icons/play.svg";
import CommonMessages from "components/common/CommonMessages";
import { successNotifications } from "utils/successNotifications";

export const getDefaultValues = (intl: any): IWaterBalancesState => {
  return {
    name: intl.formatMessage(waterBalanceCreateMessages.waterBalanceUnnamed),
    description: "",
    resolution: 10,
    frequency: frequencyOptions[3].value,
    max_scale_value: 10000,
    enable_max_scale: false,
    structure_id: null,
    weather_dataset_id: null,
    production_dataset_id: null,
    ETP_dataset_id: null,
    universal_dataset_id: null,
    soil_information_id: null,
    crop_id: null,
    steering_algorithm_id: null,
    grassland_primary_yield_calculation: false,
    edge_effect: true,
  };
};

const WaterBalanceCreate = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client_id, project_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  useUpdateNavigation();

  const onChangePage = (id: string) =>
    navigate(
      ROUTES.WATER_BALANCES_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", id)
    );

  const onCreate = (
    values: IWaterBalancesState,
    actions: FormikHelpers<IWaterBalancesState>
  ) => {
    const objOut = {
      name: values.name,
      description: values.description,
      resolution: values.resolution,
      frequency: values.frequency,
      max_scale_value: values.max_scale_value,
      enable_max_scale: values.enable_max_scale,
      structure_id: values.structure_id,
      weather_dataset_id: values.weather_dataset_id,
      production_dataset_id: values.production_dataset_id,
      ETP_dataset_id: values.ETP_dataset_id,
      universal_dataset_id: values.universal_dataset_id,
      water_balance_id: values.soil_information_id,
      crop_id: values.crop_id,
      crop_for_water_balance_id: values.crop_id,
      steering_algorithm_for_water_balance_id: values.steering_algorithm_id,
      grassland_primary_yield_calculation:
        values.grassland_primary_yield_calculation,
    };

    dispatch(
      createWaterBalance({
        ...objOut,
        clientId: client_id,
        projectId: project_id,
        callback: (id: string) => {
          successNotifications({
            title: intl.formatMessage(CommonMessages.create),
            message: intl.formatMessage(CommonMessages.successCreate, {
              objet_type: intl.formatMessage(CommonMessages.waterBalance),
            }),
          });
          onChangePage(id);
        },
        finallyCallback: () => actions.setSubmitting(false),
      })
    );
  };

  const formik = useFormik({
    initialValues: getDefaultValues(intl),
    onSubmit: onCreate,
    validationSchema: WaterBalanceSchema(intl),
  });

  const soil_information = useSelector(
    getSoilInformationsById(formik.values.soil_information_id)
  );

  usePrompt(
    !isEqual(formik.values, getDefaultValues(intl)) &&
      !formik.isSubmitting &&
      !isLoading
  );

  const onBack = () =>
    navigate(
      ROUTES.WATER_BALANCES.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );

  const toResultPage = (waterBalanceId: string, resultId: string) =>
    navigate(
      ROUTES.WATER_BALANCES_RESULT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":water_balance_id", waterBalanceId)
        .replace(":id", resultId)
    );

  const runSimulate = (waterBalanceId: string) => {
    dispatch(
      runWaterBalance({
        clientId: client_id,
        projectId: project_id,
        waterBalanceId: waterBalanceId,
        callback: (id: string) => toResultPage(waterBalanceId, id),
      })
    );
  };

  const onRunSimulation = () => {
    setIsLoading(true);
    formik.validateForm().then((errors: any) => {
      Object.keys(errors).forEach((key) => formik.setFieldTouched(key));
    });

    if (formik.isValid) {
      const objOut = {
        name: formik.values.name,
        description: formik.values.description,
        resolution: formik.values.resolution,
        frequency: formik.values.frequency,
        max_scale_value: formik.values.max_scale_value,
        enable_max_scale: formik.values.enable_max_scale,
        structure_id: formik.values.structure_id,
        weather_dataset_id: formik.values.weather_dataset_id,
        production_dataset_id: formik.values.production_dataset_id,
        ETP_dataset_id: formik.values.ETP_dataset_id,
        universal_dataset_id: formik.values.universal_dataset_id,
        water_balance_id: formik.values.soil_information_id,
        crop_id: formik.values.crop_id,
        crop_for_water_balance_id: formik.values.crop_id,
        steering_algorithm_for_water_balance_id:
          formik.values.steering_algorithm_id,
        grassland_primary_yield_calculation:
          formik.values.grassland_primary_yield_calculation,
      };

      dispatch(
        createWaterBalance({
          ...objOut,
          clientId: client_id,
          projectId: project_id,
          callback: runSimulate,
        })
      );
    } else {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContent
        header={
          <>
            <EditName
              autoFocus
              onFocus={(event) => {
                event.target.select();
              }}
              placeholder={intl.formatMessage(
                waterBalanceCreateMessages.waterBalanceName
              )}
              onChange={formik.handleChange}
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              errorMessage={
                formik.touched.name ? (formik.errors.name as string) : ""
              }
            />

            <div className={styled.flex}>
              <Button
                disabled={isLoading}
                isLoading={isLoading}
                onClick={onRunSimulation}
                iconBefore={<Play />}
                className={styled.addPoint}
              >
                <FormattedMessage {...CommonMessages.run} />
              </Button>
              <Button variant="text" onClick={onBack}>
                <FormattedMessage {...commonMessages.cancel} />
              </Button>

              <Button
                iconBefore={<Save />}
                type="submit"
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting}
              >
                <FormattedMessage {...commonMessages.save} />
              </Button>
            </div>
          </>
        }
      >
        <WaterBalanceBody formik={formik} onRunSimulation={onRunSimulation} />
      </MainContent>
    </form>
  );
};

export default WaterBalanceCreate;

