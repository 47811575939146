import { ISoilInformations } from "./SoilInformationsCreateTypes";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { FormikHelpers, useFormik } from "formik";
import SoilInformationsSchema from "./SoilInformationsSchema";
import { useIntl } from "react-intl";
import { createSoilInformation } from "store/soil_informations/actions";
import SoilInformationsCreateMessages from "./SoilInformationsCreateMessages";
import { successNotifications } from "utils/successNotifications";
import CommonMessages from "components/common/CommonMessages";

export const getInitialFormValue = (intl: any): ISoilInformations => {
  return {name: intl.formatMessage(SoilInformationsCreateMessages.soilInformationUnnamed),
  ru_water: 0,
  rfu_water: 0,
  irrigation: false,}
};

function SoilInformationsCreateHook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const { client_id, project_id } = useParams();

  const onBack = () =>
    navigate(
      ROUTES.SOIL_INFORMATIONS.replace(
        ":client_id",
        client_id as string
      ).replace(":project_id", project_id as string)
    );
  const onEditPage = (id: number | string) =>
    navigate(
      ROUTES.SOIL_INFORMATION_EDIT.replace(
        ":client_id",
        client_id as string
      ).replace(":project_id", project_id as string).replace(":id", id as string)
    );

  const createSoilInformationFunc = (
    values: ISoilInformations,
    actions: FormikHelpers<ISoilInformations>
  ) => {
    var soil = {
      name: values.name,
      ru_water: values.ru_water,
      rfu_water: values.rfu_water,
      irrigation: values.irrigation,
    };

    dispatch(
      createSoilInformation({
        ...soil,
        clientId: client_id,
        projectId: project_id,
        callback: ({data}: any) => {
          successNotifications({title: intl.formatMessage(CommonMessages.create), message: intl.formatMessage(CommonMessages.successCreate, {objet_type: intl.formatMessage(CommonMessages.soil_information)})})
          onEditPage(data.id)
        },
        finallyCallback: () => actions.setSubmitting(false),
      })
    );
  };

  const formik = useFormik({
    validationSchema: SoilInformationsSchema(intl),
    onSubmit: createSoilInformationFunc,
    initialValues: getInitialFormValue(intl),
  });

  return {
    models: {
      client_id,
      project_id,
      formik,
    },
    commands: {
      onBack
    }
  };
}

export { SoilInformationsCreateHook };
