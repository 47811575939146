import React, {useEffect, useState} from "react";
import styled from "./styled.module.scss";
import Button from "components/common/button/Button";
import Header from "components/Header/Header";
import SortDropdown from "components/SortDropdown/SortDropdown";
import ClientCard, { IClient } from "components/ClientCard/ClientCard";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import ClientModal from "components/ClientModal/ClientModal";
import { FormattedMessage } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import { useDispatch, useSelector } from "react-redux";
import {
  getClientById,
  getClientsListState,
  getClientsLoadingState,
} from "store/clients/selectors";
import { deleteClient } from "store/clients/actions";
import withAuthProtection from "hoc/authProtection";
import { Loader } from "components/common/loader/Loader";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import {getUserDataState} from "store/user/selectors";
import {IUser, UserRoles} from "components/SettingUserCard/SettingUserCard";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "constants/routes";

const ClientsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const clientsList = useSelector(getClientsListState);
  const isLoading = useSelector(getClientsLoadingState);
  const [isShowModal, setIsShowModal] = useState(false);
  const [editId, setEditId] = useState<string | number | undefined>(undefined);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const editClient = useSelector(getClientById(editId));
  const user: IUser = useSelector(getUserDataState);

  useEffect(() => {
    if (!!user && user.role.name !== UserRoles.ADMIN) {
      navigate(ROUTES.PROJECTS_LIST.replace(':client_id', String(user.client.id)))
    }
  }, [user]);


  const onHideWarning = () => setDeleteId(null);

  const onShowWarning = (id: number) => () => {
    setDeleteId(id);
  };

  const onHideModal = () => {
    setIsShowModal(false);
    setEditId(undefined);
  };

  const onShowModal = () => {
    setIsShowModal(true);
  };

  const onEdit = (id: string | number) => () => {
    setEditId(id);
    setIsShowModal(true);
  };

  const onDelete = () => {
    dispatch(
      deleteClient({
        id: deleteId,
        callback: onHideWarning,
      })
    );
  };

  return (
    <>
      <Header />
      <main className={styled.wrapper}>
        <section className={styled.headerSection}>
          <h1 className={styled.title}>
            <FormattedMessage {...commonMessages.clients} />
          </h1>

          <div className={styled.control}>
            <SortDropdown type="client" />
            <Button onClick={onShowModal} iconBefore={<Plus />}>
              <FormattedMessage {...commonMessages.createNew} />
            </Button>
          </div>
        </section>

        {isLoading ? (
          <Loader />
        ) : (
          <section className={styled.projectsSection}>
            {clientsList.map((client: IClient) => (
              <ClientCard
                key={client.id}
                client={client}
                onDelete={onShowWarning(client.id)}
                onEdit={onEdit(client.id)}
              />
            ))}
          </section>
        )}
      </main>
      <ClientModal
        show={isShowModal}
        onHide={onHideModal}
        editId={editId}
        {...(editId && { initialValues: editClient })}
      />
      <LeaveModalWindow
        type={LeaveModalEnum.DELETE}
        onDelete={onDelete}
        show={deleteId !== null}
        onHide={onHideWarning}
      />
    </>
  );
};

export default withAuthProtection(ClientsList);
