import { defineMessages } from "react-intl";

export default defineMessages({
  edit : {
    id             : "CardMenuMessages.edit",
    defaultMessage : "Edit",
  },
  delete : {
    id             : "CardMenuMessages.delete",
    defaultMessage : "Delete",
  },
});
