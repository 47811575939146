import { object, string, mixed, number, array } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";

const DatasetAnalysisSchema = (intl: IntlShape) =>
  object().shape({
    weather_dataset_id: number()
      .required(intl.formatMessage(validationMessages.required))
      .positive(intl.formatMessage(validationMessages.required)),
    ETP_dataset_id: number()
      .required(intl.formatMessage(validationMessages.required))
      .positive(intl.formatMessage(validationMessages.required)),
    other_weather_dataset_id: number()
      .nullable()
      .required(intl.formatMessage(validationMessages.required))
      .positive(intl.formatMessage(validationMessages.required)),
    other_ETP_dataset_id: number()
      .nullable()
      .required(intl.formatMessage(validationMessages.required))
      .positive(intl.formatMessage(validationMessages.required)),
  });

export default DatasetAnalysisSchema;
