import routine from 'store/services/routine';

export const getBatchesWaterBalance = routine('GET_BATCHES_WATER_BALANCE');
export const createBatchWaterBalance = routine('CREATE_BATCH_WATER_BALANCE');
export const copyBatchWaterBalance = routine('COPY_BATCH_WATER_BALANCE');
export const deleteBatchWaterBalance = routine('DELETE_BATCH_WATER_BALANCE');
export const updateBatchWaterBalance = routine('UPDATE_BATCH_WATER_BALANCE');
export const getBatchWaterBalanceParametersOfInterest = routine('GET_BATCH_WATER_BALANCE_PARAMETERS_INTEREST');

export const runBatchWaterBalanceSimulation = routine('RUN_BATCH_WATER_BALANCE_SIMULATION');
export const cancelBatchWaterBalanceSimulation = routine('CANCEL_BATCH_WATER_BALANCE_SIMULATION');
export const getBatchWaterBalanceResults = routine('GET_BATCH_WATER_BALANCE_RESULTS');
export const deleteBatchWaterBalanceResult = routine('DELETE_BATCH_WATER_BALANCE_RESULT');
export const getBatchWaterBalanceSimulationResult = routine('GET_BATCH_WATER_BALANCE_SIMULATION_RESULT');
export const resetBatchWaterBalanceResults = routine('RESET_BATCH_WATER_BALANCE_RESULTS');
export const resetBatchWaterBalanceSimulationResult = routine('RESET_BATCH_WATER_BALANCE_SIMULATION_RESULTS');


