import React, { LabelHTMLAttributes } from "react";
import clsx from "clsx";
import styled from "./styled.module.scss";

interface ILabel extends LabelHTMLAttributes<HTMLLabelElement> {
  children: string | React.ReactElement;
}

const Label = ({ children, className, ...rest }: ILabel) => {
  return (
    <label className={clsx(styled.label, className)} {...rest}>
      {children}
    </label>
  );
};

export default Label;
