import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { runSizingOptimizationSimulation as action } from "store/sizing_optimizations/actions";
// api
import { runSizingOptimizationSimulation as api } from "store/sizing_optimizations/api";

import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(
      action.success({...response.data})
    );

    payload?.callback(response.data.id);
  } catch (error) {
    console.error(error)
    yield call(errorNotifications, error.response?.data);
    yield put(action.failure(error));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* runBatchSimulation() {
  yield takeLatest(action.TRIGGER, saga);
}
