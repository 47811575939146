import { useEffect } from "react";
import AddRowSchema from "./AddRowSchema";
import { useFormik } from "formik";
import { ITableFirstFields } from "./AddModalRowSteeringAlgorithmsTableTypes";
import {
  IRowField,
  IRowState,
  IRowType,
  getRowTypeValue,
} from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmsCreateTypes";
import { useIntl } from "react-intl";
import validationMessages from "components/common/ValidationMessages";

const defaultFormValue: ITableFirstFields = {
  type: IRowType.time_slot,
  state: IRowState.max_irradiance,
  value: getRowTypeValue(IRowType.time_slot),
  period_id: 0,
};

export interface IRowsCreateHook {
  onSave: (v: IRowField) => void;
  onEdit: (v: IRowField) => void;
  rows: Array<IRowField>;
  isEdit: boolean;
  currentRow?: IRowField | undefined;
  currentPeriodId?: number;
  show: boolean;
}

interface IError {
  hourStart?: string;
  hourEnd?: string;
}

function AddModalRowSteeringAlgorithmsTableHooks({
  onSave,
  rows,
  isEdit,
  onEdit,
  currentRow,
  currentPeriodId,
  show,
}: IRowsCreateHook) {
  const intl = useIntl();

  const validate = (values: any) => {
    const errors: IError = {};
    if (!values.hourStart && !values.hourEnd) {
      errors.hourStart = intl.formatMessage(validationMessages.required);
      errors.hourEnd = intl.formatMessage(validationMessages.required);
    } else if (!values.hourStart) {
      errors.hourStart = intl.formatMessage(validationMessages.required);
    } else if (!values.hourEnd) {
      errors.hourEnd = intl.formatMessage(validationMessages.required);
    } else if (values.hourStart > values.hourEnd) {
      errors.hourStart = intl.formatMessage(
        validationMessages.invalidDateRange
      );
      errors.hourEnd = intl.formatMessage(validationMessages.invalidDateRange);
    } else if (values.hourStart > 24 || values.hourStart < 0) {
      errors.hourStart = intl.formatMessage(
        validationMessages.invalidDateRange
      );
    } else if (values.hourEnd > 24 || values.hourEnd < 0) {
      errors.hourEnd = intl.formatMessage(validationMessages.invalidDateRange);
    }
    return errors;
  };
  const onSubmit = (values: ITableFirstFields) => {
    const dataForm = {
      period_id: values.period_id,
      type: values.type!,
      state: values.state!,
    };

    isEdit
      ? onEdit({
          ...values,
          ...dataForm,
          id: isEdit && currentRow ? currentRow.id : rows.length,
          value: values.value,
        })
      : onSave({
          ...values,
          ...dataForm,
          id: isEdit && currentRow ? currentRow.id : rows.length,
          value: values.value,
        });

    formik.resetForm();
  };
  const formik = useFormik<ITableFirstFields>({
    validationSchema: AddRowSchema(intl),
    validate,
    initialValues: defaultFormValue,
    onSubmit,
  });

  useEffect(() => {
    if (isEdit) {
      defaultFormValue.type = currentRow ? currentRow.type : undefined;
      defaultFormValue.state = currentRow ? currentRow.state : undefined;
      defaultFormValue.value = currentRow ? currentRow.value : {};
    } else {
      defaultFormValue.type = IRowType.time_slot;
      defaultFormValue.state = IRowState.max_irradiance;
      defaultFormValue.value = getRowTypeValue(IRowType.time_slot);
    }
    return () => {
      defaultFormValue;
    };
  }, [currentRow, isEdit, currentPeriodId]);

  useEffect(() => {
    if (!show) {
      formik.resetForm();
    }
  }, [show]);

  return {
    models: { formik },
    methods: { onSubmit },
  };
}

export { AddModalRowSteeringAlgorithmsTableHooks };
