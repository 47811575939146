import React from "react";
import styled from "./styled.module.scss";
import clsx from "clsx";
import { SpanError } from "components/common/span-error/SpanError";
import { IFormik } from "components/common/FormikType";
import CheckBox from "components/common/checkBox/CheckBox";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import { IWaterBalancesState } from "components/WaterBalanceBody/WaterBalanceBody";

interface IWaterBalanceCheckBox {
  formik: IFormik<IWaterBalancesState>;
  formikKey: keyof IWaterBalancesState;
  label: any;
  disabled?: boolean
}


const WaterBalanceCheckBox = ({
  formik,
  formikKey,
  label,
  disabled
}: IWaterBalanceCheckBox) => {
  const isError = formik.touched[formikKey] && !!formik.errors[formikKey];
  const errorMessage = formik.errors[formikKey];

  const onChangeCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!disabled)
      formik.setFieldValue(formikKey, !e.target.checked);
  };

  return (
    <div
      className={clsx(styled.container, {
        [styled.error]: isError,
        [styled.disabled]: disabled,
        [styled.checkBox]: true,
      })}
      onClick={() => !disabled && formik.setFieldValue(formikKey, !formik.values[formikKey])}
    >
      <div className={styled.row}>
        <div style={{ display: "flex" }}>
          <span className={styled["checkbox-label"]}>
            <Icon name={ICON_NAMES_ENUM.calculator} className={styled.icon} />
            {label}
          </span>
        </div>
        <div style={{ width: "fit-content", height: "fit-content" }}>
          <CheckBox
            label=""
            onChange={onChangeCheckBox}
            value={formikKey}
            checked={formik.values[formikKey] as boolean}
          />
        </div>
      </div>
      {isError && <SpanError errorMessage={errorMessage} />}
    </div>
  );
};

export default WaterBalanceCheckBox;
