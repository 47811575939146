import { defineMessages } from "react-intl";

export default defineMessages({
  userManagement : {
    id             : "SettingUsersMessages.userManagement",
    defaultMessage : "User Management",
  },
  inviteNewUser : {
    id             : "SettingUsersMessages.inviteNewUser",
    defaultMessage : "Invite New User",
  },
});
