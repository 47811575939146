import React from "react";
import styled from "./styled.module.scss";
import CardMenu from "components/CardMenu/CardMenu";
import moment from "moment";
import clientCardMessages from "components/ClientCard/ClientCardMessages";
import { FormattedMessage } from "react-intl";
import { Link, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Tooltip from "@mui/material/Tooltip/Tooltip";

export interface IProject {
  created_at: string;
  id: number;
  lat: number;
  long: number;
  name: string;
  updated_at: string;
}

interface IProjectCard {
  project: IProject;
  onEdit: () => void;
  onDelete: () => void;
}

const ProjectCard = ({ project, onEdit, onDelete }: IProjectCard) => {
  const intl = useIntl();
  const { client_id } = useParams();
  const linkPath = ROUTES.STRUCTURES.replace(
    ":client_id",
    client_id as string
  ).replace(":project_id", String(project.id));
  return (
    <div className={styled.container}>
      <div>
        <Link className={styled.name} to={linkPath}>
          {project.name}
        </Link>
        <span className={styled.date}>
          <FormattedMessage {...clientCardMessages.lastUpdate} />
          {": "}
          {moment.utc(project.updated_at).local().fromNow()}
        </span>
      </div>
      <Tooltip title={intl.formatMessage(commonMessages.delete)}>
        <CardMenu onEdit={onEdit} onDelete={onDelete} />
      </Tooltip>
    </div>
  );
};

export default ProjectCard;
