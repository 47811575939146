import { useEffect, useState } from "react";
import commonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import { ReactComponent as Save } from "assets/icons/save.svg";
import { ROUTES } from "constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getSingleDataset,
  getTimezones,
  updateDataset,
  deleteDataset,
  getDatasetAnalysis,
} from "store/datasets/actions";
import { getDatasetItem } from "store/datasets/selectors";
import { DatasetModel } from "components/DatasetModel/DatasetModel";
import EditName from "components/EditName/EditName";
import { FormattedMessage, useIntl } from "react-intl";
import datasetCreateMessages from "../DatasetsCreate/DatasetsEditMessages";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import styled from "./DatasetEdit.module.scss";
import MainContent from "components/MainContent/MainContent";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import { FormikHelpers, useFormik } from "formik";
import DatasetEditSchema from "./DatasetEditSchema";
import { IDataSet } from "../DatasetsCreate/DatasetsCreateTypes";
import { usePrompt } from "hooks/usePromt";
import { isEqual } from "lodash";
import {
  getDatasetDailyChart,
  getDatasetMonthlyChart
} from "store/datasets/api";
import { getInitialFormValue } from "pages/DatasetsCreate/DatasetsCreate";
import { successNotifications } from "utils/successNotifications";
import CommonMessages from "components/common/CommonMessages";
import { Loader } from "components/common/loader/Loader";
import { getProjectById } from "store/projects/selectors";
import { printVarsHook } from "utils/printVarsHook";
import DatasetAnalysis from "components/DatasetAnalysis/DatasetAnalysis";

const color = ["#A66", "#6A6", "#66A", "#555", "#AA6", "#A6A", "#6AA"];

const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

function DatasetEdit() {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();
  const { client_id, project_id, id } = useParams();
  const datasetStore = useSelector(getDatasetItem);
  const [show, onShow] = useState(false);
  const [modalType, setModalType] = useState<LeaveModalEnum>(
    LeaveModalEnum.SAVE
  );
  const [monthly_points, set_monthly_points] = useState(Array());
  const [daily_points, set_daily_points] = useState(Array());
  const actualProject = useSelector(getProjectById(project_id))

  useEffect(() => {
    dispatch(
      getSingleDataset({
        clientId: client_id,
        projectId: project_id,
        dataset_id: id,
      })
    );
    dispatch(getTimezones());
  }, [id]);

  useEffect(() => {
    if (datasetStore.id) {
      getDatasetMonthlyChart({
        clientId: client_id,
        projectId: project_id,
        dataset_id: datasetStore.id,
      })
        .then((res) => {
          set_monthly_points([
            ...res.data.map((item: any) => ({
              ...item,
              month: month[item.month - 1],
            })),
          ]);
        })
        .catch(() => set_monthly_points(Array()));
    }
  }, [datasetStore]);

  useEffect(() => {
    if (datasetStore.id) {
      getDatasetDailyChart({
        clientId: client_id,
        projectId: project_id,
        dataset_id: datasetStore.id,
      })
        .then((res) => {
          set_daily_points([...res.data.map((item: any) => ({ ...item }))]);
        })
        .catch(() => set_daily_points(Array()));
    }
  }, [datasetStore]);



  const onBack = () => {
    navigate(
      ROUTES.DATASETS.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );
    onShow(false);
  };

  const onUpdate = (values: IDataSet, actions: FormikHelpers<IDataSet>) => {
    dispatch(
      updateDataset({
        callback: () => {
          successNotifications({
            title: intl.formatMessage(CommonMessages.edit),
            message: intl.formatMessage(CommonMessages.successEdit, {
              objet_type: intl.formatMessage(CommonMessages.dataset),
            }),
          });
          dispatch(
            getSingleDataset({
              clientId: client_id,
              projectId: project_id,
              dataset_id: id,
            })
          );
        },
        finallyCallback: () => actions.setSubmitting(false),
        clientId: client_id,
        projectId: project_id,
        dataset_id: id,
        ...values,
      })
    );
  };

  const onDelete = () => {
    dispatch(
      deleteDataset({
        clientId: client_id,
        projectId: project_id,
        datasetId: id,
        callback: onBack,
      })
    );
  };

  const onExit = (type: LeaveModalEnum) => {
    setModalType(type);
    onShow(true);
  };

  const onHide = () => {
    onShow(false);
  };

  const onCancel = () =>
    navigate(
      ROUTES.DATASETS.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );

  const formik = useFormik<IDataSet>({
    validationSchema: DatasetEditSchema(intl),
    onSubmit: onUpdate,
    initialValues: getInitialFormValue(intl),
  });

  const isDataChanged = !isEqual(formik.values, datasetStore);

  usePrompt(isDataChanged && !formik.isSubmitting && !show);

  useEffect(() => {
    formik.setValues(datasetStore);
  }, [datasetStore, id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContent
        header={
          <>
            <EditName
              placeholder={intl.formatMessage(
                datasetCreateMessages.datasetName
              )}
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              errorMessage={formik.touched.name ? formik.errors.name : ""}
              onBlur={formik.handleBlur}
            />

            <div className={styled.buttons}>
              <Button
                onClick={() => onExit(LeaveModalEnum.DELETE)}
                iconBefore={<Icon name={ICON_NAMES_ENUM.delete} />}
                variant="text"
                className={styled.deleteButton}
              >
                <FormattedMessage {...commonMessages.delete} />
              </Button>
              <Button onClick={onBack} variant="text">
                <FormattedMessage {...commonMessages.cancel} />
              </Button>
              <Button
                iconBefore={<Save />}
                type="submit"
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting || !isDataChanged}
              >
                <FormattedMessage {...commonMessages.save} />
              </Button>
            </div>
          </>
        }
      >
        <div className={styled.list}>
          {
            actualProject ?
              <DatasetModel
                handelChange={formik.setFieldValue}
                formik={formik}
                errors={formik.errors}
                daily_points={!isDataChanged ? daily_points : undefined}
                monthly_points={!isDataChanged ? monthly_points : undefined}
                actual_dataset_type={datasetStore ? datasetStore.dataset_type : undefined}
                analysis_table_values={!isDataChanged ? {} : undefined}
                utc={actualProject.utc}
              />
              :
              <Loader />
          }

          <LeaveModalWindow
            type={modalType}
            onSave={formik.handleSubmit}
            onDelete={onDelete}
            onClose={onCancel}
            show={show}
            onHide={onHide}
          />
        </div>
        {datasetStore && !isDataChanged && <DatasetAnalysis datasetStore={datasetStore} />}
      </MainContent>
    </form>
  );
}

export { DatasetEdit };

