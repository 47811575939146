import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { createSizingOptimization as action } from "store/sizing_optimizations/actions";
// api
import { createSizingOptimization as api } from "store/sizing_optimizations/api";
import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload: { callback, finallyCallback, ...payload } }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success(response.data));

    if (callback) callback(response);
  } catch (error) {
    yield call(errorNotifications, error.response.data.data);
    yield put(action.failure(error));
  } finally {
    yield put(action.fulfill());
    if (finallyCallback) finallyCallback();
  }
}

export default function* createSizingOptimization() {
  yield takeLatest(action.TRIGGER, saga);
}
