import { SoftShadows } from "@react-three/drei";
import { useEffect, useRef } from "react";
import * as THREE from "three";

export const Sun = ({ position, distance, field_height, field_width }: any) => {

  const ref = useRef<any>(null)

  useEffect(() => {
    if (ref.current)
      ref.current.lookAt(new THREE.Vector3(0, 0, 0))
  }, [ref])
  return (
    <>
      <mesh position={new THREE.Vector3(position.x, position.y, position.z)}>
        <sphereGeometry args={[10, 32, 32]} />
        <meshBasicMaterial color="yellow" transparent />
      </mesh>
      <ambientLight intensity={0.5} color="#eaeaea" />
      <directionalLight
        position={new THREE.Vector3(position.x, position.y, position.z)}
        castShadow
        shadow-mapSize-width={4096}
        shadow-mapSize-height={4096}
      >
        <orthographicCamera
          attach="shadow-camera"
          args={[
            Math.sqrt(
              Math.pow(field_height, 2) + Math.pow(field_width, 2)
            ) / 2,
            -Math.sqrt(
              Math.pow(field_height, 2) + Math.pow(field_width, 2)
            ) / 2,
            Math.sqrt(
              Math.pow(field_height, 2) + Math.pow(field_width, 2)
            ) / 2,
            -Math.sqrt(
              Math.pow(field_height, 2) + Math.pow(field_width, 2)
            ) / 2,
          ]}

        />
      </directionalLight>
    </>
  );
};