import React from "react";
import { ReactComponent as Crops } from "assets/icons/crops.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as Copy } from "assets/icons/copy.svg";
import { ReactComponent as Steering } from "assets/icons/settings.svg";
import { ReactComponent as Export } from "assets/icons/export.svg";
import styled from "./styled.module.scss";
import clsx from "clsx";
import moment from "moment";
import { Tooltip } from "@mui/material";
import { useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";

interface ICropsCard {
  name: string;
  date: string;
  onEdit: () => void;
  onDelete: () => void;
  onCopy: () => void;
  onCreateSteeringAlgorithm: () => void;
  onCopyToOtherProject: () => void;
}

const CropsCard = ({
  name,
  date,
  onEdit,
  onCopy,
  onDelete,
  onCreateSteeringAlgorithm,
  onCopyToOtherProject
}: ICropsCard) => {
  const intl = useIntl()
  const stopPropagation =
    (event: React.MouseEvent<HTMLButtonElement>) => (obj: () => void) => {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      obj();
    };

    const duplicateToOtherProjectCard = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      onCopyToOtherProject();
    };

  return (
    <div className={styled.container} onClick={onEdit}>
      <div className={styled.card}>
        <div className={styled.flex}>
          <Crops />
          <p className={styled.name}>{name}</p>
        </div>

        <div className={styled.flex}>
          <span className={styled.date}>
            {moment.utc(date).local().format("DD MMMM YYYY, HH:mm")}
          </span>
          <Tooltip title={intl.formatMessage(commonMessages.createSteeringForCrop).replace(':crop_name', name)}>
            <button
              className={styled.button}
              onClick={(e) => stopPropagation(e)(onCreateSteeringAlgorithm)}
              type="button"
            >
              <Steering />
            </button>
          </Tooltip>
          <span className={styled.vertical} />
          <Tooltip title={intl.formatMessage(commonMessages.edit)}>
            <button className={styled.button} onClick={onEdit} type="button">
              <Edit />
            </button>
          </Tooltip>
          <span className={styled.vertical} />
          <Tooltip title={intl.formatMessage(commonMessages.copy)}>
            <button
              className={styled.button}
              onClick={(e) => stopPropagation(e)(onCopy)}
              type="button"
            >
              <Copy />
            </button>
          </Tooltip>
          <Tooltip title={intl.formatMessage(commonMessages.copy_to_other_project)}>
            <button
              className={clsx(styled.button, styled.copy_to_other_project)}
              onClick={duplicateToOtherProjectCard}
              type="button"
            >
              <Export />
            </button>
          </Tooltip>
          <span className={styled.vertical} />
          <Tooltip title={intl.formatMessage(commonMessages.delete)}>
            <button
              type="button"
              className={clsx(styled.button, styled.red)}
              onClick={(e) => stopPropagation(e)(onDelete)}
            >
              <Delete />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default CropsCard;
