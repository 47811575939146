import styled from "components/WaterBalanceDropItem/styled.module.scss";
import Line from "components/SimulationDropItem/Line";

const SoilInformationsRenderList = ({ selected }: { selected: any }) => {
  return (
    <ul className={styled.ul}>
      <Line title="Name" value={selected.name} />
      <Line title="Water Storage Capacity" value={`${selected.ru_water} mm`} />
      <Line title="Easily Usable Water Capacity" value={`${selected.rfu_water} mm`} />
      <Line title="Irrigation" value={selected.irrigation ? 'True' : 'False'} />
    </ul>
  );
};

export default SoilInformationsRenderList;
