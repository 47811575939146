import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getProjectsList = ({ clientId, sort }) =>
  apiGET({
    url: `/clients/${clientId}/projects`,
    params: {
      ...sort,
    },
  });

export const createProject = ({ clientId, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/projects`,
    data,
  });

export const deleteProject = ({ clientId, projectId }) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}`,
  });

export const updateProject = ({ clientId, projectId, ...data }) =>
  apiPATCH({
    url: `/clients/${clientId}/projects/${projectId}`,
    data,
  });
