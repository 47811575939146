import { defineMessages } from "react-intl";

export default defineMessages({
  enqueuedMain : {
    id             : "StatusLabelMessages.enqueuedMain",
    defaultMessage : "Enqueued main",
  },
  running : {
    id             : "StatusLabelMessages.running",
    defaultMessage : "Running",
  },
  runningMain : {
    id             : "StatusLabelMessages.runningMain",
    defaultMessage : "Running main",
  },
  runningPeriods : {
    id             : "StatusLabelMessages.runningPeriods",
    defaultMessage : "Running periods",
  },
  completed : {
    id             : "StatusLabelMessages.completed",
    defaultMessage : "Completed",
  },
  failed : {
    id             : "StatusLabelMessages.failed",
    defaultMessage : "Failed",
  },
  canceled : {
    id             : "StatusLabelMessages.canceled",
    defaultMessage : "Canceled",
  },
  canceling : {
    id             : "StatusLabelMessages.canceling",
    defaultMessage : "Canceling",
  },
});
