import { defineMessages } from "react-intl";

export default defineMessages({
  changeStatus : {
    id             : "ChangeUserStatusModalMessages.changeStatus",
    defaultMessage : "Change Status",
  },
  text : {
    id             : "ChangeUserStatusModalMessages.text",
    defaultMessage : "Are you sure you want to change status of user?",
  },
});
