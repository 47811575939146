import React, { forwardRef } from "react";
import { ReactComponent as Batch } from "assets/icons/batch.svg";
import { ReactComponent as BatchResult } from "assets/icons/batch-result.svg";
import { ReactComponent as Crops } from "assets/icons/crops.svg";
import { ReactComponent as DataElec } from "assets/icons/data-elec.svg";
import { ReactComponent as DataTemp } from "assets/icons/data-temp.svg";
import { ReactComponent as Simulation } from "assets/icons/simulation.svg";
import { ReactComponent as SimulationResult } from "assets/icons/simulation-result.svg";
import { ReactComponent as Structures } from "assets/icons/structures.svg";
import { ReactComponent as TerrainIcon } from "assets/icons/terrain-icon.svg";
import styled from "./styled.module.scss";
import { Link } from "react-router-dom";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";

export interface IAccordionItem {
  icon: "b" | "br" | "c" | "de" | "dt" | "si" | "sr" | "s" | "so";
  name: string;
  to?: string;
}

const AccordionItem = forwardRef<HTMLDivElement, IAccordionItem>(
  ({ icon, name, to }, ref) => {
    const getIcon = (key: IAccordionItem["icon"]) => {
      switch (key) {
        case "b":
          return <Batch />;
        case "br":
          return <BatchResult />;
        case "c":
          return <Crops />;
        case "de":
          return <DataElec />;
        case "dt":
          return <DataTemp />;
        case "si":
          return <Simulation />;
        case "sr":
          return <SimulationResult />;
        case "s":
          return <Structures />;
        case "so":
          return (
            <Icon
              name={ICON_NAMES_ENUM.terrain_icon}
              className={styled.file__icon}
            />
          );
      }
    };

    const Component = !!to ? Link : "span";

    return (
      <div ref={ref} className={styled.container}>
        {getIcon(icon)}
        <Component to={to as string} className={styled.label}>
          {name}
        </Component>
      </div>
    );
  }
);

export default AccordionItem;
