import { object, string, number, boolean } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";
import steeringAlgorithmMessages from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmsCreateMessages";

const SoilInformationsSchema = (intl: IntlShape) =>
  object().shape({
    name: string()
      .max(50, intl.formatMessage(validationMessages.maxLength, { number: 50 }))
      .required(intl.formatMessage(validationMessages.required)),
      ru_water: number().moreThan(0),
      rfu_water: number().moreThan(0),
      irrigation: boolean(),
  });

export default SoilInformationsSchema;
