import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { runWaterBalance as action } from "store/water_balances/actions";
// api
import { runWaterBalance as api } from "store/water_balances/api";
import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(
      action.success({
        ...response.data,
        waterBalanceId: payload.waterBalanceId,
      })
    );

    payload?.callback(response.data.id);
  } catch (error) {
    yield call(errorNotifications, error.response?.data);
    yield put(action.failure(error));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* runWaterBalance() {
  yield takeLatest(action.TRIGGER, saga);
}
