import { defineMessages } from "react-intl";

export default defineMessages({
  deleteUser : {
    id             : "DeleteUserModalMessages.inviteNewUser",
    defaultMessage : "Delete user",
  },
  text : {
    id             : "DeleteUserModalMessages.text",
    defaultMessage : "Are you sure you want to delete user?",
  },
});
