import { defineMessages } from "react-intl";

export default defineMessages({
  Name: {
    id: "SortDropdownMessages.Name",
    defaultMessage: "Name",
  },
  name: {
    id: "SortDropdownMessages.name",
    defaultMessage: "name",
  },
  Date: {
    id: "SortDropdownMessages.Date",
    defaultMessage: "Date",
  },
  sortBy: {
    id: "SortDropdownMessages.sortBy",
    defaultMessage: "Sort by",
  },
});
