import React, { Fragment } from "react";
import styled from "components/SimulationDropItem/styled.module.scss";
import Line from "components/SimulationDropItem/Line";
import { ICrops } from "pages/Crops/Crops";
import moment from "moment";
import { ICropType } from "pages/CropsCreate/CropsCreateTypes";

const CropsRenderList = ({ selected }: { selected: ICrops }) => {
  const type =
    selected.periods[0].start_date && selected.periods[0].end_date
      ? ICropType.normalDates
      : ICropType.degreeDays;
  return (
    <ul className={styled.ul}>
      {type == ICropType.degreeDays && (
        <>
          <Line title="Sowing Date" value={selected.periods[0].sowing_date!} />
          <Line title="Base Degree Day" value={selected.periods[0].base_degree_day!} />
        </>
      )}

      <br />

      {selected.periods.map((period, i) => (
        <Fragment key={i}>
          {i > 0 && <br />}
          <Line title="Stage Name" value={period.name} />
          <Line title="Crop Height (m)" value={period.crop_height} />
          {type == ICropType.normalDates && (
            <>
              <Line
                title="Start Date"
                value={moment(period.start_date).format("DD MMM")}
              />
              <Line
                title="End Date"
                value={moment(period.end_date).format("DD MMM")}
              />
            </>
          )}
          {type == ICropType.degreeDays && (
            <>
              <Line title="Start Degree Day" value={period.start_degree_day!} />
              <Line title="End Degree Day" value={period.end_degree_day!} />
            </>
          )}
          <Line title="Cultural Coefficient" value={period.crop_coefficient} />
        </Fragment>
      ))}
    </ul>
  );
};

export default CropsRenderList;
