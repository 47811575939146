import React, { InputHTMLAttributes } from "react";
import styled from "./styled.module.scss";
import clsx from "clsx";
import { SpanError } from "../span-error/SpanError";

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  label?: any;
  beforeIcon?: React.ReactElement | string;
  afterIcon?: React.ReactElement | string;
  isError?: boolean;
  errorMessage?: string;
  noBorder?: boolean;
  integerNumber?: boolean;
  rightContent?: React.ReactElement | null;
}

const Input = ({
  name,
  label,
  beforeIcon,
  afterIcon,
  className,
  isError = false,
  errorMessage,
  noBorder = false,
  integerNumber,
  type,
  rightContent = null,
  ...rest
}: IInput) => {
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      type === "number" &&
      integerNumber &&
      (e.key === "." || e.key === ",")
    ) {
      e.preventDefault();
    }
  };

  return (
    <div className={clsx(styled.container, className)}>
      {!!label && (
        <label className={styled.label} htmlFor={name}>
          {label}
        </label>
      )}

      <div
        className={clsx(styled.inputContainer, {
          [styled.noBorder]: noBorder,
          [styled.errorBorder]: !!errorMessage || isError,
        })}
      >
        {!!beforeIcon && <div className={styled.beforeIcon}>{beforeIcon}</div>}
        <input
          className={clsx(styled.input, {
            [styled.inputWithBeforeIcon]: !!beforeIcon,
            [styled.inputWithAfterIcon]: !!afterIcon,
            [styled.minPadding]: noBorder,
          })}
          id={name}
          name={name}
          type={type}
          onKeyDown={onKeyDown}
          {...rest}
        />
        {rightContent}
        {!!afterIcon && <span className={styled.afterIcon}>{afterIcon}</span>}
      </div>
      <SpanError errorMessage={errorMessage} />
    </div>
  );
};

export default Input;
