import { put, takeLatest } from "redux-saga/effects";
import { getClientSort as action } from "store/clients/actions";
import localforage from "store/services/localForage";

function* saga() {
  try {
    yield put(action.request());

    const sort = yield localforage.getItem("client-sort");

    yield put(action.success(JSON.parse(sort)));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(action.fulfill());
  }
}

export default function* getClientSort() {
  yield takeLatest(action.TRIGGER, saga);
}
