import { useEffect, useMemo, useRef } from "react";
import Fuse from "fuse.js";

export default function useFuse<T>(
  initialValue: ReadonlyArray<T>,
  search: string,
  options: Fuse.IFuseOptions<T>
) {
  const fuse = useRef(new Fuse(initialValue, options));

  useEffect(() => {
    fuse.current.setCollection(initialValue);
  }, [initialValue]);

  return useMemo(
    () =>
      search
        ? fuse.current.search(search).map(({ item }) => item)
        : initialValue,
    [search, initialValue]
  );
}
