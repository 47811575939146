import { useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "components/common/select/Select";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Text as RechartText,
  ResponsiveContainer,
  ReferenceArea,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import chartToCsv from "utils/chartToCsv";
import chartToPng from "utils/chartToPng";
import SimulationResultMessages from "pages/SimulationResult/SimulationResultMessages";
import moment from "moment";
import WaterBalanceResultBodyMessages from "components/WaterBalanceResultBody/WaterBalanceResultBodyMessages";
import WaterBalanceResultMenuMessages from "components/WaterBalanceResultMenu/WaterBalanceResultMenuMessages";

const irrUnites = ["Fraction", "Daily PAR"];

class GrasslandYieldContentProps {
  result: any;
}

function getEquidistantDates(crop: any, n: number) {
  const startDateList = crop.periods.map(
    (period: any) => new Date(period.start_date)
  );
  const endDateList = crop.periods.map(
    (period: any) => new Date(period.end_date)
  );
  const startDate = new Date(Math.min(...startDateList));
  const endDate = new Date(Math.max(...endDateList));
  const start = new Date(startDate);
  const end = new Date(endDate);
  const totalDiff = end.getTime() - start.getTime();
  const interval = totalDiff / (n + 1);
  const dates = [];
  for (let i = 1; i <= n; i++) {
    const date = new Date(start.getTime() + interval * i);
    dates.push(moment(date).format("YYYY-MM-DD"));
  }

  return dates;
}

const GrasslandYieldContent = ({ result }: GrasslandYieldContentProps) => {
  const intl = useIntl();
  const unitePeriodIrrOptions = useMemo(() => {
    return irrUnites.map((item) => ({
      value: item,
      label: item,
    }));
  }, [irrUnites]);
  const [inactiveKeysPeriod, setInactiveKeyPeriod] = useState(Array<string>());

  const daily_grassland_points = useMemo(() => {
    if (!result.simulation.grassland_primary_yield_calculation) return null;

    var return_obj = {
      cumulated: Array<any>(),
      normal: Array<any>(),
    };
    for (let i = 0; i < result.result_data[0].result_daily_data.length; i++) {
      const element = result.result_data[0].result_daily_data[i];
      if (element.yield_ref != null) {
        return_obj.cumulated.push({
          date: element.date,
          zt: element.yield_ref,
          under_panel: element.yield_under_panels,
          between_panel: element.yield_between_panels,
          agri_pv: element.yield_agripv,
        });
        return_obj.normal.push({
          date: element.date,
          zt: element.growth_velocity_ref,
          under_panel: element.growth_velocity_under_panels,
          between_panel: element.growth_velocity_between_panels,
          agri_pv: element.growth_velocity_agripv,
        });
      }
    }

    return return_obj;
  }, [result]);

  const grassland_table_values = useMemo(() => {
    if (!result.simulation.grassland_primary_yield_calculation) return null;

    const return_obj = [];
    const endDateList = result.simulation.crop_for_water_balance.periods.map(
      (period: any) => new Date(period.end_date)
    );
    const cropEndDate = new Date(Math.max(...endDateList));
    for (
      let index = 0;
      index < result.simulation.crop_for_water_balance.periods.length;
      index++
    ) {
      const element = result.simulation.crop_for_water_balance.periods[index];
      const daily_data = result.result_data[0].result_daily_data.find(
        (item: any) => item.date == element.end_date
      );
      return_obj.push({
        period: element.name,
        zt: daily_data.yield_ref,
        under_panel: daily_data.yield_under_panels,
        between_panel: daily_data.yield_between_panels,
        agri_pv: daily_data.yield_agripv,
        final: cropEndDate.getTime() == new Date(element.end_date).getTime(),
      });
    }
    return return_obj;
  }, [result]);

  const CustomizedTick = (props: any) => {
    const { x, y, stroke, payload, offset } = props;
    return (
      <g transform={`translate(${x},${y - 10})`}>
        <RechartText
          x={0}
          y={0}
          dy={16}
          angle={-65}
          width={100}
          fontSize={10}
          textAnchor="end"
          verticalAnchor="middle"
        >
          {moment(payload.value).format("DD MMM")}
        </RechartText>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const period = result.simulation.crop_for_water_balance.periods.find(
        (period: any) =>
          new Date(period.start_date) <= new Date(label) &&
          new Date(label) <= new Date(period.end_date)
      );

      return (
        <div className={styled.customTooltip}>
          <p className={styled.label}>{moment(label).format("DD MMM")}</p>
          <p className={styled.label}>
            {intl.formatMessage(CropCreateMessages.stageName)}:{" "}
            {period ? period.name : "Out of periods."}
          </p>
          <div>
            {payload.map((pld: any) => (
              <div className={styled.flex} id={pld.name}>
                <div>{pld.name} :</div>
                <div style={{ color: pld.stroke }}>
                  {pld.value} {pld.unit}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  const GrasslandTypeOptions = [
    { label: "Harvestable", value: "HARVESTABLE" },
    { label: "Grassland Yeild", value: "YIELD" },
  ];

  const [selectedGrassTypeOptions, setSelectedGrassTypeOptions] = useState<
    "HARVESTABLE" | "YIELD"
  >("HARVESTABLE");

  return (
    <div className={styled.list}>
      <div className={styled.optionContainer}>
        <div className={styled.uniteSelector}>
          <Select
            label="Select Chart Type"
            labelPosition="left"
            options={GrasslandTypeOptions}
            value={GrasslandTypeOptions.find(
              (item) => item.value == selectedGrassTypeOptions
            )}
            onChange={(e: any) => setSelectedGrassTypeOptions(e.value)}
            isSearchable={false}
            menuHeight={400}
          />
        </div>
      </div>
      {daily_grassland_points && (
        <div className={styled.row}>
          {selectedGrassTypeOptions === "YIELD" && (
            <div className={styled.chartContainer}>
              <div style={{ width: "100%", height: "100%" }}>
                <div className={styled.optionContainer}>
                  <div className={styled.chartTitle}>
                    {"Growth Dynamics of Grassland"}
                  </div>
                  <div className={styled.downloadContainer}>
                    <TooltipMUI
                      title={intl.formatMessage(
                        WaterBalanceResultBodyMessages.hintChartToPng
                      )}
                    >
                      <div
                        className={styled.chartToPngButton}
                        onClick={chartToPng("GrasslandYeildChart")}
                      >
                        <Icon
                          name={ICON_NAMES_ENUM.download_image}
                          className={styled.file__icon}
                        />
                      </div>
                    </TooltipMUI>
                    <TooltipMUI
                      title={intl.formatMessage(
                        WaterBalanceResultBodyMessages.hintChartToCsv
                      )}
                    >
                      <div
                        className={styled.chartToPngButton}
                        onClick={chartToCsv(
                          daily_grassland_points.normal,
                          `${result.simulation.name} Grassland Yeild (kgMS/ha/j)`
                        )}
                      >
                        <Icon
                          name={ICON_NAMES_ENUM.download_file}
                          className={styled.file__icon}
                        />
                      </div>
                    </TooltipMUI>
                  </div>
                </div>
                <div id={"GrasslandYeildChart"}>
                  <ResponsiveContainer width="100%" height={300}>
                    <ComposedChart
                      width={500}
                      height={500}
                      data={daily_grassland_points.normal}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        height={75}
                        dataKey="date"
                        tick={<CustomizedTick />}
                        interval={0}
                        ticks={getEquidistantDates(
                          result.simulation.crop_for_water_balance,
                          11
                        )}
                      />
                      <YAxis>
                        <Label
                          fontSize={12}
                          style={{
                            textAnchor: "middle",
                            transform:
                              "translateX(-15%) translateY(45%) rotateZ(270deg)",
                          }}
                          position={"insideLeft"}
                          angle={270}
                          value={"Growth rate (kgMS/ha/j)"}
                        />
                      </YAxis>
                      <Tooltip content={CustomTooltip} />
                      <Legend
                        onClick={(o) => {
                          if (
                            !inactiveKeysPeriod.find(
                              (inactiveKey) => inactiveKey == o.dataKey
                            )
                          )
                            setInactiveKeyPeriod([
                              ...inactiveKeysPeriod,
                              o.dataKey,
                            ]);
                          else {
                            var temp = inactiveKeysPeriod;
                            temp.splice(
                              inactiveKeysPeriod.findIndex(
                                (inactiveKey) => inactiveKey == o.dataKey
                              ),
                              1
                            );
                            setInactiveKeyPeriod([...temp]);
                          }
                        }}
                      />
                      {result.simulation.crop_for_water_balance.periods.map(
                        (period: any, index: number) => (
                          <ReferenceArea
                            id={period.id}
                            x1={period.start_date}
                            x2={period.end_date}
                            fill={index % 2 ? "#333" : "#888"}
                            fillOpacity={0.1}
                          />
                        )
                      )}
                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={"zt"}
                        name="Control Zone"
                        stroke={"#AA6666"}
                        fill="url(#splitColor)"
                        unit={"kgMS/ha/j"}
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) => inactiveKey == "zt"
                          )
                            ? true
                            : false
                        }
                      />
                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={"under_panel"}
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) => inactiveKey == "under_panel"
                          )
                            ? true
                            : false
                        }
                        name="Under Panels"
                        stroke={"#66AA66"}
                        fill="url(#splitColor)"
                        unit={"kgMS/ha/j"}
                      />
                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={"between_panel"}
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) => inactiveKey == "between_panel"
                          )
                            ? true
                            : false
                        }
                        name="Between Panels"
                        stroke={"#6666AA"}
                        fill="url(#splitColor)"
                        unit={"kgMS/ha/j"}
                      />
                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={"agri_pv"}
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) => inactiveKey == "agri_pv"
                          )
                            ? true
                            : false
                        }
                        name="AgriPV Zone"
                        stroke={"#444444"}
                        fill="url(#splitColor)"
                        unit={"kgMS/ha/j"}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          )}
          {selectedGrassTypeOptions === "HARVESTABLE" && (
            <div className={styled.chartContainer}>
              <div style={{ width: "100%", height: "100%" }}>
                <div className={styled.optionContainer}>
                  <div className={styled.chartTitle}>
                    {"Harvestable production"}
                  </div>
                  <div className={styled.downloadContainer}>
                    <TooltipMUI
                      title={intl.formatMessage(
                        WaterBalanceResultBodyMessages.hintChartToPng
                      )}
                    >
                      <div
                        className={styled.chartToPngButton}
                        onClick={chartToPng("HarvestableProductionChart")}
                      >
                        <Icon
                          name={ICON_NAMES_ENUM.download_image}
                          className={styled.file__icon}
                        />
                      </div>
                    </TooltipMUI>
                    <TooltipMUI
                      title={intl.formatMessage(
                        WaterBalanceResultBodyMessages.hintChartToCsv
                      )}
                    >
                      <div
                        className={styled.chartToPngButton}
                        onClick={chartToCsv(
                          daily_grassland_points.cumulated,
                          `${result.simulation.name} Harvestable Production (kgMS/ha)`
                        )}
                      >
                        <Icon
                          name={ICON_NAMES_ENUM.download_file}
                          className={styled.file__icon}
                        />
                      </div>
                    </TooltipMUI>
                  </div>
                </div>
                <div id={"HarvestableProductionChart"}>
                  <ResponsiveContainer width="100%" height={300}>
                    <ComposedChart
                      width={500}
                      height={500}
                      data={daily_grassland_points.cumulated}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        height={75}
                        dataKey="date"
                        tick={<CustomizedTick />}
                        interval={0}
                        ticks={getEquidistantDates(
                          result.simulation.crop_for_water_balance,
                          11
                        )}
                      />
                      <YAxis>
                        <Label
                          fontSize={12}
                          style={{
                            textAnchor: "middle",
                            transform:
                              "translateX(-15%) translateY(45%) rotateZ(270deg)",
                          }}
                          position={"insideLeft"}
                          angle={270}
                          value={"Harvestable production (kgMS/ha)"}
                        />
                      </YAxis>
                      <Tooltip content={CustomTooltip} />
                      <Legend
                        onClick={(o) => {
                          if (
                            !inactiveKeysPeriod.find(
                              (inactiveKey) => inactiveKey == o.dataKey
                            )
                          )
                            setInactiveKeyPeriod([
                              ...inactiveKeysPeriod,
                              o.dataKey,
                            ]);
                          else {
                            var temp = inactiveKeysPeriod;
                            temp.splice(
                              inactiveKeysPeriod.findIndex(
                                (inactiveKey) => inactiveKey == o.dataKey
                              ),
                              1
                            );
                            setInactiveKeyPeriod([...temp]);
                          }
                        }}
                      />
                      {result.simulation.crop_for_water_balance.periods.map(
                        (period: any, index: number) => (
                          <ReferenceArea
                            id={period.id}
                            x1={period.start_date}
                            x2={period.end_date}
                            fill={index % 2 ? "#333" : "#888"}
                            fillOpacity={0.1}
                          />
                        )
                      )}
                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={"zt"}
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) => inactiveKey == "zt"
                          )
                            ? true
                            : false
                        }
                        name="Control Zone"
                        stroke={"#AA6666"}
                        fill="url(#splitColor)"
                        unit={"kgMS/ha"}
                      />

                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={"under_panel"}
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) => inactiveKey == "under_panel"
                          )
                            ? true
                            : false
                        }
                        name="Under Panels"
                        stroke={"#66AA66"}
                        fill="url(#splitColor)"
                        unit={"kgMS/ha"}
                      />
                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={"between_panel"}
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) => inactiveKey == "between_panel"
                          )
                            ? true
                            : false
                        }
                        name="Between Panels"
                        stroke={"#6666AA"}
                        fill="url(#splitColor)"
                        unit={"kgMS/ha"}
                      />
                      <Line
                        dot={<></>}
                        type="monotone"
                        dataKey={"agri_pv"}
                        hide={
                          inactiveKeysPeriod.find(
                            (inactiveKey) => inactiveKey == "agri_pv"
                          )
                            ? true
                            : false
                        }
                        name="AgriPV Zone"
                        stroke={"#444444"}
                        fill="url(#splitColor)"
                        unit={"kgMS/ha"}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      {grassland_table_values && (
        <div className={styled.tableContainer}>
          <div className={styled.downloadContainer}>
            <div className={styled.chartTitle}>
              <FormattedMessage
                {...WaterBalanceResultMenuMessages.tableTitle}
              />
            </div>
            <TooltipMUI
              title={intl.formatMessage(
                WaterBalanceResultBodyMessages.hintTableToPng
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToPng("GrasslandYieldTable")}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_image}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
            <TooltipMUI
              title={intl.formatMessage(
                WaterBalanceResultBodyMessages.hintTableToCsv
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToCsv(
                  grassland_table_values,
                  `${result.simulation.name} table data`
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_file}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
          </div>
          <div className={`${styled.flex} ${styled.tableContainerOverflow}`}>
            <table className={styled.dataTable} id="GrasslandYieldTable">
              <thead>
                <tr>
                  <th colSpan={4}>Final Harverstable Production (kgMS/ha)</th>
                </tr>
                <tr>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.controlZoneSubTitle}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.underPanelsSubTitle}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.betweenPanelsZoneSubTitle}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle}
                    />
                  </th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <td className={styled.specialCaseTd}>
                    <span>
                      {grassland_table_values.find((obj: any) => obj.final)!.zt}
                    </span>
                  </td>
                  <td>
                    <span>
                      {
                        grassland_table_values.find((obj: any) => obj.final)!
                          .under_panel
                      }
                    </span>
                  </td>
                  <td>
                    <span>
                      {
                        grassland_table_values.find((obj: any) => obj.final)!
                          .between_panel
                      }
                    </span>
                  </td>
                  <td>
                    <span>
                      {
                        grassland_table_values.find((obj: any) => obj.final)!
                          .agri_pv
                      }
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default GrasslandYieldContent;
