import React, { useCallback } from "react";
import ReactDOM from "react-dom/client";
import { useBlocker } from "hooks/useBlocker";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import translations, { Languages } from "translations";
import { IntlProvider } from "react-intl";

export function usePrompt(when = true) {
  const blocker = useCallback((tx: any) => {
    const element = document.createElement("div");
    element.setAttribute("id", "prompt-dialog-container");
    element.setAttribute("aria-hidden", "true");

    const div = ReactDOM.createRoot(element);

    const closePrompt = (state: boolean) => {
      if (element) {
        div.unmount();
      }
      if (!state) {
        document.body.removeChild(element);
      } else {
        tx.retry();
      }
    };

    document.body.appendChild(element);
    div.render(
      <IntlProvider locale="EN" messages={translations[Languages.EN]}>
        <LeaveModalWindow
          type={LeaveModalEnum.CANCEL}
          show={when}
          onHide={() => closePrompt(false)}
          onConfirm={() => closePrompt(true)}
        />
      </IntlProvider>
    );
  }, [when]);

  useBlocker(blocker, when);
}
