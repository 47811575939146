import { defineMessages } from "react-intl";

export default defineMessages({
  steeringAlgorithmName: {
    id: "SteeringAlgorithmCreateMessages.steeringAlgorithmName",
    defaultMessage: "Steering Algorithm Name",
  },
  steeringAlgorithmUnnamed: {
    id: "SteeringAlgorithmCreateMessages.steeringAlgorithmUnnamed",
    defaultMessage: "Steering Algorithm Unnamed",
  },
  selectCrop: {
    id: "SteeringAlgorithmCreateMessages.selectCrop",
    defaultMessage: "Select Crop",
  },
  addNewRow: {
    id: "SteeringAlgorithmCreateMessages.addNewRow",
    defaultMessage: "Add new Rule",
  },
  periodStart: {
    id: "SteeringAlgorithmCreateMessages.periodStart",
    defaultMessage: "Start date",
  },
  periodEnd: {
    id: "SteeringAlgorithmCreateMessages.periodEnd",
    defaultMessage: "End date",
  },
  stageName: {
    id: "SteeringAlgorithmCreateMessages.stageName",
    defaultMessage: "Stage Name",
  },
  rules: {
    id: "SteeringAlgorithmCreateMessages.rules",
    defaultMessage: "Rules",
  },
  mustContain: {
    id: "SteeringAlgorithmCreateMessages.mustContain",
    defaultMessage: "There must be at least one rule.",
  },
});
