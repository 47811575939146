import {
  getProjectsList,
  updateProject,
  deleteProject,
  createProject,
  changeProjectSort,
  getProjectSort,
} from "store/projects/actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  list: [],
  sort: {
    order: "asc",
    order_by: "created_at",
  },
};

const projectsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case changeProjectSort.TRIGGER: {
      return {
        ...state,
        sort: payload.sort,
      };
    }

    case getProjectSort.SUCCESS: {
      if (payload) {
        return {
          ...state,
          sort: payload,
        };
      }

      return {
        ...state,
      };
    }

    case getProjectsList.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getProjectsList.SUCCESS: {
      return {
        ...state,
        list: payload,
      };
    }

    case createProject.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }

    case deleteProject.SUCCESS: {
      const list = state.list.filter(({ id }) => id !== payload.id);

      return {
        ...state,
        list,
      };
    }

    case updateProject.SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = payload;

      return {
        ...state,
        list: newList,
      };
    }

    case getProjectsList.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default projectsReducer;
