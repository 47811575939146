import React, { useEffect, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ROUTES } from "constants/routes";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import MainContent from "components/MainContent/MainContent";
import { useNavigate, useParams } from "react-router-dom";
import BatchesCard from "components/BatchesCard/BatchesCard";
import { useDispatch, useSelector } from "react-redux";
import { copyBatch, deleteBatch, getBatches } from "store/batches/actions";
import { getBatchesListState } from "store/batches/selectors";

const Batches = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client_id, project_id } = useParams();
  const batches = useSelector(getBatchesListState);

  const [deleteId, setDeleteId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(getBatches({ clientId: client_id, projectId: project_id }));
  }, [project_id, client_id]);

  const onDelete = () => {
    dispatch(
      deleteBatch({
        clientId: client_id,
        projectId: project_id,
        batchId: deleteId,
        callback: () => setDeleteId(null),
      })
    );
  };

  const onCopy = (id: string | number) => () => {
    dispatch(
      copyBatch({
        clientId: client_id,
        projectId: project_id,
        batchId: id,
      })
    );
  };

  const onShowWarning = (id: number) => {
    setDeleteId(id);
  };

  const onCreatePress = () => {
    navigate(
      ROUTES.BATCHES_CREATE.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );
  };

  return (
    <MainContent
      header={
        <>
          <h2 className={styled.title}>
            <FormattedMessage {...commonMessages.batches} />
          </h2>

          <Button iconBefore={<Plus />} onClick={onCreatePress}>
            <FormattedMessage {...commonMessages.createNew} />
          </Button>
        </>
      }
    >
      <div className={styled.list}>
        {batches.map((batch: any) => (
          <BatchesCard
            key={batch.id}
            name={batch.name}
            date={batch.updated_at}
            rows_number={batch.rows_number}
            onEdit={() =>
              navigate(
                ROUTES.BATCHES_EDIT.replace(":client_id", client_id as string)
                  .replace(":project_id", project_id as string)
                  .replace(":id", String(batch.id))
              )
            }
            onDelete={() => onShowWarning(batch.id)}
            onCopy={onCopy(batch.id)}
          />
        ))}
        <LeaveModalWindow
          type={LeaveModalEnum.DELETE}
          onDelete={onDelete}
          show={deleteId !== null}
          onHide={() => setDeleteId(null)}
        />
      </div>
    </MainContent>
  );
};

export default Batches;
