import { all, fork } from 'redux-saga/effects';
// sagas
import signIn from './signIn';
import logout from './logout';
import auth from './auth';
import editProfile from './editProfile';
import setPassword from './setPassword';
import resetPassword from './resetPassword';

export default function* user() {
  yield all([
    fork(signIn),
    fork(logout),
    fork(auth),
    fork(editProfile),
    fork(setPassword),
    fork(resetPassword),
  ]);
}
