import React from "react";
import styled from "./styled.module.scss";

interface ICustomToggle {
  children: React.ReactNode | string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const CustomToggle = React.forwardRef<HTMLButtonElement, ICustomToggle>(
  ({ children, onClick }, ref) => (
    <button
      className={styled.toggle}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </button>
  )
);

export default CustomToggle;
