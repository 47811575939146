import React, {
  DragEvent,
  forwardRef,
  InputHTMLAttributes,
  useRef,
  useState,
} from "react";
import clsx from "clsx";
import mergeRefs from "../../utils/marge-refs";
import styled from "./DragUploader.module.scss";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import { FormattedMessage, useIntl } from "react-intl";
import dragUploaderMessages from "./DragUploaderMessages";
import { SpanError } from "components/common/span-error/SpanError";

export enum DropzoneErrors {
  MAX_SIZE = "MAX_SIZE",
  TYPE = "TYPE",
}

export interface FileInvalidDropzone {
  file: File;
  erorrs: string[];
}

interface DropzoneProps {
  name?: InputHTMLAttributes<HTMLInputElement>["name"];
  label?: React.ReactNode;
  accept?: Array<string>;
  multiple?: boolean;
  maxSize?: number; //in bytes
  errorMessage?: boolean;
  onChange: (files: File[]) => void;
}

const DragUploader: React.ForwardRefRenderFunction<
  HTMLInputElement,
  DropzoneProps
> = (
  { name, label, accept, multiple = true, maxSize, errorMessage, onChange },
  ref
) => {
  const intl = useIntl();
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dragCounter = useRef<number>(0);
  const [isDragging, setIsDragging] = useState<boolean>(false);

  const filesSelected = () => {
    if (fileInputRef?.current?.files?.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const checkTypeFile = (file: File) => {
    return !(accept?.indexOf(file.type) === -1);
  };

  const checkFileSize = (file: File) => {
    return !maxSize ? true : file.size <= maxSize;
  };

  const validateFiles = (files: FileList) => {
    const validFiles: File[] = [];
    const invalidFiles: FileInvalidDropzone[] = [];

    for (let i = 0; i < files.length; i++) {
      const file: FileInvalidDropzone = {
        file: files[i],
        erorrs: [],
      };

      if (!checkTypeFile(files[i])) {
        file.erorrs.push(DropzoneErrors.TYPE);
      }
      if (!checkFileSize(files[i])) {
        file.erorrs.push(DropzoneErrors.MAX_SIZE);
      }

      if (file.erorrs.length) invalidFiles.push(file);
      else validFiles.push(files[i]);
    }

    return {
      validFiles: validFiles,
      invalidFiles: invalidFiles,
    };
  };

  const handleFiles = (files: FileList) => {
    const { validFiles } = validateFiles(files);

    if (validFiles.length) onChange(validFiles);
  };

  const preventDefault = (event: DragEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const dragOver = (event: DragEvent<HTMLLabelElement>) => {
    preventDefault(event);
  };

  const dragEnter = (event: DragEvent<HTMLLabelElement>) => {
    preventDefault(event);
    dragCounter.current++;
    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      setIsDragging(true);
    }
  };

  const dragLeave = (event: DragEvent<HTMLLabelElement>) => {
    preventDefault(event);
    dragCounter.current--;
    if (dragCounter.current > 0) return;
    setIsDragging(false);
  };

  const fileDrop = (event: DragEvent<HTMLLabelElement>) => {
    preventDefault(event);
    setIsDragging(false);
    const files = event.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
      dragCounter.current = 0;
    }
  };

  return (
    <label
      onDragOver={dragOver}
      onDragEnter={dragEnter}
      onDragLeave={dragLeave}
      onDrop={fileDrop}
      className={clsx(styled.zone, {
        [styled["zone--error"]]: !!errorMessage,
      })}
    >
      <Icon name={ICON_NAMES_ENUM.upload} className={styled.zone__image} />
      <p className={styled.zone__title}>
        <FormattedMessage {...dragUploaderMessages.uploadDrop} />{" "}
      </p>
      <input
        id="file-upload"
        className={styled["input-fide"]}
        ref={mergeRefs(fileInputRef, ref)}
        name={name}
        type="file"
        accept={accept?.join(", ")}
        multiple={multiple}
        onChange={filesSelected}
      />
      <div className={clsx({ "is-dragging": isDragging })}>
        <span className={styled.here}>
          <FormattedMessage {...dragUploaderMessages.orclick} />
          <span>
            <FormattedMessage {...dragUploaderMessages.here} />
          </span>
          <FormattedMessage {...dragUploaderMessages.to_select_from_PC} />
        </span>
      </div>

      {errorMessage && (
        <SpanError
          errorMessage={intl.formatMessage(dragUploaderMessages.fileRequired)}
        />
      )}
    </label>
  );
};

export const Dropzone = forwardRef(DragUploader);
