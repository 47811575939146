import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getWaterBalances = ({ clientId, projectId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/simulations/water_balances`,
  });

export const getWaterBalance = ({ clientId, projectId, waterBalanceId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${waterBalanceId}`,
  });

export const createWaterBalance = ({ clientId, projectId, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/simulations`,
    data,
  });

export const copyWaterBalance = ({ clientId, projectId, waterBalanceId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${waterBalanceId}/copy`,
  });

export const deleteWaterBalance = ({ clientId, projectId, waterBalanceId }) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${waterBalanceId}`,
  });

export const updateWaterBalance = ({
  clientId,
  projectId,
  waterBalanceId,
  ...data
}) =>
  apiPATCH({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${waterBalanceId}`,
    data,
  });

export const runWaterBalance = ({ clientId, projectId, waterBalanceId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${waterBalanceId}/run`,
  });

export const getWaterBalanceResult = ({
  clientId,
  projectId,
  waterBalanceId,
  resultId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${waterBalanceId}/results/${resultId}`,
  });

export const deleteWaterBalanceResult = ({
  clientId,
  projectId,
  waterBalanceId,
  resultId,
}) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${waterBalanceId}/results/${resultId}`,
  });

export const cancelWaterBalance = ({
  clientId,
  projectId,
  waterBalanceId,
  resultId,
}) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${waterBalanceId}/results/${resultId}/cancel`,
  });

export const getWaterBalanceResultImage = ({
  clientId,
  projectId,
  waterBalanceId,
  resultId,
  imageId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${waterBalanceId}/results/${resultId}/images/${imageId}`,
    responseType: "blob",
  }).then((r) => URL.createObjectURL(r));

export const getWaterBalanceResultFile = ({
  clientId,
  projectId,
  waterBalanceId,
  resultId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/simulations/${waterBalanceId}/results/${resultId}/download`,
    responseType: "blob",
  }).then((r) => URL.createObjectURL(r));
