import React from "react";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { DnDTypes } from "constants/dndTypes";
import AccordionItem, {
  IAccordionItem,
} from "components/AccordionItem/AccordionItem";
import { useLocation } from "react-router-dom";

interface IAccordionDragItem extends IAccordionItem {
  dragType: DnDTypes;
  data: {
    id: number;
    name: string;
  };
}

const AccordionDragItem = ({ dragType, data, ...rest }: IAccordionDragItem) => {
  const { pathname } = useLocation();

  const [_, drag] = useDrag(
    () => ({
      type: dragType,
      item: data,
      canDrag:
        pathname.includes("simulations/create") ||
        pathname.includes("simulations/edit") ||
        pathname.includes("soil_informations/create") ||
        pathname.includes("soil_informations/edit") ||
        pathname.includes("water_balances/create") ||
        pathname.includes("water_balances/edit") ||
        pathname.includes("sizing_optimization/create") ||
        pathname.includes("sizing_optimization/edit"),
      collect: (monitor: DragSourceMonitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [dragType, pathname]
  );

  return <AccordionItem ref={drag} {...rest} />;
};
export default AccordionDragItem;
