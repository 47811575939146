import { put, call, takeEvery } from "redux-saga/effects";
// actions
import { getNavigation as action } from "store/navigation/actions";
// api
import { getNavigation as api } from "store/navigation/api";

function* saga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success(response.data));

    if (payload?.callback) payload.callback();
  } catch (error) {
    console.error(error);
  } finally {
    yield put(action.fulfill());
  }
}

export default function* getNavigation() {
  yield takeEvery(action.TRIGGER, saga);
}
