import { defineMessages } from "react-intl";

export default defineMessages({
  structureName: {
    id: "StructuresCreateMessages.structureName",
    defaultMessage: "Structure Name",
  },
  structureUnnamed: {
    id: "StructuresCreateMessages.structureUnnamed",
    defaultMessage: "Unnamed Structure",
  },
  tablePosition: {
    id: "StructuresCreateMessages.tablePosition",
    defaultMessage: "Table Position",
  },
  PvModule: {
    id: "StructuresCreateMessages.PvModule",
    defaultMessage: "PV module properties",
  },
  visualDisplay: {
    id: "StructuresCreateMessages.visualDisplay",
    defaultMessage: "3D Visual Display",
  },
  samplePoints: {
    id: "StructuresCreateMessages.samplePoints",
    defaultMessage: "Sample Points",
  },
  structureType: {
    id: "StructuresCreateMessages.structureType",
    defaultMessage: "Structure Type",
  },
  agriPVZoneDefinition: {
    id: "StructuresCreateMessages.agriPVZoneDefinition",
    defaultMessage: "AgriPV Zone Definition",
  },
  nonFarmingBandWidth: {
    id: "StructuresCreateMessages.nonFarmingBandWidth",
    defaultMessage: "Non farming band width (m)",
  },
  sunPosition: {
    id: "StructuresCreateMessages.sunPosition",
    defaultMessage: "Sun position",
  },
  panelHeight: {
    id: "StructuresCreateMessages.panelHeight",
    defaultMessage: "Table Height (m)",
  },
  tableOpacity: {
    id: "StructuresCreateMessages.tableOpacity",
    defaultMessage: "Panel Opacity",
  },
  tableSize: {
    id: "StructuresCreateMessages.tableSize",
    defaultMessage: "Table Size (m)",
  },
  tableWidth: {
    id: "StructuresCreateMessages.tableWidth",
    defaultMessage: "Table Width (m)",
  },
  moduleEfficiency: {
    id: "StructuresCreateMessages.moduleEfficiency",
    defaultMessage: "Module efficiency STC (%)",
  },
  temperatureCoefficientsPower: {
    id: "StructuresCreateMessages.temperatureCoefficientsPower",
    defaultMessage: "Temperature coefficients of power (%/°C)",
  },
  albedo: {
    id: "StructuresCreateMessages.albedo",
    defaultMessage: "Albedo",
  },
  bifacialFactor: {
    id: "StructuresCreateMessages.bifacialFactor",
    defaultMessage: "Bifacial Factor (%)",
  },
  tableLength: {
    id: "StructuresCreateMessages.tableLength",
    defaultMessage: "Table Length (m)",
  },
  initialOffset: {
    id: "StructuresCreateMessages.initialOffset",
    defaultMessage: "Initial Offset (m)",
  },
  numberOfPanels: {
    id: "StructuresCreateMessages.numberOfPanels",
    defaultMessage: "Number of tables",
  },
  spaceBetweenPanels: {
    id: "StructuresCreateMessages.spaceBetweenPanels",
    defaultMessage: "Space between tables (m)",
  },
  pitchBetweenPanels: {
    id: "StructuresCreateMessages.pitchBetweenPanels",
    defaultMessage: "Pitch between tables (m)",
  },
  gapBetweenPanels: {
    id: "StructuresCreateMessages.gapBetweenPanels",
    defaultMessage: "Gap between adjacent tables (m)",
  },
  fieldSize: {
    id: "StructuresCreateMessages.fieldSize",
    defaultMessage: "Field size (m)",
  },
  resolution: {
    id: "StructuresCreateMessages.resolution",
    defaultMessage: "Resolution (Pts/m)",
  },
  azimuth: {
    id: "StructuresCreateMessages.azimuth",
    defaultMessage: "Azimuth",
  },
  zenith: {
    id: "StructuresCreateMessages.zenith",
    defaultMessage: "Zenith",
  },
  panelTilt: {
    id: "StructuresCreateMessages.panelTilt",
    defaultMessage: "Panel Tilt",
  },
  panelMaxTilt: {
    id: "StructuresCreateMessages.panelMaxTilt",
    defaultMessage: "Panel max Tilt",
  },
  backTracking: {
    id: "StructuresCreateMessages.backTracking",
    defaultMessage: "Back Tracking",
  },
  panelTranslation: {
    id: "StructuresCreateMessages.panelTranslation",
    defaultMessage: "Panel Translation",
  },
  maximalLateralTranslation: {
    id: "StructuresCreateMessages.maximalLateralTranslation",
    defaultMessage: "Maximal Lateral Translation (m)",
  },
  lateralOffset: {
    id: "StructuresCreateMessages.lateralOffset",
    defaultMessage: "Lateral Offset (m)",
  },
  fixed: {
    id: "StructuresCreateMessages.fixed",
    defaultMessage: "Fixed",
  },
  tracker: {
    id: "StructuresCreateMessages.tracker",
    defaultMessage: "Tracker",
  },
  date: {
    id: "StructuresCreateMessages.date",
    defaultMessage: "Date",
  },
  day: {
    id: "StructuresCreateMessages.day",
    defaultMessage: "Day",
  },
  hour: {
    id: "StructuresCreateMessages.hour",
    defaultMessage: "Hour",
  },
  defaultPosition: {
    id: "StructuresCreateMessages.defaultPosition",
    defaultMessage: "Default Position",
  },
  addNewPoint: {
    id: "StructuresCreateMessages.addNewPoint",
    defaultMessage: "Add new point",
  },
  upperTableTipHeight: {
    id: "StructuresCreateMessages.upperTableTipHeight",
    defaultMessage: "Upper table tip height (m)",
  },
  lowerTableTipHeight: {
    id: "StructuresCreateMessages.lowerTableTipHeight",
    defaultMessage: "Lower table tip height (m)",
  },
  rotationAxisHeight: {
    id: "StructuresCreateMessages.rotationAxisHeight",
    defaultMessage: "Rotation Axis Height (m)",
  },
  groundCoverageRatio: {
    id: "StructuresCreateMessages.groundCoverageRatio",
    defaultMessage: "Ground Coverage Ratio (%)",
  },
});
