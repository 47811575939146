import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { signIn as signInAction } from "store/user/actions";
// api
import { signIn as signInApi } from "store/user/api";
// services
import { setAccessToken } from "store/user/services/tokenService";

function* signInSaga({ payload: { callback, ...payload } }) {
  try {
    yield put(signInAction.request());

    const { access_token, user } = yield call(signInApi, payload);

    yield call(setAccessToken, access_token);

    yield put(
      signInAction.success({
        token: access_token,
        user,
      })
    );

    if (callback) callback(user);
  } catch (error) {
    yield put(signInAction.failure(error));
  } finally {
    yield put(signInAction.fulfill());
  }
}

export default function* signIn() {
  yield takeLatest(signInAction.TRIGGER, signInSaga);
}
