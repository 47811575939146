import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import styled from "./styled.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelWaterBalance,
  getWaterBalanceResult,
  resetWaterBalanceResult,
} from "store/water_balances/actions";
import MainContent from "components/MainContent/MainContent";
import { getWaterBalanceResultState } from "store/water_balances/selectors";
import { Loader } from "components/common/loader/Loader";
import StatusLabel, { StatusesEnum } from "components/StatusLabel/StatusLabel";
import Button from "components/common/button/Button";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import {
  deleteWaterBalanceResult,
  getWaterBalanceResultFile,
  getWaterBalanceResultImage,
} from "store/water_balances/api";
import { ROUTES } from "constants/routes";
import { downloadFile } from "utils/downloadFile";
import { IPeriod } from "components/SimulationResultBody/SimulationResultBody";
import { cancelSimulation } from "store/simulations/actions";
import WaterBalanceResultBody from "components/WaterBalanceResultBody/WaterBalanceResultBody";

const REFRESH_DATA_TIME = 10000;

const WaterBalanceResult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client_id, project_id, water_balance_id, id } = useParams();
  const result = useSelector(getWaterBalanceResultState);
  const [image, setImage] = useState("");
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<IPeriod>(null);

  const isPrepare = result?.status === StatusesEnum.PREPARE;
  const isRunningMain = result?.status === StatusesEnum.RUNNING_MAIN;
  const isRunningPeriods = result?.status === StatusesEnum.RUNNING_PERIODS;
  const isCancelingWaterBalance = result?.status === StatusesEnum.CANCELING;
  const isProgressWaterBalance =
    isPrepare || isRunningMain || isRunningPeriods || isCancelingWaterBalance;
  const isSuccessWaterBalance = result?.status === StatusesEnum.COMPLETED;
  const isFailedWaterBalance = result?.status === StatusesEnum.FAILED;
  const isCancelWaterBalance = result?.status === StatusesEnum.CANCELED;

  const options = useMemo(() => {
    return result?.result_data.map((item: any) => ({
      value: item.id,
      label: item.period.name,
    }));
  }, [result?.result_data]);

  const onSelect = (option: any) => {
    setSelectedPeriod(option);
  };

  const getResult = () => {
    dispatch(
      getWaterBalanceResult({
        clientId: client_id,
        projectId: project_id,
        waterBalanceId: water_balance_id,
        resultId: id,
      })
    );
  };

  const onDownload = () => {
    setIsFileLoading(true);
    getWaterBalanceResultFile({
      clientId: client_id,
      projectId: project_id,
      waterBalanceId: water_balance_id,
      resultId: id,
    })
      .then((href) => {
        downloadFile(href, `${result.name}.zip`);
      })
      .finally(() => setIsFileLoading(false));
  };

  const onBack = () => {
    navigate(
      ROUTES.WATER_BALANCES_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", water_balance_id as string)
    );
  };

  const onDelete = () => {
    deleteWaterBalanceResult({
      clientId: client_id,
      projectId: project_id,
      waterBalanceId: water_balance_id,
      resultId: id,
    }).then(() => {
      onBack()
    })
  };

  const onCancel = () => {
    dispatch(
      cancelWaterBalance({
        clientId: client_id,
        projectId: project_id,
        waterBalanceId: water_balance_id,
        resultId: id,
      })
    );
  };

  useEffect(() => {
    if (options?.length && isSuccessWaterBalance) {
      setSelectedPeriod(options[0]);
    }
  }, [options?.length, isSuccessWaterBalance]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isProgressWaterBalance) {
      timeoutId = setTimeout(getResult, REFRESH_DATA_TIME);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [result]);

  useEffect(() => {
    getResult();

    return () => {
      dispatch(resetWaterBalanceResult());
    };
  }, [client_id, project_id, water_balance_id, id]);

  useEffect(() => {
    if (selectedPeriod !== null && isSuccessWaterBalance) {
      getWaterBalanceResultImage({
        clientId: client_id,
        projectId: project_id,
        waterBalanceId: water_balance_id,
        resultId: id,
        imageId: selectedPeriod?.value,
      }).then((img) => setImage(img));
    }
  }, [selectedPeriod]);

  if (!result) return <Loader />;

  return (
    <MainContent
      header={
        <>
          <div className={styled.headerRow}>
            <h2 className={styled.title}>{result.name}</h2>
            <StatusLabel status={result?.status} />
          </div>

          <div className={styled.headerRow}>
            {(isSuccessWaterBalance ||
              isCancelWaterBalance ||
              isFailedWaterBalance) && (
              <Button
                variant="text"
                iconBefore={<Delete />}
                onClick={onDelete}
                className={styled.deleteButton}
              >
                <FormattedMessage {...commonMessages.delete} />
              </Button>
            )}

            {isSuccessWaterBalance && (
              <Button
                iconBefore={<Icon name={ICON_NAMES_ENUM.arrow_down} />}
                disabled={isFileLoading}
                isLoading={isFileLoading}
                onClick={onDownload}
              >
                <FormattedMessage {...commonMessages.download} />
              </Button>
            )}
          </div>
        </>
      }
    >
      <WaterBalanceResultBody
        result={result}
        onSelect={onSelect}
        selectedPeriod={selectedPeriod}
        image={image}
        onCancelWaterBalance={onCancel}
      />
    </MainContent>
  );
};

export default WaterBalanceResult;
