import React, { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import { ReactComponent as Save } from "assets/icons/save.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import structuresCreateMessages from "pages/StructuresCreate/StructuresCreateMessages";
import styled from "./styled.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import EditName from "components/EditName/EditName";
import { deleteStructure, updateStructure } from "store/structures/actions";
import { useDispatch } from "react-redux";
import {
  IStructureFormik,
  getInitialValue,
} from "pages/StructuresCreate/StructuresCreate";
import MainContent from "components/MainContent/MainContent";
import StructuresBody from "components/StructuresBody/StructuresBody";
import { FormikHelpers, useFormik } from "formik";
import StructuresSchema from "pages/StructuresCreate/StructuresSchema";
import {
  drawStructureImage,
  getStructure,
  getStructureImage,
} from "store/structures/api";
import preRender from "assets/pre-render-partial.png";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import { errorNotifications } from "utils/errorNotifications";
import { usePrompt } from "hooks/usePromt";
import { isEqual } from "lodash";
import { successNotifications } from "utils/successNotifications";

const StructuresEdit = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client_id, project_id, id } = useParams();
  const [image, setImage] = useState(preRender);
  const [imageLoading, setImageLoading] = useState(false);
  const [isDeleteWarning, setIsDeleteWarning] = useState(false);
  const [initialStructure, setInitialStructure] = useState<any>(null);

  const onHideWarning = () => setIsDeleteWarning(false);

  const onShowWarning = () => setIsDeleteWarning(true);

  const onBack = () =>
    navigate(
      ROUTES.STRUCTURES.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );

  const onEditPage = () =>
    navigate(
      ROUTES.STRUCTURES_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", id as string)
    );

  const onDelete = () => {
    dispatch(
      deleteStructure({
        clientId: client_id,
        projectId: project_id,
        structureId: id,
        callback: onBack,
      })
    );
  };

  const onUpdate = (
    values: IStructureFormik,
    actions: FormikHelpers<IStructureFormik>
  ) => {
    const obj = {
      ...values,
      panel_y:
        values.structure_type == "fixed"
          ? values.panel_width
          : values.panel_length,
      panel_x:
        values.structure_type == "fixed"
          ? values.panel_length
          : values.panel_width,
      panels_gap_x:
        values.panels_gap_x +
        (values.structure_type == "fixed" ? values.panel_length : 0),
      panels_gap_y:
        values.panels_gap_y +
        (values.structure_type == "tracker" ? values.panel_length : 0),
    };
    dispatch(
      updateStructure({
        ...obj,
        clientId: client_id,
        projectId: project_id,
        structureId: id,
        callback: () => {
          successNotifications({
            title: intl.formatMessage(commonMessages.edit),
            message: intl.formatMessage(commonMessages.successEdit, {
              objet_type: intl.formatMessage(commonMessages.structure),
            }),
          });
          setInitialStructure({ ...formik.values });
        },
        finallyCallback: () => actions.setSubmitting(false),
      })
    );
  };

  const formik = useFormik<IStructureFormik>({
    validationSchema: StructuresSchema(intl),
    onSubmit: onUpdate,
    initialValues: getInitialValue("fixed", intl),
  });

  const isDataChanged = !isEqual(formik.values, initialStructure);

  usePrompt(isDataChanged && !formik.isSubmitting && !isDeleteWarning);

  const onDraw = () => {
    setImageLoading(true);
    drawStructureImage({
      clientId: client_id,
      projectId: project_id,
      ...formik.values,
    })
      .then((img) => {
        setImage(img);
        setImageLoading(false);
      })
      .catch(async (error) => {
        const text = await error.response.data.text();
        const errorObj = JSON.parse(text);
        errorNotifications(errorObj.data);
        setImage(preRender);
        setImageLoading(false);
      });
  };

  useEffect(() => {
    getStructureImage({
      clientId: client_id,
      projectId: project_id,
      structureId: id,
    }).then((img) => setImage(img));

    getStructure({
      clientId: client_id,
      projectId: project_id,
      structureId: id,
    }).then(({ data }) => {
      var obj = {
        ...data,
        panel_width:
          data.structure_type == "fixed" ? data.panel_y : data.panel_x,
        panel_length:
          data.structure_type == "fixed" ? data.panel_x : data.panel_y,
        panels_gap_x:
          Math.round((data.panels_gap_x -
          (data.structure_type == "fixed" ? data.panel_x : 0)) * 10) / 10,
        panels_gap_y:
          Math.round((data.panels_gap_y -
          (data.structure_type == "tracker" ? data.panel_y : 0)) * 10) / 10,
      };

      const angle =
        obj.structure_type == "fixed"
          ? obj.static_angle
          : obj.tracking_max_angle;
      const panel_size = obj.panel_width / 2;
      const val1 =
        Math.round(
          (obj.panel_height + panel_size * Math.sin((angle * Math.PI) / 180)) *
            100
        ) / 100;
      const val2 =
        Math.round(
          (obj.panel_height - panel_size * Math.sin((angle * Math.PI) / 180)) *
            100
        ) / 100;
      const lower = val1 < val2 ? val1 : val2;
      const upper = val1 > val2 ? val1 : val2;
      obj = {
        ...obj,
        panel_lower: lower,
        panel_upper: upper,
      };

      formik.setValues(obj);

      setInitialStructure(obj);
    });
  }, [client_id, project_id, id]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContent
        header={
          <>
            <EditName
              onFocus={(event) => {
                event.target.select();
              }}
              placeholder={intl.formatMessage(
                structuresCreateMessages.structureName
              )}
              onChange={formik.handleChange}
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              errorMessage={
                formik.touched.name ? (formik.errors.name as string) : ""
              }
            />

            <div className={styled.flex}>
              <Button
                onClick={onShowWarning}
                iconBefore={<Delete />}
                variant="text"
                className={styled.deleteButton}
              >
                <FormattedMessage {...commonMessages.delete} />
              </Button>

              <Button variant="text" onClick={onBack}>
                <FormattedMessage {...commonMessages.cancel} />
              </Button>

              <Button
                iconBefore={<Save />}
                type="submit"
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting || !isDataChanged}
              >
                <FormattedMessage {...commonMessages.save} />
              </Button>
            </div>
          </>
        }
      >
        {!!initialStructure && (
          <StructuresBody
            formik={formik}
            image={image}
            onDraw={onDraw}
            onImageLoading={imageLoading}
            setInitialValue={(structure_type: "fixed" | "tracker") =>
              formik.setValues({
                ...getInitialValue(structure_type, intl),
                name: formik.values.name,
              })
            }
          />
        )}

        <LeaveModalWindow
          type={LeaveModalEnum.DELETE}
          onDelete={onDelete}
          show={isDeleteWarning}
          onHide={onHideWarning}
        />
      </MainContent>
    </form>
  );
};

export default StructuresEdit;
