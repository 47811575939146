import { all, fork } from 'redux-saga/effects';
// sagas
import getWaterBalances from 'store/water_balances/sagas/getWaterBalances';
import getWaterBalance from 'store/water_balances/sagas/getWaterBalance';
import createWaterBalance from 'store/water_balances/sagas/createWaterBalance';
import deleteWaterBalance from 'store/water_balances/sagas/deleteWaterBalance';
import updateWaterBalance from 'store/water_balances/sagas/updateWaterBalance';
import runWaterBalance from 'store/water_balances/sagas/runWaterBalance';
import getWaterBalanceResult from 'store/water_balances/sagas/getWaterBalanceResult';
import copyWaterBalance from 'store/water_balances/sagas/copyWaterBalance';
import deleteWaterBalanceResult from 'store/water_balances/sagas/deleteWaterBalanceResult';
import cancelWaterBalance from 'store/water_balances/sagas/cancelWaterBalance';

export default function* water_balances() {
  yield all([
    fork(getWaterBalances),
    fork(getWaterBalance),
    fork(createWaterBalance),
    fork(deleteWaterBalance),
    fork(updateWaterBalance),
    fork(runWaterBalance),
    fork(getWaterBalanceResult),
    fork(copyWaterBalance),
    fork(deleteWaterBalanceResult),
    fork(cancelWaterBalance),
  ]);
}
