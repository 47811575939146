import { toast } from "react-toastify";
import { Msg } from "components/common/msg/Msg";

const onPushNotification = (msg: any) => {
  toast.success(<Msg type="success" msg={msg.message} title={msg.title} />, {
    position: "bottom-left",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    icon: false,
    closeButton: true,
  });
};

export const successNotifications = (msg: {title: string, message: string}) => {
  onPushNotification(msg);
};
