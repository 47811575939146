import {
  getDatasets,
  createDataset,
  getDatasetAnalysis,
  deleteDataset,
  getTimezones,
  getSingleDataset,
  updateDataset,
  copyDataset,
  createDatasetFromAPI,
  createDatasetFromOpen,
} from "store/datasets/actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  list: [],
  item: {},
  timezones: [],
  analysis: {},
  isLoadingCopy: false,
  isLoading: false,
};

const datasetsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case getDatasets.TRIGGER: {
      return {
        ...state,
        // isLoading: true,
      };
    }
    case getDatasets.SUCCESS: {
      return {
        ...state,
        list: payload,
      };
    }

    case getDatasets.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case getTimezones.TRIGGER:
    case updateDataset.TRIGGER:
    case createDataset.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case createDatasetFromAPI.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case createDatasetFromOpen.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case getTimezones.SUCCESS: {
      return {
        ...state,
        timezones: payload,
      };
    }
    case getTimezones.FULFILL:
    case updateDataset.FULFILL:
    case createDataset.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case createDatasetFromAPI.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case createDatasetFromOpen.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case createDataset.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }
    case createDatasetFromAPI.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }
    case createDatasetFromOpen.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }
    case copyDataset.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
        isLoadingCopy: false,
      };
    }
    case copyDataset.TRIGGER: {
      return {
        ...state,
        isLoadingCopy: true,
      };
    }
    case copyDataset.FULFILL: {
      return {
        ...state,
        isLoadingCopy: false,
      };
    }

    case getSingleDataset.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case getSingleDataset.SUCCESS: {
      return {
        ...state,
        item: payload,
      };
    }
    case getSingleDataset.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case updateDataset.SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = payload;

      return {
        ...state,
        list: newList,
      };
    }
    case deleteDataset.SUCCESS: {
      const list = state.list.filter(({ id }) => id !== payload.id);
      return {
        ...state,
        list,
      };
    }
    case getDatasetAnalysis.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getDatasetAnalysis.FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case getDatasetAnalysis.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        analysis: payload,
      };
    }
    default: {
      return state;
    }
  }
};

export default datasetsReducer;
