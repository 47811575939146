import {
  getSoilInformations,
  getSingleSoilInformation,
  createSoilInformation,
  copySoilInformation,
  deleteSoilInformation,
  updateSoilInformation,
  createCurrentSoilInformationByLocalization,
} from "store/soil_informations/actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  list: [],
  item: {},
};

const soilInformationsReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case getSoilInformations.TRIGGER:
    case createCurrentSoilInformationByLocalization.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getSoilInformations.SUCCESS: {
      return {
        ...state,
        list: payload,
      };
    }

    case getSoilInformations.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case copySoilInformation.TRIGGER:
    case getSingleSoilInformation.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getSingleSoilInformation.SUCCESS: {
      return {
        ...state,
        item: payload,
      };
    }

    case copySoilInformation.FULFILL:
    case getSingleSoilInformation.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case createSoilInformation.SUCCESS:
    case createCurrentSoilInformationByLocalization.SUCCESS:
    case copySoilInformation.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }

    case updateSoilInformation.SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = payload;

      return {
        ...state,
        list: newList,
      };
    }

    case deleteSoilInformation.SUCCESS: {
      const list = state.list.filter(({ id }) => id !== payload.id);
      return {
        ...state,
        list,
      };
    }

    default: {
      return state;
    }
  }
};

export default soilInformationsReducer;
