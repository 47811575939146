import React, { useState } from "react";
import styled from "./styled.module.scss";
import Header from "components/Header/Header";
import Button from "components/common/button/Button";
import ProjectCard, { IProject } from "components/ProjectCard/ProjectCard";
import SortDropdown from "components/SortDropdown/SortDropdown";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import ProjectModal from "components/ProjectModal/ProjectModal";
import { FormattedMessage } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import { useDispatch, useSelector } from "react-redux";
import {
  getProjectById,
  getProjectsListState,
  getProjectsLoadingState,
} from "store/projects/selectors";
import { useParams } from "react-router-dom";
import { getClientById } from "store/clients/selectors";
import { deleteProject } from "store/projects/actions";
import withAuthProtection from "hoc/authProtection";
import { Loader } from "components/common/loader/Loader";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";

const ProjectsList = () => {
  const dispatch = useDispatch();
  const { client_id } = useParams();
  const projectList = useSelector(getProjectsListState);
  const isLoading = useSelector(getProjectsLoadingState);
  const currentClient = useSelector(getClientById(client_id));

  const [isShowModal, setIsShowModal] = useState(false);
  const [editId, setEditId] = useState<string | number | undefined>(undefined);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const editProject = useSelector(getProjectById(editId));

  const onHideWarning = () => setDeleteId(null);

  const onShowWarning = (id: number) => () => {
    setDeleteId(id);
  };

  const onHideModal = () => {
    setIsShowModal(false);
    setEditId(undefined);
  };

  const onShowModal = () => {
    setIsShowModal(true);
  };

  const onEdit = (id: string | number) => () => {
    setEditId(id);
    setIsShowModal(true);
  };

  const onDelete = () => {
    dispatch(
      deleteProject({
        clientId: client_id,
        projectId: deleteId,
        callback: onHideWarning,
      })
    );
  };

  return (
    <>
      <Header />
      <main className={styled.wrapper}>
        <section className={styled.headerSection}>
          <div className={styled.row}>
            <h1 className={styled.title}>
              {currentClient?.name}{" "}
              <span className={styled.gray}>
                <FormattedMessage {...commonMessages.projects} />
              </span>
            </h1>

            <div className={styled.control}>
              <SortDropdown type="project" />
              <Button onClick={onShowModal} iconBefore={<Plus />}>
                <FormattedMessage {...commonMessages.createNew} />
              </Button>
            </div>
          </div>

          <p className={styled.subtitle}>{currentClient?.description}</p>
        </section>
        {isLoading ? (
          <Loader />
        ) : (
          <section className={styled.projectsSection}>
            {projectList.map((project: IProject) => (
              <ProjectCard
                key={project.id}
                project={project}
                onDelete={onShowWarning(project.id)}
                onEdit={onEdit(project.id)}
              />
            ))}
          </section>
        )}
      </main>

      <ProjectModal
        show={isShowModal}
        onHide={onHideModal}
        editId={editId}
        utc={editId ? editProject.utc : undefined}
        {...(editId && { initialValues: editProject })}
      />
      <LeaveModalWindow
        type={LeaveModalEnum.DELETE}
        onDelete={onDelete}
        show={deleteId !== null}
        onHide={onHideWarning}
      />
    </>
  );
};

export default withAuthProtection(ProjectsList);
