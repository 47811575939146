import clsx from "clsx";
import styled from "./SpanError.module.scss";

interface ISpanError {
  errorMessage ?: string;
  className?: string;
}

function SpanError({ errorMessage , className }: ISpanError) {
  if (!errorMessage ) {
    return null;
  }
  return (
    <span className={clsx(styled.error, className)}>
      {errorMessage }
    </span>
  );
}

export { SpanError };
