import React, { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import styled from "./styled.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  cancelSimulation,
  deleteSimulationResult,
  getSimulationResult,
  resetSimulationResult,
} from "store/simulations/actions";
import MainContent from "components/MainContent/MainContent";
import { getSimulationResultState } from "store/simulations/selectors";
import { Loader } from "components/common/loader/Loader";
import StatusLabel, { StatusesEnum } from "components/StatusLabel/StatusLabel";
import Button from "components/common/button/Button";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import {
  getSimulationResultFile,
  getSimulationResultImage,
} from "store/simulations/api";
import { ROUTES } from "constants/routes";
import { downloadFile } from "utils/downloadFile";
import SimulationResultBody, {
  IPeriod,
} from "components/SimulationResultBody/SimulationResultBody";

const REFRESH_DATA_TIME = 10000;

const SimulationResult = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client_id, project_id, simulation_id, id } = useParams();
  const result = useSelector(getSimulationResultState);
  const [image, setImage] = useState("");
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState<IPeriod>(null);

  const isPrepare = result?.status === StatusesEnum.PREPARE;
  const isRunningMain = result?.status === StatusesEnum.RUNNING_MAIN;
  const isRunningPeriods = result?.status === StatusesEnum.RUNNING_PERIODS;
  const isCancelingSimulation = result?.status === StatusesEnum.CANCELING;
  const isProgressSimulation =
    isPrepare || isRunningMain || isRunningPeriods || isCancelingSimulation;
  const isSuccessSimulation = result?.status === StatusesEnum.COMPLETED;
  const isFailedSimulation = result?.status === StatusesEnum.FAILED;
  const isCancelSimulation = result?.status === StatusesEnum.CANCELED;

  const options = useMemo(() => {
    return result?.result_data.map((item: any) => ({
      value: item.id,
      label: item.period.name,
    }));
  }, [result?.result_data]);

  const onSelect = (option: any) => {
    setSelectedPeriod(option);
  };

  const getResult = () => {
    dispatch(
      getSimulationResult({
        clientId: client_id,
        projectId: project_id,
        simulationId: simulation_id,
        resultId: id,
      })
    );
  };

  const onDownload = () => {
    setIsFileLoading(true);
    getSimulationResultFile({
      clientId: client_id,
      projectId: project_id,
      simulationId: simulation_id,
      resultId: id,
    })
      .then((href) => {
        downloadFile(href, `${result.name}.zip`);
      })
      .finally(() => setIsFileLoading(false));
  };

  const onBack = () => {
    navigate(
      ROUTES.SIMULATIONS_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", simulation_id as string)
    );
  };

  const onDelete = () => {
    dispatch(
      deleteSimulationResult({
        clientId: client_id,
        projectId: project_id,
        simulationId: simulation_id,
        resultId: id,
        callback: onBack,
      })
    );
  };

  const onCancel = () => {
    dispatch(
      cancelSimulation({
        clientId: client_id,
        projectId: project_id,
        simulationId: simulation_id,
        resultId: id,
      })
    );
  };

  useEffect(() => {
    if (options?.length && isSuccessSimulation) {
      setSelectedPeriod(options[0]);
    }
  }, [options?.length, isSuccessSimulation]);

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isProgressSimulation) {
      timeoutId = setTimeout(getResult, REFRESH_DATA_TIME);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [result]);

  useEffect(() => {
    setImage("")
    getResult();

    return () => {
      dispatch(resetSimulationResult());
    };
  }, [client_id, project_id, simulation_id, id]);

  useEffect(() => {
    if (selectedPeriod !== null && isSuccessSimulation) {
      getSimulationResultImage({
        clientId: client_id,
        projectId: project_id,
        simulationId: simulation_id,
        resultId: id,
        imageId: selectedPeriod?.value,
      }).then((img) => setImage(img));
    }
  }, [selectedPeriod, isSuccessSimulation]);

  if (!result) return <Loader />;

  return (
    <MainContent
      header={
        <>
          <div className={styled.headerRow}>
            <h2 className={styled.title}>{result.name}</h2>
            <StatusLabel status={result?.status} />
          </div>

          <div className={styled.headerRow}>
            {(isSuccessSimulation ||
              isCancelSimulation ||
              isFailedSimulation) && (
              <Button
                variant="text"
                iconBefore={<Delete />}
                onClick={onDelete}
                className={styled.deleteButton}
              >
                <FormattedMessage {...commonMessages.delete} />
              </Button>
            )}

            {isSuccessSimulation && (
              <Button
                iconBefore={<Icon name={ICON_NAMES_ENUM.arrow_down} />}
                disabled={isFileLoading}
                isLoading={isFileLoading}
                onClick={onDownload}
              >
                <FormattedMessage {...commonMessages.download} />
              </Button>
            )}
          </div>
        </>
      }
    >
      <SimulationResultBody
        result={result}
        onSelect={onSelect}
        selectedPeriod={selectedPeriod}
        image={image}
        onCancelSimulation={onCancel}
      />
    </MainContent>
  );
};

export default SimulationResult;
