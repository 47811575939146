import { all, fork } from 'redux-saga/effects';
// sagas
import createUser from './createUser';
import getUsers from './getUsers';
import deleteUser from './deleteUser';
import updateUser from './updateUser';
import activateUser from './activateUser';
import deactivateUser from './deactivateUser';

export default function* user() {
  yield all([
    fork(createUser),
    fork(getUsers),
    fork(deleteUser),
    fork(updateUser),
    fork(activateUser),
    fork(deactivateUser),
  ]);
}
