import React, { useState } from "react";
import { ReactComponent as Crops } from "assets/icons/crops.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";

import styled from "./styled.module.scss";
import clsx from "clsx";
import moment from "moment";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import { useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import { Tooltip } from "@mui/material";

const SteeringAlgorithmCropsCard = (props: {
  children: any;
  name: string;
  date: string;
  onAddSteeringAlgorithm: () => void;
  onDelete: () => void;
  onClick: () => void;
}) => {
  const [open, setOpen] = useState(false);
  const intl = useIntl();

  const onToggle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setOpen(!open);
  };

  const onAddSteeringAlgorithmButton = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    props.onAddSteeringAlgorithm();
  };

  return (
    <div>
      <div className={styled.container} onClick={props.onClick}>
        <div className={styled.card}>
          <div className={styled.flex}>
            <button
              className={clsx(styled.button, {
                [styled.toggleOpen]: !open,
              })}
              onClick={onToggle}
              type="button"
            >
              <Arrow />
            </button>
            <Crops />
            <p className={styled.name}>{props.name}</p>
          </div>

          <div className={styled.flex}>
            <span className={styled.date}>
              {moment.utc(props.date).local().format("DD MMMM YYYY, HH:mm")}
            </span>
            <Tooltip
              title={intl
                .formatMessage(commonMessages.createSteeringForCrop)
                .replace(":crop_name", props.name)}
            >
              <button
                className={styled.button}
                onClick={onAddSteeringAlgorithmButton}
                type="button"
              >
                <Icon name={ICON_NAMES_ENUM.plus_circle} />
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
      <div className={styled.list}>{!open ? <>{props.children}</> : <></>}</div>
    </div>
  );
};

export default SteeringAlgorithmCropsCard;
