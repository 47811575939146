import { object, string, number, boolean, array, ValidationError } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";
import StructuresCreateMessages from "./StructuresCreateMessages";

export const panel_height_schema = (intl: IntlShape) =>
  number()
    .moreThan(0, intl.formatMessage(validationMessages.moreThan, { number: 0 }))
    .required(intl.formatMessage(validationMessages.required));

export const panel_opacity_schema = (intl: IntlShape) =>
  number()
    .min(0, intl.formatMessage(validationMessages.minValue, { number: 0 }))
    .max(1, intl.formatMessage(validationMessages.maxValue, { number: 1 }))
    .required(intl.formatMessage(validationMessages.required));

export const panel_size_schema = (intl: IntlShape) =>
  number()
    .moreThan(0, intl.formatMessage(validationMessages.moreThan, { number: 0 }))
    .required(intl.formatMessage(validationMessages.required));

export const initial_offset_schema = (intl: IntlShape) =>
  number()
    .min(
      -100,
      intl.formatMessage(validationMessages.minValue, { number: -100 })
    )
    .max(100, intl.formatMessage(validationMessages.maxValue, { number: 100 }))
    .required(intl.formatMessage(validationMessages.required));

export const panels_number_schema = (intl: IntlShape) =>
  number()
    .integer(intl.formatMessage(validationMessages.integer))
    .min(0, intl.formatMessage(validationMessages.minValue, { number: 0 }))
    .required(intl.formatMessage(validationMessages.required));

export const panels_gap_schema = (intl: IntlShape) =>
  number()
    .min(0, intl.formatMessage(validationMessages.minValue, { number: 0 }))
    .required(intl.formatMessage(validationMessages.required));

export const panel_tilt_schema = (intl: IntlShape) =>
  number()
    .min(-90, intl.formatMessage(validationMessages.minValue, { number: -90 }))
    .max(90, intl.formatMessage(validationMessages.maxValue, { number: 90 }))
    .required(intl.formatMessage(validationMessages.required));

export const panel_max_tilt_schema = (intl: IntlShape) =>
  number()
    .min(0, intl.formatMessage(validationMessages.minValue, { number: 0 }))
    .max(90, intl.formatMessage(validationMessages.maxValue, { number: 90 }))
    .required(intl.formatMessage(validationMessages.required));

export const field_size_schema = (intl: IntlShape) =>
  number()
    .integer(intl.formatMessage(validationMessages.integer))
    .min(1, intl.formatMessage(validationMessages.minValue, { number: 1 }))
    .required(intl.formatMessage(validationMessages.required));

export const azimuth_schema = (intl: IntlShape) =>
  number()
    .min(-90, intl.formatMessage(validationMessages.minValue, { number: -90 }))
    .max(90, intl.formatMessage(validationMessages.maxValue, { number: 90 }))
    .required(intl.formatMessage(validationMessages.required));

export const module_efficiency_schema = (intl: IntlShape) =>
  number()
    .min(0, intl.formatMessage(validationMessages.minValue, { number: 0 }))
    .max(100, intl.formatMessage(validationMessages.maxValue, { number: 100 }))
    .required(intl.formatMessage(validationMessages.required));

export const temperature_cefficients_power_schema = (intl: IntlShape) =>
  number()
    .min(-1, intl.formatMessage(validationMessages.minValue, { number: -1 }))
    .max(0, intl.formatMessage(validationMessages.maxValue, { number: 0 }))
    .required(intl.formatMessage(validationMessages.required));

export const albedo_schema = (intl: IntlShape) =>
  number()
    .min(0, intl.formatMessage(validationMessages.minValue, { number: 0 }))
    .max(1, intl.formatMessage(validationMessages.maxValue, { number: 1 }))
    .required(intl.formatMessage(validationMessages.required));

export const bifacial_factor_schema = (intl: IntlShape) =>
  number()
    .min(0, intl.formatMessage(validationMessages.minValue, { number: 0 }))
    .max(100, intl.formatMessage(validationMessages.maxValue, { number: 100 }))
    .required(intl.formatMessage(validationMessages.required));

const StructuresSchema = (intl: IntlShape) =>
  object()
    .shape({
      name: string()
        .max(
          50,
          intl.formatMessage(validationMessages.maxLength, { number: 50 })
        )
        .required(intl.formatMessage(validationMessages.required)),
      panel_height: panel_height_schema(intl),
      panel_opacity: panel_opacity_schema(intl),
      panel_width: panel_size_schema(intl),
      panel_length: panel_size_schema(intl),
      initial_offset_x: initial_offset_schema(intl),
      initial_offset_y: initial_offset_schema(intl),
      panels_number_x: panels_number_schema(intl),
      panels_number_y: panels_number_schema(intl),
      panels_gap_x: panels_gap_schema(intl),
      panels_gap_y: panels_gap_schema(intl),
      field_size_x: field_size_schema(intl),
      field_size_y: field_size_schema(intl),
      no_crop_zone: number()
        .min(0, intl.formatMessage(validationMessages.minValue, { number: 0 }))
        .nullable(),
      resolution: number()
        .integer(intl.formatMessage(validationMessages.integer))
        .min(1, intl.formatMessage(validationMessages.minValue, { number: 1 }))
        .required(intl.formatMessage(validationMessages.required)),
      azimuth: azimuth_schema(intl),
      structure_type: string().required(
        intl.formatMessage(validationMessages.required)
      ),
      static_angle: number().required(
        intl.formatMessage(validationMessages.required)
      ),
      tracking_max_angle: number().required(
        intl.formatMessage(validationMessages.required)
      ),
      backtracking: boolean(),
      displacement_tracking: boolean(),
      translation_max_delta: number()
        .min(1, intl.formatMessage(validationMessages.minValue, { number: 1 }))
        .required(intl.formatMessage(validationMessages.required)),
      translation_init_delta: number()
        .min(0, intl.formatMessage(validationMessages.minValue, { number: 0 }))
        .required(intl.formatMessage(validationMessages.required)),
      solar_position: string().required(
        intl.formatMessage(validationMessages.required)
      ),
      azimuth_solar: number().required(
        intl.formatMessage(validationMessages.required)
      ),
      zenith_solar: number().required(
        intl.formatMessage(validationMessages.required)
      ),
      day: string().required(intl.formatMessage(validationMessages.required)),
      hour: number()
        .integer(intl.formatMessage(validationMessages.integer))
        .min(0, intl.formatMessage(validationMessages.minValue, { number: 0 }))
        .max(
          23,
          intl.formatMessage(validationMessages.maxValue, { number: 23 })
        )
        .required(intl.formatMessage(validationMessages.required)),
      points: array().of(
        object().shape({
          name: string(),
          x: number(),
          y: number(),
        })
      ),
      module_efficiency_STC: module_efficiency_schema(intl),
      temperature_cefficients_power: temperature_cefficients_power_schema(intl),
      albedo: albedo_schema(intl),
      bifacial_factor: bifacial_factor_schema(intl),
    })
    .test("no_crop_zone_size_max", (value, context) => {
      if (value.no_crop_zone != null && value.no_crop_zone > value.panel_width!)
        throw new ValidationError(
          context.createError({
            path: `no_crop_zone`,
            message: intl.formatMessage(validationMessages.maxValue, {
              number: `${intl.formatMessage(
                StructuresCreateMessages.tableWidth
              )} : ${value.panel_width!}`,
            }),
          })
        );
      return true;
    });
export default StructuresSchema;
