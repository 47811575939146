import { number, object, string } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";

// '/(time_slot|new_type_goes_here)/'
export const validRowTypes = new RegExp(
  '/(time_slot)/'
);

const AddRowSchema = (intl: IntlShape) =>
  object().shape({
    type: string().matches(validRowTypes, validationMessages.typeNotFound)
      .required(intl.formatMessage(validationMessages.required)),
      state: string().matches(validRowTypes, validationMessages.typeNotFound)
      .required(intl.formatMessage(validationMessages.required)),
    value: object<any>().required(),
  });

export default AddRowSchema;
