import { object, string } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";

const ProfilePageSchema = (intl: IntlShape) =>
  object().shape({
    name: string()
      .max(50, intl.formatMessage(validationMessages.maxLength, { number: 50 }))
      .required(intl.formatMessage(validationMessages.required)),
    email: string()
      .email(validationMessages.email)
      .required(intl.formatMessage(validationMessages.required)),
    role: string().required(intl.formatMessage(validationMessages.required)),
  });

export default ProfilePageSchema;
