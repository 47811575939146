import { defineMessages } from "react-intl";

export default defineMessages({
  name: {
    id: "BatchesCreateMessages.name",
    defaultMessage: "Batches Name",
  },
  batchUnnamed: {
    id: "BatchesCreateMessages.batchUnnamed",
    defaultMessage: "Unnamed Batch",
  },
});
