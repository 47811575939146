import { SolarPanel } from './SolarPanel'

export const AllSolarPanel = (props: {
  nb_panel_x: number;
  nb_panel_y: number;
  panel_span_x: number;
  panel_span_y: number;
  panel_height: number;
  panel_size_x: number;
  panel_size_y: number;
  panel_rotation: number;
  opacity: number;
  floor: { height: number; width: number };
  offset_x: number;
  offset_y: number;
  solar_azimuth: number;
  solar_zenith: number;
  structure_type: "tracker" | "fixed";
  tracking_max_angle: number;
  backtracking: boolean;
  sun_position: any;
  sun_angle: number;
}): any => {
  var nb_panel_x = 0,
    nb_panel_y = 0;
  var panel_x = props.offset_x,
    panel_y = props.offset_y;
  var returnArray = [];
  var i = 0;

  while (nb_panel_x < props.nb_panel_x) {
    while (nb_panel_y < props.nb_panel_y) {
      returnArray.push(
        <SolarPanel
          panel_height={props.panel_height}
          panel_x={panel_x}
          panel_y={panel_y}
          rotation={props.panel_rotation}
          solar_angle={Math.abs(props.sun_angle * 180 / Math.PI) < props.tracking_max_angle ? -(props.sun_angle * 180 / Math.PI) : (props.sun_angle * 180 / Math.PI < 0 ? props.tracking_max_angle : -props.tracking_max_angle)}
          panel_size_height={props.panel_size_y}
          panel_size_width={props.panel_size_x}
          floor={{ height: props.floor.height, width: props.floor.width }}
          opacity={props.opacity}
          solar_azimuth={props.solar_azimuth}
          solar_zenith={props.solar_zenith}
          structure_type={props.structure_type}
          tracking_max_angle={props.tracking_max_angle}
          backtracking={props.backtracking}
          key={(i++).toString()}
        />
      );

      panel_y += props.panel_span_y;
      nb_panel_y++;
    }
    nb_panel_y = 0;
    panel_y = props.offset_y;
    panel_x += props.panel_span_x;
    nb_panel_x++;
  }

  return returnArray;
};
