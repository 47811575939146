import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCropsItem, getIsLoading } from "store/crops/selectors";
import { useDispatch, useSelector } from "react-redux";
import { getSingleCrop } from "store/crops/actions";
import moment from "moment/moment";
import { ROUTES } from "constants/routes";
import { updateCrop, deleteCrop } from "store/crops/actions";
import { FormikHelpers, useFormik } from "formik";
import CropSchema from "../CropsCreate/CropSchema";
import { useIntl } from "react-intl";
import { getSoilInformationsItemState } from "store/soil_informations/selectors";
import { deleteSoilInformation, getSingleSoilInformation, updateSoilInformation } from "store/soil_informations/actions";
import { ISoilInformations } from "pages/SoilInformationsCreate/SoilInformationsCreateTypes";
import SoilInformationsSchema from "pages/SoilInformationsCreate/SoilInformationsSchema";
import { getInitialFormValue } from "pages/SoilInformationsCreate/SoilInformationsCreateHook";
import { successNotifications } from "utils/successNotifications";
import CommonMessages from "components/common/CommonMessages";

function SoilInformationsEditHook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const soil_information = useSelector(getSoilInformationsItemState);
  const isLoading = useSelector(getIsLoading);
  const { client_id, project_id, id } = useParams();

  const getCurrentSoilInformationInfo = async () => {
    await formik.setFieldValue("name", soil_information.name);
    await formik.setFieldValue("ru_water", soil_information.ru_water);
    await formik.setFieldValue("rfu_water", soil_information.rfu_water);
    await formik.setFieldValue("irrigation", soil_information.irrigation);
  };

  useEffect(() => {
    dispatch(
      getSingleSoilInformation({
        clientId: client_id,
        projectId: project_id,
        soilInformationId: id,
      })
    );
  }, [client_id, project_id, id]);

  useEffect(() => {
    if (soil_information.name != undefined) {
      getCurrentSoilInformationInfo();
    }
  }, [soil_information]);
  const onBack = () =>
    navigate(
      ROUTES.SOIL_INFORMATIONS.replace(
        ":client_id",
        client_id as string
      ).replace(":project_id", project_id as string)
    );
  const onUpdate = (values: ISoilInformations, actions: FormikHelpers<ISoilInformations>) => {
    var soil = {
      name: values.name,
      ru_water: values.ru_water,
      rfu_water: values.rfu_water,
      irrigation: values.irrigation,
    };

    dispatch(
      updateSoilInformation({
        ...soil,
        clientId: client_id,
        projectId: project_id,
        soilInformationId: id,
        callback: () => {
          dispatch(
            getSingleSoilInformation({
              clientId: client_id,
              projectId: project_id,
              soilInformationId: id,
            })
          );
          successNotifications({title: intl.formatMessage(CommonMessages.edit), message: intl.formatMessage(CommonMessages.successEdit, {objet_type: intl.formatMessage(CommonMessages.soil_information)})})
        },
        finallyCallback: () => actions.setSubmitting(false),
      })
    );
  };
  const onDeleteSoilInformation = () => {
    dispatch(
      deleteSoilInformation({
        clientId: client_id,
        projectId: project_id,
        soilInformationId: Number(id),
        callback: onBack,
      })
    );
  };

  const formik = useFormik<ISoilInformations>({
    validationSchema: SoilInformationsSchema(intl),
    onSubmit: onUpdate,
    initialValues: getInitialFormValue(intl),
  });

  return {
    models: {
      client_id,
      project_id,
      id,
      isLoading,
      formik,
    },
    commands: {
      onDeleteSoilInformation,
      onBack,
    },
  };
}

export { SoilInformationsEditHook };
