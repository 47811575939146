import React, { InputHTMLAttributes } from "react";
import styled from "./styled.module.scss";
import clsx from "clsx";
import { SpanError } from "components/common/span-error/SpanError";

interface IEditName extends InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: string;
}

const EditName = ({ className, errorMessage, ...rest }: IEditName) => {
  return (
    <div className={styled.container}>
      <input
        className={clsx(styled.input, className, {
          [styled.errorBorder]: !!errorMessage,
        })}
        {...rest}
      />
      <SpanError errorMessage={errorMessage} />
    </div>
  );
};

export default EditName;
