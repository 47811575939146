import React, { useState } from "react";
import { SingleDatePicker, SingleDatePickerShape } from "react-dates";
import moment, { Moment } from "moment/moment";
import { ReactComponent as Calendar } from "assets/icons/calendar.svg";
import styled from "./styled.module.scss";
import Select from "components/common/select/Select";
import { SpanError } from "../span-error/SpanError";

interface IDatePicker {
  label?: string;
  placeholder?: string;
  date: Moment | null;
  openDown?: boolean;
  onChange: (v: Moment | null) => void;
  errorMessage?: string;
}

const monthOptions = moment.months().map((label, value) => ({ label, value }));
const yearOptions = Array(50)
  .fill(null)
  .map((_, index) => ({
    value: moment().year() - index,
    label: moment().year() - index,
  }));
const defaultDate = moment().year(2022);
const DatePicker = ({
  label,
  date,
  onChange,
  id,
  placeholder,
  openDown = true,
  errorMessage,
}: IDatePicker &
  Omit<
    SingleDatePickerShape,
    "date" | "focused" | "onDateChange" | "onFocusChange"
  >) => {
  const [focus, setFocus] = useState(false);

  return (
    <div className={styled.container}>
      {!!label && <label className={styled.label}>{label}</label>}
      <SingleDatePicker
        openDirection={openDown ? "down" : "up"}
        customInputIcon={<Calendar />}
        renderMonthElement={({ month, onMonthSelect, onYearSelect }) => (
          <div
            style={{ display: "flex", justifyContent: "center", gap: "1rem" }}
          >
            <div style={{ flex: 1 }}>
              <Select
                menuHeight={200}
                options={monthOptions}
                value={monthOptions.find(
                  ({ value }) => value === month.month()
                )}
                onChange={(v: any) => onMonthSelect(month, v.value)}
              />
            </div>
          </div>
        )}
        navNext={<span />}
        navPrev={<span />}
        renderWeekHeaderElement={() => <span />}
        inputIconPosition="after"
        focused={focus}
        date={date || defaultDate}
        onFocusChange={({ focused }) => setFocus(focused)}
        onDateChange={(d) => onChange(d)}
        numberOfMonths={1}
        displayFormat="DD MMM"
        id={id}
        placeholder={placeholder}
        isOutsideRange={() => false}
      />
      <SpanError errorMessage={errorMessage} />
    </div>
  );
};

export default DatePicker;
