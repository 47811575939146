import React, { FormEvent } from "react";
import Button from "components/common/button/Button";
import styled from "./styled.module.scss";
import ModalContainer from "components/ModalContainer/ModalContainer";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import changeUserStatusModalMessages from "components/ChangeUserStatusModal/ChangeUserStatusModalMessages";

interface IDeleteUser {
  show: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  email: string;
}

const ChangeUserStatusModal = ({
  show,
  onCancel,
  onSubmit,
  email,
}: IDeleteUser) => {
  const intl = useIntl();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <ModalContainer
      show={show}
      onHide={onCancel}
      dialogClassName={styled.dialog}
      title={intl.formatMessage(changeUserStatusModalMessages.changeStatus)}
      subTitle={intl.formatMessage(changeUserStatusModalMessages.text)}
    >
      <form className={styled.form} onSubmit={handleSubmit}>
        <p className={styled.email}>{email}</p>
        <div className={styled.row}>
          <Button type="submit">
            <FormattedMessage {...commonMessages.change} />
          </Button>
          <Button variant="text" onClick={onCancel}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default ChangeUserStatusModal;
