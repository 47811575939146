import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import { composeWithDevTools } from "redux-devtools-extension";

// Reducers
import reducers from "./reducers";
// Sagas
import sagas from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const middleware = applyMiddleware(sagaMiddleware);

const store = createStore(reducers, composeWithDevTools(middleware));

sagaMiddleware.run(sagas);

export default store;
