import {
  deleteBatch,
  getBatches,
  copyBatch,
  createBatch,
  updateBatch,
  getParametersOfInterest,
  runBatchSimulation,
  getBatchResults,
  getBatchSimulationResult,
  resetBatchSimulationResult,
  resetBatchResults,
  cancelBatchSimulation,
} from "store/batches/actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  list: [],
  parameters: [],
  batchResults: null,
  simulationResult: null,
};

const batchesReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case getBatches.TRIGGER:
    case runBatchSimulation.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getBatches.SUCCESS: {
      return {
        ...state,
        list: payload,
      };
    }

    case createBatch.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }

    case copyBatch.SUCCESS: {
      const newBatch = {
        ...payload,
        rows_number: payload.rows.length,
      };
      return {
        ...state,
        list: [...state.list, newBatch],
      };
    }

    case deleteBatch.SUCCESS: {
      const list = state.list.filter(({ id }) => id !== payload.id);
      return {
        ...state,
        list,
      };
    }

    case updateBatch.SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = payload;

      return {
        ...state,
        list: newList,
      };
    }

    case getParametersOfInterest.SUCCESS: {
      return {
        ...state,
        parameters: payload,
      };
    }

    case runBatchSimulation.SUCCESS:
    case cancelBatchSimulation.SUCCESS:
    case getBatchResults.SUCCESS: {
      return {
        ...state,
        batchResults: payload,
      };
    }

    case resetBatchResults.TRIGGER: {
      return {
        ...state,
        batchResults: INITIAL_STATE.batchResults,
      };
    }

    case getBatchSimulationResult.SUCCESS: {
      return {
        ...state,
        simulationResult: payload,
      };
    }

    case resetBatchSimulationResult.TRIGGER: {
      return {
        ...state,
        simulationResult: INITIAL_STATE.simulationResult,
      };
    }

    case getBatches.FULFILL:
    case runBatchSimulation.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default batchesReducer;
