import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { ReactComponent as Icon } from "assets/icons/sort.svg";
import { ReactComponent as ArrowDown } from "assets/icons/arrow-down.svg";
import styled from "./styled.module.scss";
import CustomToggle from "components/CustomToggle/CustomToggle";
import { changeClientSort } from "store/clients/actions";
import { changeProjectSort } from "store/projects/actions";
import { useDispatch, useSelector } from "react-redux";
import localforage from "store/services/localForage";
import { getClientsSortState } from "store/clients/selectors";
import { getProjectsSortState } from "store/projects/selectors";
import { FormattedMessage } from "react-intl";
import SortDropdownMessages from "./SortDropdownMessages";

interface ISortVariant {
  action: ISort;
  title: React.ReactNode;
}

interface ISort {
  order_by: string;
  order: string;
}

interface ISortDropdown {
  type: "client" | "project";
}

const sortVariants: ISortVariant[] = [
  {
    action: {
      order_by: "name",
      order: "asc",
    },
    title: (
      <>
        <span>
          {" "}
          <FormattedMessage {...SortDropdownMessages.Name} />
        </span>{" "}
        <ArrowDown />
      </>
    ),
  },
  {
    action: {
      order_by: "name",
      order: "desc",
    },
    title: (
      <>
        <span>
          <FormattedMessage {...SortDropdownMessages.Name} />
        </span>{" "}
        <ArrowDown className={styled.rotate} />
      </>
    ),
  },
  {
    action: {
      order_by: "created_at",
      order: "asc",
    },
    title: (
      <>
        <span>
          <FormattedMessage {...SortDropdownMessages.Date} />
        </span>{" "}
        <ArrowDown />
      </>
    ),
  },
  {
    action: {
      order_by: "created_at",
      order: "desc",
    },
    title: (
      <>
        <span>
          <FormattedMessage {...SortDropdownMessages.Date} />
        </span>{" "}
        <ArrowDown className={styled.rotate} />
      </>
    ),
  },
];

const SortDropdown = ({ type }: ISortDropdown) => {
  const dispatch = useDispatch();
  const clientSort = useSelector(getClientsSortState);
  const projectSort = useSelector(getProjectsSortState);
  const [value, setValue] = useState(sortVariants[2]);

  const onChangeClientSort = (sort: ISort) => {
    dispatch(
      changeClientSort({
        sort,
      })
    );
    localforage.setItem("client-sort", JSON.stringify(sort));
  };

  const onChangeProjectSort = (sort: ISort) => {
    dispatch(
      changeProjectSort({
        sort,
      })
    );
    localforage.setItem("project-sort", JSON.stringify(sort));
  };

  const onSelect = (item: ISortVariant) => () => {
    if (type === "client") {
      onChangeClientSort(item.action);
    }
    if (type === "project") {
      onChangeProjectSort(item.action);
    }
  };

  useEffect(() => {
    if (type === "client") {
      const index = sortVariants.findIndex(
        ({ action: { order_by, order } }) =>
          order_by === clientSort?.order_by && order === clientSort?.order
      );

      if (index >= 0) setValue(sortVariants[index]);
    }

    if (type === "project") {
      const index = sortVariants.findIndex(
        ({ action: { order_by, order } }) =>
          order_by === projectSort?.order_by && order === projectSort?.order
      );

      if (index >= 0) setValue(sortVariants[index]);
    }
  }, [clientSort, projectSort]);

  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>
        <Icon className={styled.icon} />{" "}
        <FormattedMessage {...SortDropdownMessages.sortBy} />{" "}
        <strong>{value.title}</strong>
      </Dropdown.Toggle>

      <Dropdown.Menu align="end">
        {sortVariants.map((item, index) => (
          <Dropdown.Item onClick={onSelect(item)} key={index}>
            {item.title}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default SortDropdown;
