import { defineMessages } from "react-intl";

export default defineMessages({
  myProfile : {
    id             : "ProfileMessages.myProfile",
    defaultMessage : "My Profile",
  },
  settings : {
    id             : "ProfileMessages.settings",
    defaultMessage : "Settings",
  },
  logout : {
    id             : "ProfileMessages.logout",
    defaultMessage : "Logout",
  },
  language : {
    id             : "ProfileMessages.language",
    defaultMessage : "Language",
  },
});
