import React, { useEffect, useState } from "react";
import Button from "components/common/button/Button";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ReactComponent as Save } from "assets/icons/save.svg";
import { ReactComponent as Long } from "assets/icons/long.svg";
import { ReactComponent as Lat } from "assets/icons/lat.svg";
import styled from "./styled.module.scss";
import { FormikValues, useFormik } from "formik";
import Input from "components/common/input/Input";
import ModalContainer from "components/ModalContainer/ModalContainer";
import ProjectSchema from "components/ProjectModal/ProjectSchema";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import projectModalMessages from "components/ProjectModal/ProjectModalMessages";
import { useDispatch } from "react-redux";
import { createProject, updateProject } from "store/projects/actions";
import { useParams } from "react-router-dom";
import tzLookup from 'tz-lookup';
import moment from 'moment-timezone';

interface IProjectModal {
  show: boolean;
  onHide: () => void;
  initialValues?: IProjectForm;
  editId?: string | number;
  utc?: number | undefined;
}

interface IProjectForm {
  name: string;
  lat: string;
  long: string;
}

const defaultFormValue: IProjectForm = {
  name: "",
  lat: "",
  long: "",
};

const ProjectModal = ({
  show,
  onHide,
  editId,
  initialValues = defaultFormValue,
  utc = undefined,
}: IProjectModal) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { client_id } = useParams();
  const [utcValue, setUtcValue] = useState("");

  const onSubmit = (values: FormikValues) => {
    if (editId) {
      dispatch(
        updateProject({ clientId: client_id, projectId: editId, ...values })
      );
    } else {
      dispatch(createProject({ clientId: client_id, ...values }));
    }
    onHide();
  };

  const isValidPosition =  () => {
    if (formik.values.lat === null || formik.values.long === null)
      return false
    if (formik.values.lat.length <= 0 || formik.values.long.length <= 0)
      return false
    if (formik.errors.lat && formik.errors.long)
      return false
    return true;
  }

  const getUtc = () => {
    try {
      if (isValidPosition()) {
        const timeZone = tzLookup(parseInt(formik.values.lat), parseInt(formik.values.long))
        const currentTime = moment.tz(new Date(), timeZone);
        const utcOffset = currentTime.utcOffset();
        if (currentTime.isDST())
          return `UTC${utcOffset - 60 < 0 ? '': '+'}${`${(utcOffset % 60) === 0 ? (utcOffset / 60) - 1 :`${Math.floor(Math.abs(utcOffset / 60)) - 1}:${(utcOffset % 60) }`}`}`
        return `UTC${utcOffset < 0 ? '': '+'}${`${(utcOffset % 60) === 0 ? utcOffset / 60 :`${Math.floor(Math.abs(utcOffset / 60))}:${(utcOffset % 60) }`}`}` 
      }
    }
    catch (err) {
      return ("Invalid position")
    }
  } 

  const formik = useFormik<IProjectForm>({
    validationSchema: ProjectSchema(intl),
    onSubmit,
    initialValues,
  });

  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (!show) {
      setUtcValue("")
      formik.resetForm();
    }
  }, [show]);

  return (
    <ModalContainer
      show={show}
      onHide={onHide}
      dialogClassName={styled.dialog}
      title={
        editId
          ? intl.formatMessage(projectModalMessages.editProject)
          : intl.formatMessage(projectModalMessages.createNew)
      }
    >
      <form className={styled.form} onSubmit={formik.handleSubmit}>
        <Input
          name="name"
          label={intl.formatMessage(projectModalMessages.nameLabel)}
          placeholder={intl.formatMessage(projectModalMessages.namePlaceholder)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          errorMessage={(formik.touched.name && formik.errors.name) as string}
        />

        <div className={styled.row}>
          <Input
            name="lat"
            type="number"
            label={intl.formatMessage(projectModalMessages.latitude)}
            placeholder={intl.formatMessage(
              projectModalMessages.latitudePlaceholder
            )}
            beforeIcon={<Long />}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onBlurCapture={getUtc}
            value={formik.values.lat}
            errorMessage={(formik.touched.lat && formik.errors.lat) as string}
          />

          <Input
            name="long"
            type="number"
            label={intl.formatMessage(projectModalMessages.longitude)}
            placeholder={intl.formatMessage(
              projectModalMessages.longitudePlaceholder
            )}
            beforeIcon={<Lat />}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            onBlurCapture={getUtc}
            value={formik.values.long}
            errorMessage={(formik.touched.long && formik.errors.long) as string}
          />
        </div>

        <Input
            name="utc"
            type="text"
            disabled={true}
            label={intl.formatMessage(projectModalMessages.UTCLabel)}
            placeholder={intl.formatMessage(
              projectModalMessages.UTCPlaceholder
            )}
            value={getUtc()}
          />

        <div className={styled.row}>
          <Button iconBefore={editId ? <Save /> : <Plus />} type="submit">
            {editId ? (
              <FormattedMessage {...commonMessages.save} />
            ) : (
              <FormattedMessage {...commonMessages.create} />
            )}
          </Button>
          <Button variant="text" onClick={onHide}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default ProjectModal;
