import React from "react";
import ReactSelect, { Props } from "react-select";
import { FormattedMessage } from "react-intl";
import textSelectMessages from "components/common/textSelect/TextSelectMessages";

interface ITextSelect extends Props {
  size?: "small" | "medium";
  menuAnchor?: "right" | "left";
  menuWidth?: number;
  width?: React.CSSProperties['width']
}

const TextSelect = ({
  name,
  size = "small",
  menuAnchor = "right",
  menuWidth = 200,
  width,
  ...rest
}: ITextSelect) => {
  return (
    <ReactSelect
      id={name}
      name={name}
      classNamePrefix="select"
      components={{
        IndicatorSeparator: null,
      }}
      styles={{
        input: (base) => ({
          ...base,
          textOverflow:'none', 
          fontFamily: "Lato",
          fontSize: size === "small" ? "0.75rem" : "0.875rem",
        }),
        container: (base) => ({
          ...base,
          fontFamily: "Lato",
          fontSize: size === "small" ? "0.75rem" : "0.875rem",
          ...(!!width && { width: width })
        }),
        valueContainer: (base) => ({
          ...base,
          paddingTop: 0,
          paddingBottom: 0,
          paddingRight: 0,
        }),
        control: (base) => ({
          ...base,
          borderRadius: 4,
          border: "none",
          minHeight: 20,
          backgroundColor: "transparent",
        }),
        dropdownIndicator: (base) => ({
          ...base,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        placeholder: (base) => ({
          ...base,
          color: "#333837",
        }),
        menu: (base) => ({
          ...base,
          width: menuWidth,
          ...(menuAnchor === "right" ? { right: 0 } : { left: 0 }),
        }),
      }}
      placeholder={<FormattedMessage {...textSelectMessages.select} />}
      {...rest}
    />
  );
};

export default TextSelect;
