import {
  getStructures,
  updateStructure,
  deleteStructure,
  createStructure,
  getStructureImage,
  drawStructureImage,
  copyStructure,
} from "store/structures/actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  list: [],
  image: null,
};

const structuresReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case getStructures.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case getStructures.SUCCESS: {
      return {
        ...state,
        list: payload,
      };
    }

    case createStructure.SUCCESS:
    case copyStructure.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }

    case deleteStructure.SUCCESS: {
      const list = state.list.filter(({ id }) => id !== payload.id);

      return {
        ...state,
        list,
      };
    }

    case updateStructure.SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = payload;

      return {
        ...state,
        list: newList,
      };
    }

    case getStructureImage.SUCCESS:
    case drawStructureImage.SUCCESS: {
      return {
        ...state,
        image: payload,
      };
    }

    case getStructures.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default structuresReducer;
