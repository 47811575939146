import { put, takeLatest, call, select } from "redux-saga/effects";
// actions
import { getTimezones as action } from "store/datasets/actions";
// api
import { getTimezones as api } from "store/datasets/api";
import { getTimezonesList } from "../selectors";

function transformTimezones(timezones) {
  const localTimeZoneIndex = timezones.findIndex(
    (item) => item === "Local time zone"
  );
  const localTime = timezones.splice(localTimeZoneIndex, 1);

  const keyVar = "Etc/GMT";

  const sortKeys = timezones
    .map((item) => Number(item.split(keyVar)[1]))
    .sort((a, b) => a - b)
    .map((item) => `${keyVar}${item > 0 ? "+" : ""}${item}`);

  const sortedTimezones = [...localTime, ...sortKeys];

  return sortedTimezones.map((timezone) => ({
    value: timezone,
    label: timezone,
  }));
}

function* saga() {
  try {
    const timezones = yield select(getTimezonesList);

    if (!timezones.length) {
      yield put(action.request());
      const response = yield call(api);
      yield put(action.success(transformTimezones(response.data)));
    }
  } catch (error) {
    console.error(error);
  } finally {
    yield put(action.fulfill());
  }
}

export default function* getTimezones() {
  yield takeLatest(action.TRIGGER, saga);
}
