import { signIn, logout, auth, editProfile, setPassword } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  error: null,
  user: null,
  token: "",
};

const authReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case signIn.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case setPassword.SUCCESS:
    case signIn.SUCCESS: {
      return {
        ...state,
        ...payload,
      };
    }

    case auth.SUCCESS: {
      return {
        ...state,
        user: payload,
      };
    }

    case editProfile.SUCCESS: {
      return {
        ...state,
        user: payload,
      };
    }

    case signIn.FAILURE: {
      return {
        ...state,
        error: payload,
      };
    }

    case signIn.FULFILL:
    case logout.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default authReducer;
