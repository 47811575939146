import React, { useEffect, useMemo, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import styled from "./styled.module.scss";
import { ReactComponent as Structures } from "assets/icons/structures.svg";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import StructuresRenderList from "components/SimulationDropItem/StructuresRenderList";
import { ReactComponent as Crops } from "assets/icons/crops.svg";
import CropsRenderList from "components/SimulationDropItem/CropsRenderList";
import { ReactComponent as DataTemp } from "assets/icons/data-temp.svg";
import simulationDropItemMessages from "components/SimulationDropItem/SimulationDropItemMessages";
import DatasetRenderList from "components/SimulationDropItem/DatasetRenderList";
import { ReactComponent as DataElec } from "assets/icons/data-elec.svg";
import SimulationResultBodyMessages from "./SimulationResultBodyMessages";
import { default as LineSimulation } from "components/SimulationDropItem/Line";
import Spinner from "react-bootstrap/Spinner";
import Select from "components/common/select/Select";
import { SpanError } from "components/common/span-error/SpanError";
import { StatusesEnum } from "components/StatusLabel/StatusLabel";
import Button from "components/common/button/Button";
import SteeringAlgorithmsRenderList from "components/SimulationDropItem/SteeringAlgorithmsRenderList";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Text as RechartText,
  ResponsiveContainer,
  TooltipProps,
  BarChart,
} from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import clsx from "clsx";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import DistributionGraph from "components/DistributionGraph/DistributionGraph";
import { ICrops } from "pages/Crops/Crops";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";

interface ISimulationResultBody {
  result: any;
  selectedPeriod: IPeriod;
  image: string;
  onSelect: (option: any) => void;
  onCancelSimulation?: () => void;
}

interface ISelectOption {
  value: string;
  label: string;
}

const irrUnites = ["Fraction", "Daily PAR"];
const ETPUnites = ["Fraction"];
const prodUnites = ["Production"];
const waterDeficit = ["Cumulated"];

const color = ["#A66", "#6A6", "#66A", "#555", "#AA6", "#A6A", "#6AA"];

export type IPeriod = ISelectOption | null;

const SimulationResultBody = ({
  result,
  selectedPeriod,
  image,
  onSelect,
  onCancelSimulation,
}: ISimulationResultBody) => {
  const intl = useIntl();
  const [isCanceling, setIsCanceling] = useState(false);

  const isPrepare = result?.status === StatusesEnum.PREPARE;
  const isRunningMain = result?.status === StatusesEnum.RUNNING_MAIN;
  const isRunningPeriods = result?.status === StatusesEnum.RUNNING_PERIODS;
  const isProgressSimulation = isPrepare || isRunningMain || isRunningPeriods;
  const isSuccessSimulation = result?.status === StatusesEnum.COMPLETED;
  const isFailedSimulation = result?.status === StatusesEnum.FAILED;

  const [isStructureOpen, setIsStructureOpen] = useState(false);
  const [isCropOpen, setIsCropOpen] = useState(false);
  const [isSteeringAlgorithmOpen, setIsSteeringAlgorithmOpen] = useState(false);
  const [isWeatherDatasetOpen, setIsWeatherDatasetOpen] = useState(false);
  const [isProductionDatasetOpen, setIsProductionDatasetOpen] = useState(false);
  const [isETPDatasetOpen, setIsETPDatasetOpen] = useState(false);

  const options = useMemo(() => {
    return result?.result_data.map((item: any) => ({
      value: item.id,
      label: item.period.name,
    }));
  }, [result?.result_data]);

  const [selectedPeriodIrrUnite, setSelectedPeriodIrrUnite] = useState<
    "Fraction" | "Daily PAR"
  >("Fraction");
  const [selectedPeriodETPUnite, setSelectedPeriodETPUnite] =
    useState<"Fraction">("Fraction");
  const [selectedPeriodProdUnite, setSelectedPeriodProdUnite] =
    useState<"Production">("Production");
  const [selectedPeriodWatUnite, setSelectedPeriodWatUnite] =
    useState<"Cumulated">("Cumulated");

  const chartToCsv = (chartData: any, title: string) => () => {
    const generateCSV = (data: any) => {
      let csvContent = "";

      if (data.length === 0) {
        return csvContent;
      }
      const keys = Object.keys(data[0]);
      csvContent += keys.join(",") + "\n";
      data.forEach((item: any) => {
        const row = keys.map((key) => item[key]);
        csvContent += row.join(",") + "\n";
      });
      return csvContent;
    };

    const saveFile = (file: any) => {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = file.name;
      link.click();
    };

    const csvData = generateCSV(chartData);
    const blob = new Blob(["\ufeff", csvData], {
      type: "text/csv;charset=utf-8",
    });
    const url = URL.createObjectURL(blob);

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], `${title}.csv`, {
          type: "text/csv;charset=utf-8",
        });
        URL.revokeObjectURL(url);
        saveFile(file);
      });
  };

  const unitePeriodIrrOptions = useMemo(() => {
    return irrUnites.map((item) => ({
      value: item,
      label: item,
    }));
  }, [irrUnites]);

  const unitePeriodETPOptions = useMemo(() => {
    return ETPUnites.map((item) => ({
      value: item,
      label: item,
    }));
  }, [ETPUnites]);

  const unitePeriodWatOptions = useMemo(() => {
    return waterDeficit.map((item) => ({
      value: item,
      label: item,
    }));
  }, [waterDeficit]);

  const unitePeriodProdOptions = useMemo(() => {
    return prodUnites.map((item) => ({
      value: item,
      label: item,
    }));
  }, [prodUnites]);

  const currentProgress = useMemo(() => {
    const sumPeriodsPath = result?.result_data?.reduce(
      (a: any, { current, length, status }: any) => {
        if (status === StatusesEnum.COMPLETED) {
          a += length;
        } else {
          a += current;
        }
        return a;
      },
      0
    );

    const mainPath =
      isRunningMain || isPrepare ? result.current : result.length;

    return (mainPath + sumPeriodsPath) / (result.total_length / 100) || 0;
  }, [result]);

  const chartToPng = (chartId: string) => () => {
    const chartElement = document.getElementById(chartId);

    html2canvas(chartElement!)
      .then((canvas) => {
        const dataURL = canvas.toDataURL("image/png");

        const downloadLink = document.createElement("a");
        downloadLink.href = dataURL;
        downloadLink.download = `${chartId}.png`;

        downloadLink.click();
      })
      .catch((error) => {
        errorNotifications(`Erreur lors de la création du PNG : '${error}'`);
      });
  };

  const calculatePeriod = (period: any) => {
    if (period.status === "enqueued") return "Waiting...";
    if (period.status === "completed") return "Completed";
    return `${(period.current / (period.length / 100)).toFixed(1)} %`;
  };

  const onCancel = () => {
    if (onCancelSimulation) onCancelSimulation();
    setIsCanceling(true);
  };

  const [inactiveKeysPeriodIrr, setInactiveKeyPeriodIrr] = useState(
    Array<string>()
  );

  type MyType = "etp_zt" | "agri_pv";

  const calculateWaterDeficitSimulation = (
    result_period_data: any,
    type: MyType
  ) => {
    if (!result.simulation.ETP_dataset)
      return "Simulation Doesn't has ETP dataset.";
    else {
      const etp =
        type === "agri_pv"
          ? result_period_data.result_daily_data.reduce(
              (acc_daily: number, daily_data: any) =>
                acc_daily + daily_data.etp_agri_pv,
              0
            )
          : result_period_data.result_daily_data.reduce(
              (acc_daily: number, daily_data: any) => {
                return acc_daily + daily_data.etp_zt;
              },
              0
            );

      const start_period = result_period_data.period.start_date;
      const end_period = result_period_data.period.end_date;

      const precipitation = result.simulation.ETP_dataset.dataset_data.reduce(
        (acc: number, val: any) => {
          if (
            start_period <= val.date &&
            end_period >= val.date &&
            val.month === null
          )
            acc += val.precipitation;
          return acc;
        },
        0
      );
      return Math.round(precipitation - etp);
    }
  };

  const getRowBackgroundColor = (value: number, type: string) => {
    const sortedData = [...tableValues.map((val: any) => val[type])].sort(
      (a, b) => a - b
    );
    const middle = Math.floor(sortedData.length / 2);
    const median =
      sortedData.length % 2 === 0
        ? (sortedData[middle - 1] + sortedData[middle]) / 2
        : sortedData[middle];

    const min = sortedData[0];
    const max = sortedData[sortedData.length - 1];

    var green, red;

    if (value === median) {
      red = green = 255;
    } else if (value <= min) {
      green = 0;
      red = 255;
    } else if (value >= max) {
      green = 255;
      red = 0;
    } else if (value <= median) {
      const intensity = (value - min) / (median - min);
      red = 255;
      green = Math.round(255 * intensity);
    } else {
      const intensity = (value - median) / (max - median);
      red = Math.round(255 * (1 - intensity));
      green = 255;
    }

    if (
      type == "HETEROGENEITY" ||
      type === "TAUX_DE_COUVERTURE" ||
      type === "ETP_FRACTION"
    )
      return `rgb(${green}, ${red}, 0)`;
    else return `rgb(${red}, ${green}, 0)`;
  };

  const tableValues = useMemo(() => {
    if (result && selectedPeriodIrrUnite) {
      return result.result_data.map((item: any) => ({
        IRR_FRACTION:
          Math.round(((item.area_agrilpv * 100) / item.power_ref) * 10) / 10,
        DAILY_PAR: Math.round(
          ((1 / 2.1) * item.area_agrilpv) /
            ((new Date(item.period.end_date).getTime() -
              new Date(item.period.start_date).getTime()) /
              (1000 * 3600 * 24))
        ),
        HETEROGENEITY:
          Math.round(
            ((item.heterogeneity *
              (new Date(item.period.end_date).getTime() -
                new Date(item.period.start_date).getTime())) /
              (1000 * 3600 * 24) /
              ((new Date(item.period.end_date).getTime() -
                new Date(item.period.start_date).getTime()) /
                (1000 * 3600 * 24))) *
              10
          ) / 10,
        ETP_FRACTION:
          Math.round(
            ((((item.etp_agripv *
              ((new Date(item.period.end_date).getTime() -
                new Date(item.period.start_date).getTime()) /
                (1000 * 3600 * 24))) /
              ((new Date(item.period.end_date).getTime() -
                new Date(item.period.start_date).getTime()) /
                (1000 * 3600 * 24))) *
              100) /
              ((item.etp_ref *
                ((new Date(item.period.end_date).getTime() -
                  new Date(item.period.start_date).getTime()) /
                  (1000 * 3600 * 24))) /
                ((new Date(item.period.end_date).getTime() -
                  new Date(item.period.start_date).getTime()) /
                  (1000 * 3600 * 24)))) *
              10
          ) / 10,
        WATER_DEFICIT: calculateWaterDeficitSimulation(item, "agri_pv"),
      }));
    }
    return null;
  }, [result, selectedPeriodIrrUnite]);

  // const calculateMeanOfTableValues = (type: string) => {
  //   let totalValue = 0;
  //   let totalDays = 0;
  //   for (let index = 0; index < result.result_data.length; index++) {
  //     const period = result.result_data[index].period;
  //     const period_length =
  //       (new Date(period.end_date).getTime() -
  //         new Date(period.start_date).getTime()) /
  //       (1000 * 3600 * 24);

  //     totalValue += period_length * tableValues[index][type];
  //     totalDays += period_length;
  //   }

  //   if (type == 'IRR_FRACTION' || type == 'ETP_FRACTION')
  //     return Math.round((totalValue / totalDays) * 10) / 10;
  //   else
  //     return Math.round(totalValue / totalDays);

  // };

  const waterDeficitGraph = useMemo(() => {
    if (result && selectedPeriodIrrUnite) {
      return result.result_data.map((item: any) => ({
        name: item.period.name,
        WATER_DEFICIT: calculateWaterDeficitSimulation(item, "agri_pv"),
        witness_zone: calculateWaterDeficitSimulation(item, "etp_zt"),
      }));
    }
    return null;
  }, [result, selectedPeriodIrrUnite]);

  const period_irr_points = useMemo(() => {
    if (result && selectedPeriodIrrUnite) {
      return result.result_data.map((item: any) => ({
        name: item.period.name,
        witness_zone:
          Math.round(
            (selectedPeriodIrrUnite == "Daily PAR"
              ? Math.round(
                  ((1 / 2.1) * item.power_ref) /
                    ((new Date(item.period.end_date).getTime() -
                      new Date(item.period.start_date).getTime()) /
                      (1000 * 3600 * 24))
                )
              : (item.power_ref * 100) / item.power_ref) * 100
          ) / 100,
        under_panel:
          Math.round(
            (selectedPeriodIrrUnite == "Daily PAR"
              ? ((1 / 2.1) * item.area_under_panels) /
                ((new Date(item.period.end_date).getTime() -
                  new Date(item.period.start_date).getTime()) /
                  (1000 * 3600 * 24))
              : (item.area_under_panels * 100) / item.power_ref) * 100
          ) / 100,
        between_panel: Math.round(
          selectedPeriodIrrUnite == "Daily PAR"
            ? ((1 / 2.1) * item.area_between_panels) /
                ((new Date(item.period.end_date).getTime() -
                  new Date(item.period.start_date).getTime()) /
                  (1000 * 3600 * 24))
            : (item.area_between_panels * 100) / item.power_ref
        ) /* 100
          ) / 100*/,
        agriPV:
          Math.round(
            (selectedPeriodIrrUnite == "Daily PAR"
              ? ((1 / 2.1) * item.area_agrilpv) /
                ((new Date(item.period.end_date).getTime() -
                  new Date(item.period.start_date).getTime()) /
                  (1000 * 3600 * 24))
              : (item.area_agrilpv * 100) / item.power_ref) * 100
          ) / 100,
        heterogeneity: item.heterogeneity,
      }));
    }
    return null;
  }, [result, selectedPeriodIrrUnite]);

  const [inactiveKeysPeriodETP, setInactiveKeyPeriodETP] = useState(
    Array<string>()
  );

  const period_ETP_points = useMemo(() => {
    if (result && selectedPeriodETPUnite) {
      return result.result_data.map((item: any) => ({
        name: item.period.name,
        etp_witness_zone:
          Math.round(((item.etp_ref * 100) / item.etp_ref) * 100) / 100,
        etp_under_panel:
          Math.round(((item.etp_under_panels * 100) / item.etp_ref) * 100) /
          100,
        etp_between_panel:
          Math.round(((item.etp_between_panels * 100) / item.etp_ref) * 100) /
          100,
        etp_agriPV:
          Math.round(((item.etp_agripv * 100) / item.etp_ref) * 100) / 100,
      }));
    }
    return null;
  }, [result, selectedPeriodETPUnite]);

  const [inactiveKeysPeriodProd, setInactiveKeyPeriodProd] = useState(
    Array<string>()
  );

  const period_prod_points = useMemo(() => {
    if (result && selectedPeriodProdUnite) {
      return result.result_data.map((item: any) => ({
        name: item.period.name,
        production_max:
          Math.round(
            item.result_daily_data
              .map((o: any) => o.production_max)
              .reduce((a: any, b: any) => a + b, 0) * 100
          ) / 100,
        production:
          Math.round(
            item.result_daily_data
              .map((o: any) => o.production)
              .reduce((a: any, b: any) => a + b, 0) * 100
          ) / 100,
      }));
    }
    return null;
  }, [result, selectedPeriodProdUnite]);

  const CustomizedTick = (props: any) => {
    const { x, y, stroke, payload, offset } = props;
    return (
      <g transform={`translate(${x},${y - 10})`}>
        <RechartText
          x={0}
          y={0}
          dy={16}
          angle={-65}
          width={100}
          fontSize={8}
          textAnchor="end"
          verticalAnchor="middle"
        >
          {payload.value}
        </RechartText>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className={styled.customTooltip}>
          <p className={styled.label}>{label}</p>
          <div>
            {payload.map((pld: any) => (
              <div className={styled.flex} id={pld.name}>
                <div>{pld.name} :</div>
                <div style={{ color: pld.stroke ? pld.stroke : pld.fill }}>
                  {pld.value} {pld.unit}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    return null;
  };

  // const tableDatasThermic = () => {
  //   const tablesDatas = data.map((period) => ({
  //     period_name: period.period_name,
  //     control_zone_twenty_five_degres: period.control_zone_twenty_five_degres,
  //     control_zone_zero_degres: period.control_zone_zero_degres,
  //     agripv_twenty_five_degres: period.agripv_twenty_five_degres,
  //     agripv_zero_degres: period.agripv_zero_degres,
  //     period_days : Math.round((period.period_end_date.getTime() - period.period_start_date.getTime())  / 60000 / 60 / 24)
  //   }))

  //   const totalThermicDatas = tablesDatas.reduce((acc, period_datas) => {
  //     acc.agripv_zero_degres += period_datas.agripv_zero_degres
  //     acc.agripv_twenty_five_degres += period_datas.agripv_twenty_five_degres
  //     acc.control_zone_zero_degres += period_datas.control_zone_zero_degres
  //     acc.control_zone_twenty_five_degres += period_datas.control_zone_twenty_five_degres,
  //     acc.period_days += period_datas.period_days
  //     return acc;
  //   }, {period_name: "Total", agripv_zero_degres: 0, agripv_twenty_five_degres: 0, control_zone_zero_degres: 0, control_zone_twenty_five_degres: 0, period_days: 0})

  //   return [...tablesDatas, totalThermicDatas]
  // }

  return (
    <>
      <div style={{ display: "flex" }}>
        <div className={styled.column}>
          {/*STRUCTURE*/}
          <div
            className={styled.essenceContainer}
            onClick={() => {
              setIsStructureOpen(!isStructureOpen);
            }}
          >
            <div className={styled.essenceRow}>
              <div className={styled.essenceLabel}>
                <button
                  className={clsx(styled.toggle, {
                    [styled.toggleOpen]: isStructureOpen,
                  })}
                  type="button"
                >
                  <Arrow />
                </button>
                <Structures />
                <span>
                  <FormattedMessage {...commonMessages.structures} />
                </span>
              </div>
              <span className={styled.essenceName}>
                {result.simulation.structure.name}
              </span>
            </div>
            <div
              className={clsx(styled.essenceContent, {
                [styled.essenceContentShow]: isStructureOpen,
              })}
            >
              <StructuresRenderList selected={result.simulation.structure} />
            </div>
          </div>

          {/*CROPS*/}
          <div
            className={styled.essenceContainer}
            onClick={() => {
              setIsCropOpen(!isCropOpen);
            }}
          >
            <div className={styled.essenceRow}>
              <div className={styled.essenceLabel}>
                <button
                  className={clsx(styled.toggle, {
                    [styled.toggleOpen]: isCropOpen,
                  })}
                  type="button"
                >
                  <Arrow />
                </button>
                <Crops />
                <span>
                  <FormattedMessage {...commonMessages.crops} />
                </span>
              </div>
              <span className={styled.essenceName}>
                {result.simulation.crop.name}
              </span>
            </div>
            <div
              className={clsx(styled.essenceContent, {
                [styled.essenceContentShow]: isCropOpen,
              })}
            >
              <CropsRenderList selected={result.simulation.crop} />
            </div>
          </div>

          {/*STEERING ALGORITHM*/}
          {result.simulation.steering_algorithm && (
            <div
              className={styled.essenceContainer}
              onClick={() => {
                setIsSteeringAlgorithmOpen(!isSteeringAlgorithmOpen);
              }}
            >
              <div className={styled.essenceRow}>
                <div className={styled.essenceLabel}>
                  <button
                    className={clsx(styled.toggle, {
                      [styled.toggleOpen]: isSteeringAlgorithmOpen,
                    })}
                    type="button"
                  >
                    <Arrow />
                  </button>
                  <DataTemp />
                  <span>
                    <FormattedMessage
                      {...simulationDropItemMessages.steeringAlgorithms}
                    />
                  </span>
                </div>
                <span className={styled.essenceName}>
                  {result.simulation.steering_algorithm.name}
                </span>
              </div>
              <div
                className={clsx(styled.essenceContent, {
                  [styled.essenceContentShow]: isSteeringAlgorithmOpen,
                })}
              >
                <SteeringAlgorithmsRenderList
                  selected={result.simulation.steering_algorithm}
                />
              </div>
            </div>
          )}

          {/*UNIVERSAL DATASET*/}
          {result.simulation.weather_dataset.dataset_type === "universal" ? (
            <div
              className={styled.essenceContainer}
              onClick={() => {
                setIsWeatherDatasetOpen(!isWeatherDatasetOpen);
              }}
            >
              <div className={styled.essenceRow}>
                <div className={styled.essenceLabel}>
                  <button
                    className={clsx(styled.toggle, {
                      [styled.toggleOpen]: isWeatherDatasetOpen,
                    })}
                    type="button"
                  >
                    <Arrow />
                  </button>
                  <DataTemp />
                  <span>
                    <FormattedMessage
                      {...simulationDropItemMessages.universalDatasets}
                    />
                  </span>
                </div>
                <span className={styled.essenceName}>
                  {result.simulation.weather_dataset.name}
                </span>
              </div>
              <div
                className={clsx(styled.essenceContent, {
                  [styled.essenceContentShow]: isWeatherDatasetOpen,
                })}
              >
                <DatasetRenderList
                  selected={result.simulation.weather_dataset}
                />
              </div>
            </div>
          ) : (
            <div className={styled.oldDataset}>
              {/*WEATHER DATASET*/}
              <div
                className={styled.essenceContainer}
                onClick={() => {
                  setIsWeatherDatasetOpen(!isWeatherDatasetOpen);
                }}
              >
                <div className={styled.essenceRow}>
                  <div className={styled.essenceLabel}>
                    <button
                      className={clsx(styled.toggle, {
                        [styled.toggleOpen]: isWeatherDatasetOpen,
                      })}
                      type="button"
                    >
                      <Arrow />
                    </button>
                    <DataTemp />
                    <span>
                      <FormattedMessage
                        {...simulationDropItemMessages.weatherDatasets}
                      />
                    </span>
                  </div>
                  <span className={styled.essenceName}>
                    {result.simulation.weather_dataset.name}
                  </span>
                </div>
                <div
                  className={clsx(styled.essenceContent, {
                    [styled.essenceContentShow]: isWeatherDatasetOpen,
                  })}
                >
                  <DatasetRenderList
                    selected={result.simulation.weather_dataset}
                  />
                </div>
              </div>

              {/*ETP DATASET*/}
              {result.simulation.ETP_dataset && (
                <div
                  className={styled.essenceContainer}
                  onClick={() => {
                    setIsETPDatasetOpen(!isETPDatasetOpen);
                  }}
                >
                  <div className={styled.essenceRow}>
                    <div className={styled.essenceLabel}>
                      <button
                        className={clsx(styled.toggle, {
                          [styled.toggleOpen]: isETPDatasetOpen,
                        })}
                        type="button"
                      >
                        <Arrow />
                      </button>
                      <DataTemp />
                      <span>
                        <FormattedMessage
                          {...simulationDropItemMessages.etpDatasets}
                        />
                      </span>
                    </div>
                    <span className={styled.essenceName}>
                      {result.simulation.ETP_dataset.name}
                    </span>
                  </div>
                  <div
                    className={clsx(styled.essenceContent, {
                      [styled.essenceContentShow]: isETPDatasetOpen,
                    })}
                  >
                    <DatasetRenderList
                      selected={result.simulation.ETP_dataset}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>

        <div className={styled.simuEssenceContainer}>
          {isSuccessSimulation ? (
            <div className={styled.simulationResultInfoContainer}>
              <div className={styled.simulationResultInfo}>
                <h3 className={styled.statusTitle}>
                  {isProgressSimulation ? (
                    <FormattedMessage
                      {...SimulationResultBodyMessages.simulationInProgress}
                    />
                  ) : (
                    <FormattedMessage
                      {...SimulationResultBodyMessages.simulationResults}
                    />
                  )}
                </h3>
                <p className={styled.description}>
                  {result.simulation.description}
                </p>
                <LineSimulation
                  title={intl.formatMessage(
                    SimulationResultBodyMessages.simulationFrequency
                  )}
                  value={result.simulation.frequency}
                />
                <LineSimulation
                  title={intl.formatMessage(
                    SimulationResultBodyMessages.resolution
                  )}
                  value={result.simulation.resolution}
                />
                {isSuccessSimulation && (
                  <Select
                    label={intl.formatMessage(
                      SimulationResultBodyMessages.periodSimulation
                    )}
                    options={options}
                    value={selectedPeriod}
                    onChange={onSelect}
                  />
                )}
              </div>
              <div className={styled.waitContainer}>
                <img
                  className={styled.resultImage}
                  src={image}
                  alt="Simulation result image"
                />
              </div>
            </div>
          ) : null}

          {isProgressSimulation && (
            <>
              <div className={styled.progressBarContainer}>
                <div className={styled.waitItem}>
                  <Spinner animation="border" className={styled.spinner} />
                  <p className={styled.inProgress}>
                    <FormattedMessage
                      {...SimulationResultBodyMessages.inProgress}
                      values={{
                        value: isRunningPeriods ? 2 : 1,
                      }}
                    />
                  </p>

                  {!isNaN(currentProgress) && (
                    <>
                      <ProgressBar
                        striped
                        now={currentProgress}
                        className={styled.progressBar}
                      />
                      <h5 className={styled.progressBarLabel}>
                        {currentProgress.toFixed(0)} %
                      </h5>

                      <ul className={styled.list}>
                        {isRunningPeriods ? (
                          result.result_data.map(
                            (periodResult: any, index: number) => (
                              <LineSimulation
                                key={index}
                                title={periodResult.period.name}
                                value={calculatePeriod(periodResult)}
                              />
                            )
                          )
                        ) : (
                          <LineSimulation
                            title="Main"
                            value={`${(
                              result.current /
                              (result.length / 100)
                            ).toFixed(1)} %`}
                          />
                        )}
                      </ul>
                    </>
                  )}
                </div>
              </div>

              {!!onCancelSimulation && (
                <Button
                  variant="outlined"
                  className={styled.cancelBtn}
                  onClick={onCancel}
                  disabled={isCanceling}
                  isLoading={isCanceling}
                >
                  <FormattedMessage {...commonMessages.cancel} />
                </Button>
              )}
            </>
          )}

          {isFailedSimulation && (
            <SpanError errorMessage={result?.exception ?? ""} />
          )}
        </div>
      </div>
      {isSuccessSimulation ? (
        <div className={styled.simuFlex}>
          {period_irr_points && period_ETP_points && period_prod_points && (
            <div className={styled.allPeriodsChartContainer}>
              <div className={styled.chartBlock}>
                <div className={styled.chartContainer}>
                  <div className={styled.optionContainer}>
                    <div className={styled.uniteSelector}>
                      <Select
                        options={unitePeriodIrrOptions}
                        value={unitePeriodIrrOptions.find(
                          (item) => item.value == selectedPeriodIrrUnite
                        )}
                        onChange={(e: any) => {
                          setSelectedPeriodIrrUnite(e.value);
                        }}
                        isSearchable={false}
                        menuHeight={400}
                      />
                    </div>
                    <TooltipMUI
                      title={intl.formatMessage(
                        SimulationResultBodyMessages.hintLegend
                      )}
                    >
                      <div className={styled.chartTitle}>
                        {intl.formatMessage(
                          SimulationResultBodyMessages.irrPerPeriod
                        )}
                      </div>
                    </TooltipMUI>
                    <div className={styled.downloadContainer}>
                      <TooltipMUI
                        title={intl.formatMessage(
                          SimulationResultBodyMessages.hintChartToPng
                        )}
                      >
                        <div
                          className={styled.chartToPngButton}
                          onClick={chartToPng(
                            intl.formatMessage(
                              SimulationResultBodyMessages.irrPerPeriod
                            )
                          )}
                        >
                          <Icon
                            name={ICON_NAMES_ENUM.download_image}
                            className={styled.file__icon}
                          />
                        </div>
                      </TooltipMUI>
                      <TooltipMUI
                        title={intl.formatMessage(
                          SimulationResultBodyMessages.hintChartToCsv
                        )}
                      >
                        <div
                          className={styled.chartToPngButton}
                          onClick={chartToCsv(
                            period_irr_points,
                            `${result.simulation.name} Monthly Irradiance of total crop ${result.simulation.crop.name} (${selectedPeriodIrrUnite})`
                          )}
                        >
                          <Icon
                            name={ICON_NAMES_ENUM.download_file}
                            className={styled.file__icon}
                          />
                        </div>
                      </TooltipMUI>
                    </div>
                  </div>

                  <div
                    id={intl.formatMessage(
                      SimulationResultBodyMessages.irrPerPeriod
                    )}
                  >
                    <ResponsiveContainer width="100%" height={300}>
                      <ComposedChart
                        data={period_irr_points}
                        width={500}
                        height={700}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey={"name"}
                          tick={<CustomizedTick />}
                          height={75}
                          interval={0}
                        />
                        <YAxis
                          domain={[0, 100]}
                          interval={0}
                          yAxisId={"left"}
                          orientation="left"
                        >
                          <Label
                            style={{ textAnchor: "middle" }}
                            position={"left"}
                            angle={270}
                            fontSize={12}
                            value={intl.formatMessage(
                              selectedPeriodIrrUnite == "Fraction"
                                ? SimulationResultBodyMessages.irradianceFraction
                                : SimulationResultBodyMessages.irrandianceDailyPAR
                            )}
                          />
                        </YAxis>
                        <YAxis yAxisId="right" orientation="right" interval={0}>
                          <Label
                            style={{ textAnchor: "middle" }}
                            position={"insideRight"}
                            angle={270}
                            fontSize={12}
                            value={intl.formatMessage(
                              SimulationResultBodyMessages.heterogeneityTh
                            )}
                          />
                        </YAxis>
                        <Tooltip content={CustomTooltip} />
                        <Legend
                          onClick={(o) => {
                            if (
                              !inactiveKeysPeriodIrr.find(
                                (inactiveKey) => inactiveKey == o.dataKey
                              )
                            )
                              setInactiveKeyPeriodIrr([
                                ...inactiveKeysPeriodIrr,
                                o.dataKey,
                              ]);
                            else {
                              var temp = inactiveKeysPeriodIrr;
                              temp.splice(
                                inactiveKeysPeriodIrr.findIndex(
                                  (inactiveKey) => inactiveKey == o.dataKey
                                ),
                                1
                              );
                              setInactiveKeyPeriodIrr([...temp]);
                            }
                          }}
                        />
                        <Bar
                          type="monotone"
                          dataKey={"witness_zone"}
                          name="Control Zone"
                          yAxisId="left"
                          fill={"#AA6666"}
                          hide={
                            inactiveKeysPeriodIrr.find(
                              (inactiveKey) => inactiveKey == "witness_zone"
                            )
                              ? true
                              : false
                          }
                          unit={
                            selectedPeriodIrrUnite == "Fraction" ? "%" : "Wh/m²"
                          }
                        />
                        <Bar
                          type="monotone"
                          dataKey={"under_panel"}
                          name="Under Panels"
                          yAxisId="left"
                          fill={"#66AA66"}
                          hide={
                            inactiveKeysPeriodIrr.find(
                              (inactiveKey) => inactiveKey == "under_panel"
                            )
                              ? true
                              : false
                          }
                          unit={
                            selectedPeriodIrrUnite == "Fraction" ? "%" : "Wh/m²"
                          }
                        />
                        <Bar
                          type="monotone"
                          dataKey={"between_panel"}
                          name="Between Panels"
                          yAxisId="left"
                          fill={"#6666AA"}
                          hide={
                            inactiveKeysPeriodIrr.find(
                              (inactiveKey) => inactiveKey == "between_panel"
                            )
                              ? true
                              : false
                          }
                          unit={
                            selectedPeriodIrrUnite == "Fraction" ? "%" : "Wh/m²"
                          }
                        />
                        <Bar
                          type="monotone"
                          dataKey={"agriPV"}
                          name="AgriPV Zone"
                          yAxisId="left"
                          fill={"#444444"}
                          hide={
                            inactiveKeysPeriodIrr.find(
                              (inactiveKey) => inactiveKey == "agriPV"
                            )
                              ? true
                              : false
                          }
                          unit={
                            selectedPeriodIrrUnite == "Fraction" ? "%" : "Wh/m²"
                          }
                        />
                        <Line
                          type="linear"
                          dataKey={"heterogeneity"}
                          yAxisId="right"
                          hide={
                            inactiveKeysPeriodIrr.find(
                              (inactiveKey) => inactiveKey == "heterogeneity"
                            )
                              ? true
                              : false
                          }
                          dot={({ cx, cy }) => {
                            return (
                              <circle
                                key={cx.toString()}
                                cx={cx}
                                cy={cy}
                                r={6}
                                fill="#66AAAA"
                              />
                            );
                          }}
                          unit={"%"}
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div className={styled.verticalLimiter} />
                <div className={styled.chartContainer}>
                  <div className={styled.optionContainer}>
                    <div className={styled.uniteSelector}></div>
                    <TooltipMUI
                      title={intl.formatMessage(
                        SimulationResultBodyMessages.hintLegend
                      )}
                    >
                      <div className={styled.chartTitle}>
                        {intl.formatMessage(
                          SimulationResultBodyMessages.watPerPeriod
                        )}
                      </div>
                    </TooltipMUI>
                    <div className={styled.downloadContainer}>
                      <TooltipMUI
                        title={intl.formatMessage(
                          SimulationResultBodyMessages.hintChartToPng
                        )}
                      >
                        <div
                          className={styled.chartToPngButton}
                          onClick={chartToPng(
                            intl.formatMessage(
                              SimulationResultBodyMessages.watPerPeriod
                            )
                          )}
                        >
                          <Icon
                            name={ICON_NAMES_ENUM.download_image}
                            className={styled.file__icon}
                          />
                        </div>
                      </TooltipMUI>
                      <TooltipMUI
                        title={intl.formatMessage(
                          SimulationResultBodyMessages.hintChartToCsv
                        )}
                      >
                        <div
                          className={styled.chartToPngButton}
                          onClick={chartToCsv(
                            waterDeficitGraph,
                            `${result.simulation.name} Daily Water deficit of total crop ${result.simulation.crop.name} (${selectedPeriodWatUnite})`
                          )}
                        >
                          <Icon
                            name={ICON_NAMES_ENUM.download_file}
                            className={styled.file__icon}
                          />
                        </div>
                      </TooltipMUI>
                    </div>
                  </div>
                  <div
                    id={intl.formatMessage(
                      SimulationResultBodyMessages.watPerPeriod
                    )}
                  >
                    <ResponsiveContainer width="100%" height={300}>
                      <ComposedChart
                        width={500}
                        height={500}
                        data={waterDeficitGraph}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey={"name"}
                          tick={<CustomizedTick />}
                          height={75}
                          interval={0}
                        />
                        <YAxis interval={0} yAxisId={"left"} orientation="left">
                          <Label
                            fontSize={12}
                            style={{ textAnchor: "middle" }}
                            position={"insideLeft"}
                            angle={270}
                            value={intl.formatMessage(
                              SimulationResultBodyMessages.waterDeficit
                            )}
                          />
                        </YAxis>
                        <Tooltip content={CustomTooltip} />
                        <Legend />
                        <Bar
                          dataKey={"witness_zone"}
                          type="monotone"
                          fill={"#AA6666"}
                          name={intl.formatMessage(
                            SimulationResultBodyMessages.refZoneWaterDeficit
                          )}
                          yAxisId="left"
                          unit={"mm"}
                        />
                        <Bar
                          dataKey={"WATER_DEFICIT"}
                          type="monotone"
                          fill={"#444444"}
                          name={intl.formatMessage(
                            SimulationResultBodyMessages.AgriPvWaterDeficit
                          )}
                          yAxisId="left"
                          unit={"mm"}
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>

              <div className={styled.limiter} />

              <div className={styled.chartBlock}>
                <div className={styled.chartContainer}>
                  <div className={styled.optionContainer}>
                    <div className={styled.uniteSelector}></div>
                    <TooltipMUI
                      title={intl.formatMessage(
                        SimulationResultBodyMessages.hintLegend
                      )}
                    >
                      <div className={styled.chartTitle}>
                        {intl.formatMessage(
                          SimulationResultBodyMessages.ETPPerPeriod
                        )}
                      </div>
                    </TooltipMUI>
                    <div className={styled.downloadContainer}>
                      <TooltipMUI
                        title={intl.formatMessage(
                          SimulationResultBodyMessages.hintChartToPng
                        )}
                      >
                        <div
                          className={styled.chartToPngButton}
                          onClick={chartToPng(
                            intl.formatMessage(
                              SimulationResultBodyMessages.ETPPerPeriod
                            )
                          )}
                        >
                          <Icon
                            name={ICON_NAMES_ENUM.download_image}
                            className={styled.file__icon}
                          />
                        </div>
                      </TooltipMUI>
                      <TooltipMUI
                        title={intl.formatMessage(
                          SimulationResultBodyMessages.hintChartToCsv
                        )}
                      >
                        <div
                          className={styled.chartToPngButton}
                          onClick={chartToCsv(
                            period_ETP_points,
                            `${result.simulation.name} Daily ETP of total crop ${result.simulation.crop.name} (${selectedPeriodETPUnite})`
                          )}
                        >
                          <Icon
                            name={ICON_NAMES_ENUM.download_file}
                            className={styled.file__icon}
                          />
                        </div>
                      </TooltipMUI>
                    </div>
                  </div>

                  <div
                    id={intl.formatMessage(
                      SimulationResultBodyMessages.ETPPerPeriod
                    )}
                  >
                    <ResponsiveContainer width="100%" height={300}>
                      <ComposedChart
                        data={period_ETP_points}
                        width={500}
                        height={500}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey={"name"}
                          tick={<CustomizedTick />}
                          height={75}
                          interval={0}
                        />
                        <YAxis yAxisId="left" interval={0} orientation="left">
                          <Label
                            style={{ textAnchor: "middle" }}
                            position={"insideLeft"}
                            angle={270}
                            fontSize={12}
                            value={intl.formatMessage(
                              SimulationResultBodyMessages.ETPAgriPVZone
                            )}
                          />
                        </YAxis>
                        <Tooltip content={CustomTooltip} />
                        <Legend
                          onClick={(o) => {
                            if (
                              !inactiveKeysPeriodETP.find(
                                (inactiveKey) => inactiveKey == o.dataKey
                              )
                            )
                              setInactiveKeyPeriodETP([
                                ...inactiveKeysPeriodETP,
                                o.dataKey,
                              ]);
                            else {
                              var temp = inactiveKeysPeriodETP;
                              temp.splice(
                                inactiveKeysPeriodETP.findIndex(
                                  (inactiveKey) => inactiveKey == o.dataKey
                                ),
                                1
                              );
                              setInactiveKeyPeriodETP([...temp]);
                            }
                          }}
                        />
                        <Bar
                          type="monotone"
                          dataKey={"etp_witness_zone"}
                          name="Control Zone"
                          yAxisId="left"
                          fill={"#AA6666"}
                          hide={
                            inactiveKeysPeriodETP.find(
                              (inactiveKey) => inactiveKey == "etp_witness_zone"
                            )
                              ? true
                              : false
                          }
                          unit={"%"}
                        />
                        <Bar
                          type="monotone"
                          dataKey={"etp_under_panel"}
                          name="Under Panels"
                          yAxisId="left"
                          fill={"#66AA66"}
                          hide={
                            inactiveKeysPeriodETP.find(
                              (inactiveKey) => inactiveKey == "etp_under_panel"
                            )
                              ? true
                              : false
                          }
                          unit={"%"}
                        />
                        <Bar
                          type="monotone"
                          dataKey={"etp_between_panel"}
                          name="Between Panels"
                          yAxisId="left"
                          fill={"#6666AA"}
                          hide={
                            inactiveKeysPeriodETP.find(
                              (inactiveKey) =>
                                inactiveKey == "etp_between_panel"
                            )
                              ? true
                              : false
                          }
                          unit={"%"}
                        />
                        <Bar
                          type="monotone"
                          dataKey={"etp_agriPV"}
                          name="AgriPV Zone"
                          yAxisId="left"
                          fill={"#444444"}
                          hide={
                            inactiveKeysPeriodETP.find(
                              (inactiveKey) => inactiveKey == "etp_agriPV"
                            )
                              ? true
                              : false
                          }
                          unit={"%"}
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                </div>
                <div className={styled.verticalLimiter} />
                <div className={styled.chartContainer}>
                  <div className={styled.optionContainer}>
                    <div className={styled.uniteSelector}></div>
                    <TooltipMUI
                      title={intl.formatMessage(
                        SimulationResultBodyMessages.hintLegend
                      )}
                    >
                      <div className={styled.chartTitle}>
                        {intl.formatMessage(
                          SimulationResultBodyMessages.ProdPerPeriod
                        )}
                      </div>
                    </TooltipMUI>
                    <div className={styled.downloadContainer}>
                      <TooltipMUI
                        title={intl.formatMessage(
                          SimulationResultBodyMessages.hintChartToPng
                        )}
                      >
                        <div
                          className={styled.chartToPngButton}
                          onClick={chartToPng(
                            intl.formatMessage(
                              SimulationResultBodyMessages.ProdPerPeriod
                            )
                          )}
                        >
                          <Icon
                            name={ICON_NAMES_ENUM.download_image}
                            className={styled.file__icon}
                          />
                        </div>
                      </TooltipMUI>
                      <TooltipMUI
                        title={intl.formatMessage(
                          SimulationResultBodyMessages.hintChartToCsv
                        )}
                      >
                        <div
                          className={styled.chartToPngButton}
                          onClick={chartToCsv(
                            period_prod_points,
                            `${result.simulation.name} Daily Prod of total crop ${result.simulation.crop.name} (${selectedPeriodProdUnite})`
                          )}
                        >
                          <Icon
                            name={ICON_NAMES_ENUM.download_file}
                            className={styled.file__icon}
                          />
                        </div>
                      </TooltipMUI>
                    </div>
                  </div>

                  <div
                    id={intl.formatMessage(
                      SimulationResultBodyMessages.ProdPerPeriod
                    )}
                  >
                    <ResponsiveContainer width="100%" height={300}>
                      <ComposedChart
                        data={period_prod_points}
                        width={500}
                        height={500}
                        margin={{
                          top: 10,
                          right: 30,
                          left: 20,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey={"name"}
                          tick={<CustomizedTick />}
                          height={75}
                          interval={0}
                        />
                        <YAxis yAxisId="left" interval={0} orientation="left">
                          <Label
                            fontSize={12}
                            style={{ textAnchor: "middle" }}
                            position={"insideLeft"}
                            angle={270}
                            value={intl.formatMessage(
                              SimulationResultBodyMessages.prodAgriPV
                            )}
                          />
                        </YAxis>
                        <Tooltip content={CustomTooltip} />
                        <Legend
                          onClick={(o) => {
                            if (
                              !inactiveKeysPeriodProd.find(
                                (inactiveKey) => inactiveKey == o.dataKey
                              )
                            )
                              setInactiveKeyPeriodProd([
                                ...inactiveKeysPeriodProd,
                                o.dataKey,
                              ]);
                            else {
                              var temp = inactiveKeysPeriodProd;
                              temp.splice(
                                inactiveKeysPeriodProd.findIndex(
                                  (inactiveKey) => inactiveKey == o.dataKey
                                ),
                                1
                              );
                              setInactiveKeyPeriodProd([...temp]);
                            }
                          }}
                        />
                        <Bar
                          type="monotone"
                          dataKey={"production_max"}
                          name="Production sun tracking"
                          yAxisId="left"
                          fill={"#AA6666"}
                          hide={
                            inactiveKeysPeriodProd.find(
                              (inactiveKey) => inactiveKey == "production_max"
                            )
                              ? true
                              : false
                          }
                          unit={"kWh/kWc"}
                        />
                        <Bar
                          type="monotone"
                          dataKey={"production"}
                          name="Production agriPV"
                          yAxisId="left"
                          fill={"#66AA66"}
                          hide={
                            inactiveKeysPeriodProd.find(
                              (inactiveKey) => inactiveKey == "production"
                            )
                              ? true
                              : false
                          }
                          unit={"kWh/kWc"}
                        />
                      </ComposedChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className={styled.downloadContainer}>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintTableToPng
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToPng("table simulation")}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_image}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintTableToCsv
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={() => {
                  const values = tableValues.map((data: any, index: number) => {
                    const val: any = {};

                    val[
                      intl.formatMessage(
                        SimulationResultBodyMessages.periodSimulation
                      )
                    ] = result.simulation.crop.periods[index].name;
                    val[
                      intl.formatMessage(
                        SimulationResultBodyMessages.irradianceFraction
                      ) +
                      " " +
                      intl.formatMessage(
                        SimulationResultBodyMessages.irradianceFractionSubTitle
                      )
                    ] = data.IRR_FRACTION;
                    val[
                      intl.formatMessage(
                        SimulationResultBodyMessages.meanDailyPAR
                      ) +
                      " " +
                      intl.formatMessage(
                        SimulationResultBodyMessages.meanDailyPARSubTitle
                      )
                    ] = data.DAILY_PAR;
                    val[
                      intl.formatMessage(
                        SimulationResultBodyMessages.heterogeneityTh
                      )
                    ] = data.HETEROGENEITY;
                    val[
                      intl.formatMessage(
                        SimulationResultBodyMessages.ETPAgriPVZone
                      ) +
                      " " +
                      intl.formatMessage(
                        SimulationResultBodyMessages.ETPAgriPVZoneSubTitle
                      )
                    ] = data.ETP_FRACTION;
                    val[
                      intl.formatMessage(
                        SimulationResultBodyMessages.waterDeficit
                      ) +
                      " " +
                      intl.formatMessage(
                        SimulationResultBodyMessages.waterDeficitSubTitle
                      )
                    ] = data.WATER_DEFICIT;

                    return val;
                  });

                  chartToCsv(
                    [...values],
                    `${result.simulation.name} table data`
                  )();
                }}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_file}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
          </div>
          <div className={styled.tableContainer}>
            <table className={styled.dataTable} id="table simulation">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage
                      {...SimulationResultBodyMessages.periodsSimulation}
                    />
                  </th>
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        SimulationResultBodyMessages.irradianceFractionHelp
                      )}
                    >
                      <div>
                        <div className={styled.flex}>
                          <FormattedMessage
                            {...SimulationResultBodyMessages.irradianceFraction}
                          />
                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </div>
                        <div className={styled.subTitle}>
                          <FormattedMessage
                            {...SimulationResultBodyMessages.irradianceFractionSubTitle}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        SimulationResultBodyMessages.meanDailyPARHelp
                      )}
                    >
                      <div>
                        <div className={styled.flex}>
                          <FormattedMessage
                            {...SimulationResultBodyMessages.meanDailyPAR}
                          />
                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </div>
                        <div className={styled.subTitle}>
                          <FormattedMessage
                            {...SimulationResultBodyMessages.meanDailyPARSubTitle}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        SimulationResultBodyMessages.heterogeneityHelp
                      )}
                    >
                      <div className={styled.flex}>
                        <FormattedMessage
                          {...SimulationResultBodyMessages.heterogeneityTh}
                        />

                        <div className={styled["help-icon"]}>
                          <Icon
                            name={ICON_NAMES_ENUM.help_circle}
                            className={styled.file__icon}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        SimulationResultBodyMessages.ETPAgriPVZoneHelp
                      )}
                    >
                      <div>
                        <div className={styled.flex}>
                          <FormattedMessage
                            {...SimulationResultBodyMessages.ETPAgriPVZone}
                          />

                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </div>

                        <div className={styled.subTitle}>
                          <FormattedMessage
                            {...SimulationResultBodyMessages.ETPAgriPVZoneSubTitle}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        SimulationResultBodyMessages.waterDeficitHelp
                      )}
                    >
                      <div>
                        <div className={styled.flex}>
                          <FormattedMessage
                            {...SimulationResultBodyMessages.waterDeficit}
                          />
                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </div>
                        <div className={styled.subTitle}>
                          <FormattedMessage
                            {...SimulationResultBodyMessages.waterDeficitSubTitle}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                </tr>
              </thead>
              <tbody>
                {result.result_data.map(
                  (resultPerPeriod: any, index: number) => (
                    <tr key={resultPerPeriod.id}>
                      <td>
                        <span>{resultPerPeriod.period.name}</span>
                      </td>
                      <td
                        style={{
                          backgroundColor: getRowBackgroundColor(
                            tableValues[index].IRR_FRACTION,
                            "IRR_FRACTION"
                          ),
                        }}
                      >
                        {
                          // IRR FRACTION
                          tableValues[index].IRR_FRACTION
                        }
                      </td>
                      <td
                        style={{
                          backgroundColor: getRowBackgroundColor(
                            tableValues[index].DAILY_PAR,
                            "DAILY_PAR"
                          ),
                        }}
                      >
                        {
                          // DAILY PAR
                          tableValues[index].DAILY_PAR
                        }
                      </td>
                      <td
                        style={{
                          backgroundColor: getRowBackgroundColor(
                            tableValues[index].HETEROGENEITY,
                            "HETEROGENEITY"
                          ),
                        }}
                      >
                        {
                          // HETEROGENEITY
                          tableValues[index].HETEROGENEITY
                        }
                      </td>
                      <td
                        style={{
                          backgroundColor: getRowBackgroundColor(
                            tableValues[index].ETP_FRACTION,
                            "ETP_FRACTION"
                          ),
                        }}
                      >
                        {
                          // ETP FRACTION
                          tableValues[index].ETP_FRACTION
                        }
                      </td>
                      <td
                        style={{
                          backgroundColor: getRowBackgroundColor(
                            tableValues[index].WATER_DEFICIT,
                            "WATER_DEFICIT"
                          ),
                        }}
                      >
                        {tableValues[index].WATER_DEFICIT}
                      </td>
                    </tr>
                  )
                )}
                {/* <tr>
                  <td>
                    <span>
                      <FormattedMessage
                        {...SimulationResultBodyMessages.mean}
                      />
                    </span>
                  </td>
                  <td>
                    {
                      // IRR FRACTION
                      calculateMeanOfTableValues("IRR_FRACTION")
                    }
                  </td>
                  <td>
                    {
                      // DAILY PAR
                      calculateMeanOfTableValues("DAILY_PAR")
                    }
                  </td>
                  <td>
                    {
                      // HETEROGENEITY
                      calculateMeanOfTableValues("HETEROGENEITY")
                    }
                  </td>
                  <td>
                    {
                      // ETP FRACTION
                      calculateMeanOfTableValues("ETP_FRACTION")
                    }
                  </td>
                  <td>{calculateMeanOfTableValues("WATER_DEFICIT")}</td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default SimulationResultBody;

