import React from "react";
import styled from "components/SimulationDropItem/styled.module.scss";
import Line from "components/SimulationDropItem/Line";
import { useIntl } from "react-intl";
import SimulationCreateMessages from "pages/SimulationCreate/SimulationCreateMessages";
import SimulationDropItemMessages from "./SimulationDropItemMessages";

const GeneralRenderList = ({ selected }: { selected: any }) => {
  const intl = useIntl()
  return (
    <ul className={styled.ul}>
      <Line title={intl.formatMessage(SimulationCreateMessages.resolution)} value={`${selected.resolution} pts/m`} />
      <Line title={intl.formatMessage(SimulationDropItemMessages.timeStep)} value={`${selected.frequency}`} />
      {/* <Line title="Timezone" value={selected.timezone} /> */}
    </ul>
  );
};

export default GeneralRenderList;
