import React, { useEffect } from "react";
import Button from "components/common/button/Button";
import { ReactComponent as Arrow } from "assets/icons/arrow-down.svg";
import styled from "./styled.module.scss";
import { FormikValues, useFormik } from "formik";
import Input from "components/common/input/Input";
import ModalContainer from "components/ModalContainer/ModalContainer";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import { useDispatch } from "react-redux";
import inviteUserModalMessages from "components/InviteUserModal/InviteUserModalMessages";
import Select from "components/common/select/Select";
import { selectOptionsText } from "components/common/select/selectOptionsText";
import { createUser } from "store/users/actions";
import { useParams } from "react-router-dom";
import InviteUserSchema from "components/InviteUserModal/InviteUserSchema";
import { UserRoles } from "components/SettingUserCard/SettingUserCard";

interface IInviteUser {
  show: boolean;
  onHide: () => void;
}

interface IInviteUserForm {
  email: string;
  role: string;
}

const defaultFormValue: IInviteUserForm = {
  email: "",
  role: "user",
};

const InviteUserModal = ({ show, onHide }: IInviteUser) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { client_id } = useParams();
  const { userRoles } = selectOptionsText();

  const filteredRoles = userRoles.filter(
    (item) => item.value !== UserRoles.ADMIN
  );

  const onSubmit = (values: FormikValues) => {
    dispatch(createUser({ ...values, client_id }));
    onHide();
  };

  const formik = useFormik<IInviteUserForm>({
    validationSchema: InviteUserSchema(intl),
    onSubmit,
    initialValues: defaultFormValue,
  });

  const onSelect = (option: any) => {
    formik.setFieldValue("role", option.value);
  };

  const getInputProps = (key: keyof IInviteUserForm) => ({
    name: key,
    value: formik.values[key] as string | number,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    errorMessage: formik.touched[key] ? (formik.errors[key] as string) : "",
  });

  useEffect(() => {
    if (!show) formik.resetForm();
  }, [show]);

  return (
    <ModalContainer
      show={show}
      onHide={onHide}
      dialogClassName={styled.dialog}
      title={intl.formatMessage(inviteUserModalMessages.inviteNewUser)}
    >
      <form className={styled.form} onSubmit={formik.handleSubmit}>
        <Input
          label={intl.formatMessage(inviteUserModalMessages.emailLabel)}
          placeholder={intl.formatMessage(inviteUserModalMessages.emailLabel)}
          {...getInputProps("email")}
        />

        <Select
          label={intl.formatMessage(inviteUserModalMessages.roleLabel)}
          options={filteredRoles}
          defaultValue={filteredRoles[0]}
          onChange={onSelect}
        />

        <div className={styled.row}>
          <Button iconBefore={<Arrow />} type="submit">
            <FormattedMessage {...inviteUserModalMessages.sendInvite} />
          </Button>
          <Button variant="text" onClick={onHide}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default InviteUserModal;
