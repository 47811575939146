import React from "react";
import styled from "./styled.module.scss";
import clsx from "clsx";
import { FormattedMessage } from "react-intl";
import statusLabelMessages from "components/StatusLabel/StatusLabelMessages";

export enum StatusesEnum {
  PREPARE = "enqueued_main",
  RUNNING_MAIN = "running_main",
  RUNNING_PERIODS = "running_periods",
  RUNNING = "running",
  FAILED = "failed",
  COMPLETED = "completed",
  CANCELED = 'canceled',
  CANCELING = 'canceling',
}

interface IStatusLabel {
  status: StatusesEnum;
}

const StatusLabel = ({ status }: IStatusLabel) => {
  const isPrepare = status === StatusesEnum.PREPARE;
  const isRunning = status === StatusesEnum.RUNNING;
  const isRunningMain = status === StatusesEnum.RUNNING_MAIN;
  const isRunningPeriods = status === StatusesEnum.RUNNING_PERIODS;
  const isProgress =
    isPrepare || isRunning || isRunningMain || isRunningPeriods;
  const isSuccess = status === StatusesEnum.COMPLETED;
  const isFailed = status === StatusesEnum.FAILED;
  const isCanceled = status === StatusesEnum.CANCELED;
  const isCanceling = status === StatusesEnum.CANCELING;

  return (
    <div
      className={clsx(styled.container, {
        [styled.pending]: isProgress || isCanceling,
        [styled.success]: isSuccess,
        [styled.failed]: isFailed || isCanceled,
      })}
    >
      {isPrepare && <FormattedMessage {...statusLabelMessages.enqueuedMain} />}
      {isRunning && (
        <FormattedMessage {...statusLabelMessages.running} />
      )}
      {isRunningMain && (
        <FormattedMessage {...statusLabelMessages.runningMain} />
      )}
      {isRunningPeriods && (
        <FormattedMessage {...statusLabelMessages.runningPeriods} />
      )}
      {isSuccess && <FormattedMessage {...statusLabelMessages.completed} />}
      {isFailed && <FormattedMessage {...statusLabelMessages.failed} />}
      {isCanceled && <FormattedMessage {...statusLabelMessages.canceled} />}
      {isCanceling && <FormattedMessage {...statusLabelMessages.canceling} />}
    </div>
  );
};

export default StatusLabel;
