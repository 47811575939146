import chartToCsv from "utils/chartToCsv";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import MainContent from "components/MainContent/MainContent";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import parametersInterestMessages from "components/ParametersInterest/ParametersInterestMessages";
import clsx from "clsx";
import { ReactComponent as Arrow } from "assets/icons/arrow-down.svg";
import { ReactComponent as LinkIcon } from "assets/icons/link.svg";
import batchesResultMessages from "pages/BatchesResult/BatchesResultMessages";
import {
  useParamsList,
  IInterestNameVariants,
  IInterestParamsItem,
} from "components/ParametersInterest/useParamsList";
import StatusLabel, { StatusesEnum } from "components/StatusLabel/StatusLabel";
import { ParamsInterests } from "components/BatchesBody/BatchesBody";
import Input from "components/common/input/Input";
import { useSelector } from "react-redux";
import { getNavigationState } from "store/navigation/selectors";
import { useOptionData } from "hooks/useOptionData";
import {
  cancelBatchSimulation,
  deleteBatchResult,
  getBatchResults,
  resetBatchResults,
} from "store/batches/actions";
import { getBatchResultsState } from "store/batches/selectors";
import { ROUTES } from "constants/routes";
import { Loader } from "components/common/loader/Loader";
import {
  getBatchMetadata,
  getBatchResultFiles,
  getBatchSimulationResultFiles,
} from "store/batches/api";
import Button from "components/common/button/Button";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import commonMessages from "components/common/CommonMessages";
import { downloadFile } from "utils/downloadFile";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import CommonMessages from "components/common/CommonMessages";
import {
  XAxis,
  Tooltip,
  YAxis,
  Bar,
  ComposedChart,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
  Line,
  Text as RechartText,
  Label,
} from "recharts";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import BatchesResultBodyMessages from "pages/BatchesResult/BatchesResultMessages";
import { Tooltip as TooltipMUI } from "@mui/material";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import JSZip from "jszip";
import { ICrops } from "pages/Crops/Crops";
import chartToPng from "utils/chartToPng";

const REFRESH_DATA_TIME = 10000;

export type simulationsProgressType = {
  current: number;
  total: number;
}[];

const BatchesResult = () => {
  const intl = useIntl();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client_id, project_id, batch_id, id } = useParams();
  const params = useParamsList();
  const batchResult = useSelector(getBatchResultsState);
  const { crops, universal_datasets, batches, steering_algorithms } =
    useSelector(getNavigationState);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [isMetadataFileLoading, setIsMetadataFileLoading] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isSimulationFileLoading, setIsSimulationFileLoading] = useState<
    Record<number, boolean>
  >({});

  const findFullName = (findId: number) => {
    for (const batch of batches) {
      for (const result of batch.results) {
        const index = result.simulations.findIndex(
          ({ id }: any) => id === findId
        );

        if (index !== -1) {
          return `${batch.name} - ${result.name} - ${result.simulations[index].name}`;
        }
      }
    }
    return batchResult.name;
  };

  const isSuccessSimulation = batchResult?.status === StatusesEnum.COMPLETED;
  const isCanceledSimulation = batchResult?.status === StatusesEnum.CANCELED;
  const isProgressSimulation = batchResult?.status === StatusesEnum.RUNNING;

  const universalDatasetOptions = useOptionData(universal_datasets);
  const cropOptions = useOptionData(crops);
  const steeringAlgorithmOptions = useOptionData(steering_algorithms);

  const onDownloadSimulation = (simulation_result: any) => () => {
    setIsSimulationFileLoading((prev) => ({
      ...prev,
      [simulation_result.id]: true,
    }));
    getBatchSimulationResultFiles({
      clientId: client_id,
      projectId: project_id,
      batchId: batch_id,
      resultId: id,
      simulationId: simulation_result.id,
    })
      .then((href) => {
        downloadFile(href, `${findFullName(simulation_result.id)}.zip`);
      })
      .finally(() => {
        setIsSimulationFileLoading((prev) => ({
          ...prev,
          [simulation_result.id]: false,
        }));
      });
  };

  const onDownload = () => {
    setIsFileLoading(true);
    getBatchResultFiles({
      clientId: client_id,
      projectId: project_id,
      batchId: batch_id,
      resultId: id,
    })
      .then((href) => {
        downloadFile(href, `${findFullName(batchResult.name)}.zip`);
      })
      .finally(() => setIsFileLoading(false));
  };

  const onDownloadMetadata = () => {
    setIsMetadataFileLoading(true);
    getBatchMetadata({
      clientId: client_id,
      projectId: project_id,
      batchId: batch_id,
      resultId: id,
    })
      .then((href) => {
        downloadFile(href, `${findFullName(batchResult.name)}.csv`);
      })
      .finally(() => setIsMetadataFileLoading(false));
  };

  const onCancel = () => {
    setIsCanceling(true);
    dispatch(
      cancelBatchSimulation({
        clientId: client_id,
        projectId: project_id,
        batchId: batch_id,
        resultId: id,
        callback: () => setIsCanceling(false),
      })
    );
  };

  const onBack = () => {
    navigate(
      ROUTES.BATCHES_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", batch_id as string)
    );
  };

  const onDelete = () => {
    dispatch(
      deleteBatchResult({
        clientId: client_id,
        projectId: project_id,
        batchId: batch_id,
        resultId: id,
        callback: onBack,
      })
    );
  };

  const getResult = () => {
    dispatch(
      getBatchResults({
        clientId: client_id,
        projectId: project_id,
        batchId: batch_id,
        resultId: id,
      })
    );
  };

  useEffect(() => {
    let timeoutId: ReturnType<typeof setTimeout>;
    if (isProgressSimulation) {
      timeoutId = setTimeout(getResult, REFRESH_DATA_TIME);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [batchResult]);

  useEffect(() => {
    getResult();

    return () => {
      dispatch(resetBatchResults());
    };
  }, [client_id, project_id, batch_id, id]);

  const getCurrentField = useCallback(
    (value: IInterestNameVariants, rows: ParamsInterests) => {
      const param = params.find(
        ({ name }: IInterestParamsItem) => name === value
      );

      if (!param) return;

      if (param.type === "input") {
        return <Input type="number" noBorder value={rows[value]} disabled />;
      }

      if (param.type === "point") {
        return (
          <div className={styled.row}>
            <Input
              className={styled.twoInput}
              type="number"
              beforeIcon="X"
              noBorder
              disabled
              defaultValue={rows[value]?.x}
            />
            <Input
              className={styled.twoInput}
              type="number"
              beforeIcon="Y"
              noBorder
              disabled
              defaultValue={rows[value]?.y}
            />
          </div>
        );
      }

      if (param.type === "crop") {
        const selectedId = rows[value];
        const selectedValue = cropOptions.find(
          (item: any) => item.value === selectedId
        );

        return selectedValue?.label;
      }

      if (param.type === "universal_dataset") {
        const selectedId = rows[value];
        const selectedValue = universalDatasetOptions.find(
          (item: any) => item.value === selectedId
        );

        return selectedValue?.label;
      }

      if (param.type === "steering_algorithm") {
        const selectedId = rows[value];
        const selectedValue = steeringAlgorithmOptions.find(
          (item: any) => item.value === selectedId
        );
        if (selectedId == null) return "None";
        return selectedValue?.label;
      }
    },
    [params]
  );

  const calculateWaterDeficitSimulation = (simulation_result: any) => {
    const cropDateAreBetweenDates = (
      crop: ICrops,
      start_date: Date,
      end_date: Date
    ) => {
      const isDateInRange = (date: Date, crop: ICrops) => {
        for (var i = 0; i < crop.periods.length; i++) {
          if (
            date >= new Date(crop.periods[i].start_date!) &&
            date <= new Date(crop.periods[i].end_date!)
          ) {
            return true;
          }
        }
        return false;
      };

      start_date.setFullYear(
        new Date(crop.periods[0].start_date!).getFullYear()
      );
      end_date.setFullYear(new Date(crop.periods[0].start_date!).getFullYear());

      var current_date = new Date(start_date);

      while (current_date <= end_date) {
        if (!isDateInRange(current_date, crop)) {
          return false;
        }
        current_date.setDate(current_date.getDate() + 1);
      }

      return true;
    };

    const start_date = new Date("2022-04-01");
    const end_date = new Date("2022-06-30");

    if (!simulation_result.simulation.ETP_dataset)
      return "Simulation Doesn't has ETP dataset.";
    else if (
      cropDateAreBetweenDates(
        simulation_result.simulation.crop,
        start_date,
        end_date
      )
    ) {
      const etp = simulation_result.result_data.reduce(
        (acc: number, val: any) =>
          acc +
          val.result_daily_data.reduce(
            (acc_daily: number, daily_data: any) =>
              acc_daily +
              (new Date(daily_data.date) >= start_date &&
              new Date(daily_data.date) <= end_date
                ? daily_data.etp_agri_pv
                : 0),
            0
          ),
        0
      );

      const precipitation =
        simulation_result.simulation.ETP_dataset.dataset_data.reduce(
          (acc: number, val: any) =>
            acc + (val.month >= 4 && val.month <= 6 ? val.precipitation : 0),
          0
        );

      return Math.round((precipitation - etp) * 100) / 100;
    } else return "Out of periods";
  };

  const tableValues = useMemo(() => {
    if (!batchResult) return null;
    return batchResult.rows.map((row: any, index: number) => ({
      IRR_FRACTION:
        Math.round(
          (((row.simulation_result.result_data.reduce(
            (acc: number, val: any) => acc + val.area_agrilpv,
            0
          ) /
            row.simulation_result.result_data.length) *
            100) /
            row.simulation_result.result_data.reduce(
              (acc: number, val: any) => acc + val.power_ref,
              0
            )) *
            row.simulation_result.result_data.length *
            10
        ) / 10,
      DAILY_PAR: Math.round(
        row.simulation_result.result_data.reduce(
          (acc: number, val: any) => acc + (1 / 2.1) * val.area_agrilpv,
          0
        ) /
          row.simulation_result.result_data.reduce(
            (acc: number, val: any) =>
              acc +
              (new Date(val.period.end_date).getTime() -
                new Date(val.period.start_date).getTime()) /
                (1000 * 3600 * 24),
            0
          )
      ),
      HETEROGENEITY:
        Math.round(
          (row.simulation_result.result_data.reduce(
            (acc: number, val: any) =>
              acc +
              (val.heterogeneity *
                (new Date(val.period.end_date).getTime() -
                  new Date(val.period.start_date).getTime())) /
                (1000 * 3600 * 24),
            0
          ) /
            row.simulation_result.result_data.reduce(
              (acc: number, val: any) =>
                acc +
                (new Date(val.period.end_date).getTime() -
                  new Date(val.period.start_date).getTime()) /
                  (1000 * 3600 * 24),
              0
            )) *
            10
        ) / 10,
      ETP_FRACTION:
        Math.round(
          (((row.simulation_result.result_data.reduce(
            (acc: number, val: any) =>
              acc +
              val.etp_agripv *
                ((new Date(val.period.end_date).getTime() -
                  new Date(val.period.start_date).getTime()) /
                  (1000 * 3600 * 24)),
            0
          ) /
            row.simulation_result.result_data.reduce(
              (acc: number, val: any) =>
                acc +
                (new Date(val.period.end_date).getTime() -
                  new Date(val.period.start_date).getTime()) /
                  (1000 * 3600 * 24),
              0
            )) *
            100) /
            (row.simulation_result.result_data.reduce(
              (acc: number, val: any) =>
                acc +
                val.etp_ref *
                  ((new Date(val.period.end_date).getTime() -
                    new Date(val.period.start_date).getTime()) /
                    (1000 * 3600 * 24)),
              0
            ) /
              row.simulation_result.result_data.reduce(
                (acc: number, val: any) =>
                  acc +
                  (new Date(val.period.end_date).getTime() -
                    new Date(val.period.start_date).getTime()) /
                    (1000 * 3600 * 24),
                0
              ))) *
            10
        ) / 10,
      ZONE_CULTIVABLE:
        Math.round(
          (row.simulation_result.simulation.structure.structure_type == "fixed"
            ? (row.simulation_result.simulation.structure.panels_gap_y -
                row.simulation_result.simulation.structure.no_crop_zone) /
              row.simulation_result.simulation.structure.panels_gap_y
            : (row.simulation_result.simulation.structure.panels_gap_x -
                row.simulation_result.simulation.structure.no_crop_zone) /
              row.simulation_result.simulation.structure.panels_gap_x) * 10000
        ) / 100,
      TAUX_DE_COUVERTURE:
        Math.round(
          (row.simulation_result.simulation.structure.structure_type == "fixed"
            ? (row.simulation_result.simulation.structure.panel_y *
                Math.cos(
                  (row.simulation_result.simulation.structure.static_angle *
                    Math.PI) /
                    180
                )) /
              row.simulation_result.simulation.structure.panels_gap_y
            : row.simulation_result.simulation.structure.panel_x /
              row.simulation_result.simulation.structure.panels_gap_x) * 1000
        ) / 10,
      PUISSANCE_INSTALLEE: Math.round(
        (10000 / 2.85) *
          ((row.simulation_result.simulation.structure.panel_x *
            row.simulation_result.simulation.structure.panel_y) /
            (row.simulation_result.simulation.structure.panels_gap_x *
              row.simulation_result.simulation.structure.panels_gap_y)) *
          0.55
      ),
      WATER_DEFICIT: calculateWaterDeficitSimulation(row.simulation_result),
    }));
  }, [batchResult]);

  if (!batchResult) return <Loader />;

  const getRowBackgroundColor = (value: number | string, type: string) => {
    const sortedData = [...tableValues.map((val: any) => val[type])].sort(
      (a, b) => a - b
    );

    sortedData.filter((item) => typeof item != "string");

    if (typeof value == "string") return "white";

    const middle = Math.floor(sortedData.length / 2);
    const median =
      sortedData.length % 2 === 0
        ? (sortedData[middle - 1] + sortedData[middle]) / 2
        : sortedData[middle];

    const min = sortedData[0];
    const max = sortedData[sortedData.length - 1];

    var green, red;

    if (value === median) {
      red = green = 255;
    } else if (value <= min) {
      green = 0;
      red = 255;
    } else if (value >= max) {
      green = 255;
      red = 0;
    } else if (value <= median) {
      const intensity = (value - min) / (median - min);
      red = 255;
      green = Math.round(255 * intensity);
    } else {
      const intensity = (value - median) / (max - median);
      red = Math.round(255 * (1 - intensity));
      green = 255;
    }

    if (
      type == "HETEROGENEITY" ||
      type === "TAUX_DE_COUVERTURE" ||
      type === "ETP_FRACTION"
    )
      return `rgb(${green}, ${red}, 0)`;
    else return `rgb(${red}, ${green}, 0)`;
  };

  const simulationsProgressData: simulationsProgressType = batchResult.rows.map(
    ({ simulation_result }: any) => {
      const isRunningMain =
        simulation_result?.status === StatusesEnum.RUNNING_MAIN;
      const isPrepare = simulation_result?.status === StatusesEnum.PREPARE;
      const isCompleted = simulation_result?.status === StatusesEnum.COMPLETED;

      const sumPeriodsPath = simulation_result?.result_data?.reduce(
        (a: any, { current }: any) => {
          a += current;
          return a;
        },
        0
      );

      const mainPath =
        isRunningMain || isPrepare
          ? simulation_result.current
          : simulation_result.length;

      const current = isCompleted
        ? simulation_result.total_length || 100
        : mainPath + sumPeriodsPath || 0;

      return {
        current,
        total: simulation_result.total_length || 100,
      };
    }
  );

  const simulationsProgress = simulationsProgressData.map(
    ({ current, total }) => (current / (total / 100)).toFixed(0)
  );

  const totalProgressData = simulationsProgressData.reduce(
    (a, c) => {
      a.current += c.current;
      a.total += c.total;
      return a;
    },
    { current: 0, total: 0 }
  );

  const totalProgress = (
    totalProgressData.current /
    (totalProgressData.total / 100)
  ).toFixed(0);

  const CustomizedTick = (props: any) => {
    const { x, y, stroke, payload, offset } = props;
    return (
      <g transform={`translate(${x},${y - 10})`}>
        <RechartText
          x={0}
          y={0}
          dy={16}
          angle={-65}
          width={100}
          fontSize={10}
          textAnchor="end"
          verticalAnchor="middle"
        >
          {payload.value}
        </RechartText>
      </g>
    );
  };

  return (
    <MainContent
      header={
        <>
          <div className={styled.row}>
            <h2 className={styled.title}>{batchResult.name}</h2>
            <StatusLabel status={batchResult.status} />
            {!isSuccessSimulation && (
              <span className={styled.progressText}>{totalProgress}%</span>
            )}
          </div>
          <div className={styled.headerRow}>
            {(isSuccessSimulation || isCanceledSimulation) && (
              <Button
                variant="text"
                iconBefore={<Delete />}
                onClick={onDelete}
                className={styled.deleteButton}
              >
                <FormattedMessage {...commonMessages.delete} />
              </Button>
            )}

            {isProgressSimulation && (
              <Button
                variant="outlined"
                disabled={isCanceling}
                isLoading={isCanceling}
                onClick={onCancel}
              >
                <FormattedMessage {...commonMessages.cancel} />
              </Button>
            )}

            {isSuccessSimulation && (
              <div style={{ display: "flex", gap: "1rem" }}>
                <Button
                  iconBefore={<Icon name={ICON_NAMES_ENUM.arrow_down} />}
                  disabled={isFileLoading}
                  isLoading={isFileLoading}
                  onClick={onDownload}
                >
                  <FormattedMessage {...commonMessages.downloadAll} />
                </Button>
                <Button
                  iconBefore={<Icon name={ICON_NAMES_ENUM.arrow_down} />}
                  disabled={isMetadataFileLoading}
                  isLoading={isMetadataFileLoading}
                  onClick={onDownloadMetadata}
                >
                  <FormattedMessage {...commonMessages.downloadAllMetadata} />
                </Button>
              </div>
            )}
          </div>
        </>
      }
    >
      <div className={`${styled.tableContainerFirst}`}>
        <table className={styled.table}>
          <thead>
            <tr>
              <th>
                <FormattedMessage {...batchesResultMessages.simulationPage} />
              </th>

              {Object.keys(batchResult.rows[0].batch_row).map((item) => (
                <th key={item}>
                  <FormattedMessage
                    {...parametersInterestMessages[
                      item as IInterestNameVariants
                    ]}
                  />
                </th>
              ))}

              <th>
                <FormattedMessage {...batchesResultMessages.status} />
              </th>

              <th className={styled.lastColumn} />
            </tr>
          </thead>

          <tbody>
            {batchResult.rows.map((simulation: any, index: number) => (
              <tr
                key={simulation.simulation_result.id}
                className={clsx({
                  [styled.successRow]:
                    simulation.simulation_result.status ===
                    StatusesEnum.COMPLETED,
                  [styled.failedRow]:
                    simulation.simulation_result.status === StatusesEnum.FAILED,
                })}
              >
                <td>
                  <Link
                    className={styled.link}
                    to={ROUTES.BATCHES_SIMULATION_RESULT.replace(
                      ":client_id",
                      client_id as string
                    )
                      .replace(":project_id", project_id as string)
                      .replace(":batch_id", batch_id as string)
                      .replace(":result_id", id as string)
                      .replace(
                        ":simulation_id",
                        simulation.simulation_result.id as string
                      )}
                  >
                    <span>{simulation.simulation_result.name}</span>
                    <LinkIcon />
                  </Link>
                </td>
                {Object.keys(simulation.batch_row).map((item) => (
                  <td key={`${item}-${simulation.simulation_result.id}`}>
                    {getCurrentField(
                      item as IInterestNameVariants,
                      simulation.batch_row
                    )}
                  </td>
                ))}
                <td>
                  <StatusLabel
                    status={simulation.simulation_result.status as StatusesEnum}
                  />
                </td>
                <td>
                  {simulation.simulation_result.status ===
                  StatusesEnum.COMPLETED ? (
                    <Button
                      variant="text"
                      disabled={
                        isSimulationFileLoading[simulation.simulation_result.id]
                      }
                      isLoading={
                        isSimulationFileLoading[simulation.simulation_result.id]
                      }
                      className={clsx(styled.button)}
                      onClick={onDownloadSimulation(
                        simulation.simulation_result
                      )}
                    >
                      <Arrow />
                    </Button>
                  ) : (
                    <span className={styled.progressText}>
                      {simulationsProgress[index]}%
                    </span>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {batchResult.status == StatusesEnum.COMPLETED && (
        <div className={styled.list}>
          <div
            className={`${styled.flex} ${styled.spaceBetween} ${styled.alignCenter}`}
          >
            <h2 className={styled.title}>
              <FormattedMessage {...batchesResultMessages.batchAnalysis} />
            </h2>
            <div className={styled.downloadContainer}>
              <TooltipMUI
                title={intl.formatMessage(
                  BatchesResultBodyMessages.hintDatasToPng
                )}
              >
                <div
                  className={styled.chartToPngButton}
                  onClick={chartToPng([
                    "tab1",
                    "tab2",
                    "graph1",
                    "graph2",
                    "graph3",
                    "graph4",
                  ])}
                >
                  <Icon
                    name={ICON_NAMES_ENUM.download_image}
                    className={styled.file__icon}
                  />
                </div>
              </TooltipMUI>
              <TooltipMUI
                title={intl.formatMessage(
                  BatchesResultBodyMessages.hintDatasToCsv
                )}
              >
                <div
                  className={styled.chartToPngButton}
                  onClick={chartToCsv(tableValues, "data")}
                >
                  <Icon
                    name={ICON_NAMES_ENUM.download_file}
                    className={styled.file__icon}
                  />
                </div>
              </TooltipMUI>
            </div>
          </div>
          <div className={styled.tableContainer}>
            <table className={styled.dataTable} id="tab1">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage {...CommonMessages.simulation} />
                  </th>

                  {Object.keys(batchResult.rows[0].batch_row).map((item) => (
                    <th key={item}>
                      <FormattedMessage
                        {...parametersInterestMessages[
                          item as IInterestNameVariants
                        ]}
                      />
                    </th>
                  ))}
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        batchesResultMessages.irradianceFractionHelp
                      )}
                    >
                      <div>
                        <div
                          className={`${styled.flex} ${styled.justifyCenter}`}
                        >
                          <FormattedMessage
                            {...batchesResultMessages.irradianceFraction}
                          />
                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </div>
                        <div className={styled.subTitle}>
                          <FormattedMessage
                            {...batchesResultMessages.ETPAgriPVZoneSubTitle}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        batchesResultMessages.meanDailyPARHelp
                      )}
                    >
                      <div>
                        <div
                          className={`${styled.flex} ${styled.justifyCenter}`}
                        >
                          <FormattedMessage
                            {...batchesResultMessages.meanDailyPAR}
                          />

                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </div>
                        <div className={styled.subTitle}>
                          <FormattedMessage
                            {...batchesResultMessages.ETPAgriPVZoneSubTitle}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        batchesResultMessages.heterogeneityHelp
                      )}
                    >
                      <div>
                        <div
                          className={`${styled.flex} ${styled.justifyCenter}`}
                        >
                          <FormattedMessage
                            {...batchesResultMessages.heterogeneity}
                          />

                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        batchesResultMessages.ETPAgriPVZoneHelp
                      )}
                    >
                      <div>
                        <div
                          className={`${styled.flex} ${styled.justifyCenter}`}
                        >
                          <FormattedMessage
                            {...batchesResultMessages.ETPAgriPVZone}
                          />

                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </div>

                        <div className={styled.subTitle}>
                          <FormattedMessage
                            {...batchesResultMessages.ETPAgriPVZoneSubTitle}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        batchesResultMessages.waterDeficitHelp
                      )}
                    >
                      <div>
                        <div
                          className={`${styled.flex} ${styled.justifyCenter}`}
                        >
                          <FormattedMessage
                            {...batchesResultMessages.waterDeficit}
                          />
                          <div className={styled["help-icon"]}>
                            <Icon
                              name={ICON_NAMES_ENUM.help_circle}
                              className={styled.file__icon}
                            />
                          </div>
                        </div>
                        <div className={styled.subTitle}>
                          <FormattedMessage
                            {...batchesResultMessages.waterDeficitSubTitle}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                </tr>
              </thead>

              <tbody>
                {batchResult.rows.map((simulation: any, index: number) => (
                  <tr key={simulation.simulation_result.id}>
                    <td>
                      <span>{simulation.simulation_result.name}</span>
                    </td>
                    {Object.keys(simulation.batch_row).map((item) => (
                      <td key={`${item}-${simulation.simulation_result.id}`}>
                        {getCurrentField(
                          item as IInterestNameVariants,
                          simulation.batch_row
                        )}
                      </td>
                    ))}
                    <td
                      style={{
                        backgroundColor: getRowBackgroundColor(
                          tableValues[index].IRR_FRACTION,
                          "IRR_FRACTION"
                        ),
                      }}
                    >
                      {
                        // IRR FRACTION
                        tableValues[index].IRR_FRACTION
                      }
                    </td>
                    <td
                      style={{
                        backgroundColor: getRowBackgroundColor(
                          tableValues[index].DAILY_PAR,
                          "DAILY_PAR"
                        ),
                      }}
                    >
                      {
                        // DAILY PAR
                        tableValues[index].DAILY_PAR
                      }
                    </td>
                    <td
                      style={{
                        backgroundColor: getRowBackgroundColor(
                          tableValues[index].HETEROGENEITY,
                          "HETEROGENEITY"
                        ),
                      }}
                    >
                      {
                        // HETEROGENEITY
                        tableValues[index].HETEROGENEITY
                      }
                    </td>
                    <td
                      style={{
                        backgroundColor: getRowBackgroundColor(
                          tableValues[index].ETP_FRACTION,
                          "ETP_FRACTION"
                        ),
                      }}
                    >
                      {
                        // ETP FRACTION
                        tableValues[index].ETP_FRACTION
                      }
                    </td>
                    <td
                      style={{
                        backgroundColor: getRowBackgroundColor(
                          tableValues[index].WATER_DEFICIT,
                          "WATER_DEFICIT"
                        ),
                      }}
                    >
                      {tableValues[index].WATER_DEFICIT}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className={styled.tableContainer}>
            <table className={styled.dataTable} id="tab2">
              <thead>
                <tr>
                  <th>
                    <FormattedMessage {...CommonMessages.simulation} />
                  </th>

                  {Object.keys(batchResult.rows[0].batch_row).map((item) => (
                    <th key={item}>
                      <FormattedMessage
                        {...parametersInterestMessages[
                          item as IInterestNameVariants
                        ]}
                      />
                    </th>
                  ))}
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        batchesResultMessages.farmingZoneFractionHelp
                      )}
                    >
                      <div className={`${styled.flex} ${styled.justifyCenter}`}>
                        <FormattedMessage
                          {...batchesResultMessages.farmingZoneFraction}
                        />
                        <div className={styled["help-icon"]}>
                          <Icon
                            name={ICON_NAMES_ENUM.help_circle}
                            className={styled.file__icon}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        batchesResultMessages.groundCoverageRatioHelp
                      )}
                    >
                      <div className={`${styled.flex} ${styled.justifyCenter}`}>
                        <FormattedMessage
                          {...batchesResultMessages.groundCoverageRatio}
                        />
                        <div className={styled["help-icon"]}>
                          <Icon
                            name={ICON_NAMES_ENUM.help_circle}
                            className={styled.file__icon}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                  <th>
                    <TooltipMUI
                      title={intl.formatMessage(
                        batchesResultMessages.installedCapacityHelp
                      )}
                    >
                      <div className={`${styled.flex} ${styled.justifyCenter}`}>
                        <FormattedMessage
                          {...batchesResultMessages.installedCapacity}
                        />
                        <div className={styled["help-icon"]}>
                          <Icon
                            name={ICON_NAMES_ENUM.help_circle}
                            className={styled.file__icon}
                          />
                        </div>
                      </div>
                    </TooltipMUI>
                  </th>
                </tr>
              </thead>

              <tbody>
                {batchResult.rows.map((simulation: any, index: number) => (
                  <tr key={simulation.simulation_result.id}>
                    <td>
                      <span>{simulation.simulation_result.name}</span>
                    </td>
                    {Object.keys(simulation.batch_row).map((item) => (
                      <td key={`${item}-${simulation.simulation_result.id}`}>
                        {getCurrentField(
                          item as IInterestNameVariants,
                          simulation.batch_row
                        )}
                      </td>
                    ))}
                    <td
                      style={{
                        backgroundColor: getRowBackgroundColor(
                          tableValues[index].ZONE_CULTIVABLE,
                          "ZONE_CULTIVABLE"
                        ),
                      }}
                    >
                      {
                        // % zone cultivable
                        tableValues[index].ZONE_CULTIVABLE
                      }
                    </td>
                    <td
                      style={{
                        backgroundColor: getRowBackgroundColor(
                          tableValues[index].TAUX_DE_COUVERTURE,
                          "TAUX_DE_COUVERTURE"
                        ),
                      }}
                    >
                      {
                        // TAUX DE COUVERTURE
                        tableValues[index].TAUX_DE_COUVERTURE
                      }
                    </td>
                    <td
                      style={{
                        backgroundColor: getRowBackgroundColor(
                          tableValues[index].PUISSANCE_INSTALLEE,
                          "PUISSANCE_INSTALLEE"
                        ),
                      }}
                    >
                      {
                        // PUISSANCE INSTALLEE
                        tableValues[index].PUISSANCE_INSTALLEE
                      }
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className={`${styled.flex} ${styled.spaceBetween}`}>
            <div className={styled.halfChartContainer}>
              <div className={styled.title}>
                <FormattedMessage
                  {...batchesResultMessages.fractionAndHeterogeneityOfIrradiance}
                />
              </div>
              <div className={styled.chart} id="graph1">
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    width={500}
                    height={500}
                    data={tableValues.map((val: any, index: number) => ({
                      name: batchResult.rows[index].simulation_result.name,
                      IRR_FRACTION: val.IRR_FRACTION,
                      HETEROGENEITY: val.HETEROGENEITY,
                      ETP_FRACTION: val.ETP_FRACTION,
                    }))}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      height={75}
                      dataKey="name"
                      interval={0}
                      tick={<CustomizedTick />}
                    />
                    <YAxis
                      domain={[0, 100]}
                      yAxisId={"left"}
                      orientation="left"
                    >
                      <Label
                        style={{
                          textAnchor: "middle",
                        }}
                        position={"insideLeft"}
                        angle={270}
                        value={intl.formatMessage(
                          batchesResultMessages.irradianceFraction
                        )}
                      />
                    </YAxis>
                    <YAxis yAxisId={"right"} orientation="right">
                      <Label
                        style={{
                          textAnchor: "middle",
                        }}
                        position={"insideRight"}
                        angle={270}
                        value={intl.formatMessage(
                          batchesResultMessages.heterogeneity
                        )}
                      />
                    </YAxis>
                    <Tooltip />
                    <Legend />
                    <Bar
                      yAxisId={"left"}
                      dataKey="IRR_FRACTION"
                      fill="#AA6666"
                      name={intl.formatMessage(
                        batchesResultMessages.irradianceFraction
                      )}
                    />
                    <Line
                      yAxisId={"right"}
                      dot={({ cx, cy }) => {
                        return (
                          <circle
                            key={cx.toString()}
                            cx={cx}
                            cy={cy}
                            r={6}
                            fill="#66AA66"
                          />
                        );
                      }}
                      type="monotone"
                      dataKey={"HETEROGENEITY"}
                      name={intl.formatMessage(
                        batchesResultMessages.heterogeneity
                      )}
                      stroke={"#66AA66"}
                      // activeBar={<Rectangle fill="pink" stroke="blue" />}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className={styled.halfChartContainer}>
              <div className={styled.title}>
                <FormattedMessage {...batchesResultMessages.ETPFraction} />{" "}
                <FormattedMessage
                  {...batchesResultMessages.ETPAgriPVZoneSubTitle}
                />
              </div>
              <div className={styled.chart} id="graph2">
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    width={500}
                    height={500}
                    data={tableValues.map((val: any, index: number) => ({
                      name: batchResult.rows[index].simulation_result.name,
                      IRR_FRACTION: val.IRR_FRACTION,
                      HETEROGENEITY: val.HETEROGENEITY,
                      ETP_FRACTION: val.ETP_FRACTION,
                    }))}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      height={75}
                      dataKey="name"
                      interval={0}
                      tick={<CustomizedTick />}
                    />
                    <YAxis domain={[0, 100]}>
                      <Label
                        style={{
                          textAnchor: "middle",
                        }}
                        position={"insideLeft"}
                        angle={270}
                        value={intl.formatMessage(
                          batchesResultMessages.ETPAgriPVZone
                        )}
                      />
                    </YAxis>
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="ETP_FRACTION"
                      fill="#66AA66"
                      name={intl.formatMessage(
                        batchesResultMessages.ETPAgriPVZone
                      )}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
          <div className={`${styled.flex} ${styled.spaceBetween}`}>
            <div className={styled.halfChartContainer}>
              <div className={styled.title}>
                <FormattedMessage
                  {...batchesResultMessages.waterDeficitGraphTitle}
                />
              </div>
              <div className={styled.chart} id="graph3">
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    width={500}
                    height={500}
                    data={tableValues.map((val: any, index: number) => ({
                      name: batchResult.rows[index].simulation_result.name,
                      WATER_DEFICIT: val.WATER_DEFICIT,
                    }))}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      height={75}
                      dataKey="name"
                      interval={0}
                      tick={<CustomizedTick />}
                    />
                    <YAxis>
                      <Label
                        style={{
                          textAnchor: "middle",
                        }}
                        position={"insideLeft"}
                        angle={270}
                        value={intl.formatMessage(
                          batchesResultMessages.waterDeficit
                        )}
                      />
                    </YAxis>
                    <Tooltip />
                    <Legend />
                    <Bar
                      dataKey="WATER_DEFICIT"
                      fill="#6666AA"
                      name={intl.formatMessage(
                        batchesResultMessages.waterDeficit
                      )}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className={styled.halfChartContainer}>
              <div className={styled.title}>
                <FormattedMessage
                  {...batchesResultMessages.GCRAndInstalledCapacity}
                />
              </div>
              <div className={styled.chart} id="graph4">
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart
                    width={500}
                    height={500}
                    data={tableValues.map((val: any, index: number) => ({
                      name: batchResult.rows[index].simulation_result.name,
                      TAUX_DE_COUVERTURE: val.TAUX_DE_COUVERTURE,
                      PUISSANCE_INSTALLEE: val.PUISSANCE_INSTALLEE,
                    }))}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      height={75}
                      dataKey="name"
                      interval={0}
                      tick={<CustomizedTick />}
                    />
                    <YAxis domain={[0, 100]} yAxisId="left" orientation="left">
                      <Label
                        style={{
                          textAnchor: "middle",
                        }}
                        position={"insideLeft"}
                        angle={270}
                        value={intl.formatMessage(
                          batchesResultMessages.groundCoverageRatio
                        )}
                      />
                    </YAxis>
                    <YAxis yAxisId="right" orientation="right">
                      <Label
                        style={{
                          textAnchor: "middle",
                        }}
                        position={"insideRight"}
                        angle={270}
                        value={intl.formatMessage(
                          batchesResultMessages.installedCapacity
                        )}
                      />
                    </YAxis>
                    <Tooltip />
                    <Legend />

                    <Bar
                      dataKey="TAUX_DE_COUVERTURE"
                      fill="#444444"
                      name={intl.formatMessage(
                        batchesResultMessages.groundCoverageRatio
                      )}
                      yAxisId="left"
                    />
                    <Line
                      dot={({ cx, cy }) => {
                        return (
                          <circle
                            key={cx.toString()}
                            cx={cx}
                            cy={cy}
                            r={6}
                            fill="#66AA66"
                          />
                        );
                      }}
                      type="monotone"
                      dataKey={"PUISSANCE_INSTALLEE"}
                      name={intl.formatMessage(
                        batchesResultMessages.installedCapacity
                      )}
                      stroke={"#66AA66"}
                      yAxisId="right"
                      // activeBar={<Rectangle fill="pink" stroke="blue" />}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </div>
          </div>
        </div>
      )}
    </MainContent>
  );
};

export default BatchesResult;

