import React, { useState } from "react";
import { useFormik } from "formik";
import AuthLayout from "components/AuthLayout/AuthLayout";
import Button from "components/common/button/Button";
import Input from "components/common/input/Input";
import LoginSchema from "./schema";
import styled from "./styled.module.scss";
import loginMessages from "pages/Login/LoginMessages";
import { FormattedMessage, useIntl } from "react-intl";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { signIn } from "store/user/actions";
import { SpanError } from "components/common/span-error/SpanError";
import { getUserErrorState, getUserLoadingState } from "store/user/selectors";
import {IUser, UserRoles} from "components/SettingUserCard/SettingUserCard";

interface ILoginForm {
  email: string;
  password: string;
}

const Login = () => {
  const [isShowPass, setShowPass] = useState(false);
  const authError = useSelector(getUserErrorState);
  const isLoading = useSelector(getUserLoadingState);
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useDispatch();

  const moveAfterLogin = (user: IUser) => {
    if (user.role.name === UserRoles.ADMIN) {
      navigate(ROUTES.CLIENTS_LIST);
    } else {
      navigate(ROUTES.PROJECTS_LIST.replace(":client_id", String(user.client.id)));
    }
  }

  const onSubmit = async ({ email, password }: ILoginForm) => {
    dispatch(
      signIn({
        email,
        password,
        callback: moveAfterLogin,
      })
    );
  };

  const formik = useFormik<ILoginForm>({
    validationSchema: LoginSchema(intl),
    onSubmit,
    initialValues: {
      email: "",
      password: "",
    },
  });

  return (
    <AuthLayout>
      <Logo className={styled.logo} />
      <h1 className={styled.title}>
        <FormattedMessage {...loginMessages.logIn} />
        <SpanError
          errorMessage={!!authError ? "Incorrect email or password" : ""}
          className={styled["login-invalid"]}
        />
      </h1>
      <p className={styled.subtitle}>
        <FormattedMessage {...loginMessages.subtitle} />
      </p>
      <form className={styled.form} onSubmit={formik.handleSubmit}>
        <Input
          name="email"
          label={intl.formatMessage(loginMessages.emailLabel)}
          placeholder={intl.formatMessage(loginMessages.emailPlaceholder)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          errorMessage={formik.touched.email ? formik.errors.email : ""}
        />
        <Input
          name="password"
          type={isShowPass ? "text" : "password"}
          label={intl.formatMessage(loginMessages.passwordLabel)}
          placeholder={intl.formatMessage(loginMessages.passwordPlaceholder)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          errorMessage={formik.touched.password ? formik.errors.password : ""}
          afterIcon={
            <button
              type="button"
              className={styled["eyes-wrapper"]}
              onClick={() => setShowPass(!isShowPass)}
            >
              <Icon
                name={
                  isShowPass
                    ? ICON_NAMES_ENUM.eyes_open
                    : ICON_NAMES_ENUM.eyes_close
                }
                className={styled.eyes}
              />
            </button>
          }
        />

        <Button type="submit" fullwidth isLoading={isLoading}>
          <span className={styled["button-text"]}>
            <FormattedMessage {...loginMessages.logIn} />
          </span>
        </Button>
      </form>
    </AuthLayout>
  );
};

export default Login;
