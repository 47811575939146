import { defineMessages } from "react-intl";

export default defineMessages({
  changeRole : {
    id             : "ChangeUserRoleModalMessages.changeRole",
    defaultMessage : "Change Role",
  },
  text : {
    id             : "ChangeUserRoleModalMessages.text",
    defaultMessage : "Are you sure you want to change role of user?",
  },
});
