import { object, string, ref } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";
import { password_schema } from "pages/Register/schema";

const RegisterSchema = (intl: IntlShape) =>
  object().shape({
    password: password_schema(intl),
    confirm_password: string()
      .oneOf(
        [ref("password"), null],
        intl.formatMessage(validationMessages.passwordNotMatch)
      )
      .required(intl.formatMessage(validationMessages.required)),
  });

export default RegisterSchema;
