import { defineMessages } from "react-intl";

export default defineMessages({
  generalSetting: {
    id: "BatchesBodyMessages.generalSetting",
    defaultMessage: "General Settings",
  },
  baseSimulation: {
    id: "BatchesBodyMessages.baseSimulation",
    defaultMessage: "Base Simulation",
  },
  frequency: {
    id: "BatchesBodyMessages.frequency",
    defaultMessage: "Simulation Frequency",
  },
  interest: {
    id: "BatchesBodyMessages.interest",
    defaultMessage: "Parameters of interest",
  },
  addRow: {
    id: "BatchesBodyMessages.addRow",
    defaultMessage: "Add row",
  },
});
