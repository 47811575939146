import { Form } from "react-bootstrap";
import { FeedbackType } from "react-bootstrap/esm/Feedback";
import { FormCheckType } from "react-bootstrap/esm/FormCheck";

const Toggle = (props: {
  label: string;
  checked: boolean;
  disabled: boolean;
  onClick: (event: any) => void;
}) => {
  return <Form.Check type="switch" {...props} />;
};

export default Toggle;
