import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getCrops = ({ clientId, projectId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/crops`,
  });

export const getSingleCrop = ({ clientId, projectId, crop_id }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/crops/${crop_id}`,
  });

export const getSimulationCrop = ({ clientId, projectId, crop_id }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/crops/simulation_obj/${crop_id}`,
  });

export const getSingleCopyCrop = ({ clientId, projectId, crop_id }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/crops/get_copy/${crop_id}`,
  });

export const copyCropToOtherProject = ({ clientId, projectId, cropId, projectToId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/crops/${cropId}/copy/${projectToId}`,
  });

export const createCrop = ({ clientId, projectId, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/crops`,
    data,
  });

export const copyCrop = ({ clientId, projectId, cropId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/crops/${cropId}/copy`,
  });

export const updateCrop = ({ clientId, projectId, cropId, ...data }) =>
  apiPATCH({
    url: `/clients/${clientId}/projects/${projectId}/crops/${cropId}`,
    data,
  });

export const deleteCrop = ({ clientId, projectId, cropId }) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}/crops/${cropId}`,
  });

export const getCropTemplate = () =>
  apiGET({
    url: `/extra/crop_templates`,
  });

export const getCropDegreeDaysTemplate = () =>
  apiGET({
    url: `/extra/crop_degree_days_templates`,
  });