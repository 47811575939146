import React from "react";
import ReactSelect, { Props, components } from "react-select";
import styled from "./styled.module.scss";
import { SpanError } from "components/common/span-error/SpanError";
import { ReactComponent as Arrow } from "assets/icons/arrow-select.svg";

interface ISelect extends Props {
  label?: string;
  labelPosition?: "top" | "left" | "right" | "bottom";
  errorMessage?: string;
  menuHeight?: number;
}

const DropdownIndicator = (props: any) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <Arrow />
      </components.DropdownIndicator>
    )
  );
};

const Select = ({
  className,
  label,
  labelPosition = "top",
  name,
  errorMessage,
  menuHeight = 300,
  ...rest
}: ISelect) => {
  return (
    <div className={styled.container} style={{
      display: labelPosition != "top" ? "flex" : "block",
      // textAlign: "center",
      flexDirection: labelPosition == "bottom" ? "column-reverse" : labelPosition == "right" ? "row-reverse" : undefined,
      // justifyContent: "none",
      gap: ".5rem",
    }}>
      {!!label && (
        <label className={styled.label} htmlFor={name}>
          {label}
        </label>
      )}

      <ReactSelect
        id={name}
        name={name}
        classNamePrefix="select"
        components={{
          IndicatorSeparator: null,
          DropdownIndicator,
        }}
        styles={{
          input: (base) => ({
            ...base,
            fontFamily: "Lato",
            fontSize: "0.875rem",
          }),
          container: (base) => ({
            ...base,
            fontFamily: "Lato",
            fontSize: "0.875rem",
            width: "100%",
          }),
          valueContainer: (base) => ({
            ...base,
            paddingTop: 5.5,
            paddingBottom: 5.5,
          }),
          control: (base) => ({
            ...base,
            borderRadius: 4,
            borderColor: !errorMessage
              ? "rgba(114, 118, 117, 0.4)"
              : "rgb(240, 37, 37)",
            "&:hover": {
              borderColor: "rgba(114, 118, 117, 0.4)",
            },
            boxShadow: "none",
          }),
          option: (base) => ({
            ...base,
            backgroundColor: "#fff",
            color: "#333837",
            paddingLeft: 15,
            paddingRight: 15,
            zIndex: 9999999,
            "&:active": {
              backgroundColor: "#72767556",
            },
            "&:hover": {
              backgroundColor: "#72767556",
            },
          }),
          menuList: (base) => ({
            ...base,
            maxHeight: menuHeight,
          }),
        }}
        {...rest}
      />
      <SpanError errorMessage={errorMessage} />
    </div>
  );
};

export default Select;
