import { all, fork } from "redux-saga/effects";
// sagas
import getSizingOptimization from "store/sizing_optimizations/sagas/getSizingOptimizations";
import createSizingOptimization from "store/sizing_optimizations/sagas/createSizingOptimization";
import getSingleSizingOptimization from "store/sizing_optimizations/sagas/getSingleSizingOptimization";
import getSizingOptimizationResult from "store/sizing_optimizations/sagas/getSizingOptimizationResult";
import updateSizingOptimization from "store/sizing_optimizations/sagas/updateSizingOptimization";
import deleteSizingOptimization from "store/sizing_optimizations/sagas/deleteSizingOptimization";
import copySizingOptimization from "store/sizing_optimizations/sagas/copySizingOptimization";

import runSizingOptimizationSimulation from 'store/sizing_optimizations/sagas/runSizingOptimizationSimulation';
import deleteSizingOptimizationResult from 'store/sizing_optimizations/sagas/deleteSizingOptimizationResult';
import cancelSizingOptimizationSimulation from 'store/sizing_optimizations/sagas/cancelSizingOptimizationSimulation';

export default function* auth() {
  yield all([
    fork(getSizingOptimization),
    fork(createSizingOptimization),
    fork(getSingleSizingOptimization),
    fork(updateSizingOptimization),
    fork(deleteSizingOptimization),
    fork(copySizingOptimization),
    fork(runSizingOptimizationSimulation),
    fork(deleteSizingOptimizationResult),
    fork(cancelSizingOptimizationSimulation),
    fork(getSizingOptimizationResult),
  ]);
}
