import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { updateSoilInformation as action } from "store/soil_informations/actions";
// api
import { updateSoilInformation as api } from "store/soil_informations/api";
import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload: { callback, finallyCallback, ...payload } }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success(response.data));

    if (callback) callback();
  } catch (error) {
    yield call(errorNotifications, error.response.data.data);
  } finally {
    if(finallyCallback) finallyCallback()
    yield put(action.fulfill());


  }
}

export default function* updateSoilInformation() {
  yield takeLatest(action.TRIGGER, saga);
}
