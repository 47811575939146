import { useEffect, useMemo, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import Select from "components/common/select/Select";
import {
  Bar,
  ComposedChart,
  CartesianGrid,
  Label,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
  Line,
  Text as RechartText,
  ResponsiveContainer,
} from "recharts";
import { default as TooltipMUI } from "@mui/material/Tooltip";
import html2canvas from "html2canvas";
import { errorNotifications } from "utils/errorNotifications";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import SimulationResultBodyMessages from "components/SimulationResultBody/SimulationResultBodyMessages";
import CustomTooltip from "components/CustomTooltip/CustomTooltip";
import CropCreateMessages from "pages/CropsCreate/CropCreateMessages";
import chartToCsv from "utils/chartToCsv";
import chartToPng from "utils/chartToPng";
import CustomTick from "components/CustomTick/CustomTick";
import { getETPTable } from "store/analisys/api";
import { useParams } from "react-router-dom";
import WaterBalanceResultMenuMessages from "./WaterBalanceResultMenuMessages";

const irrUnites = ["Fraction", "Daily PAR"];

class IrradianceContentProps {
  result: any;
  unite: "FRACTION" | "MM" = "FRACTION";
}

const ETPContent = ({ result, unite }: IrradianceContentProps) => {
  const intl = useIntl();

  const { client_id, project_id, water_balance_id, id } = useParams();
  const [tableValues, setTableValues] = useState([]);

  useEffect(() => {
    getETPTable({
      clientId: client_id,
      projectId: project_id,
      simulationId: water_balance_id,
      id: id,
    }).then((res) => setTableValues(res.data));
  }, [client_id, project_id, water_balance_id, id]);

  const [inactiveKeysPeriodETP, setInactiveKeyPeriodETP] = useState(
    Array<string>()
  );

  if (!tableValues.length) return <div style={{ height: "100vh" }} />;

  return (
    <div className={styled.list}>
      <div className={styled.chartContainer}>
        <div className={styled.optionContainer}>
          <div></div>
          <div className={styled.downloadContainer}>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintChartToPng
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToPng(
                  intl.formatMessage(SimulationResultBodyMessages.ETPPerPeriod)
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_image}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
            <TooltipMUI
              title={intl.formatMessage(
                SimulationResultBodyMessages.hintChartToCsv
              )}
            >
              <div
                className={styled.chartToPngButton}
                onClick={chartToCsv(
                  tableValues,
                  `${result.simulation.name} ETP per Stage`
                )}
              >
                <Icon
                  name={ICON_NAMES_ENUM.download_file}
                  className={styled.file__icon}
                />
              </div>
            </TooltipMUI>
          </div>
        </div>

        <div id={intl.formatMessage(SimulationResultBodyMessages.ETPPerPeriod)}>
          <TooltipMUI
            title={intl.formatMessage(SimulationResultBodyMessages.hintLegend)}
          >
            <div className={styled.chartTitle}>
              {intl.formatMessage(SimulationResultBodyMessages.ETPPerPeriod)}
            </div>
          </TooltipMUI>
          <ResponsiveContainer width="100%" height={300}>
            <ComposedChart
              data={tableValues.map((data: any) => ({
                ...data,
                zt: unite == "FRACTION" ? data.fraction_etp_zt : data.etp_zt,
                under_panel:
                  unite == "FRACTION"
                    ? data.fraction_etp_under_panel
                    : data.etp_under_panel,
                between_panel:
                  unite == "FRACTION"
                    ? data.fraction_etp_between_panel
                    : data.etp_between_panel,
                agri_pv:
                  unite == "FRACTION"
                    ? data.fraction_etp_agri_pv
                    : data.etp_agri_pv,
              }))}
              width={500}
              height={500}
              margin={{
                top: 10,
                right: 30,
                left: 20,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey={"period.name"}
                tick={<CustomTick />}
                height={75}
                interval={0}
              />
              <YAxis yAxisId="left" interval={0} orientation="left">
                <Label
                  style={{ textAnchor: "middle" }}
                  position={"insideLeft"}
                  angle={270}
                  fontSize={12}
                  value={intl.formatMessage(
                    SimulationResultBodyMessages.ETPAgriPVZone,
                    { unite: unite == "FRACTION" ? "%" : "mm" }
                  )}
                />
              </YAxis>
              <Tooltip content={CustomTooltip} />
              <Legend
                onClick={(o) => {
                  if (
                    !inactiveKeysPeriodETP.find(
                      (inactiveKey) => inactiveKey == o.dataKey
                    )
                  )
                    setInactiveKeyPeriodETP([
                      ...inactiveKeysPeriodETP,
                      o.dataKey,
                    ]);
                  else {
                    var temp = inactiveKeysPeriodETP;
                    temp.splice(
                      inactiveKeysPeriodETP.findIndex(
                        (inactiveKey) => inactiveKey == o.dataKey
                      ),
                      1
                    );
                    setInactiveKeyPeriodETP([...temp]);
                  }
                }}
              />
              <Bar
                type="monotone"
                dataKey={"zt"}
                name="Control Zone"
                yAxisId="left"
                fill={"#AA6666"}
                hide={
                  inactiveKeysPeriodETP.find(
                    (inactiveKey) => inactiveKey == "zt"
                  )
                    ? true
                    : false
                }
                unit={unite == "FRACTION" ? "%" : "mm"}
              />
              <Bar
                type="monotone"
                dataKey={"under_panel"}
                name="Under Panels"
                yAxisId="left"
                fill={"#66AA66"}
                hide={
                  inactiveKeysPeriodETP.find(
                    (inactiveKey) => inactiveKey == "under_panel"
                  )
                    ? true
                    : false
                }
                unit={unite == "FRACTION" ? "%" : "mm"}
              />
              <Bar
                type="monotone"
                dataKey={"between_panel"}
                name="Between Panels"
                yAxisId="left"
                fill={"#6666AA"}
                hide={
                  inactiveKeysPeriodETP.find(
                    (inactiveKey) => inactiveKey == "between_panel"
                  )
                    ? true
                    : false
                }
                unit={unite == "FRACTION" ? "%" : "mm"}
              />
              <Bar
                type="monotone"
                dataKey={"agri_pv"}
                name="AgriPV Zone"
                yAxisId="left"
                fill={"#444444"}
                hide={
                  inactiveKeysPeriodETP.find(
                    (inactiveKey) => inactiveKey == "agri_pv"
                  )
                    ? true
                    : false
                }
                unit={unite == "FRACTION" ? "%" : "mm"}
              />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div>
        <div className={styled.downloadContainer}>
          <div className={styled.chartTitle}>
            {intl.formatMessage(SimulationResultBodyMessages.ETPPerPeriod)}
          </div>
          <TooltipMUI
            title={intl.formatMessage(
              SimulationResultBodyMessages.hintChartToPng
            )}
          >
            <div
              className={styled.chartToPngButton}
              onClick={chartToPng(
                "Table " +
                  intl.formatMessage(
                    SimulationResultBodyMessages.ETPAgriPVZone,
                    {
                      unite: unite == "FRACTION" ? "%" : "mm",
                    }
                  )
              )}
            >
              <Icon
                name={ICON_NAMES_ENUM.download_image}
                className={styled.file__icon}
              />
            </div>
          </TooltipMUI>
        </div>
        <div
          id={
            "Table " +
            intl.formatMessage(SimulationResultBodyMessages.ETPAgriPVZone, {
              unite: unite == "FRACTION" ? "%" : "mm",
            })
          }
        >
          <table className={styled.dataTable}>
            <thead>
              <tr>
                <th rowSpan={2}>
                  <FormattedMessage {...CropCreateMessages.stageName} />
                </th>
                <th colSpan={4}>
                  <FormattedMessage
                    {...SimulationResultBodyMessages.ETPAgriPVZone}
                    values={{ unite: unite == "FRACTION" ? "%" : "mm" }}
                  />
                </th>
              </tr>
              <tr className={styled.specialCaseTable}>
                <th>
                  <FormattedMessage
                    {...WaterBalanceResultMenuMessages.controlZoneSubTitle}
                  />
                </th>
                <th>
                  <FormattedMessage
                    {...WaterBalanceResultMenuMessages.underPanelsSubTitle}
                  />
                </th>
                <th>
                  <FormattedMessage
                    {...WaterBalanceResultMenuMessages.betweenPanelsZoneSubTitle}
                  />
                </th>
                <th>
                  <FormattedMessage
                    {...WaterBalanceResultMenuMessages.agriPVZoneSubTitle}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {tableValues.map((values: any) => (
                <tr key={values.period.name}>
                  <td>{values.period.name}</td>
                  <td>
                    {unite == "FRACTION"
                      ? values.fraction_etp_zt
                      : values.etp_zt}
                  </td>
                  <td>
                    {unite == "FRACTION"
                      ? values.fraction_etp_under_panel
                      : values.etp_under_panel}
                  </td>
                  <td>
                    {unite == "FRACTION"
                      ? values.fraction_etp_between_panel
                      : values.etp_between_panel}
                  </td>
                  <td>
                    {unite == "FRACTION"
                      ? values.fraction_etp_agri_pv
                      : values.etp_agri_pv}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ETPContent;
