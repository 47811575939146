import { all, fork } from 'redux-saga/effects';
// sagas
import getSimulations from 'store/simulations/sagas/getSimulations';
import getSimulation from 'store/simulations/sagas/getSimulation';
import createSimulation from 'store/simulations/sagas/createSimulation';
import deleteSimulation from 'store/simulations/sagas/deleteSimulation';
import updateSimulation from 'store/simulations/sagas/updateSimulation';
import runSimulation from 'store/simulations/sagas/runSimulation';
import getSimulationResult from 'store/simulations/sagas/getSimulationResult';
import copySimulation from 'store/simulations/sagas/copySimulation';
import deleteSimulationResult from 'store/simulations/sagas/deleteSimulationResult';
import cancelSimulation from 'store/simulations/sagas/cancelSimulation';

export default function* simulations() {
  yield all([
    fork(getSimulations),
    fork(getSimulation),
    fork(createSimulation),
    fork(deleteSimulation),
    fork(updateSimulation),
    fork(runSimulation),
    fork(getSimulationResult),
    fork(copySimulation),
    fork(deleteSimulationResult),
    fork(cancelSimulation),
  ]);
}
