import React, { useEffect, useState } from "react";
import MainContent from "components/MainContent/MainContent";
import EditName from "components/EditName/EditName";
import batchesCreateMessages from "pages/BatchesCreate/BatchesCreateMessages";
import styled from "pages/BatchesCreate/styled.module.scss";
import Button from "components/common/button/Button";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import { ReactComponent as Save } from "assets/icons/save.svg";
import BatchesBody, { IBatchesState } from "components/BatchesBody/BatchesBody";
import { FormikHelpers, useFormik } from "formik";
import BatchesSchema from "pages/BatchesCreate/BatchesSchema";
import { ROUTES } from "constants/routes";
import {
  deleteBatch,
  runBatchSimulation,
  updateBatch,
} from "store/batches/actions";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getBatch } from "store/batches/api";
import { IInterestNameVariants } from "components/ParametersInterest/useParamsList";
import { Loader } from "components/common/loader/Loader";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import { usePrompt } from "hooks/usePromt";
import { isEqual, cloneDeep } from "lodash";
import { useUpdateNavigation } from "hooks/useUpdateNavigation";
import { getDefaultValues } from "pages/BatchesCreate/BatchesCreate";

const BatchesEdit = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client_id, project_id, id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteWarning, setIsDeleteWarning] = useState(false);
  const [initialValue, setInitialValue] = useState(getDefaultValues(intl));
  const [isNavigationReady, setIsNavigationReady] = useState(false);
  useUpdateNavigation(() => setIsNavigationReady(true));

  const getCurrentNavigation = () => {
    getBatch({
      clientId: client_id,
      projectId: project_id,
      batchId: id,
    })
      .then(({ data }) => {
        const currentValue: IBatchesState = {
          name: data.name,
          base_simulation_id: data.simulation.id,
          frequency: data.frequency,
          interests: !!data.rows.length
            ? (Object.keys(data.rows[0]) as IInterestNameVariants[])
            : [],
          rows: data.rows,
        };
        formik.setValues(cloneDeep(currentValue));
        setInitialValue(currentValue);
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (isNavigationReady) {
      getCurrentNavigation();
    }
  }, [isNavigationReady, id]);

  const onHideWarning = () => setIsDeleteWarning(false);

  const onShowWarning = () => setIsDeleteWarning(true);

  const onBack = () =>
    navigate(
      ROUTES.BATCHES.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );

  const onUpdate = (
    values: IBatchesState,
    actions: FormikHelpers<IBatchesState>
  ) => {

    var obj_out = {...values}

    if (obj_out.interests.find((interest) => interest == "steering_algorithm_id"))
      obj_out.rows.map((row) => {if (row.steering_algorithm_id == undefined) row.steering_algorithm_id = null})

    dispatch(
      updateBatch({
        ...values,
        clientId: client_id,
        projectId: project_id,
        batchId: id,
        callback: onBack,
        finallyCallback: () => actions.setSubmitting(false),
      })
    );
  };

  const onDelete = () => {
    dispatch(
      deleteBatch({
        clientId: client_id,
        projectId: project_id,
        batchId: id,
        callback: onBack,
      })
    );
  };

  const formik = useFormik({
    initialValues: getDefaultValues(intl),
    onSubmit: onUpdate,
    validationSchema: BatchesSchema(intl),
  });

  const isDataChanged = !isEqual(formik.values, initialValue);

  usePrompt(isDataChanged && !formik.isSubmitting && !isDeleteWarning);

  const toResultPage = (result_id: string) =>
    navigate(
      ROUTES.BATCHES_RESULT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":batch_id", id as string)
        .replace(":id", result_id)
    );

  const runSimulation = () => {
    dispatch(
      runBatchSimulation({
        clientId: client_id,
        projectId: project_id,
        batchId: id,
        callback: toResultPage,
      })
    );
  };

  const onRunSimulation = () => {
    if (isDataChanged) {
      setInitialValue(formik.values);

      dispatch(
        updateBatch({
          ...formik.values,
          clientId: client_id,
          projectId: project_id,
          batchId: id,
          callback: runSimulation,
        })
      );
    } else {
      runSimulation();
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      {isLoading && <Loader />}

      <MainContent
        header={
          <>
            <EditName
              onFocus={(event) => {
                event.target.select();
              }}
              placeholder={intl.formatMessage(batchesCreateMessages.name)}
              onChange={formik.handleChange}
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              errorMessage={
                formik.touched.name ? (formik.errors.name as string) : ""
              }
            />

            <div className={styled.flex}>
              <Button
                variant="text"
                iconBefore={<Delete />}
                onClick={onShowWarning}
                className={styled.deleteButton}
              >
                <FormattedMessage {...commonMessages.delete} />
              </Button>

              <Button variant="text" onClick={onBack}>
                <FormattedMessage {...commonMessages.cancel} />
              </Button>

              <Button
                iconBefore={<Save />}
                type="submit"
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting || !isDataChanged}
              >
                <FormattedMessage {...commonMessages.save} />
              </Button>
            </div>
          </>
        }
      >
        <BatchesBody formik={formik} onRunSimulation={onRunSimulation} />
        <LeaveModalWindow
          type={LeaveModalEnum.DELETE}
          onDelete={onDelete}
          show={isDeleteWarning}
          onHide={onHideWarning}
        />
      </MainContent>
    </form>
  );
};

export default BatchesEdit;
