import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { deleteBatchWaterBalanceResult as action } from "store/batches_water_balance/actions";
// api
import { deleteBatchWaterBalanceResult as api } from "store/batches_water_balance/api";
import {errorNotifications} from "utils/errorNotifications";

function* saga({ payload: { callback, ...payload } }) {
  try {
    yield put(action.request());

    yield call(api, payload);

    yield put(action.success(payload));

    if (callback) callback();
  } catch (error) {
    yield call(errorNotifications, error.response.data.data);
    yield put(action.failure(error));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* deleteBatchWaterBalanceResult() {
  yield takeLatest(action.TRIGGER, saga);
}
