import routine from 'store/services/routine';

export const getBatches = routine('GET_BATCHES');
export const createBatch = routine('CREATE_BATCH');
export const copyBatch = routine('COPY_BATCH');
export const deleteBatch = routine('DELETE_BATCH');
export const updateBatch = routine('UPDATE_BATCH');
export const getParametersOfInterest = routine('GET_PARAMETERS_INTEREST');

export const runBatchSimulation = routine('RUN_BATCH_SIMULATION');
export const cancelBatchSimulation = routine('CANCEL_BATCH_SIMULATION');
export const getBatchResults = routine('GET_BATCH_RESULTS');
export const deleteBatchResult = routine('DELETE_BATCH_RESULT');
export const getBatchSimulationResult = routine('GET_BATCH_SIMULATION_RESULT');
export const resetBatchResults = routine('RESET_BATCH_RESULTS');
export const resetBatchSimulationResult = routine('RESET_BATCH_SIMULATION_RESULTS');


