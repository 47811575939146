import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getUsers = (id) =>
  apiGET({
    url: `clients/${id}/users`,
  });

export const getUser = ({ user_id, client_id }) =>
  apiGET({
    url: `clients/${client_id}/users/${user_id}`,
  });

export const createUser = ({ client_id, ...data }) =>
  apiPOST({
    url: `clients/${client_id}/users`,
    data,
  });

export const deleteUser = ({ user_id, client_id }) =>
  apiDELETE({
    url: `clients/${client_id}/users/${user_id}`,
  });

export const updateUser = ({ client_id, user_id, ...data }) =>
  apiPATCH({
    url: `clients/${client_id}/users/${user_id}`,
    data,
  });

export const activateUser = ({ client_id, user_id }) =>
  apiPOST({
    url: `clients/${client_id}/users/${user_id}/activate`,
  });

export const deactivateUser = ({ client_id, user_id }) =>
  apiPOST({
    url: `clients/${client_id}/users/${user_id}/deactivate`,
  });
