import { useState } from "react";
import { IRowField, IValues } from "./SteeringAlgorithmsCreateTypes";
import { useDispatch } from "react-redux";
import { createSteeringAlgorithm } from "store/steering_algorithms/actions";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { FormikHelpers, useFormik } from "formik";
import SteeringAlgorithmsSchema from "./SteeringAlgorithmSchema";
import { useIntl } from "react-intl";
import SteeringAlgorithmsCreateMessages from "./SteeringAlgorithmsCreateMessages";
import { successNotifications } from "utils/successNotifications";
import CommonMessages from "components/common/CommonMessages";

export const getInitialFormValue = (intl: any): IValues => {
  return { name: intl.formatMessage(SteeringAlgorithmsCreateMessages.steeringAlgorithmUnnamed), crop_id: 0, row: [] };
};

function SteeringAlgorithmsCreateHook() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl();
  const { client_id, project_id } = useParams();

  const [IsOpenModal, setIsOpenModal] = useState(false);
  const [isEditFunc, setIsEditFunc] = useState<boolean>(false);
  const [currentRow, setCurrentRow] = useState<IRowField | undefined>();

  const onEditField = async (row: IRowField) => {
    await setCurrentRow(row);
    await setIsEditFunc(true);
    onShowModal();
  };
  const onEditSave = async (row: IRowField) => {
    const temp = [...formik.values.row];
    let thisRow = temp.findIndex((item) => item.id === row.id);

    if (thisRow >= 0) {
      temp[thisRow] = row;
      await formik.setFieldValue("row", temp);
      onHideModal();
    }
  };
  const resetForm = () => {
    var temp = { ...formik.values };

    temp.row.splice(0, temp.row.length);
  };
  const onAddNewRow = async () => {
    await setCurrentRow(undefined)
    await setIsEditFunc(false);
    await setIsOpenModal(true);
  };
  const onShowModal = () => {
    setIsOpenModal(true);
  };
  const onHideModal = () => {
    setIsOpenModal(false);
  };
  const onDelete = (id: number) => {
    const temp = [...formik.values.row];
    temp.splice(
      temp.findIndex((element) => element.id === id),
      1
    );
    formik.setFieldValue("row", temp);
  };
  const onSaveRow = (v: IRowField) => {
    const temp = [...formik.values.row];
    temp.push({
      id: v.id,
      value: v.value,
      type: v.type,
      state: v.state,
      period_id: v.period_id,
    });
    formik.setFieldValue("row", temp);
    onHideModal();
  };
  const onCopyRow = (v: IRowField) => {
    const temp = [...formik.values.row];
    temp.push({
      id: temp.length,
      value: v.value,
      type: v.type,
      state: v.state,
      period_id: v.period_id,
    });
    formik.setFieldValue("row", temp);
    onHideModal();
  };
  const onDeleteRow = (v: IRowField) => {
    const temp = [...formik.values.row];
    const index = temp.findIndex((item) => item === v);
    temp.splice(index, 1);
    for (let i = index; i < temp.length; i++) temp[i].id -= 1;

    formik.setFieldValue("row", temp);
    onHideModal();
  };
  const onBack = () =>
    navigate(
      ROUTES.STEERING_ALGORITHM.replace(
        ":client_id",
        client_id as string
      ).replace(":project_id", project_id as string)
    );
    const onEditPage = (id: number | string) =>
    navigate(
      ROUTES.STEERING_ALGORITHM_EDIT.replace(
        ":client_id",
        client_id as string
      ).replace(":project_id", project_id as string).replace(":id", id as string)
    );

  const createSteeringAlgorithmFunc = (
    values: IValues,
    actions: FormikHelpers<IValues>
  ) => {
    const rows = values.row.map((row) => ({
      id: row.id,
      period_id: row.period_id,
      row_type: row.type,
      row_state: row.state,
      row_value: JSON.stringify(row.value),
    }));
    const steering_algorithm = {
      name: values.name,
      crop_id: values.crop_id,
      rows: rows,
      project_id: project_id,
    };
    dispatch(
      createSteeringAlgorithm({
        ...steering_algorithm,
        clientId: client_id,
        projectId: project_id,
        callback: ({data}: any) => {
          successNotifications({title: intl.formatMessage(CommonMessages.create), message: intl.formatMessage(CommonMessages.successCreate, {objet_type: intl.formatMessage(CommonMessages.steering)})})
          onEditPage(data.id)
        },
        finallyCallback: () => actions.setSubmitting(false),
      })
    );
  };

  const formik = useFormik({
    validationSchema: SteeringAlgorithmsSchema(intl),
    onSubmit: createSteeringAlgorithmFunc,
    initialValues: getInitialFormValue(intl),
  });

  return {
    models: {
      IsOpenModal,
      isEditFunc,
      currentRow,
      client_id,
      project_id,
      formik,
    },
    commands: {
      resetForm,
      onSaveRow,
      onDelete,
      onAddNewRow,
      onCopyRow,
      onDeleteRow,
      onEditSave,
      onEditField,
      onHideModal,
    },
  };
}

export { SteeringAlgorithmsCreateHook };
