import { all, fork } from 'redux-saga/effects';
// sagas
import getBatches from 'store/batches/sagas/getBatches';
import copyBatch from 'store/batches/sagas/copyBatch';
import createBatch from 'store/batches/sagas/createBatch';
import deleteBatch from 'store/batches/sagas/deleteBatch';
import updateBatch from 'store/batches/sagas/updateBatch';
import getParametersOfInterest from 'store/batches/sagas/getParametersOfInterest';
import runBatchSimulation from 'store/batches/sagas/runBatchSimulation';
import getBatchResults from 'store/batches/sagas/getBatchResults';
import getBatchSimulationResult from 'store/batches/sagas/getBatchSimulationResult';
import deleteBatchResult from 'store/batches/sagas/deleteBatchResult';
import cancelBatchSimulation from 'store/batches/sagas/cancelBatchSimulation';

export default function* auth() {
  yield all([
    fork(getBatches),
    fork(copyBatch),
    fork(createBatch),
    fork(deleteBatch),
    fork(updateBatch),
    fork(getParametersOfInterest),
    fork(runBatchSimulation),
    fork(getBatchResults),
    fork(getBatchSimulationResult),
    fork(deleteBatchResult),
    fork(cancelBatchSimulation),
  ]);
}
