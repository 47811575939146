import { defineMessages } from "react-intl";

export default defineMessages({
  datasetFile: {
    id: "DatasetsCreateMessages.datasetFile",
    defaultMessage: "Dataset File",
  },
  comment: {
    id: "DatasetsCreateMessages.comment",
    defaultMessage: "Comment",
  },
  datasetType: {
    id: "DatasetsCreateMessages.datasetType",
    defaultMessage: "Dataset type",
  },
  samplingRate: {
    id: "DatasetsCreateMessages.samplingRate",
    defaultMessage: "Sampling rate",
  },
  timezone: {
    id: "DatasetsCreateMessages.timezone",
    defaultMessage: "Timezone",
  },
  chartTitle: {
    id: "DatasetsCreateMessages.chartTitle",
    defaultMessage: "Monthly :value_name",
  },
  dhi: {
    id: "DatasetsCreateMessages.dhi",
    defaultMessage: "diffuse light component",
  },
  ghi: {
    id: "DatasetsCreateMessages.ghi",
    defaultMessage: "direct light component",
  },
});
