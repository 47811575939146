import { apiGET, apiPOST, apiPATCH } from "store/services/api";

export const signIn = ({ email, password }) => {
  const data = new FormData();
  data.append("username", email);
  data.append("password", password);

  return apiPOST({
    url: "/auth/login",
    data: data,
  });
};

export const auth = () =>
  apiGET({
    url: "/users/me",
  });

export const editProfile = (data) =>
  apiPATCH({
    url: "/users/me",
    data,
  });

export const setPassword = ({ query, ...data }) =>
  apiPOST({
    url: `/auth/set-password${query}`,
    data,
  });

export const resetPassword = () =>
  apiPOST({
    url: "/auth/new-password",
  });
