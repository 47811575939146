import { Text as RechartText } from "recharts";

const CustomTick = (props: any) => {
  const { x, y, stroke, payload, offset } = props;
  const str = "";
  return (
    <g transform={`translate(${x},${y - 10})`}>
      <RechartText
        x={0}
        dy={payload.index % 2 ? 12 : 50}
        // angle={-65}
        width={150}
        fontSize={12}
        textAnchor="middle"
        verticalAnchor="start"
        // breakAll
      >
        {payload.value.replaceAll("-", " ")}
      </RechartText>
    </g>
  );
};

export default CustomTick;
