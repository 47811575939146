import html2canvas from "html2canvas";
import { errorNotifications } from "./errorNotifications";
import JSZip from "jszip";

const chartToPng = (chartId: string | string[]) => () => {
    if (typeof chartId == "string") {
      const chartElement = document.getElementById(chartId);

      html2canvas(chartElement!)
        .then((canvas) => {
          const dataURL = canvas.toDataURL("image/png");

          const downloadLink = document.createElement("a");
          downloadLink.href = dataURL;
          downloadLink.download = `${chartId}.png`;

          downloadLink.click();
        })
        .catch((error) => {
          errorNotifications(`Erreur lors de la création du PNG : '${error}'`);
        });
    } else {
      const zip = new JSZip();
      const promises = chartId.map((id) => {
        const chartElement = document.getElementById(id);
        return html2canvas(chartElement!)
          .then((canvas) => {
            const dataURL = canvas.toDataURL("image/png");
            zip.file(`${id}.png`, dataURL.split(";base64,").pop()!, {
              base64: true,
            });
          })
          .catch((error) => {
            errorNotifications(
              `Erreur lors de la création du PNG pour ${id}: '${error}'`
            );
          });
      });

      Promise.all(promises)
        .then(() => {
          zip
            .generateAsync({ type: "blob" })
            .then((content: any) => {
              const downloadLink = document.createElement("a");
              downloadLink.href = URL.createObjectURL(content);
              downloadLink.download = "charts.zip";
              downloadLink.click();
            })
            .catch((error: any) => {
              errorNotifications(
                `Erreur lors de la génération du fichier ZIP : '${error}'`
              );
            });
        })
        .catch((error) => {
          errorNotifications(`Erreur lors de la création des PNG : '${error}'`);
        });
    }
  };

export default chartToPng;