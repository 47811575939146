import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getRecapTable = ({
  clientId,
  projectId,
  simulationId,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/recap_table/${simulationId}/${id}`,
  });

export const getAnalisysTable = ({
  clientId,
  projectId,
  simulationId,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/analisys_table/${simulationId}/${id}`,
  });

export const getIrradianceTable = ({
  clientId,
  projectId,
  simulationId,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/irradiance_table/${simulationId}/${id}`,
  });

export const getProductionTable = ({
  clientId,
  projectId,
  simulationId,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/production_table/${simulationId}/${id}`,
  });

export const getETPTable = ({
  clientId,
  projectId,
  simulationId,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/ETP_table/${simulationId}/${id}`,
  });

export const getAvailableWaterStockTable = ({
  clientId,
  projectId,
  simulationId,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/available_water_stock_table/${simulationId}/${id}`,
  });

export const getWaterStressTable = ({
  clientId,
  projectId,
  simulationId,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/water_stress_table/${simulationId}/${id}`,
  });

export const getWaterConsumptionGraph = ({
  clientId,
  projectId,
  simulationId,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/water_consumption_graph/${simulationId}/${id}`,
  });

export const getWaterConsumptionTable = ({
  clientId,
  projectId,
  simulationId,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/water_consumption_table/${simulationId}/${id}`,
  });

export const getWaterDeficitGraph = ({
  clientId,
  projectId,
  simulationId,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/water_deficit_graph/${simulationId}/${id}`,
  });

export const getWaterDeficitTable = ({
  clientId,
  projectId,
  simulationId,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/water_deficit_table/${simulationId}/${id}`,
  });

export const getThermalStressTable = ({
  clientId,
  projectId,
  simulationId,
  id,
  ...data
}) =>
  apiGET({
    url: `clients/${clientId}/projects/${projectId}/thermal_stress_table/${simulationId}/${id}`,
  });
