import { all, fork } from 'redux-saga/effects';
// sagas
import getProjectsList from 'store/projects/sagas/getProjectsList';
import createProject from 'store/projects/sagas/createProject';
import deleteProject from 'store/projects/sagas/deleteProject';
import updateProject from 'store/projects/sagas/updateProject';
import getProjectSort from 'store/projects/sagas/getProjectSort';

export default function* auth() {
  yield all([
    fork(getProjectsList),
    fork(createProject),
    fork(deleteProject),
    fork(updateProject),
    fork(getProjectSort),
  ]);
}
