import React from "react";
import styled from "components/SimulationDropItem/styled.module.scss";
import Line from "components/SimulationDropItem/Line";

const DatasetRenderList = ({ selected }: { selected: any }) => {
  return (
    <ul className={styled.ul}>
      <Line title="Dataset type" value={selected.dataset_type} />
      <Line title="Sampling rate" value={selected.frequency} />
      {/* <Line title="Timezone" value={selected.timezone} /> */}
    </ul>
  );
};

export default DatasetRenderList;
