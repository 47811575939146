import { defineMessages } from "react-intl";

export default defineMessages({
  day_stress_thermique: {
    id: "DatasetAnalisysMessages.day_stress_thermique",
    defaultMessage: "Days in Thermal Stress",
  },
  deficit_hydrique: {
    id: "DatasetAnalisysMessages.deficit_hydrique",
    defaultMessage: "Water Deficit",
  },
  total_precipitation: {
    id: "DatasetAnalisysMessages.total_precipitation",
    defaultMessage: "Tatal Precipitation",
  },
  day_intense_precipitation: {
    id: "DatasetAnalisysMessages.day_intense_precipitation",
    defaultMessage: "Days with Intentse Precipitation",
  },
  annual_mean_tempeture: {
    id: "DatasetAnalisysMessages.annual_mean_tempeture",
    defaultMessage: "Annual Mean Tempeture",
  },
});
