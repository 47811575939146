import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import AuthLayout from "components/AuthLayout/AuthLayout";
import Button from "components/common/button/Button";
import Input from "components/common/input/Input";
import RegisterSchema from "./schema";
import styled from "./styled.module.scss";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { setPassword } from "store/user/actions";
import registerMessages from "pages/Register/RegisterMessages";

interface IRegisterForm {
  username: string;
  password: string;
  confirm_password: string;
}

const Register = () => {
  const [isShowPass, setShowPass] = useState(false);
  const navigate = useNavigate();
  const { search } = useLocation();
  const intl = useIntl();
  const dispatch = useDispatch();

  const onSubmit = async ({ username, password }: IRegisterForm) => {
    dispatch(
      setPassword({
        query: search,
        full_name: username,
        password,
        callback: () => navigate(ROUTES.CLIENTS_LIST),
      })
    );
  };

  const formik = useFormik<IRegisterForm>({
    validationSchema: RegisterSchema(intl),
    onSubmit,
    initialValues: {
      username: "",
      password: "",
      confirm_password: "",
    },
  });

  const getInputProps = (key: keyof IRegisterForm) => ({
    name: key,
    value: formik.values[key] as string | number,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    errorMessage: formik.touched[key] ? (formik.errors[key] as string) : "",
  });

  return (
    <AuthLayout>
      <Logo className={styled.logo} />
      <h1 className={styled.title}>
        <FormattedMessage {...registerMessages.welcome} />
      </h1>
      <p className={styled.subtitle}>
        <FormattedMessage {...registerMessages.subtitle} />
      </p>
      <form className={styled.form} onSubmit={formik.handleSubmit}>
        <Input
          label={intl.formatMessage(registerMessages.usernameLabel)}
          placeholder={intl.formatMessage(registerMessages.usernamePlaceholder)}
          {...getInputProps("username")}
        />
        <Input
          type={isShowPass ? "text" : "password"}
          label={intl.formatMessage(registerMessages.passwordLabel)}
          placeholder={intl.formatMessage(registerMessages.passwordPlaceholder)}
          {...getInputProps("password")}
          afterIcon={
            <button
              tabIndex={-1}
              type="button"
              className={styled["eyes-wrapper"]}
              onClick={() => setShowPass(!isShowPass)}
            >
              <Icon
                name={
                  isShowPass
                    ? ICON_NAMES_ENUM.eyes_open
                    : ICON_NAMES_ENUM.eyes_close
                }
                className={styled.eyes}
              />
            </button>
          }
        />
        <Input
          type={isShowPass ? "text" : "password"}
          label={intl.formatMessage(registerMessages.confirmPasswordLabel)}
          placeholder={intl.formatMessage(
            registerMessages.confirmPasswordPlaceholder
          )}
          {...getInputProps("confirm_password")}
          afterIcon={
            <button
              tabIndex={-1}
              type="button"
              className={styled["eyes-wrapper"]}
              onClick={() => setShowPass(!isShowPass)}
            >
              <Icon
                name={
                  isShowPass
                    ? ICON_NAMES_ENUM.eyes_open
                    : ICON_NAMES_ENUM.eyes_close
                }
                className={styled.eyes}
              />
            </button>
          }
        />

        <Button type="submit" fullwidth>
          <span className={styled["button-text"]}>
            <FormattedMessage {...registerMessages.createAccount} />
          </span>
        </Button>
      </form>
    </AuthLayout>
  );
};

export default Register;
