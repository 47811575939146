import React, { useState } from "react";
import styled from "./CropsEdit.module.scss";
import EditName from "components/EditName/EditName";
import Button from "components/common/button/Button";
import commonMessages from "components/common/CommonMessages";
import { FormattedMessage, useIntl } from "react-intl";
import cropsCreateMessages from "pages/CropsCreate/CropCreateMessages";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import clsx from "clsx";
import { AddModalPeriodCropsTable } from "components/AddModalPeriodCropsTable/AddModalPeriodCropsTable";
import { CropsEditHook } from "./CropsEditHook";
import MainContent from "components/MainContent/MainContent";
import addModalPeriodCropsTable from "components/AddModalPeriodCropsTable/AddModalPeriodCropsTableMessages";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import { usePrompt } from "hooks/usePromt";
import { isEqual } from "lodash";
import { useSelector } from "react-redux";
import { getCropsItem } from "store/crops/selectors";
import { SpanError } from "components/common/span-error/SpanError";
import { ICropType } from "pages/CropsCreate/CropsCreateTypes";
import { Tooltip } from "@mui/material";
import Select from "components/common/select/Select";
import DatePicker from "components/common/datepicker/DatePicker";
import Input from "components/common/input/Input";

function CropsEdit() {
  const navigate = useNavigate();
  const intl = useIntl();
  const [show, onShow] = useState(false);
  const crop = useSelector(getCropsItem);
  const {
    models: {
      IsOpenModal,
      isEditFunc,
      currentPeriod,
      client_id,
      project_id,
      isLoading,
      formik,
      cropTypeOptions,
    },
    commands: {
      onSavePoint,
      onDelete,
      onAddNewPeriod,
      onEditSave,
      onEditField,
      onHideModal,
      onDeleteCrop,
      onUpdate,
      onMoveDown,
      onMoveUp,
      onSelect,
    },
  } = CropsEditHook();

  const isDataChanged = (): boolean => {
    if (crop) {
      if (formik.values.name != crop.name) return true;
      if (formik.values.period.length != crop.periods.length) return true;
      if (formik.values.base_degree_day != crop.periods[0].base_degree_day) return true;
      if (formik.values.sowing_date?.format('yyyy-MM-DD') != 'Invalid date' && formik.values.sowing_date?.format("yyyy-MM-DD") != crop.periods[0].sowing_date) return true
      if (
        formik.values.period
          .map((period, index) => {
            const keys = Object.keys(period);
            const values = Object.values(period);
            for (let i = 0; i < keys.length; i++) {
              if (
                keys[i] == "starting" ||
                keys[i] == "date" ||
                keys[i] == "ending"
              )
                continue;
              else if (values[i] != crop.periods[index][keys[i]]) return true;
            }
            return false;
          })
          .find((item) => item)
      )
        return true;
    }

    return false;
  };

  const monthList = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const displayDate = (date: string | undefined) => {
    if (!date)
      return ''
    const month = monthList[Number(date.substring(5).split('-')[0]) - 1]
    return `${date.substring(5).split('-')[1]}  ${month}`
  }

  usePrompt(isDataChanged() && !formik.isSubmitting && !show);


  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContent
        header={
          <>
            <EditName
              onFocus={(event) => {
                event.target.select();
              }}
              placeholder={intl.formatMessage(cropsCreateMessages.cropName)}
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              errorMessage={formik.touched.name ? formik.errors.name : ""}
              onBlur={formik.handleBlur}
            />
            <div className={styled.flex}>
              <Button
                onClick={() => onShow(true)}
                iconBefore={<Icon name={ICON_NAMES_ENUM.delete} />}
                variant="text"
                className={styled.deleteButton}
              >
                <FormattedMessage {...commonMessages.delete} />
              </Button>
              <Button
                variant="text"
                onClick={() =>
                  navigate(
                    ROUTES.CROPS.replace(
                      ":client_id",
                      client_id as string
                    ).replace(":project_id", project_id as string)
                  )
                }
              >
                <FormattedMessage {...commonMessages.cancel} />
              </Button>

              <Button
                iconBefore={<Icon name={ICON_NAMES_ENUM.save} />}
                type="submit"
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting || !isDataChanged()}
              >
                <FormattedMessage {...commonMessages.save} />
              </Button>
            </div>
          </>
        }
      >
        <div>
        <h2 className={styled["section-title"]}>
            <FormattedMessage {...cropsCreateMessages.developmentStagesDefinitions} />
          </h2>
          <div className={styled.selector}>
            <Select
              options={cropTypeOptions}
              value={cropTypeOptions.find(
                ({ value }) => value === formik.values.crop_type
              )}
              onChange={onSelect("crop_type")}
            />
          </div>

          {formik.values.crop_type == ICropType.degreeDays && (
            <div className={styled.degreeDaysCalculation}>
              <h2 className={styled["section-title"]}>
                <FormattedMessage {...cropsCreateMessages.degreeDaysCalculation} />
              </h2>
              <div className={styled.flex}>
                <div className={styled.degreeInfoElement}>
                  <Tooltip title={intl.formatMessage(cropsCreateMessages.sowingDateHelp)} placement='top'>
                    <h3 className={styled["selector-title"]}>
                      <FormattedMessage {...cropsCreateMessages.sowingDate} />
                      <div className={styled["help-icon"]}>
                        <Icon
                          name={ICON_NAMES_ENUM.help_circle}
                          className={styled.file__icon}
                        />
                      </div>
                    </h3>
                  </Tooltip>
                  <DatePicker
                    openDown={false}
                    id="sowing_date"
                    onChange={(date) =>
                      formik.setFieldValue("sowing_date", date)
                    }
                    date={formik.values.sowing_date}
                    errorMessage={
                      formik.touched.sowing_date
                        ? formik.errors.sowing_date
                        : ""
                    }
                  />
                </div>
                <div className={styled.degreeInfoElement}>
                  <h3 className={styled["selector-title"]}>
                    <FormattedMessage {...cropsCreateMessages.baseTemperature} />
                  </h3>
                  <Input
                    type="number"
                    integerNumber
                    id="base_degree_day"
                    onChange={formik.handleChange}
                    value={formik.values.base_degree_day}
                    errorMessage={
                      formik.touched.base_degree_day
                        ? formik.errors.base_degree_day
                        : ""
                    }
                  />
                </div>
              </div>
            </div>
          )}
          <h2 className={styled["section-title"]}>
            <FormattedMessage {...cropsCreateMessages.developmentStages} />
          </h2>

          <div style={{ display: "flex" }}>
            <table className={styled.table}>
              <thead>
                <tr>
                  <th>
                    <FormattedMessage {...cropsCreateMessages.stageName} />
                  </th>
                  <th>
                    <FormattedMessage {...cropsCreateMessages.cropHeight} />
                  </th>
                  <th>
                    <FormattedMessage
                      {...(formik.values.crop_type == ICropType.normalDates
                        ? cropsCreateMessages.startDate
                        : cropsCreateMessages.startDegreeDay)}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      {...(formik.values.crop_type == ICropType.normalDates
                        ? cropsCreateMessages.endDate
                        : cropsCreateMessages.endDegreeDay)}
                    />
                  </th>
                  <th>
                    <FormattedMessage
                      {...cropsCreateMessages.culturalCoefficient}
                    />
                  </th>
                  <th></th>
                </tr>
              </thead>
              {!isLoading && (
                <tbody>
                  {formik.values.period
                    // .sort((a, b) => a.rank - b.rank)
                    .map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div
                            className={clsx(styled["td-element"], {
                              [styled.error]:
                                formik.touched.period &&
                                formik.errors.period &&
                                typeof formik.errors.period == "object" &&
                                formik.errors.period[index] &&
                                typeof formik.errors.period[index] ==
                                  "object" &&
                                (formik.errors.period[index] as any).name &&
                                typeof (formik.errors.period[index] as any)
                                  .name == "string",
                            })}
                          >
                            {item.name}
                          </div>
                          <SpanError
                            errorMessage={
                              formik.touched.period &&
                              typeof formik.touched.period == "object" &&
                              formik.touched.period[index] &&
                              formik.touched.period[index].name &&
                              formik.errors.period &&
                              typeof formik.errors.period == "object" &&
                              formik.errors.period[index] &&
                              typeof formik.errors.period[index] == "object" &&
                              (formik.errors.period[index] as any).name
                                ? ((formik.errors.period[index] as any)
                                    .name as string)
                                : undefined
                            }
                          />
                        </td>
                        <td>
                          <div className={styled["td-element"]}>
                            {item.crop_height ? item.crop_height : 0}
                          </div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>
                            {formik.values.crop_type == ICropType.normalDates
                              ? displayDate(item.starting)
                              : item.start_degree_day}
                          </div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>
                            {formik.values.crop_type == ICropType.normalDates
                              ? displayDate(item.ending)
                              : item.end_degree_day}
                          </div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>
                            {item.crop_coefficient}
                          </div>
                        </td>
                        <td>
                          <div className={styled["td-element"]}>
                            <div className={styled.row}>
                              <button
                                className={styled.button}
                                onClick={() => onEditField(item)}
                                type="button"
                              >
                                <Icon name={ICON_NAMES_ENUM.edit} />
                              </button>
                              <button
                                className={clsx(
                                  styled.button,
                                  styled.deleteButton
                                )}
                                type="button"
                                onClick={() => onDelete(item.id)}
                              >
                                <Icon name={ICON_NAMES_ENUM.delete} />
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
            <div className={styled["order-selector"]}>
              {formik.values.period
                .map((item, index) => (
                  <div className={styled.item} key={index.toString()}>
                    <button
                      className={clsx(styled.button)}
                      type="button"
                      onClick={() => {
                        onMoveUp(index);
                      }}
                      style={{ transform: "rotateZ(180deg)" }}
                    >
                      <Icon name={ICON_NAMES_ENUM.arrow_down} />
                    </button>
                    <button
                      className={clsx(styled.button)}
                      type="button"
                      onClick={() => {
                        onMoveDown(index);
                      }}
                    >
                      <Icon name={ICON_NAMES_ENUM.arrow_down} />
                    </button>
                  </div>
                ))}
            </div>
          </div>

          <SpanError
            errorMessage={
              formik.touched.period && typeof formik.errors.period == "string"
                ? (formik.errors.period as string)
                : undefined
            }
          />

          <Button
            iconBefore={<Icon name={ICON_NAMES_ENUM.plus_circle} />}
            onClick={onAddNewPeriod}
            variant="text"
            className={styled["add-period"]}
          >
            <FormattedMessage {...cropsCreateMessages.addNewStage} />
          </Button>

          <AddModalPeriodCropsTable
            title={
              isEditFunc
                ? intl.formatMessage(addModalPeriodCropsTable.editPeriod)
                : intl.formatMessage(addModalPeriodCropsTable.addPeriod)
            }
            isEdit={isEditFunc}
            show={IsOpenModal}
            onHide={onHideModal}
            onSave={onSavePoint}
            onEdit={onEditSave}
            periods={formik.values.period}
            currentPeriod={currentPeriod}
            type={formik.values.crop_type}
          />
        </div>
      </MainContent>
      <LeaveModalWindow
        type={LeaveModalEnum.DELETE}
        onSave={formik.handleSubmit}
        onDelete={onDeleteCrop}
        onClose={() => onShow(false)}
        show={show}
        onHide={() => onShow(false)}
      />
    </form>
  );
}

export { CropsEdit };
