import React from "react";
import Button from "components/common/button/Button";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import styled from "./LeaveModalWindow.module.scss";
import commonMessages from "components/common/CommonMessages";
import { FormattedMessage, useIntl } from "react-intl";
import LeaveModalWindowMessage from "./LeaveModalWindowMessages";
interface ISaveTemplate {
  onClose?: () => void;
  onSave?: () => void;
  isLoading?: boolean;
}

function SaveTemplate({ isLoading, onClose, onSave }: ISaveTemplate) {
  return (
    <>
      <Icon name={ICON_NAMES_ENUM.warning} />
      <h3 className={styled.title}>
        <FormattedMessage {...LeaveModalWindowMessage.titleSave} />
      </h3>
      <p className={styled.subtitle}>
        <FormattedMessage {...LeaveModalWindowMessage.descriptionSave} />
      </p>
      <div className={styled.row}>
        <Button
          iconBefore={<Icon name={ICON_NAMES_ENUM.save} />}
          type="submit"
          onClick={onSave}
          isLoading={isLoading} disabled={isLoading}
        >
          <FormattedMessage {...commonMessages.save}/>
        </Button>
        <Button variant="text" onClick={onClose} isLoading={isLoading} disabled={isLoading}>
          <FormattedMessage {...LeaveModalWindowMessage.discardChanges}/>
        </Button>
      </div>
    </>
  );
}

export { SaveTemplate };
