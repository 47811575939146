import React from "react";
import styled from "./styled.module.scss";

interface IDottedLine {
  item1: React.ReactElement;
  item2: React.ReactElement;
}

const DottedLine = ({ item1, item2 }: IDottedLine) => {
  return (
    <div className={styled.container}>
      <div className={styled.item1}>{item1}</div>
      <span className={styled.doted} />
      <div className={styled.item2}>{item2}</div>
    </div>
  );
};

export default DottedLine;
