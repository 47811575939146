import { defineMessages } from "react-intl";

export default defineMessages({
  title: {
    id: "NotFoundMessages.title",
    defaultMessage: "Oops... Page not found",
  },
  text: {
    id: "NotFoundMessages.text",
    defaultMessage:
      "The page you are looking for might have been removed had its name changed or is temporarily unavailable",
  },
  goHome: {
    id: "NotFoundMessages.goHome",
    defaultMessage: "Back to Homepage",
  },
});
