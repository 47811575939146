import React from "react";
import styled from "components/SimulationDropItem/styled.module.scss";
import Line from "components/SimulationDropItem/Line";
import { useIntl } from "react-intl";
import StructuresRenderListMessages from "./StructuresRenderListMessages";

const PVModulePropertiesRenderList = ({ selected }: { selected: any }) => {
  const intl = useIntl()
  return (
    <ul className={styled.ul}>
      <Line title={intl.formatMessage(StructuresRenderListMessages.moduleEfficiency)} value={`${selected.module_efficiency_STC} %`} />
      <Line title={intl.formatMessage(StructuresRenderListMessages.temperatureCoefficientsPower)} value={`${selected.temperature_cefficients_power} %/°C`} />
      <Line title={intl.formatMessage(StructuresRenderListMessages.bifacialFactor)} value={`${selected.bifacial_factor} %`} />
      {/* <Line title="Timezone" value={selected.timezone} /> */}
    </ul>
  );
};

export default PVModulePropertiesRenderList;
