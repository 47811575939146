import React, { ReactNode } from "react";
import styled from "./styled.module.scss";

interface IMainContent {
  children: ReactNode | string;
  header: ReactNode | string;
}

const MainContent = ({ children, header }: IMainContent) => {
  return (
    <div className={styled.body}>
      <div className={styled.header}>{header}</div>
      <main className={styled.main}>{children}</main>
    </div>
  );
};

export default MainContent;
