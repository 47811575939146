import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getBatchesWaterBalance = ({ clientId, projectId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/batches_water_balance`,
  });

export const getBatchWaterBalance = ({ clientId, projectId, batchWaterBalanceId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/batches_water_balance/${batchWaterBalanceId}`,
  });

export const createBatchWaterBalance = ({ clientId, projectId, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/batches_water_balance`,
    data,
  });

export const copyBatchWaterBalance = ({ clientId, projectId, batchWaterBalanceId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/batches_water_balance/${batchWaterBalanceId}/copy`,
  });

export const deleteBatchWaterBalance = ({ clientId, projectId, batchWaterBalanceId }) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}/batches_water_balance/${batchWaterBalanceId}`,
  });

export const updateBatchWaterBalance = ({ clientId, projectId, batchWaterBalanceId, ...data }) =>
  apiPATCH({
    url: `/clients/${clientId}/projects/${projectId}/batches_water_balance/${batchWaterBalanceId}`,
    data,
  });

export const getBatchWaterBalanceParametersOfInterest = () =>
  apiGET({
    url: `/extra/batch_water_balance_params`,
  });

export const runBatchWaterBalanceSimulation = ({ clientId, projectId, batchWaterBalanceId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/batches_water_balance/${batchWaterBalanceId}/run`,
  });

export const cancelBatchWaterBalanceSimulation = ({ clientId, projectId, batchWaterBalanceId, resultId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/batches_water_balance/${batchWaterBalanceId}/results/${resultId}/cancel`,
  });

export const getBatchWaterBalanceResults = ({ clientId, projectId, batchWaterBalanceId, resultId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/batches_water_balance/${batchWaterBalanceId}/results/${resultId}`,
  });

export const getBatchWaterBalanceSimulationResult = ({
  clientId,
  projectId,
  batchWaterBalanceId,
  resultId,
  simulationWaterBalanceId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/batches_water_balance/${batchWaterBalanceId}/results/${resultId}/simulations_water_balance/${simulationWaterBalanceId}`,
  });

export const deleteBatchWaterBalanceResult = ({ clientId, projectId, batchWaterBalanceId, resultId }) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}/batches_water_balance/${batchWaterBalanceId}/results/${resultId}`,
  });

export const getBatchWaterBalanceSimulationResultImage = ({
  clientId,
  projectId,
  batchWaterBalanceId,
  resultId,
  simulationWaterBalanceId,
  imageId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/batches_water_balance/${batchWaterBalanceId}/results/${resultId}/simulations_water_balance/${simulationWaterBalanceId}/images/${imageId}`,
    responseType: "blob",
  }).then((r) => URL.createObjectURL(r));

export const getBatchWaterBalanceSimulationResultFiles = ({
  clientId,
  projectId,
  batchWaterBalanceId,
  resultId,
  simulationWaterBalanceId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/batches_water_balance/${batchWaterBalanceId}/results/${resultId}/simulations_water_balance/${simulationWaterBalanceId}/download`,
    responseType: "blob",
  }).then((r) => URL.createObjectURL(r));

export const getBatchWaterBalanceResultFiles = ({
  clientId,
  projectId,
  batchWaterBalanceId,
  resultId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/batches_water_balance/${batchWaterBalanceId}/results/${resultId}/download`,
    responseType: "blob",
  }).then((r) => URL.createObjectURL(r));

export const getBatchWaterBalanceMetadata = ({
    clientId,
    projectId,
    batchWaterBalanceId,
    resultId,
  }) =>
    apiGET({
      url: `/clients/${clientId}/projects/${projectId}/batches_water_balance/${batchWaterBalanceId}/results/${resultId}/download_metadata`,
      responseType: "blob",
    }).then((r) => URL.createObjectURL(r));
