import React from "react";
import { ReactComponent as Simulation } from "assets/icons/simulation.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as Copy } from "assets/icons/copy.svg";

import styled from "./styled.module.scss";
import clsx from "clsx";
import moment from "moment";
import { useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import { Tooltip } from "@mui/material";

interface IPoint {
  id: number;
  name: string;
  x: number;
  y: number;
}

export interface ISimulation {
  azimuth: number;
  azimuth_solar: number;
  backtracking: boolean;
  created_at: string;
  day: string;
  displacement_tracking: boolean;
  field_size_x: number;
  field_size_y: number;
  hour: number;
  id: number;
  initial_offset_x: number;
  initial_offset_y: number;
  name: string;
  panel_height: number;
  panel_opacity: number;
  panel_x: number;
  panel_y: number;
  panels_gap_x: number;
  panels_gap_y: number;
  panels_number_x: number;
  panels_number_y: number;
  points: IPoint[];
  resolution: number;
  solar_position: string;
  static_angle: number;
  structure_filename_hex: string;
  structure_type: string;
  tracking_init_delta: number;
  tracking_max_angle: number;
  tracking_max_delta: number;
  updated_at: string;
  zenith_solar: number;
}

interface ISimulationCard {
  name: string;
  date: string;
  onEdit: () => void;
  onDelete: () => void;
  onCopy: () => void;
}

const SimulationCard = ({
  name,
  date,
  onEdit,
  onCopy,
  onDelete,
}: ISimulationCard) => {
  const intl = useIntl();

  const deleteCard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    onDelete();
  };
  const duplicateCard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    onCopy();
  };

  return (
    <div className={styled.container} onClick={onEdit}>
      <div className={styled.card}>
        <div className={styled.flex}>
          <Simulation />
          <p className={styled.name}>{name}</p>
        </div>

        <div className={styled.flex}>
          <span className={styled.date}>
            {moment.utc(date).local().format("DD MMMM YYYY, HH:mm")}
          </span>
          <Tooltip title={intl.formatMessage(commonMessages.edit)}>
            <button className={styled.button} onClick={onEdit}>
              <Edit />
            </button>
          </Tooltip>
          <Tooltip title={intl.formatMessage(commonMessages.copy)}>
            <button className={styled.button} onClick={duplicateCard}>
              <Copy />
            </button>
          </Tooltip>
          <span className={styled.vertical} />
          <Tooltip title={intl.formatMessage(commonMessages.delete)}>
            <button
              className={clsx(styled.button, styled.red)}
              onClick={deleteCard}
            >
              <Delete />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default SimulationCard;
