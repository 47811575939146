import { useContext, useEffect } from "react";
import {
  UNSAFE_NavigationContext as NavigationContext,
  Navigator,
} from "react-router-dom";
import { Blocker, History } from "history";

export function useBlocker(blocker: Blocker, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) return;

    const unblock = (navigator as Navigator & Pick<History, "block">).block(
      (tx: any) => {
        const autoUnblockingTx = {
          ...tx,
          retry() {
            unblock();
            tx.retry();
          },
        };

        blocker(autoUnblockingTx);
      }
    );

    return unblock;
  }, [navigator, blocker, when]);
}
