import { put, takeLatest } from "redux-saga/effects";
// actions
import { getProjectSort as action } from "store/projects/actions";
import localforage from "store/services/localForage";

function* saga() {
  try {
    yield put(action.request());

    const sort = yield localforage.getItem("project-sort");

    yield put(action.success(JSON.parse(sort)));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(action.fulfill());
  }
}

export default function* getProjectSort() {
  yield takeLatest(action.TRIGGER, saga);
}
