import { defineMessages } from "react-intl";

export default defineMessages({
  editProject : {
    id             : "ProjectModalMessages.editProject",
    defaultMessage : "Edit project",
  },
  createNew : {
    id             : "ProjectModalMessages.createNew",
    defaultMessage : "Create a new project",
  },
  nameLabel : {
    id             : "ProjectModalMessages.nameLabel",
    defaultMessage : "Project name",
  },
  namePlaceholder : {
    id             : "ProjectModalMessages.namePlaceholder",
    defaultMessage : "Enter name",
  },
  siteCoordinates : {
    id             : "ProjectModalMessages.siteCoordinates",
    defaultMessage : "Site coordinates",
  },
  latitudePlaceholder : {
    id             : "ProjectModalMessages.latitudePlaceholder",
    defaultMessage : "Latitude",
  },
  longitudePlaceholder : {
    id             : "ProjectModalMessages.longitudePlaceholder",
    defaultMessage : "Longitude",
  },
  latitude : {
    id             : "ProjectModalMessages.latitude",
    defaultMessage : "Latitude",
  },
  longitude : {
    id             : "ProjectModalMessages.longitude",
    defaultMessage : "Longitude",
  },  
  UTCLabel : {
    id             : "ProjectModalMessages.UTCLabel",
    defaultMessage : "UTC",
  },
  UTCPlaceholder : {
    id             : "ProjectModalMessages.UTCPlaceholder",
    defaultMessage : "Project timezone",
  },
});
