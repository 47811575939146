import React from "react";
import styled from "./styled.module.scss";
import TextSelect from "components/common/textSelect/TextSelect";
import { getInitials } from "utils/getInitials";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { selectOptionsText } from "components/common/select/selectOptionsText";

import { IClient } from "components/ClientCard/ClientCard";
import Toggle from "components/common/toggle/Toggle";

export enum UserRoles {
  ADMIN = "admin",
  MANAGER = "manager",
  USER = "user",
}

export interface IUser {
  client: IClient;
  id: number;
  full_name: string;
  email: string;
  role: { name: UserRoles };
  is_active: boolean;
}

interface ISettingUserCard {
  user: IUser;
  isYou: boolean;
  onDelete: () => void;
  onChangeRole: (a: string) => void;
  onChangeStatus: () => void;
}

const SettingUserCard = ({
  user,
  isYou,
  onDelete,
  onChangeRole,
  onChangeStatus,
}: ISettingUserCard) => {
  const { userRoles } = selectOptionsText();
  const filteredRoles = userRoles.filter(
    (item) => item.value !== UserRoles.ADMIN
  );

  const onSelect = (item: any) => {
    onChangeRole(item.value);
  };

  return (
    <div className={styled.container}>
      <div className={styled.nameContainer}>
        <div className={styled.avatar}>{getInitials(user.full_name)}</div>
        <span className={styled.name}>{user.full_name}</span>
        {isYou && <span className={styled.you}>(You)</span>}
      </div>

      <span className={styled.email}>{user.email}</span>

      <span>
        <TextSelect
          width="fit-content"
          options={filteredRoles}
          value={userRoles.find((item: any) => item.value == user.role.name)}
          onChange={onSelect}
          isDisabled={isYou}
        />
      </span>

      <Toggle
        label="Active"
        checked={user.is_active}
        disabled={isYou}
        onClick={onChangeStatus}
      />

      <button className={styled.delete} onClick={onDelete} disabled={isYou}>
        <Delete />
      </button>
    </div>
  );
};

export default SettingUserCard;
