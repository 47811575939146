import { put, takeLatest, call } from "redux-saga/effects";
// actions
import {createClient as createClientAction} from "store/clients/actions";
// api
import { createClient as createClientApi } from "store/clients/api";

function* saga({ payload }) {
  try {
    yield put(createClientAction.request());

    const response = yield call(createClientApi, payload);

    yield put(createClientAction.success(response.data));
  } catch (error) {
    console.error(error);
    yield put(createClientAction.failure(error));
  } finally {
    yield put(createClientAction.fulfill());
  }
}

export default function* createClient() {
  yield takeLatest(createClientAction.TRIGGER, saga);
}
