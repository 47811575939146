import routine from 'store/services/routine';

export const getWaterBalances = routine('GET_WATER_BALANCES');
export const getWaterBalance = routine('GET_WATER_BALANCE');
export const resetWaterBalance = routine('RESET_WATER_BALANCE');
export const createWaterBalance = routine('CREATE_WATER_BALANCE');
export const copyWaterBalance = routine('COPY_WATER_BALANCE');
export const deleteWaterBalance = routine('DELETE_WATER_BALANCE');
export const updateWaterBalance = routine('UPDATE_WATER_BALANCE');
export const runWaterBalance = routine('RUN_WATER_BALANCE');
export const cancelWaterBalance = routine('CANCEL_WATER_BALANCE');
export const getWaterBalanceResult = routine('GET_WATER_BALANCE_RESULT');
export const deleteWaterBalanceResult = routine('DELETE_WATER_BALANCE_RESULT');
export const resetWaterBalanceResult = routine('RESET_WATER_BALANCE_RESULT');
