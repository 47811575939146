import { defineMessages } from "react-intl";

export default defineMessages({
  logIn : {
    id             : "LoginMessages.logIn",
    defaultMessage : "Log In",
  },
  subtitle : {
    id             : "LoginMessages.subtitle",
    defaultMessage : "Enter your username and password",
  },
  emailLabel : {
    id             : "LoginMessages.emailLabel",
    defaultMessage : "Email",
  },
  emailPlaceholder : {
    id             : "LoginMessages.emailPlaceholder",
    defaultMessage : "Enter email address",
  },
  passwordLabel : {
    id             : "LoginMessages.passwordLabel",
    defaultMessage : "Password",
  },
  passwordPlaceholder : {
    id             : "LoginMessages.passwordPlaceholder",
    defaultMessage : "Enter password",
  },
});
