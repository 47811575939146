import { defineMessages } from "react-intl";

export default defineMessages({
  dragAndDrop: {
    id: "SimulationDropItemMessages.dragAndDrop",
    defaultMessage: "Drag and Drop here or select",
  },
  weatherDatasets: {
    id: "SimulationDropItemMessages.weatherDatasets",
    defaultMessage: "Weather Dataset",
  },
  productionDatasets: {
    id: "SimulationDropItemMessages.productionDatasets",
    defaultMessage: "Electrical Production Dataset",
  },
  etpDatasets: {
    id: "SimulationDropItemMessages.etpDatasets",
    defaultMessage: "Evapotranspiration Dataset",
  },
  universalDatasets : {
    id             : "SimulationDropItemMessages.universalDatasets",
    defaultMessage : "Universal Dataset",
  },
  steeringAlgorithms: {
    id: "SimulationDropItemMessages.steeringAlgorithms",
    defaultMessage: "Steering Algorithm",
  },
  timeStep: {
    id: "SimulationDropItemMessages.timeStep",
    defaultMessage: "Time Step",
  },
});
