import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { getBatches as action } from "store/batches/actions";
// api
import { getBatches as api } from "store/batches/api";

function* getClientsSaga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success(response.data));
  } catch (error) {
    yield put(action.failure(error));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* getBatches() {
  yield takeLatest(action.TRIGGER, getClientsSaga);
}
