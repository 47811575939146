import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getBatches = ({ clientId, projectId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/batches`,
  });

export const getBatch = ({ clientId, projectId, batchId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/batches/${batchId}`,
  });

export const createBatch = ({ clientId, projectId, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/batches`,
    data,
  });

export const copyBatch = ({ clientId, projectId, batchId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/batches/${batchId}/copy`,
  });

export const deleteBatch = ({ clientId, projectId, batchId }) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}/batches/${batchId}`,
  });

export const updateBatch = ({ clientId, projectId, batchId, ...data }) =>
  apiPATCH({
    url: `/clients/${clientId}/projects/${projectId}/batches/${batchId}`,
    data,
  });

export const getParametersOfInterest = () =>
  apiGET({
    url: `/extra/params`,
  });

export const runBatchSimulation = ({ clientId, projectId, batchId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/batches/${batchId}/run`,
  });

export const cancelBatchSimulation = ({ clientId, projectId, batchId, resultId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/batches/${batchId}/results/${resultId}/cancel`,
  });

export const getBatchResults = ({ clientId, projectId, batchId, resultId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/batches/${batchId}/results/${resultId}`,
  });

export const getBatchSimulationResult = ({
  clientId,
  projectId,
  batchId,
  resultId,
  simulationId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/batches/${batchId}/results/${resultId}/simulations/${simulationId}`,
  });

export const deleteBatchResult = ({ clientId, projectId, batchId, resultId }) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}/batches/${batchId}/results/${resultId}`,
  });

export const getBatchSimulationResultImage = ({
  clientId,
  projectId,
  batchId,
  resultId,
  simulationId,
  imageId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/batches/${batchId}/results/${resultId}/simulations/${simulationId}/images/${imageId}`,
    responseType: "blob",
  }).then((r) => URL.createObjectURL(r));

export const getBatchSimulationResultFiles = ({
  clientId,
  projectId,
  batchId,
  resultId,
  simulationId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/batches/${batchId}/results/${resultId}/simulations/${simulationId}/download`,
    responseType: "blob",
  }).then((r) => URL.createObjectURL(r));

export const getBatchResultFiles = ({
  clientId,
  projectId,
  batchId,
  resultId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/batches/${batchId}/results/${resultId}/download`,
    responseType: "blob",
  }).then((r) => URL.createObjectURL(r));

export const getBatchMetadata = ({
    clientId,
    projectId,
    batchId,
    resultId,
  }) =>
    apiGET({
      url: `/clients/${clientId}/projects/${projectId}/batches/${batchId}/results/${resultId}/download_metadata`,
      responseType: "blob",
    }).then((r) => URL.createObjectURL(r));
