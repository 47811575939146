import React, { useState } from "react";
import { isEqual } from "lodash";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import { ReactComponent as Save } from "assets/icons/save.svg";
import styled from "./styled.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import EditName from "components/EditName/EditName";
import { useDispatch } from "react-redux";
import simulationCreateMessages from "pages/SimulationCreate/SimulationCreateMessages";
import { createSimulation, runSimulation } from "store/simulations/actions";
import MainContent from "components/MainContent/MainContent";
import { FormikHelpers, useFormik } from "formik";
import SimulationBody, {
  frequencyOptions,
  ISimulationsState,
} from "components/SimulationBody/SimulationBody";
import SimulationSchema from "pages/SimulationCreate/SimulationSchema";
import { usePrompt } from "hooks/usePromt";
import { useUpdateNavigation } from "hooks/useUpdateNavigation";
import { ReactComponent as Play } from "assets/icons/play.svg";
import CommonMessages from "components/common/CommonMessages";
import { successNotifications } from "utils/successNotifications";

export const getDefaultValues = (intl: any): ISimulationsState => {
  return {
    name: intl.formatMessage(simulationCreateMessages.simulationUnnamed),
    description: "",
    resolution: 10,
    frequency: frequencyOptions[3].value,
    max_scale_value: 10000,
    enable_max_scale: false,
    crop_id: null,
    structure_id: null,
    weather_dataset_id: null,
    production_dataset_id: null,
    universal_dataset_id: null,
    steering_algorithm_id: null,
    ETP_dataset_id: null,
    grassland_primary_yield_calculation: false,
    edge_effect: true,
  };
};

const SimulationCreate = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client_id, project_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  useUpdateNavigation();

  const onChangePage = (id: string) =>
    navigate(
      ROUTES.SIMULATIONS_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", id)
    );

  const onCreate = (
    values: ISimulationsState,
    actions: FormikHelpers<ISimulationsState>
  ) => {
    dispatch(
      createSimulation({
        ...values,
        clientId: client_id,
        projectId: project_id,
        callback: (id: string) => {
          successNotifications({
            title: intl.formatMessage(CommonMessages.create),
            message: intl.formatMessage(CommonMessages.successCreate, {
              objet_type: intl.formatMessage(CommonMessages.simulation),
            }),
          });
          onChangePage(id);
        },
        finallyCallback: () => actions.setSubmitting(false),
      })
    );
  };

  const formik = useFormik({
    initialValues: getDefaultValues(intl),
    onSubmit: onCreate,
    validationSchema: SimulationSchema(intl),
  });

  usePrompt(
    !isEqual(formik.values, getDefaultValues(intl)) &&
      !formik.isSubmitting &&
      !isLoading
  );

  const onBack = () =>
    navigate(
      ROUTES.SIMULATIONS.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );

  const toResultPage = (simulationId: string, resultId: string) =>
    navigate(
      ROUTES.SIMULATIONS_RESULT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":simulation_id", simulationId)
        .replace(":id", resultId)
    );

  const runSimulate = (simulationId: string) => {
    dispatch(
      runSimulation({
        clientId: client_id,
        projectId: project_id,
        simulationId: simulationId,
        callback: (id: string) => toResultPage(simulationId, id),
      })
    );
  };

  const onRunSimulation = () => {
    setIsLoading(true);
    formik.validateForm().then((errors: any) => {
      Object.keys(errors).forEach((key) => formik.setFieldTouched(key));
    });

    if (formik.isValid) {
      dispatch(
        createSimulation({
          ...formik.values,
          clientId: client_id,
          projectId: project_id,
          callback: runSimulate,
        })
      );
    } else {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContent
        header={
          <>
            <EditName
              autoFocus
              onFocus={(event) => {
                event.target.select();
              }}
              placeholder={intl.formatMessage(
                simulationCreateMessages.simulationName
              )}
              onChange={formik.handleChange}
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              errorMessage={
                formik.touched.name ? (formik.errors.name as string) : ""
              }
            />

            <div className={styled.flex}>
              <Button
                disabled={isLoading || !onRunSimulation}
                isLoading={isLoading}
                onClick={onRunSimulation}
                iconBefore={<Play />}
                className={styled.addPoint}
              >
                <FormattedMessage {...CommonMessages.run} />
              </Button>

              <Button variant="text" onClick={onBack}>
                <FormattedMessage {...commonMessages.cancel} />
              </Button>

              <Button
                iconBefore={<Save />}
                type="submit"
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting}
              >
                <FormattedMessage {...commonMessages.save} />
              </Button>
            </div>
          </>
        }
      >
        <SimulationBody formik={formik} onRunSimulation={onRunSimulation} />
      </MainContent>
    </form>
  );
};

export default SimulationCreate;

