import { all, fork } from 'redux-saga/effects';
// sagas
import getClientsList from 'store/clients/sagas/getClientsList';
import createClient from 'store/clients/sagas/createClient';
import deleteClient from 'store/clients/sagas/deleteClient';
import updateClient from 'store/clients/sagas/updateClient';
import getClientSort from 'store/clients/sagas/getClientSort';

export default function* auth() {
  yield all([
    fork(getClientsList),
    fork(createClient),
    fork(deleteClient),
    fork(updateClient),
    fork(getClientSort),
  ]);
}
