import React from "react";
import { Dropdown } from "react-bootstrap";
import CustomToggle from "components/CustomToggle/CustomToggle";
import { ReactComponent as Dots } from "assets/icons/dots.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import styled from "./styled.module.scss";
import clsx from "clsx";
import {FormattedMessage} from "react-intl";
import cardMenuMessages from "components/CardMenu/CardMenuMessages";

interface ICardMenu {
  onEdit: () => void;
  onDelete: () => void;
}

const CardMenu = ({ onEdit, onDelete }: ICardMenu) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>
        <Dots />
      </Dropdown.Toggle>

      <Dropdown.Menu align="end">
        <Dropdown.Item className={styled.item} onClick={onEdit}>
          <Edit className={styled.icon} /> <FormattedMessage {...cardMenuMessages.edit} />
        </Dropdown.Item>
        <Dropdown.Item
          className={clsx(styled.item, styled.delete)}
          onClick={onDelete}
        >
          <Delete className={styled.icon} /> <FormattedMessage {...cardMenuMessages.delete} />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default CardMenu;
