import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { cancelSizingOptimizationSimulation as action } from "store/sizing_optimizations/actions";
// api
import { cancelSizingOptimizationSimulation as api } from "store/sizing_optimizations/api";

import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success(response.data));
  } catch (error) {
    yield call(errorNotifications, error.response?.data.detail);
    yield put(action.failure(error));
  } finally {
    yield put(action.fulfill());
    payload?.callback();
  }
}

export default function* cancelBatchSimulation() {
  yield takeLatest(action.TRIGGER, saga);
}
