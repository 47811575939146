import React from "react";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import { ReactComponent as Copy } from "assets/icons/copy.svg";
import { ReactComponent as Export } from "assets/icons/export.svg";
import styled from "./styled.module.scss";
import clsx from "clsx";
import moment from "moment";
import { useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import { Tooltip } from "@mui/material";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";

interface ISoilInformationsCard {
  name: string;
  date: string;
  onEdit: () => void;
  onDelete: () => void;
  onCopy: () => void;
  onCopyToOtherProject: () => void;
}

const SoilInformationsCard = ({
  name,
  date,
  onEdit,
  onCopy,
  onDelete,
  onCopyToOtherProject,
}: ISoilInformationsCard) => {
  const intl = useIntl();
  const deleteCard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    onDelete();
  };

  const duplicateCard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    onCopy();
  };

  const duplicateToOtherProjectCard = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    onCopyToOtherProject();
  };

  return (
    <div className={styled.container} onClick={onEdit}>
      <div className={styled.card}>
        <div className={styled.flex}>
          <Icon
            name={ICON_NAMES_ENUM.terrain_icon}
            className={styled.file__icon}
          />
          <p className={styled.name}>{name}</p>
        </div>
        <div className={styled.flex}>
          <span className={styled.date}>
            {moment.utc(date).local().format("DD MMMM YYYY, HH:mm")}
          </span>
          <Tooltip title={intl.formatMessage(commonMessages.edit)}>
            <button className={styled.button} onClick={onEdit} type="button">
              <Edit />
            </button>
          </Tooltip>
          <span className={styled.vertical} />
          <Tooltip title={intl.formatMessage(commonMessages.copy)}>
            <button
              className={styled.button}
              onClick={duplicateCard}
              type="button"
            >
              <Copy />
            </button>
          </Tooltip>
          <Tooltip title={intl.formatMessage(commonMessages.copy_to_other_project)}>
            <button
              className={clsx(styled.button, styled.copy_to_other_project)}
              onClick={duplicateToOtherProjectCard}
              type="button"
            >
              <Export />
            </button>
          </Tooltip>
          <span className={styled.vertical} />
          <Tooltip title={intl.formatMessage(commonMessages.copy)}>
            <button
              type="button"
              className={clsx(styled.button, styled.red)}
              onClick={deleteCard}
            >
              <Delete />
            </button>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default SoilInformationsCard;
