import { all, fork } from "redux-saga/effects";
// sagas
import getSteeringAlgorithms from "store/steering_algorithms/sagas/getSteeringAlgorithms";
import createSteeringAlgorithm from "store/steering_algorithms/sagas/createSteeringAlgorithm";
import getSingleSteeringAlgorithm from "store/steering_algorithms/sagas/getSingleSteeringAlgorithm";
import updateSteeringAlgorithm from "store/steering_algorithms/sagas/updateSteeringAlgorithm";
import deleteSteeringAlgorithm from "store/steering_algorithms/sagas/deleteSteeringAlgorithm";
import copySteeringAlgorithm from "store/steering_algorithms/sagas/copySteeringAlgorithm";

export default function* auth() {
  yield all([
    fork(getSteeringAlgorithms),
    fork(createSteeringAlgorithm),
    fork(getSingleSteeringAlgorithm),
    fork(updateSteeringAlgorithm),
    fork(deleteSteeringAlgorithm),
    fork(copySteeringAlgorithm),
  ]);
}
