import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { getClientsList as getClientsAction } from "store/clients/actions";
// api
import { getClientsList as getClientsApi } from "store/clients/api";

function* getClientsSaga({ payload }) {
  try {
    yield put(getClientsAction.request());

    const response = yield call(getClientsApi, payload);

    yield put(getClientsAction.success(response.data));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(getClientsAction.fulfill());
  }
}

export default function* getClientsList() {
  yield takeLatest(getClientsAction.TRIGGER, getClientsSaga);
}
