import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import styled from "./styled.module.scss";
import { ROUTES } from "constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SimulationCard, {
  ISimulation,
} from "components/SimulationCard/SimulationCard";
import {
  copySimulation,
  deleteSimulation,
  getSimulations,
} from "store/simulations/actions";
import { getSimulationsListState } from "store/simulations/selectors";
import MainContent from "components/MainContent/MainContent";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import { copyWaterBalance, deleteWaterBalance, getWaterBalances } from "store/water_balances/actions";
import { getWaterBalancesListState } from "store/water_balances/selectors";

const WaterBalances = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client_id, project_id } = useParams();
  const water_balances = useSelector(getWaterBalancesListState);
  const [deleteId, setDeleteId] = useState<number | null>(null);

  useEffect(() => {
    dispatch(getWaterBalances({ clientId: client_id, projectId: project_id }));
  }, [client_id, project_id]);

  const onHideWarning = () => setDeleteId(null);

  const onShowWarning = (id: number) => () => {
    setDeleteId(id);
  };

  const onDelete = () => {
    dispatch(
      deleteWaterBalance({
        clientId: client_id,
        projectId: project_id,
        waterBalanceId: deleteId,
        callback: onHideWarning,
      })
    );
  };

  const onCopy = (id: string | number) => () => {
    dispatch(
      copyWaterBalance({
        clientId: client_id,
        projectId: project_id,
        waterBalanceId: id,
      })
    );
  };

  return (
    <MainContent
      header={
        <>
          <h2 className={styled.title}>
            <FormattedMessage {...commonMessages.waterBalances} />
          </h2>
          <Button
            iconBefore={<Plus />}
            onClick={() =>
              navigate(
                ROUTES.WATER_BALANCES_CREATE.replace(
                  ":client_id",
                  client_id as string
                ).replace(":project_id", project_id as string)
              )
            }
          >
            <FormattedMessage {...commonMessages.createNew} />
          </Button>
        </>
      }
    >
      <div className={styled.list}>
        {water_balances.map((simulation: ISimulation) => (
          <SimulationCard
            key={simulation.id}
            name={simulation.name}
            date={simulation.updated_at}
            onEdit={() =>
              navigate(
                ROUTES.WATER_BALANCES_EDIT.replace(
                  ":client_id",
                  client_id as string
                )
                  .replace(":project_id", project_id as string)
                  .replace(":id", String(simulation.id))
              )
            }
            onDelete={onShowWarning(simulation.id)}
            onCopy={onCopy(simulation.id)}
          />
        ))}
      </div>

      <LeaveModalWindow
        type={LeaveModalEnum.DELETE}
        onDelete={onDelete}
        show={deleteId !== null}
        onHide={onHideWarning}
      />
    </MainContent>
  );
};

export default WaterBalances;
