import React, { InputHTMLAttributes } from "react";
import styled from "./styled.module.scss";
import { SpanError } from "components/common/span-error/SpanError";
import clsx from "clsx";

interface ITextArea extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  rows?: number;
  errorMessage?: string;
}

const TextArea = ({
  name,
  label,
  rows = 5,
  errorMessage,
  ...rest
}: ITextArea) => {
  return (
    <div className={styled.container}>
      {!!label && (
        <label className={styled.label} htmlFor={name}>
          {label}
        </label>
      )}
      <textarea
        rows={rows}
        className={clsx(styled.input, {
          [styled.error]: !!errorMessage,
        })}
        id={name}
        name={name}
        {...rest}
      />
      <SpanError errorMessage={errorMessage} />
    </div>
  );
};

export default TextArea;
