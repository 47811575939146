import { useIntl } from "react-intl";
import parametersInterestMessages from "components/ParametersInterest/ParametersInterestMessages";
import { useDispatch, useSelector } from "react-redux";
import { getBatchesParameters } from "store/batches/selectors";
import { useEffect } from "react";
import { getParametersOfInterest } from "store/batches/actions";
import { getBatchesWaterBalanceParameters } from "store/batches_water_balance/selectors";
import { getBatchWaterBalanceParametersOfInterest } from "store/batches_water_balance/actions";

export type IInterestNameVariants =
  | "panel_height"
  | "lower_table_tip_height"
  | "panel_opacity"
  | "azimuth"
  | "panel_size"
  | "panel_tilt"
  | "panel_max_tilt"
  | "initial_offset"
  | "panels_number"
  | "panels_gap"
  | "field_size"
  // | "weather_dataset_id"
  // | "ETP_dataset_id"
  // | "production_dataset_id"
  | "universal_dataset_id"
  | "crop_id"
  | "steering_algorithm_id";

export interface IInterestParamsItem {
  category: "structures" | "crops" | "datasets" | "steering_algorithms";
  created_at: string;
  id: number;
  name: IInterestNameVariants;
  type: "input" | "point" | "crop" /*"production_dataset" | "weather_dataset" | "ETP_dataset" | */ | "universal_dataset"| "steering_algorithm";
  updated_at: string;
  label: string;
}

export function useParamsList() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const list = useSelector(getBatchesParameters);
  useEffect(() => {
    dispatch(getParametersOfInterest());
  }, []);

  const formatList: IInterestParamsItem[] = list.map(
    (item: Omit<IInterestParamsItem, "label">) => ({
      ...item,
      label: intl.formatMessage(parametersInterestMessages[item.name]),
    })
  );

  return formatList;
}