import { Moment } from "moment/moment";
import { Type } from "typescript";

export enum IRowType {
  time_slot = "time_slot",
}

export enum IRowState {
  max_irradiance = "max_irradiance",
  // max_shading = "max_shading",
}

export function getRowTypeValue(type: IRowType) {
  switch (type) {
    case IRowType.time_slot:
      return {
        hourStart: 4,
        hourEnd: 7,
      };
    default:
      return null;
  }
}

export interface IRowField {
  id: number;
  period_id: number;
  type: IRowType;
  state: IRowState;
  value: any;
}

export interface IValues {
  name: string;
  crop_id: number;
  row: IRowField[];
}

export interface IDbRowField {
  id: number;
  period_id: number;
  row_type: IRowType;
  row_state: IRowState;
  value: any;
}

export interface IDbSteeringAlgorithm {
  id: number;
  name: string;
  updated_at: string;
  created_at: string;
  crop_id: number;
  rows: IDbRowField[];
}
