import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { deleteCrop as action } from "store/crops/actions";
// api
import { deleteCrop as api } from "store/crops/api";
import { errorNotifications } from 'utils/errorNotifications'


function* saga({ payload: { callback, ...payload } }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success(response.data));

    if (callback) callback();
  } catch (error) {
    yield call(errorNotifications, error.response.data.data);

  } finally {
    yield put(action.fulfill());
  }
}

export default function* deleteCrop() {
  yield takeLatest(action.TRIGGER, saga);
}
