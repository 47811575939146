import React, { FormEvent } from "react";
import Button from "components/common/button/Button";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import styled from "./styled.module.scss";
import ModalContainer from "components/ModalContainer/ModalContainer";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import deleteUserModalMessages from "components/DeleteUserModal/DeleteUserModalMessages";

interface IDeleteUser {
  show: boolean;
  onCancel: () => void;
  onSubmit: () => void;
  email: string;
}

const DeleteUserModal = ({ show, onCancel, onSubmit, email }: IDeleteUser) => {
  const intl = useIntl();

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <ModalContainer
      show={show}
      onHide={onCancel}
      dialogClassName={styled.dialog}
      title={intl.formatMessage(deleteUserModalMessages.deleteUser)}
      subTitle={intl.formatMessage(deleteUserModalMessages.text)}
    >
      <form className={styled.form} onSubmit={handleSubmit}>
        <p className={styled.email}>{email}</p>
        <div className={styled.row}>
          <Button
            className={styled.delete}
            iconBefore={<Delete />}
            type="submit"
          >
            <FormattedMessage {...commonMessages.delete} />
          </Button>
          <Button variant="text" onClick={onCancel}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default DeleteUserModal;
