import React, { useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import AuthLayout from "components/AuthLayout/AuthLayout";
import Button from "components/common/button/Button";
import Input from "components/common/input/Input";
import RegisterSchema from "./schema";
import styled from "./styled.module.scss";
import { ReactComponent as Logo } from "assets/icons/logo.svg";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import {useLocation, useNavigate} from "react-router-dom";
import forgotMessages from "pages/Forgot/ForgotMessages";
import {setPassword} from "store/user/actions";
import {ROUTES} from "constants/routes";
import {IUser, UserRoles} from "components/SettingUserCard/SettingUserCard";

interface IForgotForm {
  password: string;
  confirm_password: string;
}

const Forgot = () => {
  const [isShowPass, setShowPass] = useState(false);
  const intl = useIntl();
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const moveAfterLogin = (user: IUser) => {
    if (user.role.name === UserRoles.ADMIN) {
      navigate(ROUTES.CLIENTS_LIST);
    } else {
      navigate(ROUTES.PROJECTS_LIST.replace(":client_id", String(user.client.id)));
    }
  }

  const onSubmit = async ({ password }: IForgotForm) => {
    dispatch(
      setPassword({
        query: search,
        password,
        callback: moveAfterLogin,
      })
    );
  };

  const formik = useFormik<IForgotForm>({
    validationSchema: RegisterSchema(intl),
    onSubmit,
    initialValues: {
      password: "",
      confirm_password: "",
    },
  });

  const getInputProps = (key: keyof IForgotForm) => ({
    name: key,
    value: formik.values[key] as string | number,
    onChange: formik.handleChange,
    onBlur: formik.handleBlur,
    errorMessage: formik.touched[key] ? (formik.errors[key] as string) : "",
  });

  return (
    <AuthLayout>
      <Logo className={styled.logo} />
      <h1 className={styled.title}>
        <FormattedMessage {...forgotMessages.welcome} />
      </h1>
      <p className={styled.subtitle}>
        <FormattedMessage {...forgotMessages.subtitle} />
      </p>
      <form className={styled.form} onSubmit={formik.handleSubmit}>
        <Input
          type={isShowPass ? "text" : "password"}
          label={intl.formatMessage(forgotMessages.passwordLabel)}
          placeholder={intl.formatMessage(forgotMessages.passwordPlaceholder)}
          {...getInputProps("password")}
          afterIcon={
            <button
              tabIndex={-1}
              type="button"
              className={styled["eyes-wrapper"]}
              onClick={() => setShowPass(!isShowPass)}
            >
              <Icon
                name={
                  isShowPass
                    ? ICON_NAMES_ENUM.eyes_open
                    : ICON_NAMES_ENUM.eyes_close
                }
                className={styled.eyes}
              />
            </button>
          }
        />
        <Input
          type={isShowPass ? "text" : "password"}
          label={intl.formatMessage(forgotMessages.confirmPasswordLabel)}
          placeholder={intl.formatMessage(
            forgotMessages.confirmPasswordPlaceholder
          )}
          {...getInputProps("confirm_password")}
          afterIcon={
            <button
              tabIndex={-1}
              type="button"
              className={styled["eyes-wrapper"]}
              onClick={() => setShowPass(!isShowPass)}
            >
              <Icon
                name={
                  isShowPass
                    ? ICON_NAMES_ENUM.eyes_open
                    : ICON_NAMES_ENUM.eyes_close
                }
                className={styled.eyes}
              />
            </button>
          }
        />

        <Button type="submit" fullwidth>
          <span className={styled["button-text"]}>
            <FormattedMessage {...forgotMessages.savePassword} />
          </span>
        </Button>
      </form>
    </AuthLayout>
  );
};

export default Forgot;
