import React, { useEffect } from "react";
import Button from "components/common/button/Button";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ReactComponent as Save } from "assets/icons/save.svg";
import styled from "./styled.module.scss";
import { FormikValues, useFormik } from "formik";
import Input from "components/common/input/Input";
import ClientSchema from "components/ClientModal/ClientSchema";
import TextArea from "components/common/textArea/TextArea";
import ModalContainer from "components/ModalContainer/ModalContainer";
import { FormattedMessage, useIntl } from "react-intl";
import clientModalMessages from "components/ClientModal/ClientModalMessages";
import commonMessages from "components/common/CommonMessages";
import { useDispatch } from "react-redux";
import { createClient, updateClient } from "store/clients/actions";

interface IClientModal {
  show: boolean;
  onHide: () => void;
  initialValues?: IClientForm;
  editId?: string | number;
}

interface IClientForm {
  name: string;
  email: string;
  description: string;
}

const defaultFormValue: IClientForm = {
  name: "",
  email: "",
  description: "",
};

const ClientModal = ({
  show,
  onHide,
  editId,
  initialValues = defaultFormValue,
}: IClientModal) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const onSubmit = (values: FormikValues) => {
    if (editId) {
      dispatch(updateClient({ clientId: editId, ...values }));
    } else {
      dispatch(createClient(values));
    }
    onHide();
  };

  const formik = useFormik<IClientForm>({
    validationSchema: ClientSchema(intl),
    onSubmit,
    initialValues,
  });

  useEffect(() => {
    formik.setValues(initialValues);
  }, [initialValues]);

  useEffect(() => {
    if (!show) formik.resetForm();
  }, [show]);

  return (
    <ModalContainer
      show={show}
      onHide={onHide}
      title={
        editId
          ? intl.formatMessage(clientModalMessages.editClient)
          : intl.formatMessage(clientModalMessages.createNew)
      }
    >
      <form className={styled.form} onSubmit={formik.handleSubmit}>
        <Input
          name="name"
          label={intl.formatMessage(clientModalMessages.nameLabel)}
          placeholder={intl.formatMessage(clientModalMessages.namePlaceholder)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
          errorMessage={(formik.touched.name && formik.errors.name) as string}
        />

        <Input
          name="email"
          label={intl.formatMessage(clientModalMessages.emailLabel)}
          placeholder={intl.formatMessage(clientModalMessages.emailPlaceholder)}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          errorMessage={(formik.touched.email && formik.errors.email) as string}
        />

        <TextArea
          name="description"
          label={intl.formatMessage(clientModalMessages.descLabel)}
          placeholder={intl.formatMessage(clientModalMessages.descPlaceholder)}
          onChange={formik.handleChange}
          value={formik.values.description}
          onBlur={formik.handleBlur}
          errorMessage={(formik.touched.description && formik.errors.description) as string}
        />

        <div className={styled.row}>
          <Button iconBefore={editId ? <Save /> : <Plus />} type="submit">
            {editId ? (
              <FormattedMessage {...commonMessages.save} />
            ) : (
              <FormattedMessage {...commonMessages.create} />
            )}
          </Button>
          <Button variant="text" onClick={onHide}>
            <FormattedMessage {...commonMessages.cancel} />
          </Button>
        </div>
      </form>
    </ModalContainer>
  );
};

export default ClientModal;
