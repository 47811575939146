import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { copySimulation as action } from "store/simulations/actions";
// api
import { copySimulation as api } from "store/simulations/api";
import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload: { callback, ...payload } }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success(response.data));

    if (callback) {
      callback();
    }
  } catch (error) {
    yield call(errorNotifications, error.response.data.data);
    yield put(action.failure(error));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* copySimulation() {
  yield takeLatest(action.TRIGGER, saga);
}
