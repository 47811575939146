import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

import styled from "./styled.module.scss";
import { getInitials } from "utils/getInitials";
import CustomToggle from "components/CustomToggle/CustomToggle";
import { ReactComponent as Settings } from "assets/icons/settings.svg";
import { ReactComponent as User } from "assets/icons/user.svg";
import { ReactComponent as Logout } from "assets/icons/log-out.svg";
import { ReactComponent as World } from "assets/icons/world.svg";
import profileMessages from "components/Profile/ProfileMessages";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { useDispatch, useSelector } from "react-redux";
import { auth, editProfile, logout } from "store/user/actions";
import { getUserDataState } from "store/user/selectors";
import { common } from "@mui/material/colors";
import CommonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";

import { ReactComponent as FR } from "assets/icons/fr.svg";
import { ReactComponent as IT } from "assets/icons/it.svg";
import { ReactComponent as US } from "assets/icons/us.svg";
import clsx from "clsx";
import { Languages } from "translations";


const Profile = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { client_id } = useParams();
  const user = useSelector(getUserDataState);

  const [modalOpen, setModalOpen] = useState(false)
  const [languageSelected, setLanguageSelected] = useState(user ? user.language : Languages.EN)

  useEffect(() => {
    if (!user) dispatch(auth());
    else setLanguageSelected(user.language)
  }, [user]);

  const onSaveLanguage = () => {
    dispatch(
      editProfile(
        { language: languageSelected }
      )
    )
  }

  const onSettingsClick = () => {
    navigate(ROUTES.SETTING_USERS.replace(":client_id", client_id));
  };

  const onOpenLanguageModal = () => {
    setModalOpen(true)
  }
  const onCloseLanguageModal = () => {
    setModalOpen(false)
  }

  const onLogoutClick = async () => {
    dispatch(logout(() => navigate(ROUTES.LOGIN)))
  };

  if (!user) return null;

  return (
    <>
      <Dropdown className={styled.dropdown}>
        <Dropdown.Toggle as={CustomToggle}>
          <div className={styled.container}>
            <div className={styled.textContainer}>
              <h4 className={styled.name}>{user.full_name}</h4>
              <span className={styled.email}>{user.email}</span>
            </div>

            <span className={styled.initials}>{getInitials(user.full_name || 'new user')}</span>
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu align="end">
          <Dropdown.Item
            as={Link}
            className={styled.dropdownItem}
            to={ROUTES.PROFILE}
            state={{ background: location }}
          >
            <User /> <FormattedMessage {...profileMessages.myProfile} />
          </Dropdown.Item>
          {!!client_id && (
            <Dropdown.Item
              className={styled.dropdownItem}
              onClick={onSettingsClick}
            >
              <Settings /> <FormattedMessage {...profileMessages.settings} />
            </Dropdown.Item>
          )}

          {/* <Dropdown.Divider className={styled.dropdownDivider} />

          <Dropdown.Item
            className={styled.dropdownItem}
            onClick={onOpenLanguageModal}
          >
            <World /> <FormattedMessage {...profileMessages.language} />
          </Dropdown.Item> */}

          <Dropdown.Divider className={styled.dropdownDivider} />

          <Dropdown.Item className={styled.dropdownItem} onClick={onLogoutClick}>
            <Logout /> <FormattedMessage {...profileMessages.logout} />
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
      <Modal show={modalOpen} >
        <Modal.Header>
          <Modal.Title> <FormattedMessage {...profileMessages.language} /> </Modal.Title>
        </Modal.Header>

        <div className={styled.selector}>
          <div className={clsx(styled.icon, { [styled.selected]: languageSelected == Languages.FR })} onClick={() => setLanguageSelected(Languages.FR)}>
            <FR />
          </div>
          <div className={clsx(styled.icon, { [styled.selected]: languageSelected == Languages.IT })} onClick={() => setLanguageSelected(Languages.IT)}>
            <IT />
          </div>
          <div className={clsx(styled.icon, { [styled.selected]: languageSelected == Languages.EN })} onClick={() => setLanguageSelected(Languages.EN)}>
            <US />
          </div>

        </div>

        <Modal.Footer>
          <Button onClick={onCloseLanguageModal}>
            <FormattedMessage {...CommonMessages.close} />
          </Button>
          <Button onClick={onSaveLanguage}>
            <FormattedMessage {...CommonMessages.save} />
          </Button>
        </Modal.Footer>

      </Modal>
    </>
  );
};

export default Profile;
