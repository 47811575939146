import React, { useEffect, useState } from "react";
import styled from "./styled.module.scss";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import { ReactComponent as Plus } from "assets/icons/plus.svg";
import { ROUTES } from "constants/routes";
import { useNavigate, useParams } from "react-router-dom";
import { getCropsListState } from "store/crops/selectors";
import { useDispatch, useSelector } from "react-redux";
import MainContent from "components/MainContent/MainContent";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import { ICrops } from "pages/Crops/Crops";
import { getSoilInformationsListState } from "store/soil_informations/selectors";
import {
  copySoilInformation,
  createCurrentSoilInformationByLocalization,
  deleteSoilInformation,
  getSoilInformations,
} from "store/soil_informations/actions";
import SoilInformationsCard from "components/SoilInformationsCard/SoilInformationsCard";
import { ISteeringAlgorithm } from "pages/SteeringAlgorithms/SteeringAlgorithms";
import { getProjectsListState } from "store/projects/selectors";
import { useOptionData } from "hooks/useOptionData";
import { Modal } from "react-bootstrap";
import { ICON_NAMES_ENUM, Icon } from "components/common/icon/Icon";
import CommonMessages from "components/common/CommonMessages";
import Select from "components/common/select/Select";
import { copySoilInformationToOtherProject } from "store/soil_informations/api";
import { successNotifications } from "utils/successNotifications";

export interface ISoilInformation {
  id: number;
  name: string;
  ru_water: number;
  rfu_water: number;
  irrigation: boolean;
  is_copy: boolean;
  deleted: boolean;
  created_at: string;
  updated_at: string;
}

function SoilInformations() {
  const intl = useIntl()
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { client_id, project_id } = useParams();
  const [show, onShow] = useState(false);
  const [currentId, setCurrentId] = useState(NaN);
  const soil_informations = useSelector(getSoilInformationsListState);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [projectId, setProjectId] = useState(0);
  const [copyToOtherProjectId, setCopyToOtherProjectId] = useState<
    number | null
  >(null);
  const projectList = useSelector(getProjectsListState);
  const projectOptions = useOptionData(projectList);
  const [onCreatingCurrentSoil, setOnCreatingCurrentSoil] = useState(false)

  useEffect(() => {
    dispatch(
      getSoilInformations({ clientId: client_id, projectId: project_id })
    );
  }, [client_id, project_id]);

  const onDeleteSoilInformation = (id: number | string) => {
    dispatch(
      deleteSoilInformation({
        clientId: client_id,
        projectId: project_id,
        soilInformationId: id,
        callback: () => onShow(false),
      })
    );
  };

  const onShowWarning = (id: number) => {
    setCurrentId(id);
    onShow(true);
  };

  const onCreateNavigate = () => {
    navigate(
      ROUTES.SOIL_INFORMATION_CREATE.replace(
        ":client_id",
        client_id as string
      ).replace(":project_id", project_id as string)
    );
  };

  const onEditNavigate = (id: number) => {
    navigate(
      ROUTES.SOIL_INFORMATION_EDIT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":id", String(id))
    );
  };

  const onCopy = (id: string | number) => () => {
    dispatch(
      copySoilInformation({
        clientId: client_id,
        projectId: project_id,
        soilInformationId: id,
        callback: () => {
          dispatch(
            getSoilInformations({
              clientId: client_id,
              projectId: project_id,
            })
          );
          onShow(false);
        },
      })
    );
  };

  const onCreateCurrentSoilInformationByLocalization = () => {
    setOnCreatingCurrentSoil(true)
    dispatch(
      createCurrentSoilInformationByLocalization({
        clientId: client_id,
        projectId: project_id,
        callback: () => {
          successNotifications({title: intl.formatMessage(CommonMessages.create), message: intl.formatMessage(CommonMessages.successCreate, {objet_type: intl.formatMessage(CommonMessages.soil_information)})})
        },
        finallyCallback: () => setOnCreatingCurrentSoil(false),
      })
    );
  }

  return (
    <MainContent
      header={
        <>
          <h2 className={styled.title}>
            <FormattedMessage {...commonMessages.soil_information} />
          </h2>

        <div className={styled.flex}>

          <Button iconBefore={<Plus />} onClick={onCreateCurrentSoilInformationByLocalization} isLoading={onCreatingCurrentSoil} disabled={onCreatingCurrentSoil}>
            Create from project position
          </Button>

          
          
          <Button iconBefore={<Plus />} onClick={onCreateNavigate}>
            <FormattedMessage {...commonMessages.createNew} />
          </Button>
        </div>
        </>
      }
    >
      <div className={styled.list}>
        {soil_informations.map((soilInformation: ISoilInformation, index: number) => (
          <SoilInformationsCard
            key={index.toString()}
            name={soilInformation.name}
            date={soilInformation.updated_at}
            onDelete={() => onDeleteSoilInformation(soilInformation.id)}
            onEdit={() => onEditNavigate(soilInformation.id)}
            onCopy={onCopy(soilInformation.id)}
            onCopyToOtherProject={() => {
              setCopyToOtherProjectId(soilInformation.id);
            }}
          />
        ))}
        <LeaveModalWindow
          type={LeaveModalEnum.DELETE}
          onDelete={() => {}}
          show={show}
          onHide={() => onShow(false)}
        />
        <Modal
          show={copyToOtherProjectId != null}
          onHide={() => setCopyToOtherProjectId(null)}
          centered
        >
          <div className={styled.container}>
            <Icon name={ICON_NAMES_ENUM.warning} />
            <h3 className={styled.modal_title}>
              <FormattedMessage {...CommonMessages.copy_to_other_project} />
            </h3>
            <Select
              label={"Select project"}
              options={projectOptions}
              onChange={(e: any) => {
                setProjectId(e.value);
              }}
            />
            <div className={styled.row}>
              <Button
                onClick={() => {
                  copySoilInformationToOtherProject({
                    clientId: client_id,
                    projectId: project_id,
                    soilInformationId: copyToOtherProjectId,
                    projectToId: projectId,
                  }).then(() => {
                    setCopyToOtherProjectId(null);
                  });
                }}
              >
                Copy
              </Button>
              <Button
                type="submit"
                onClick={() => {
                  setCopyToOtherProjectId(null);
                }}
              >
                <FormattedMessage {...CommonMessages.cancel} />
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </MainContent>
  );
}

export { SoilInformations };
