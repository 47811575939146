import Spinner from "react-bootstrap/Spinner";
import styled from './Loader.module.scss'

function Loader() {
  return (
    <div className={styled.container}>
      <Spinner animation="border" role="status"   variant="success" />
    </div>
  );
}

export { Loader };
