import { object, string } from "yup";
import { IntlShape } from "react-intl";
import {password_schema, username_schema} from "pages/Register/schema";
import ValidationMessages from "components/common/ValidationMessages";

const LoginSchema = (intl: IntlShape) =>
  object().shape({
    email: string().email(intl.formatMessage(ValidationMessages.email)).required(intl.formatMessage(ValidationMessages.required)),
    password: password_schema(intl),
  });

export default LoginSchema;
