import { all, fork } from "redux-saga/effects";
// sagas
import getDatasets from "store/datasets/sagas/getDatasets";
import createDataset from "store/datasets/sagas/createDataset";
import createDatasetFromAPI from "store/datasets/sagas/createDatasetFromAPI";
import createDatasetFromOpen from "store/datasets/sagas/createDatasetFromOpen";
import deleteDataset from "store/datasets/sagas/deleteDataset";
import getTimezones from "store/datasets/sagas/getTimezones";
import getSingleDataset from "store/datasets/sagas/getSingleDataset";
import updateDataset from "store/datasets/sagas/updateDataset";
import copyDataset from "store/datasets/sagas/copyDataset";
import getDatasetAnalysis from "store/datasets/sagas/getDatasetAnalysis";

export default function* auth() {
  yield all([
    fork(getDatasets),
    fork(createDataset),
    fork(createDatasetFromAPI),
    fork(createDatasetFromOpen),
    fork(deleteDataset),
    fork(getTimezones),
    fork(getSingleDataset),
    fork(updateDataset),
    fork(copyDataset),
    fork(getDatasetAnalysis),
  ]);
}
