import { defineMessages } from "react-intl";

export default defineMessages({
  editClient : {
    id             : "ClientModalMessages.editClient",
    defaultMessage : "Edit client",
  },
  createNew : {
    id             : "ClientModalMessages.createNew",
    defaultMessage : "Create a new client",
  },
  nameLabel : {
    id             : "ClientModalMessages.nameLabel",
    defaultMessage : "Name",
  },
  namePlaceholder : {
    id             : "ClientModalMessages.namePlaceholder",
    defaultMessage : "Enter name",
  },
  emailLabel : {
    id             : "ClientModalMessages.emailLabel",
    defaultMessage : "Contact Email",
  },
  emailPlaceholder : {
    id             : "ClientModalMessages.emailPlaceholder",
    defaultMessage : "Enter email",
  },
  descLabel : {
    id             : "ClientModalMessages.descLabel",
    defaultMessage : "Company Description",
  },
  descPlaceholder : {
    id             : "ClientModalMessages.descPlaceholder",
    defaultMessage : "Enter company description",
  },
});
