import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { activateUser as action } from "store/users/actions";
// api
import { activateUser as api } from "store/users/api";
import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload }) {
  try {
    yield put(action.request());

    const { data } = yield call(api, payload);

    yield put(action.success(data));
  } catch (error) {
    yield call(errorNotifications, error.response.data.data);
    yield put(action.failure(error));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* activateUser() {
  yield takeLatest(action.TRIGGER, saga);
}
