export enum DnDTypes {
  CROP = "crop",
  STRUCTURE = "structure",
  DATA_WEATHER = "weather-dataset",
  DATA_PROD = "production-dataset",
  DATA_ETP = "etp-dataset",
  DATA_UNI = "universal-dataset",
  STEERING_ALGORITHM = "steering_algorithm",
  SOIL_INFORMATION = "soil_information"
}
