import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { createBatch as action } from "store/batches/actions";
// api
import { createBatch as api } from "store/batches/api";
import {errorNotifications} from "utils/errorNotifications";

function* saga({ payload: { callback, finallyCallback,  ...payload } }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success(response.data));
    if (callback) callback(response.data);
  } catch (error) {
    yield call(errorNotifications, error.response.data.data);
    yield put(action.failure(error));
  } finally {
    yield put(action.fulfill());
    if (finallyCallback) finallyCallback();
  }
}

export default function* createBatch() {
  yield takeLatest(action.TRIGGER, saga);
}
