import { put, takeLatest, call } from "redux-saga/effects";
// actions
import { runBatchWaterBalanceSimulation as action } from "store/batches_water_balance/actions";
// api
import { runBatchWaterBalanceSimulation as api } from "store/batches_water_balance/api";

import { errorNotifications } from "utils/errorNotifications";

function* saga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(
      action.success({
        ...response.data,
        batchWaterBalanceId: payload.batchWaterBalanceId,
      })
    );

    payload?.callback(response.data.id);
  } catch (error) {
    console.error(error)
    yield call(errorNotifications, error.response?.data);
    yield put(action.failure(error));
  } finally {
    yield put(action.fulfill());
  }
}

export default function* runBatchWaterBalanceSimulation() {
  yield takeLatest(action.TRIGGER, saga);
}
