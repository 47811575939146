import { put, call, takeLatest } from "redux-saga/effects";
import { getBatchWaterBalanceParametersOfInterest as action } from "store/batches_water_balance/actions";
import { getBatchWaterBalanceParametersOfInterest as api } from "store/batches_water_balance/api";

function* saga({ payload }) {
  try {
    yield put(action.request());

    const response = yield call(api, payload);

    yield put(action.success(response.data));
  } catch (error) {
    console.error(error);
  } finally {
    yield put(action.fulfill());
  }
}

export default function* getBatchWaterBalanceParametersOfInterest() {
  yield takeLatest(action.TRIGGER, saga);
}
