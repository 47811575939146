import { number, object, string } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";

const AddPeriodSchema = (intl: IntlShape) =>
  object().shape({
    name: string()
      .max(50, intl.formatMessage(validationMessages.maxLength, { number: 50 }))
      .required(intl.formatMessage(validationMessages.required)),
    crop_height: number(),
    crop_coefficient: number().positive(),
    start_degree_day: number().min(0, intl.formatMessage(validationMessages.minValue, {number: 0})),
    end_degree_day: number().min(0, intl.formatMessage(validationMessages.minValue, {number: 0})),
  });

export default AddPeriodSchema;
