import React from "react";
import styled from "./styled.module.scss";

interface ILine {
  title: string | React.ReactElement;
  value: string | number;
}

const Line = ({ title, value }: ILine) => {
  return (
    <li className={styled.li}>
      <span className={styled.text}>{title}</span>
      <span className={styled.doted} />
      <span className={styled.text}>{value}</span>
    </li>
  );
};

export default Line;
