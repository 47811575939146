import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getSteeringAlgorithms = ({ clientId, projectId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/steering_algorithms`,
  });

export const getSingleSteeringAlgorithm = ({
  clientId,
  projectId,
  steeringAlgorithmId,
}) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/steering_algorithms/${steeringAlgorithmId}`,
  });

export const createSteeringAlgorithm = ({ clientId, projectId, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/steering_algorithms`,
    data,
  });

export const copySteeringAlgorithm = ({
  clientId,
  projectId,
  steeringAlgorithmId,
}) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/steering_algorithms/${steeringAlgorithmId}/copy`,
  });

export const updateSteeringAlgorithm = ({
  clientId,
  projectId,
  steeringAlgorithmId,
  ...data
}) =>
  apiPATCH({
    url: `/clients/${clientId}/projects/${projectId}/steering_algorithms/${steeringAlgorithmId}`,
    data,
  });

export const deleteSteeringAlgorithm = ({
  clientId,
  projectId,
  steeringAlgorithmId,
}) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}/steering_algorithms/${steeringAlgorithmId}`,
  });
