import React from "react";
import Button from "components/common/button/Button";
import { Icon, ICON_NAMES_ENUM } from "components/common/icon/Icon";
import styled from "./LeaveModalWindow.module.scss";
import commonMessages from "components/common/CommonMessages";
import { FormattedMessage } from "react-intl";
import LeaveModalWindowMessage from "./LeaveModalWindowMessages";

import clsx from "clsx";

interface IDeleteTemplate {
  onDelete?: () => void;
  onClose?: () => void;
  isLoading?: boolean;
}

function DeleteTemplate({ isLoading, onDelete, onClose }: IDeleteTemplate) {
  return (
    <>
      <Icon name={ICON_NAMES_ENUM.warning} className={styled["ml-10px"]} />
      <h3 className={clsx(styled.title, styled["ml-10px"])}>
        <FormattedMessage {...LeaveModalWindowMessage.titleDelete} />
      </h3>
      <div className={styled.row}>
        <Button
          iconBefore={<Icon name={ICON_NAMES_ENUM.delete} />}
          variant="text"
          onClick={onDelete}
          className={styled["button-delete"]}
          isLoading={isLoading} disabled={isLoading}
        >
          <FormattedMessage {...commonMessages.delete} />
        </Button>
        <Button variant="text" onClick={onClose} isLoading={isLoading} disabled={isLoading}>
          <FormattedMessage {...commonMessages.cancel} />
        </Button>
      </div>
    </>
  );
}

export { DeleteTemplate };
