import React from "react";
import withAuthProtection from "hoc/authProtection";
import { Navigate } from "react-router-dom";
import { ROUTES } from "constants/routes";

const Main = () => {
  return <Navigate to={ROUTES.CLIENTS_LIST} />;
};

export default withAuthProtection(Main);
