import { useState } from "react";
import { ReactComponent as Arrow } from "assets/icons/arrow.svg";
import styled from "./styled.module.scss";
import clsx from "clsx";
import { Link } from "react-router-dom";
import { IAccordionItem } from "components/AccordionItem/AccordionItem";
import { ReactComponent as Batch } from "assets/icons/batch.svg";
import { ReactComponent as BatchResult } from "assets/icons/batch-result.svg";
import { ReactComponent as Simulation } from "assets/icons/simulation.svg";
import { ReactComponent as SimulationResult } from "assets/icons/simulation-result.svg";
import { ReactComponent as Crops } from "assets/icons/crops.svg";

interface ICustomItemDrawerFoldable {
  name: string;
  icon: "b" | "br" | "si" | "sr" | "c";
  to?: string;
  children: any;
}

const ItemDrawerFoldable = ({
  name,
  icon,
  children,
  to,
}: ICustomItemDrawerFoldable) => {
  const getIcon = (key: IAccordionItem["icon"]) => {
    switch (key) {
      case "b":
        return <Batch />;
      case "br":
        return <BatchResult />;
      case "si":
        return <Simulation />;
      case "sr":
        return <SimulationResult />;
      case "c":
        return <Crops />;
    }
  };

  const [isOpen, setIsOpen] = useState(true);

  const onToggle = () => setIsOpen(!isOpen);

  const Component = !!to ? Link : "span";

  return (
    <div>
      <div className={styled.container}>
        <button
          className={clsx(styled.toggle, {
            [styled.toggleOpen]: isOpen,
          })}
          type="button"
          {...(!!to && { onClick: onToggle })}
        >
          <Arrow />
        </button>
        {getIcon(icon)}
        <Component to={to as string} className={styled.label}>
          {name}
        </Component>
      </div>
      {isOpen ? <>{children}</> : <></>}
    </div>
  );
};

export default ItemDrawerFoldable;
