import enTranslationMessages from "./en.json";
import itTranslationMessages from "./it.json";
import frTranslationMessages from "./fr.json";
import { IntlProvider } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { getUserDataState } from "store/user/selectors";

export enum Languages {
  EN = "EN",
  IT = "IT",
  FR = "FR",
}

const formatTranslationMessages = (
  locale: Languages,
  messages: Record<string, string>,
): Record<string, string> => {
  const defaultLocale = Languages.EN;
  const messageKeys = Object.keys(messages);
  const defaultFormattedMessages = locale !== defaultLocale
    ? formatTranslationMessages(defaultLocale, enTranslationMessages)
    : {};

  return messageKeys.reduce((formattedMessages, key) => {
    const isDefaultFormattedMessage = !messages[key] && locale !== defaultLocale;
    const formattedMessage = isDefaultFormattedMessage
      ? defaultFormattedMessages[key]
      : messages[key];

    return Object.assign(formattedMessages, { [key]: formattedMessage });
  }, {});
};

// export default {
//   [Languages.EN] : formatTranslationMessages(Languages.EN, enTranslationMessages),
//   [Languages.IT] : formatTranslationMessages(Languages.IT, itTranslationMessages),
//   [Languages.FR] : formatTranslationMessages(Languages.FR, frTranslationMessages),
// };

const translations = {
  [Languages.EN] : formatTranslationMessages(Languages.EN, enTranslationMessages),
  [Languages.IT] : formatTranslationMessages(Languages.IT, itTranslationMessages),
  [Languages.FR] : formatTranslationMessages(Languages.FR, frTranslationMessages),
}


export const TranslationProvider = ({children}: any) => {
  const user = useSelector(getUserDataState);
  const locale =  Languages.EN;
  // const locale = user ? (user.language as Languages) : Languages.EN;

  return <IntlProvider locale={locale} messages={translations[locale]}>
      {children}
    </IntlProvider>
}



export default translations;
