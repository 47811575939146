import { object, string, array, number } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";
import steeringAlgorithmMessages from "pages/SteeringAlgorithmsCreate/SteeringAlgorithmsCreateMessages";

const SteeringAlgorithmSchema = (intl: IntlShape) =>
  object().shape({
    name: string()
      .max(50, intl.formatMessage(validationMessages.maxLength, { number: 50 }))
      .required(intl.formatMessage(validationMessages.required)),
    crop_id: number().moreThan(0, intl.formatMessage(validationMessages.required)),
    row: array().min(1, intl.formatMessage(validationMessages.required))
  });

export default SteeringAlgorithmSchema;
