import { useIntl } from "react-intl";
import SelectMessages from "./selectMessages";
import { UserRoles } from "components/SettingUserCard/SettingUserCard";

export function selectOptionsText() {
  const intl = useIntl();

  const datasetTypes = [
    {
      value: "weather",
      label: intl.formatMessage(SelectMessages.weather),
    },
    // {
    //   value: "production",
    //   label: intl.formatMessage(SelectMessages.production),
    // },
    {
      value: "ETP",
      label: intl.formatMessage(SelectMessages.etp),
    },
  ];

  const userRoles = [
    {
      value: UserRoles.USER,
      label: intl.formatMessage(SelectMessages.user),
    },
    {
      value: UserRoles.MANAGER,
      label: intl.formatMessage(SelectMessages.manager),
    },
    {
      value: UserRoles.ADMIN,
      label: intl.formatMessage(SelectMessages.admin),
    },
  ];

  const frequencyOptions = [
    {
      value: "5min",
      label: intl.formatMessage(SelectMessages.minutes, { value: 5 }),
    },
    {
      value: "15min",
      label: intl.formatMessage(SelectMessages.minutes, { value: 15 }),
    },
    {
      value: "30min",
      label: intl.formatMessage(SelectMessages.minutes, { value: 30 }),
    },
    {
      value: "1H",
      label: intl.formatMessage(SelectMessages.hour, { value: 1 }),
    },
    {
      value: "2H",
      label: intl.formatMessage(SelectMessages.hours, { value: 2 }),
    },
  ];

  const degreeDays = [
    { value: "Dates", label: intl.formatMessage(SelectMessages.dates) },
    {
      value: "Degree Days",
      label: intl.formatMessage(SelectMessages.degreeDays),
    },
  ];

  return { datasetTypes, frequencyOptions, degreeDays, userRoles };
}
