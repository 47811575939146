export const getInitials = (text: string) => {
  if (!text) return;
  if (text.includes(" ")) {
    const names = text.split(" ");
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[1].substring(0, 1).toUpperCase();
    }
    return initials;
  }

  return text.substring(0, 2).toUpperCase();
};
