import { apiGET, apiPOST, apiDELETE, apiPATCH } from "store/services/api";

export const getStructures = ({ clientId, projectId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/structures`,
  });

export const copyStructure = ({ clientId, projectId, structureId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/structures/${structureId}/copy`,
  });
export const copyStructureToOtherProject = ({ clientId, projectId, structureId, projectToId }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/structures/${structureId}/copy/${projectToId}`,
  });

export const getStructure = ({ clientId, projectId, structureId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/structures/${structureId}`,
  });

export const createStructure = ({ clientId, projectId, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/structures`,
    data,
  });

export const deleteStructure = ({ clientId, projectId, structureId }) =>
  apiDELETE({
    url: `/clients/${clientId}/projects/${projectId}/structures/${structureId}`,
  });

export const updateStructure = ({
  clientId,
  projectId,
  structureId,
  ...data
}) =>
  apiPATCH({
    url: `/clients/${clientId}/projects/${projectId}/structures/${structureId}`,
    data,
  });

export const getStructureImage = ({ clientId, projectId, structureId }) =>
  apiGET({
    url: `/clients/${clientId}/projects/${projectId}/structures/${structureId}/image`,
    responseType: "blob",
  }).then((r) => URL.createObjectURL(r));

export const drawStructureImage = ({ clientId, projectId, ...data }) =>
  apiPOST({
    url: `/clients/${clientId}/projects/${projectId}/structures/draw`,
    responseType: "blob",
    data,
  }).then((r) => URL.createObjectURL(r));

export const getStructureTemplate = () =>
  apiGET({
    url: `/extra/structure_template`,
  });