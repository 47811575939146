import { object, string } from "yup";
import { IntlShape } from "react-intl";
import validationMessages from "components/common/ValidationMessages";

const ClientSchema = (intl: IntlShape) =>
  object().shape({
    name: string()
      .max(50, intl.formatMessage(validationMessages.maxLength, { number: 50 }))
      .required(intl.formatMessage(validationMessages.required)),
    email: string()
      .email()
      .required(intl.formatMessage(validationMessages.required)),
    description: string().max(
      250,
      intl.formatMessage(validationMessages.maxLength, { number: 250 })
    ),
  });

export default ClientSchema;
