import { useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import commonMessages from "components/common/CommonMessages";
import Button from "components/common/button/Button";
import { ReactComponent as Save } from "assets/icons/save.svg";
import { ReactComponent as Delete } from "assets/icons/delete.svg";
import styled from "./styled.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "constants/routes";
import EditName from "components/EditName/EditName";
import { useDispatch, useSelector } from "react-redux";
import waterBalanceCreateMessages from "pages/WaterBalanceCreate/WaterBalanceCreateMessages";
import MainContent from "components/MainContent/MainContent";
import { FormikHelpers, useFormik } from "formik";
import {
  LeaveModalEnum,
  LeaveModalWindow,
} from "components/LeaveModalWindow/LeaveModalWindow";
import { usePrompt } from "hooks/usePromt";
import { isEqual } from "lodash";
import { useUpdateNavigation } from "hooks/useUpdateNavigation";
import WaterBalanceBody, {
  IWaterBalancesState,
} from "components/WaterBalanceBody/WaterBalanceBody";
import {
  getWaterBalanceItemState,
  getWaterBalancesLoadingState,
} from "store/water_balances/selectors";
import {
  deleteWaterBalance,
  getWaterBalance,
  runWaterBalance,
  updateWaterBalance,
} from "store/water_balances/actions";
import { getDefaultValues } from "pages/WaterBalanceCreate/WaterBalanceCreate";
import WaterBalanceSchema from "pages/WaterBalanceCreate/WaterBalanceSchema";
import SimulationCreateMessages from "pages/SimulationCreate/SimulationCreateMessages";
import { ReactComponent as Play } from "assets/icons/play.svg";
import CommonMessages from "components/common/CommonMessages";
import { successNotifications } from "utils/successNotifications";

const WaterBalanceEdit = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { client_id, project_id, id } = useParams();
  const water_balance = useSelector(getWaterBalanceItemState);
  const [isDeleteWarning, setIsDeleteWarning] = useState(false);
  const [isDisableLeaveWarning, setIsDisableLeaveWarning] = useState(false);
  const isLoading = useSelector(getWaterBalancesLoadingState);

  const simulationInitial = useMemo(() => {
    if (water_balance) {
      return {
        name: water_balance.name,
        resolution: water_balance.resolution,
        description: water_balance.description,
        frequency: water_balance.frequency,
        max_scale_value: water_balance.max_scale_value,
        enable_max_scale: water_balance.enable_max_scale,
        soil_information_id: water_balance.water_balance.id,
        structure_id: water_balance.structure.id,
        weather_dataset_id: water_balance.weather_dataset.id,
        production_dataset_id: water_balance.no_production_dataset
          ? null
          : water_balance.production_dataset.id,
        ETP_dataset_id: water_balance.ETP_dataset.id,
        universal_dataset_id: water_balance.universal_dataset
          ? water_balance.universal_dataset.id
          : null,
        crop_id: water_balance.crop_for_water_balance
          ? water_balance.crop_for_water_balance.id
          : null,
        steering_algorithm_id:
          water_balance.steering_algorithm_for_water_balance
            ? water_balance.steering_algorithm_for_water_balance.id
            : null,
        grassland_primary_yield_calculation:
          water_balance.grassland_primary_yield_calculation,
        edge_effect: water_balance.edge_effect,
      };
    }
    return null;
  }, [water_balance]);

  const onHideWarning = () => setIsDeleteWarning(false);

  const onShowWarning = () => setIsDeleteWarning(true);

  useEffect(() => {
    dispatch(
      getWaterBalance({
        clientId: client_id,
        projectId: project_id,
        waterBalanceId: id,
      })
    );
  }, [client_id, project_id, id]);

  const onBack = () =>
    navigate(
      ROUTES.WATER_BALANCES.replace(":client_id", client_id as string).replace(
        ":project_id",
        project_id as string
      )
    );

  const toResultPage = (result_id: string) =>
    navigate(
      ROUTES.WATER_BALANCES_RESULT.replace(":client_id", client_id as string)
        .replace(":project_id", project_id as string)
        .replace(":water_balance_id", id as string)
        .replace(":id", result_id)
    );

  const onDelete = () => {
    dispatch(
      deleteWaterBalance({
        clientId: client_id,
        projectId: project_id,
        waterBalanceId: id,
        callback: onBack,
      })
    );
  };

  const onUpdate = (
    values: IWaterBalancesState,
    actions: FormikHelpers<IWaterBalancesState>
  ) => {
    let temp = {
      ...values,
      water_balance_id: values.soil_information_id,
      crop_for_water_balance_id: values.crop_id,
      steering_algorithm_for_water_balance_id: values.steering_algorithm_id,
    };

    dispatch(
      updateWaterBalance({
        ...temp,
        clientId: client_id,
        projectId: project_id,
        waterBalanceId: id,
        callback: () => {
          successNotifications({
            title: intl.formatMessage(CommonMessages.edit),
            message: intl.formatMessage(CommonMessages.successEdit, {
              objet_type: intl.formatMessage(CommonMessages.waterBalance),
            }),
          });
          dispatch(
            getWaterBalance({
              clientId: client_id,
              projectId: project_id,
              waterBalanceId: id,
            })
          );
        },
        finallyCallback: () => actions.setSubmitting(false),
      })
    );
  };

  const formik = useFormik({
    initialValues: getDefaultValues(intl),
    onSubmit: onUpdate,
    validationSchema: WaterBalanceSchema(intl),
  });

  const isDataChanged = !isEqual(formik.values, simulationInitial);

  usePrompt(
    isDataChanged &&
      !formik.isSubmitting &&
      !isDeleteWarning &&
      !isDisableLeaveWarning
  );

  useEffect(() => {
    if (simulationInitial) {
      formik.setValues(simulationInitial);
    }
  }, [simulationInitial]);

  const runSimulate = () => {
    dispatch(
      runWaterBalance({
        clientId: client_id,
        projectId: project_id,
        waterBalanceId: id,
        callback: toResultPage,
      })
    );
  };

  const onRunSimulation = async () => {
    if (isDataChanged) {
      setIsDisableLeaveWarning(true);

      const objOut = {
        name: formik.values.name,
        description: formik.values.description,
        resolution: formik.values.resolution,
        frequency: formik.values.frequency,
        max_scale_value: formik.values.max_scale_value,
        enable_max_scale: formik.values.enable_max_scale,
        structure_id: formik.values.structure_id,
        weather_dataset_id: formik.values.weather_dataset_id,
        production_dataset_id: formik.values.production_dataset_id,
        ETP_dataset_id: formik.values.ETP_dataset_id,
        universal_dataset_id: formik.values.universal_dataset_id,
        water_balance_id: formik.values.soil_information_id,
        crop_id: formik.values.crop_id,
        crop_for_water_balance_id: formik.values.crop_id,
        steering_algorithm_for_water_balance_id:
          formik.values.steering_algorithm_id,
        grassland_primary_yield_calculation:
          formik.values.grassland_primary_yield_calculation,
      };

      dispatch(
        updateWaterBalance({
          ...objOut,
          clientId: client_id,
          projectId: project_id,
          waterBalanceId: id,
          callback: runSimulate,
        })
      );
    } else {
      if (!formik.values.crop_id) {
        formik.setFieldTouched("crop_id", true);
        formik.setFieldError("crop_id", "Error");
        document.getElementById("crop_id")!.scrollIntoView();
        return;
      }
      runSimulate();
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <MainContent
        header={
          <>
            <EditName
              onFocus={(event) => {
                event.target.select();
              }}
              placeholder={intl.formatMessage(
                waterBalanceCreateMessages.waterBalanceName
              )}
              onChange={formik.handleChange}
              name="name"
              onBlur={formik.handleBlur}
              value={formik.values.name}
              errorMessage={
                formik.touched.name ? (formik.errors.name as string) : ""
              }
            />

            <div className={styled.flex}>
              <Button
                disabled={isLoading}
                isLoading={isLoading}
                onClick={onRunSimulation}
                iconBefore={<Play />}
                className={styled.addPoint}
              >
                <FormattedMessage {...CommonMessages.run} />
              </Button>
              <Button
                variant="text"
                iconBefore={<Delete />}
                onClick={onShowWarning}
                className={styled.deleteButton}
              >
                <FormattedMessage {...commonMessages.delete} />
              </Button>

              <Button variant="text" onClick={onBack}>
                <FormattedMessage {...commonMessages.cancel} />
              </Button>

              <Button
                iconBefore={<Save />}
                type="submit"
                isLoading={formik.isSubmitting}
                disabled={formik.isSubmitting || !isDataChanged}
              >
                <FormattedMessage {...commonMessages.save} />
              </Button>
            </div>
          </>
        }
      >
        <WaterBalanceBody formik={formik} onRunSimulation={onRunSimulation} />
        <LeaveModalWindow
          type={LeaveModalEnum.DELETE}
          onDelete={onDelete}
          show={isDeleteWarning}
          onHide={onHideWarning}
        />
      </MainContent>
    </form>
  );
};

export default WaterBalanceEdit;

