import {
  getWaterBalance,
  getWaterBalances,
  createWaterBalance,
  updateWaterBalance,
  deleteWaterBalance,
  getWaterBalanceResult,
  runWaterBalance,
  resetWaterBalanceResult,
  copyWaterBalance,
  deleteWaterBalanceResult,
  resetWaterBalance,
  cancelWaterBalance,
} from "store/water_balances/actions";
import { logout } from "store/user/actions";

const INITIAL_STATE = {
  isLoading: false,
  list: [],
  result: null,
  single: null,
};

const waterBalancesReducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case logout.SUCCESS: {
      return {
        ...INITIAL_STATE,
      };
    }

    case getWaterBalances.TRIGGER:
    case runWaterBalance.TRIGGER: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case runWaterBalance.SUCCESS:
    case cancelWaterBalance.SUCCESS:
    case getWaterBalanceResult.SUCCESS: {
      return {
        ...state,
        result: payload,
      };
    }

    case resetWaterBalanceResult.TRIGGER: {
      return {
        ...state,
        result: INITIAL_STATE.result,
      };
    }

    case getWaterBalances.SUCCESS: {
      return {
        ...state,
        list: payload,
      };
    }

    case getWaterBalance.SUCCESS: {
      return {
        ...state,
        single: payload,
      };
    }

    case resetWaterBalance.TRIGGER: {
      return {
        ...state,
        single: INITIAL_STATE.single,
      };
    }

    case createWaterBalance.SUCCESS:
    case copyWaterBalance.SUCCESS: {
      return {
        ...state,
        list: [...state.list, payload],
      };
    }

    case deleteWaterBalance.SUCCESS: {
      const list = state.list.filter(({ id }) => id !== payload.id);

      return {
        ...state,
        list,
      };
    }

    case updateWaterBalance.SUCCESS: {
      const newList = [...state.list];
      const index = newList.findIndex(({ id }) => id === payload.id);
      newList[index] = payload;

      return {
        ...state,
        list: newList,
      };
    }

    case deleteWaterBalanceResult.SUCCESS: {
      return {
        ...state,
        result: null,
      };
    }

    case getWaterBalances.FULFILL:
    case runWaterBalance.FULFILL: {
      return {
        ...state,
        isLoading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default waterBalancesReducer;
